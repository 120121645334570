<div fxLayout="column" class="first-col"fxFlex="100" >
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Claim Request </mat-card-title>
              <div style="margin-right: 2%;" *ngIf="!selected_row">
                 <button mat-icon-button style="padding-right: 40px;" (click)="Add()"  matTooltip="Add New">
                      <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                    </button>
                 </div>
         </mat-card-header>

        <form [formGroup]="requestForm"   fxLayout="column" *ngIf="isAdd">
            <div  fxLayout="row" fxFlex="100%">
                <div  fxFlex="40%" class="mx-2 my-2" >
                    <mat-form-field  appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Reimbursement Type</mat-label>
                            <mat-select required formControlName="rbs_type_id">
                            <div *ngFor="let b of rbsData"> <mat-option [value]="b.id" >{{ b.rbs_type }}</mat-option> </div>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="requestForm.controls.rbs_type_id.errors?.required"> Please select Reimbursement Type </mat-error>
                    </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field  appearance="outline" aria-disabled="true"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Bill Date</mat-label>
                        <input matInput [matDatepicker]="bill_date"    [max]="minDate" placeholder="" formControlName="bill_date"  (keypress)="false" required>
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="bill_date"></mat-datepicker-toggle>
                        <mat-datepicker #bill_date></mat-datepicker>
                            <mat-error class="con-error" *ngIf="requestForm.controls.bill_date.errors?.required">
                                Please enter bill date
                            </mat-error>
                        </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field appearance="outline" aria-disabled="true"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Applied On</mat-label>
                        <input matInput [matDatepicker]="applied_on" placeholder="" formControlName="applied_on"  (keypress)="false" required>
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="applied_on"></mat-datepicker-toggle>
                        <mat-datepicker #applied_on></mat-datepicker>
                            <!-- <mat-error class="con-error" *ngIf="requestForm.controls.applied_on.errors?.required">
                                Please enter bill date
                            </mat-error> -->
                    </mat-form-field>
                </div>

            </div>
            <div  fxLayout="row" fxFlex="100%">
                <div fxFlex="40%" class="mx-2 my-2" >
                    <mat-form-field appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Bill Number </mat-label>
                        <input formControlName="bill_no"    matInput placeholder="" appRemoveSpace required maxlength="50">
                        <mat-error class="con-error" *ngIf="requestForm.controls.bill_no.errors?.required ||requestForm.controls.bill_no.errors?.['whitespace']">
                            Please enter bill number
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field  appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Bill Amount </mat-label>
                        <input formControlName="bill_amount"    type="number" matInput placeholder="" appRemoveSpace required maxlength="64">
                        <!-- <mat-error class="con-error" *ngIf="requestForm.controls.bill_amount.errors?.required || requestForm.controls.bill_amount.errors?.['whitespace'] || requestForm.controls.bill_amount.errors?.invalid">
                          Please enter a valid bill amount.
                        </mat-error> -->
                        <mat-error class="con-error" *ngIf="requestForm.controls.bill_amount.invalid && (requestForm.controls.bill_amount.dirty || requestForm.controls.bill_amount.touched)">
                          Please enter a valid bill amount.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2">
                    <mat-form-field [hidden]="!enable"  *ngIf="selected_row && selected_row.verified_by_id"  appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Approved Amount </mat-label>
                        <input formControlName="approved_amount" matInput placeholder="" >
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxFlex="100%">
                <div  fxFlex="70%" class="mx-2 my-2" >
                    <mat-form-field   appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Description</mat-label>
                        <textarea formControlName="notes" noLeadingWhitespace  matInput maxlength="250" placeholder="Description"></textarea>
                    <mat-error class="con-error" *ngIf="requestForm.controls.notes.errors?.required">
                        Please enter notes
                    </mat-error>
                    </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2"  >
                    <mat-form-field [hidden]="!enable" *ngIf="selected_row && selected_row.verified_by_id" appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Verify Comments</mat-label>
                        <textarea formControlName="verify_comments"  matInput  placeholder="Comments"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" style="align-items: center;" >
                <div *ngIf="!selected_row || selected_row.claim_file_id">
                    <span *ngIf="pdfName" class="no-wrap" style="margin-left: 7px;">Upload File:</span>&nbsp;
                    <button class="toolbar-button" ngClass.lt-md="mob_file" mat-button (click)="fileView();" class="link" style="font-size: 16px;text-align: start;" *ngIf="pdfName">
                    {{ pdfName }}
                    </button>
                    &nbsp;
                    <input *ngIf="!pdfName" style="margin-left: 0px" type="file" formControlName="document" (change)="onSelectFile($event)" single />  &nbsp;
                    <!-- <mat-icon class="toolbar-button" mat-button title="Edit" *ngIf="pdfName && !isRequestView" (click)="editdoc()">edit</mat-icon>  &nbsp; -->
                        <span>
                    <mat-icon title="Delete" *ngIf="requestForm.controls.document.value && iseditDoc && !enable"
                        (click)="deleteIcon()">delete</mat-icon>
                    </span>
                    &nbsp;
                </div>
            </div>
            <!-- <div  fxLayout="row" fxFlex="100%" style="padding-top: 1%;padding-left: 1%;">
                    <div style="padding-bottom: 1.34375em; margin-left: 10px">
                        <label style="margin-left: 10px">Supporting Document <mat-icon title="Only pdf file upto 1MB can be uploaded" class="iconstyle">info</mat-icon></label><br />
                        <button class="link" style="margin-left: 10px" (click)="fileView()" *ngIf="pdfName">{{ pdfName }} </button>
                        <input *ngIf="!pdfName" style="margin-left: 10px" type="file" formControlName="document"  (change)="onSelectFile($event)" required single accept="pdf" />
                        <img *ngIf="pdfName" class="img-fluid custom-image" style="width: 75%;"   [src]="'data:image/png;base64,' + base64textString">
                       <mat-icon title="Edit" *ngIf="pdfName" (click)="editdoc()">edit</mat-icon>
                        <mat-icon title="Delete" *ngIf="requestForm.controls.document.value" (click)="delete()">delete</mat-icon>
                        <mat-error style="margin-left: 10px" *ngIf="submitted && requestForm.controls.document.errors?.required" class="invalid-feedback">
                            error
                        </mat-error>
                    </div>
            </div>      -->
            <div >
                <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center"  style="padding-top: 1%;padding-left: 1%;">
                    <div fxLayoutAlign="center" *ngIf="isAdd">
                      <span *ngIf="!isView">
                        <button class="btn btn-primary mr-2 mb-2"  *ngIf="!isView && !selected_row || selected_row.is_employee_revisable"  (click)="AddData('Submitted')" type="submit"><span>{{selected_row && selected_row.claim_id?'Update':'Submit'}}</span></button>

                      </span>
                        <button class="btn btn-danger mr-2 mb-2"   (click)="cancel()"><span >Cancel</span></button>
                        <button class="btn btn-danger mr-2 mb-2" style="min-width:fit-content;"  *ngIf="!isView && selected_row && selected_row.is_employee_revisable"  (click)="deleteHolidayPopup('Deleted')"><span >Delete</span></button>
                        <br />
                    </div>
                </div>
            </div>
        </form>


    <!-- <mat-divider></mat-divider> -->
    <div fxLayout="column" *ngIf="isdata">
        <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
            <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
              <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
              </mat-form-field>
            </span>
          </div>
<form  autocomplete="off" >
        <div fxLayout="column" class="table-pad">
         <div class="mat-elevation-z1  example-container">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef style="text-align: right;">S.No </th>
                    <td mat-cell *matCellDef="let element; let i=index;" style="text-align: right;">
                        {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                </ng-container>
                <ng-container matColumnDef="applied_on">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">Applied On </th>
                    <td mat-cell *matCellDef="let row"> <span  style="text-align: center;">{{row.created_on | date: 'dd-MM-yyyy'}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="rbs_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Reimbursement Type  </th>
                    <td mat-cell *matCellDef="let row" > <span style="text-align: center;">{{row.reimbursement_type}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="bill_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">Bill Date  </th>
                    <td mat-cell *matCellDef="let row" > <span style="text-align: center;" >{{row.bill_date | date: 'dd-MM-yyyy'}} </span> </td>
                </ng-container>
                <!-- <ng-container matColumnDef="bill_no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Bill Number </th>
                    <td mat-cell *matCellDef="let row" > <span style="text-align: center;">{{row.bill_no}}</span> </td>
                </ng-container> -->

                <ng-container matColumnDef="bill_amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Bill Amount  </th>
                    <td mat-cell *matCellDef="let row"  style="text-align: right;"> <span style="text-align: right;"> {{row.bill_amount | currency:'INR'}} </span> </td>
                </ng-container>

                <ng-container matColumnDef="approved_amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Approved Amount  </th>
                    <td mat-cell *matCellDef="let row"  style="text-align: right;"> <span style="text-align: right;"> {{row.approved_amount?(row.approved_amount  | currency:'INR'):''}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="verified_by">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Verified By  </th>
                    <td mat-cell *matCellDef="let row"  style="text-align: center;"> <span style="text-align: center;"> {{row.verified_by}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="claim_status">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: center;"> Status  </th>
                    <td mat-cell *matCellDef="let row"> <span  style="white-space: nowrap;text-align: center;">{{row.claim_status}} </span> </td>
                </ng-container>


                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <span><button mat-icon-button ><mat-icon (click)="view(row)"  title="View">visibility</mat-icon> </button></span>
                        <span><button mat-icon-button  ><mat-icon (click)="edit(row)" title="Edit" *ngIf="row.is_employee_revisable">edit   </mat-icon> </button></span>

                     </td>
                </ng-container>
                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
    </div>
</form>
    </div>


</mat-card>
</div>


