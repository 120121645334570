<div fxLayout="column" class="first-col">
    <mat-card class="mat-elevation-z0">
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">IT Declaration Approvals</mat-card-title>
        </mat-card-header>

        <div *ngIf="displayTable">
            <div fxLayoutAlign="end" style="margin-top: 5px">
                <span style="padding-top: 5px; height: 70px" fxFlex="50" fxLayoutAlign="end">
                    <mat-form-field fxFlex="56" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput placeholder="" maxlength="100" />
                    </mat-form-field>
                </span>
            </div>
            <div fxLayout="column" fxLayout.xs="column">
                <div class="example-container mat-elevation-z1">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef>S.No</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef >  Date </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.createdOn | date:'dd-MM-yyyy' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="empid">
                            <th mat-header-cell *matHeaderCellDef > Employee ID </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.employeeCode }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="empname">
                            <th mat-header-cell *matHeaderCellDef > Employee name  </th>
                            <td mat-cell *matCellDef="let row" >
                                {{ row.empName }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef >Status</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.statusName }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="view">
                            <th mat-header-cell *matHeaderCellDef > View Details </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-icon style="cursor: pointer" title="View"
                                    (click)="openApprovalDetailsForm(row)">visibility</mat-icon>
                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="7" style="text-align: center">  No data found  </td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>

        <form [formGroup]="ITExcemptionForm" autocomplete="off" style="margin-top: 10px" *ngIf="displayForm">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
               
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Request Date</mat-label>
                    <input matInput formControlName="appliedDate" readonly />
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee ID</mat-label>
                    <input matInput formControlName="empCode" readonly />
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <input matInput formControlName="empName" readonly />
                </mat-form-field>

              
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
              
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Category</mat-label>
                    <input matInput formControlName="declaredAmount" readonly />
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Section</mat-label>
                    <input matInput formControlName="section" />
                </mat-form-field>

                              <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Receipt Number</mat-label>
                    <input matInput formControlName="receiptNumber" readonly />
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
              
               <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Submit Amount</mat-label>
                    <input matInput formControlName="submitAmount" readonly />
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Pending Amount</mat-label>
                    <input matInput formControlName="pendingAmount" readonly />
                </mat-form-field>
              </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                <mat-form-field fxFlex.xs="100" fxFlex="65.5" class="mx-2 my-2" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description" readonly></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" style="align-items: center;">
                <span *ngIf="pdfName" class="no-wrap" style="margin-left: 7px;">Upload File:</span>&nbsp;
                <button class="toolbar-button" ngClass.lt-md="mob_file" mat-button (click)="fileView();" class="link" style="font-size: 16px;text-align: start;" *ngIf="pdfName">
                   {{ pdfName }}
                </button>
                &nbsp;
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">

                 <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                     <mat-label>Approve Amount</mat-label>
                     <input matInput formControlName="approvedAmount" onlyNumber minlength="3" maxlength="6"/>
                     <mat-error class="con-error" *ngIf="ITExcemptionForm.controls.approvedAmount.errors?.required">
                        This field is required
                     </mat-error>
                 </mat-form-field>
 
             </div>
            <div fxLayoutAlign="center">
                <button *ngIf="!isApproved" class="btn btn-primary custom1 mr-2 mb-2" style="padding: 0 10px" (click)="approveSection()">
                    <span>Approve</span>
                </button>
                <button class="btn btn-danger custom1 mr-2 mb-2" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
        </form>


        <!-- <form *ngIf="displayAddForm" [formGroup]="employeeExemptionForm" autocomplete="off">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                <mat-card fxFlex="60" style="padding-left: 1%">
                    <div fxLayout="row" fxLayout.xs="column" style="padding-top: 1%">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Employee</mat-label>
                            <mat-select formControlName="employeeName">
                                <ng-container>
                                    <div *ngFor="let b of employeelist">
                                        <mat-option [value]="b.empid">{{
                                            b.empname }}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.employeeName.errors?.required">
                                Please select an option.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Category</mat-label>
                            <mat-select formControlName="category" [tabIndex]="1">
                                <ng-container>
                                    <div *ngFor="let b of categoryList">
                                        <mat-option [value]="b.categoryId" (click)="getIncomeTaxSections()">{{
                                            b.categoryName }}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.category.errors?.required">
                                Please select an option.
                            </mat-error>
                        </mat-form-field>


                    </div>

                    <div fxLayout="row" fxLayout.xs="column" style="padding-top: 1%">
                        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                            <mat-label>Select a section</mat-label>
                            <mat-select formControlName="section" [tabIndex]="1">
                                <ng-container>
                                    <div *ngFor="let b of sectionsList">
                                        <mat-option [value]="b.sectionId" (click)="
                        insertMaxExemAmount(
                          b.maxAmount,
                          b.mappingId,
                          b.description
                        )
                      ">{{ b.sectionName }}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.section.errors?.required">
                                Please select an option.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                            <mat-label>Max Excempted Amount</mat-label>
                            <input matInput formControlName="declaredAmount" readonly />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" style="padding-top: 1%">
                        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                            <mat-label>Pending Amount</mat-label>
                            <input matInput formControlName="pendingAmount" readonly />
                        </mat-form-field>
                        <mat-form-field fxFlex.xs="100" fxFlex="50" fxFlex.lt-lg="50" class="mx-2 my-2"
                            appearance="outline">
                            <mat-label>Receipt Number</mat-label>
                            <input matInput formControlName="receiptNumber" required />
                            <mat-error class="con-error" *ngIf="
                  employeeExemptionForm.controls.receiptNumber.errors?.required
                ">
                                This field is required.
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.xs="column" style="padding-top: 1%">
                        <mat-form-field fxFlex.xs="100" fxFlex="47" fxFlex.lt-lg="50" class="mx-2 my-2"
                            appearance="outline">
                            <mat-label>Submit Amount</mat-label>
                            <input matInput formControlName="submitAmount" required onlyNumber />
                            <mat-error class="con-error" *ngIf="
          employeeExemptionForm.controls.submitAmount.errors?.required
        ">
                                This field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column" fxLayout.xs="column" class="dev-Pad" style="padding-top: 5px; height: 70px">

                        <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description" required maxlength="250"></textarea>
                            <mat-error class="con-error" *ngIf="
                  employeeExemptionForm.controls.description.errors?.required
                ">
                                This field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <br />
                    <div fxLayout="row" fxLayout.xs="column" class="dev-Pad" style="padding-top: 5px; height: 70px">
                        <div style="padding-bottom: 1.34375em; margin-left: 10px">
                            <label style="margin-left: 10px"></label><br />
                            <button style="margin-left: 10px" (click)="fileView()" *ngIf="pdfName">
                                {{ pdfName }}
                            </button>
                            <input style="margin-left: 10px" type="file" formControlName="document"
                                (change)="onSelectFile($event)" required single accept="pdf" />
                            &nbsp;

                            <mat-icon title="Delete" (click)="delete()" *ngIf="
                  employeeExemptionForm.controls.document.value && !iseditDoc
                ">delete</mat-icon>
                            &nbsp;
                        </div>
                    </div>
                    <div fxLayoutAlign="center">
                        <button class="btn btn-primary mr-2 mb-2" style="padding: 0 10px" (click)="submitSection()">
                            <span>Submit</span>
                        </button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                            <span>Cancel</span>
                        </button>
                        <br />
                    </div>
                </mat-card>
                <mat-card fxFlex="40" style="padding-left: 1%; padding-top: 30px">
                    <div>{{ descriptionForDisplay }}</div>
                    <div style="padding-top: 20px" *ngIf="displayMaxLimit">
                        Max Limit is <b>{{ maximumLimit }}/-</b>
                    </div>
                </mat-card>
            </div>
        </form> -->
    </mat-card>
</div>