import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { ChartComponent } from 'angular2-chartjs';
import { AdminService } from '../../admin.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    // dateInput: 'MM-YYYY',
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-client-super-admin-dashboard',
  templateUrl: './client-super-admin-dashboard.component.html',
  styleUrls: ['./client-super-admin-dashboard.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ClientSuperAdminDashboardComponent implements OnInit {
  companyDBName: any = environment.dbName;
  pipe = new DatePipe('en-US');
  dashBoardForm: any = UntypedFormGroup;
  currentYear: any = new Date();
  companyName: any;
  minDate = new Date('2000/01/01');
  maxDate = new Date();

  @ViewChild(ChartComponent)
  chart!: ChartComponent;
  // location wise pie chart
  locPieChartType: any;
  locPieChartdata: any;
  locPieChartoptions: any = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black', // You can change the font color
        fontSize: 10 // You can change the font size as per your requirement
      }
    }
  };
  // attendance wise dounut chart
  attDonutChartType: any;
  attDonutChartdata: any;
  attDonutChartoptions: any = {
    responsive: true
  };
  // department wise leave line chart
  deptWiseLeavepieChartType: any;
  deptWiseLeavepieChartData: any;
  deptWiseLeavepieoptions: any = {
    responsive: true
  };
  deptWisePayrollMonthListdata: any;
  // shift by dept emps chart
  vbarChartType: any;
  vbarChartData: any;
  vbaroptions: any = {
    responsive: true
  };

  locByDeptEmpsdoughChartType: any;
  locByDeptEmpsdoughnutChartOptions: any = {
    responsive: true
  };
  locByDeptEmpsdoughnutChartData: any;
  // ----------
  newEmployeeStatusCount: any;
  exitEmployeeStatusCount: any;
  totalActiveEmployeeCount: any;
  totalLeaveCount: any;
  pendingLeaveCount: any;
  rejectedLeaveCount: any;
  shiftId: any;
  shiftDataList: any = [];

  allLocationsNameList: any = [];
  allLocationsTotalCount: any = [];
  emp_location_table: any = [];
  locationByDepartmentNameLis: any = [];
  locationByDepartmentCountLis: any = [];
  locationId: any = 0;
  emp_dept_table:any = [];
  shiftByDepartmentNameList: any = [];
  shiftByDepartmentCountList: any = [];

  attendanceTypeList: any = [];
  attendanceCountList: any = [];
  attd_table: any = [];
  departmentWiseLeaveNameList: any = [];
  departmentWiseLeaveCountList: any = [];
  displayCountTable:any = false;
  workLocationList: any = []
  allShiftList: any = []

  deptWisePayrollNameList: any = [];
  deptWisePayrollNameListdata: any = [];
  deptWisePayrollMonthList: any = [];
  deptWisePayrollSumList: any = [];
  deptWisePayrollYearList: any = [];
  deptWisePayrollDataset: any = [];
  deptWisePayrollbgColor: any[] = ["#118ab2", "#ffd166", "#06d6a0", "#28acaf", "#ef476f", "#ffa1b5",
    "#86c7f3", "#ffe29a", "#838c95", "#7FDBFF", "#a7c957", "#c385d8",];
  // bar chart
  deptWisePayrollBarChartType: any;
  deptWisePayrollBarChartOptions: any = {
    responsive: true
  };
  deptWisePayrollBarChartData: any;

  //   barChartOptions = {
  //     scaleShowVerticalLines: false,
  //     responsive: true
  //   };
  // barChartLabels :any= ['2012', '2013', '2014', '2015', '2016', '2017', '2018'];
  // barChartType :any= 'bar';
  // barChartLegend :any= true;
  // barChartData: ChartData<'bar'> = {
  //     labels: this.barChartLabels,
  //     datasets: [
  //   {
  //         data: [55, 60, 75, 82, 56, 62, 80],
  //         label: 'Company A',
  //         categoryPercentage: 1,
  //         backgroundColor: 'rgba(105,159,177,0.2)',
  //       borderColor: 'rgba(105,159,177,1)',
  //       },
  //       { data: [58, 55, 60, 79, 66, 57, 90],
  //         label: 'Company B',
  //         categoryPercentage: 1,
  //         backgroundColor: 'rgba(77,20,96,0.3)',
  //         borderColor: 'rgba(77,20,96,1)',
  //        }
  //     ]
  // };
  // location wise pie chart

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private LMS: CompanySettingService
  ) {
    this.companyName = sessionStorage.getItem('companyName');
    this.dashBoardForm = this.formBuilder.group(
      {
        location: ["",],
        shiftName: ["",],
        employeeStatusMonthDate: [new Date()],
        attendanceWiseDate: [new Date()],
        deptWiseleavesDate: [new Date()],
        leaveStatusDate: [new Date()],
      });
    this.getLocationWiseEmployeeCount();
    this.getWorkLocation();
    this.getNewAndExitEmployeeCountByMonth();
    this.dashBoardForm.get('employeeStatusMonthDate')?.valueChanges.subscribe((selectedValue: any) => {
      this.getNewAndExitEmployeeCountByMonth();
    })
    this.getPendingAndRejectedLeaves();
    this.getAttendanceEmployeesCountByDate();

    this.getDepartmentWiseLeavesCountByMonth();
    this.getActiveShiftList();
    this.getDepartmentWisePayrollListByMonth();
  }



  ngOnInit(): void {
    this.displayCountTable = true;
    this.dashBoardForm.controls.location.setValue(0);      
    this.getLocationIdByDeptWiseEmpList();
    this.dashBoardForm.get('location')?.valueChanges.subscribe((selectedValue: any) => {
      this.locationId = selectedValue;
      if (selectedValue != '' || selectedValue != null) {
        this.getLocationIdByDeptWiseEmpList();
      }
    })

    this.dashBoardForm.get('shiftName')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue != '' || selectedValue != null) {
        this.shiftId = selectedValue;
        this.getShiftIdByDeptEmploeeList();
      }
    })

    this.dashBoardForm.get('attendanceWiseDate')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue != '' || selectedValue != null) {
        this.getAttendanceEmployeesCountByDate();
      }
    })

    this.getLeavesTypesCountByMonth();
    this.dashBoardForm.get('leaveStatusDate')?.valueChanges.subscribe((selectedValue: any) => {
      this.getLeavesTypesCountByMonth();
    })

    this.dashBoardForm.get('deptWiseleavesDate')?.valueChanges.subscribe((selectedValue: any) => {
      this.departmentWiseLeaveNameList = [];
      this.departmentWiseLeaveCountList = [];
      this.getDepartmentWiseLeavesCountByMonth();
    })
  }
  getWorkLocation() {
    // this.companyService.getActiveBranchCities()
    // .subscribe((result: any) => {
    //   if (result.status) {
    //     this.workLocationList = JSON.parse(result.data);
    //     this.locationId = this.workLocationList.id;
    //     this.dashBoardForm.controls.location.setValue(this.workLocationList.id);
    //   }
    // })
    this.LMS.getactiveWorkLocation({ id: null }).subscribe((result) => {
      if (result.data.length > 0) {
        this.workLocationList = result.data;
       // this.locationId = result.data[0].id;
       // this.dashBoardForm.controls.location.setValue(result.data[0].id);
      }
    })
  }


  getLocationWiseEmployeeCount() {
    this.emp_location_table =[];
    let backgroundColors = ["#ffa1b5", "#86c7f3", "#ffe29a", "#3cb44b", "#6f42c1", "#f58231", "#01F9C6", "#a7c957", "#a2d5c6",
    "#077b8a", "#e52165", "#e6194B", "#ffe119", "#bfef45", "#42d4f4", "#4363d8", "#911eb4", "#5c3c92", "#f032e6", "#7FDBFF" ];
    let c = 0;
    this.adminService.getLocationWiseEmployeeCount().subscribe((res: any) => {
      console.log(res.data)
      if (res.status && res.data) {
        res.data.forEach((e: any) => {
          if (e.location == null) {
            this.allLocationsTotalCount = [];
          } else {
            e.color = backgroundColors[c++];
            this.allLocationsNameList.push(e.location);
            this.allLocationsTotalCount.push(e.count);
            this.emp_location_table.push(e);
          }
        });
      }
      this.locPieChartType = 'pie';
      this.locPieChartdata = {
        labels: this.allLocationsNameList,
        render: 'percentage',
        datasets: [
          {
            backgroundColor: backgroundColors,
            data: this.allLocationsTotalCount
          }
        ]
      }
      this.locPieChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.allLocationsNameList.length > 3 ? false : true,
          position: 'bottom',
        },

      }
    });
  }

  getAttendanceEmployeesCountByDate() {
    this.attendanceTypeList = [];
    this.attendanceCountList = [];
    this.attd_table = [];
    let date = this.pipe.transform(this.dashBoardForm.controls.attendanceWiseDate.value, 'yyyy-MM-dd');
    this.adminService.getAttendanceEmployeesCountByDate(date).subscribe((res: any) => {
      if (res.status && res.data) {
       let backgroundColors = ["rgb(76, 175, 80)", "rgb(41, 182, 246)", "rgb(255, 112, 67)", "rgb(186, 104, 200)", "blue", "#077b8a", "#e52165", "#e6194B",
       "#f58231", "#ffe119", "#bfef45", "#3cb44b", "#42d4f4", "#4363d8", "#911eb4", "#f032e6", "#7FDBFF"];
       let c = 0;
        this.attd_table = Object.keys(res.data[0]).map((key) => [key, res.data[0][key],backgroundColors[c++]]);
        Object.keys(res.data[0]).forEach((e: any) => {
          this.attendanceTypeList.push(e);
        })
        Object.values(res.data[0]).forEach((e: any) => {
           this.attendanceCountList.push(e);
        })
        this.attDonutChartType = 'doughnut';
        this.attDonutChartdata = {
          labels: this.attendanceTypeList,
          datasets: [
            {
              // a2d5c6
              backgroundColor: backgroundColors,
              data: this.attendanceCountList
            }
          ]
        }
        this.attDonutChartoptions = {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '70%',
          legend: { position: 'bottom' }

        }
      }
    });


  }

  getDepartmentWiseLeavesCountByMonth() {
    let date = this.pipe.transform(this.dashBoardForm.controls.deptWiseleavesDate.value, 'yyyy-MM-dd');
    this.adminService.getDepartmentWiseLeavesCountByMonth(date).subscribe((res: any) => {
      if (res.status && res.data) {
        res.data.forEach((e: any) => {
          if (e.deptname == null) {
            this.departmentWiseLeaveCountList = [];
          } else {
            this.departmentWiseLeaveNameList.push(e.deptname);
            this.departmentWiseLeaveCountList.push(e.count);
          }

        })
      }
      this.deptWiseLeavepieChartType = 'pie';
      this.deptWiseLeavepieChartData = {
        labels: this.departmentWiseLeaveNameList,
        datasets: [
          {

            data: this.departmentWiseLeaveCountList,
            backgroundColor: ["#22a7f0", "#ffe29a", "#838c95", "#7FDBFF", "#e52165", "#e6194B",
              "#f58231", "#ffe119", "#bfef45", "#3cb44b", "#42d4f4", "#4363d8", "#911eb4", "#f032e6", "#7FDBFF"],
          }
        ]
      }
      this.deptWiseLeavepieoptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' }
      }
    });
  }

  getActiveShiftList() {
    this.adminService.getAllShifts().subscribe((res) => {
      if (res.status && res.data.length > 0) {
        this.shiftDataList = res.data;
        this.shiftId = this.shiftDataList[0].shiftid;
        this.dashBoardForm.controls.shiftName.setValue(res.data[0].shiftid);
      }
    })
  }

  getShiftIdByDeptEmploeeList() {
    this.shiftByDepartmentNameList = [];
    this.shiftByDepartmentCountList = [];
    this.adminService.getDepartmentWiseEmployeeCountByShift(this.shiftId).subscribe((res: any) => {
      if (res.status && res.data) {
        res.data.forEach((e: any) => {
          if (e.deptname == null) {
            this.shiftByDepartmentCountList = [];
          }
          this.shiftByDepartmentNameList.push(e.deptname);
          this.shiftByDepartmentCountList.push(e.count);
        })
      }
      this.vbarChartType = 'horizontalBar';
      this.vbarChartData = {
        labels: this.shiftByDepartmentNameList,
        datasets: [
          {

            data: this.shiftByDepartmentCountList,
            backgroundColor: [
              '#5e569b',
              '#6495ED',
              '#40E0D0',
              '#FF7F50',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)', "#e52165", "#e6194B",
              "#f58231", "#ffe119", "#bfef45", "#3cb44b", "#42d4f4", "#4363d8", "#911eb4", "#f032e6", "#7FDBFF"
            ],
          }
        ]
      }
      this.vbaroptions = {
        indexAxis: 'y',
        legend: { display: false },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              suggestedMin: 3, //min
              suggestedMax: 100 //max
            }
          }]
        }
      }
    });
  }

  // --------
  getLocationIdByDeptWiseEmpList() {
    this.locationByDepartmentNameLis = [];
    this.locationByDepartmentCountLis = [];
    this.emp_dept_table = [];
    let backgroundColors = ["#118ab2", "#ffd166", "#06d6a0", "#28acaf", "#ef476f", "#e52165", "#e6194B",
    "#f58231", "#ffe119", "#bfef45", "#3cb44b", "#42d4f4", "#4363d8", "#911eb4", "#f032e6", "#7FDBFF"];
    let c = 0;
    this.adminService.getDepartmentWiseEmployeeCountByLocation(this.locationId).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        res.data.forEach((e: any) => {
          if (e.deptname == null) {
            this.locationByDepartmentCountLis = [];
          } else {
            this.locationByDepartmentNameLis.push(e.deptname);
            this.locationByDepartmentCountLis.push(e.count);
            e.color = backgroundColors[c++];
            this.emp_dept_table.push(e);
          }
        })
        this.locByDeptEmpsdoughChartType = 'doughnut';
        this.locByDeptEmpsdoughnutChartData = {
          labels: this.locationByDepartmentNameLis,
          datasets: [
            {
              backgroundColor: backgroundColors,
              //  backgroundColor: ["#0080ff", "#eda2f2", "#ffa69e", "#ff8fa3", "#ffb3c1"],
              data: this.locationByDepartmentCountLis
            }
          ]
        }
        this.locByDeptEmpsdoughnutChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '65%',
          legend: { position: 'bottom' }

        }
      } else {

      }
    });


  }
  // -----------------------
  date = new UntypedFormControl(moment());

  attendanceMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dashBoardForm.controls.attendanceWiseDate.setValue(ctrlValue);
    datepicker.close();
  }

  empStatusMonth(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dashBoardForm.controls.employeeStatusMonthDate.setValue(ctrlValue);
    datepicker.close();
  }

  leaveMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dashBoardForm.controls.leaveStatusDate.setValue(ctrlValue);
    datepicker.close();
  }

  deptLeaveMonth(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    //ctrlValue.year(normalizedMonthAndYear.year());
    ctrlValue.month(normalizedMonthAndYear.month());
    this.dashBoardForm.controls.deptWiseleavesDate.setValue(ctrlValue);
    datepicker.close();
  }

  getNewAndExitEmployeeCountByMonth() {
    let date = this.pipe.transform(this.dashBoardForm.controls.employeeStatusMonthDate.value, 'yyyy-MM-dd');
    this.adminService.getNewAndExitEmployeeCountByMonth(date).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.newEmployeeStatusCount = res.data[0].new_emp_count;
        this.exitEmployeeStatusCount = res.data[0].exit_emp_count;
        this.totalActiveEmployeeCount = res.data[0].total_emp_count;
      }
    });
  }
  /**Getting leave status data */
  getLeavesTypesCountByMonth() {
    let date = this.pipe.transform(this.dashBoardForm.controls.leaveStatusDate.value, 'yyyy-MM-dd');
    this.adminService.getLeavesTypesCountByMonth(date).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.totalLeaveCount = res.data[0].leave_count;
        // this.pendingLeaveCount = res.data[0].pending_count;
        // this.rejectedLeaveCount = res.data[0].rejected_count;
      }
    });
  }
  /**for pending and rejected leaves data */
  getPendingAndRejectedLeaves() {
    this.adminService.getPendingAndRejectedLeaves().subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        // this.totalLeaveCount = res.data[0].today_leave_count;
        this.pendingLeaveCount = res.data[0].pending_leaves;
        this.rejectedLeaveCount = res.data[0].rejected_leaves;
      }
    });
  }

  getDepartmentWisePayrollListByMonth() {
    let date = this.pipe.transform(this.currentYear, 'yyyy-MM-dd');
    this.adminService.getDepartmentWiseMonthlySalaries(date).subscribe((res: any) => {
      if (res.status && res.data) {
        res.data.forEach((e: any) => {

          if (this.deptWisePayrollDataset.length > 0) {
            let ismonththere = false;
            let indexvalue = 0;
            this.deptWisePayrollDataset.forEach((item: any, index: any) => {
              if (item.label == e.deptname) {
                item.data.push(e.sum);
                // let bgcolor=this.deptWisePayrollbgColor[index];
                // item.backgroundColor.push(bgcolor);
                ismonththere = true;
              }
              //  else{
              //   item.data.push(0);

              //  }

            });
            if (ismonththere == false) {
              let testarray = [e.sum];
              let bgcolor = [this.deptWisePayrollbgColor[0]];
              let object = { label: e.deptname, data: testarray, backgroundColor: [] };
              this.deptWisePayrollDataset.push(object);
            }
          } else {
            let testarray = [e.sum];
            let bgcolor = [this.deptWisePayrollbgColor[0]];
            let object = { label: e.deptname, data: testarray, backgroundColor: [] };
            this.deptWisePayrollDataset.push(object);
          }
          this.deptWisePayrollNameListdata.push(e.deptname);
          this.deptWisePayrollNameList = [];
          if (this.deptWisePayrollMonthList.length > 0) {
            let ismonththere = false;
            this.deptWisePayrollMonthList.forEach((t: any) => {
              if (t == e.MonthName) {
                ismonththere = true;
              }
            })
            if (ismonththere == false) {
              this.deptWisePayrollMonthList.push(e.MonthName);
            }

          } else {
            this.deptWisePayrollMonthList.push(e.MonthName);

          }

          this.deptWisePayrollSumList.push(e.sum);

          this.deptWisePayrollYearList.push(e.year);
          this.deptWisePayrollNameList = this.deptWisePayrollNameListdata.reduce((uniqArr: any, item: any) => {
            return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
          }
            , []
          );

          // this.deptWisePayrollMonthList = this.deptWisePayrollNameListdata.reduce((uniqArr:any, item:any) => {
          //   return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]}
          //   ,[]
          //   );
        })
        this.deptWisePayrollDataset.forEach((item: any, index: any) => {
          item.data.forEach((t: any) => {
            let bgcolor = this.deptWisePayrollbgColor[index + 1];
            item.backgroundColor.push(bgcolor);
          });

        });


      }
      this.deptWisePayrollBarChartType = 'bar';
      this.deptWisePayrollBarChartData = {
        labels: this.deptWisePayrollMonthList,
        datasets: this.deptWisePayrollDataset,

      }
      this.deptWisePayrollBarChartOptions = {
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 10000, //min
              // suggestedMax: 100 //max
            }
          }]
        }

      }
    });
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000); // 2 seconds
  }
}


