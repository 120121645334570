<div fxLayout="column" fxFill class="first-col">
    <form [formGroup]="errorMessagesForm" autocomplete="off">
        <mat-card fxFill>
            <mat-card-header>
                <mat-card-title> Message Master </mat-card-title>
            </mat-card-header>
            <div fxLayout="column">
                <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end" class="dev-Pad">
                    <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100%" appearance="outline">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" [tabIndex]="[1]"
                            (keyup.enter)="applyFilter($event)" placeholder="" #input>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="table-pad">
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table #table [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef><span style="text-wrap: nowrap;">S. No.</span></th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="code">Code</th>
                                <td mat-cell *matCellDef="let row">{{row.code}}</td>
                            </ng-container>
                            <ng-container matColumnDef="screenName">
                                <th mat-header-cell *matHeaderCellDef>Screen Name</th>
                                <td mat-cell *matCellDef="let row">{{row.screenname}}</td>
                            </ng-container>

                            <ng-container matColumnDef="message">
                                <th mat-header-cell *matHeaderCellDef>Message</th>
                                <td mat-cell *matCellDef="let row">
                                    <span *ngIf="enable != row.id">{{row.message}}&nbsp;</span>
                                    <span *ngIf="enable === row.id">
                                        <mat-form-field appearance="outline">
                                            <textarea minlength="10" formControlName="message" value={{row.message}}
                                                matInput placeholder="" maxlength="250">
                                </textarea>
                                            <mat-error class="con-error"
                                                *ngIf="errorMessagesForm.controls.message.errors?.required">
                                                {{requiredField}}
                                            </mat-error>
                                            <mat-error class="con-error"
                                                *ngIf="errorMessagesForm.controls.message.errors?.minlength">
                                                Please enter minimum 10 chars.
                                            </mat-error>
                                        </mat-form-field>

                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <!-- <td mat-cell *matCellDef="let row"> </td> -->
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <button mat-icon-button *ngIf="enable != row.id" (keyup.enter)="edit($event,row)">
                                        <mat-icon (click)="edit($event,row)" title="Edit" *ngIf="isadd">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="submit($event,row.code,errorMessagesForm.controls.message.value.trim(),row.screenname)">
                                        <mat-icon
                                            (click)="submit($event,row.code,errorMessagesForm.controls.message.value.trim(),row.screenname)"
                                            title="Save">save</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="canceledit($event,row.id)">
                                        <mat-icon (click)="canceledit($event,row.id)" title="Cancel">cancel</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row class="title" *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                            <tr mat-row *matRowDef="let row;columns:displayedColumns"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="5" style="text-align: center;">No data found</td>
                            </tr>

                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </div>
        </mat-card>
    </form>
</div>