<div fxLayout="column" class="first-col">
    <mat-card fxLayout="column">
        <mat-card-header class="headerbox">
            <mat-card-title class="title"> Pending Approvals </mat-card-title>
        </mat-card-header>

        <div fxLayout="column">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"
                style="padding-top: 5px;height: 70px;">
                <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                    <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                    <mat-label>Search </mat-label>
                    <input (keyup)="applyFilter($event)" [tabIndex]="1" matInput placeholder="" maxlength="100" />
                </mat-form-field>
            </div>
            <div fxLayout="column" class="table-pad">

                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>


                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap"> S. No. </th>
                            <td mat-cell *matCellDef="let row;let i = index;">{{i+1}} </td>
                        </ng-container>


                        <ng-container matColumnDef="worktype">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Work
                                Type </th>
                            <td mat-cell *matCellDef="let row"> {{row.worktype}}</td>
                        </ng-container>
                        <ng-container matColumnDef="raisedbyname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp.
                                Name </th>
                            <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                        </ng-container>
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Shift </th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.shift}}</td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="fromdate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> From Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.fromdate |
                                date:'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="todate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> To Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.todate |
                                date:'dd-MM-yyyy'}} </td>
                        </ng-container>
                        <!-- Color Column -->
                        <!-- <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Reason </th>
                    <td mat-cell *matCellDef="let row" style="width: 30%; word-break: break-all;padding-right: 20px;"> {{row.reason}} </td>
                </ng-container> -->
                        <!-- Color Column -->

                        <!-- <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.status">
                        {{row.status}}
                    </td>
                </ng-container> -->

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
                                <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="acceptApproval(row)">
                                    <mat-icon class="mr-1" title="Approve"
                                        (click)="acceptApproval(row)">check_circle_outline</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="rejectApproval(row)">
                                    <mat-icon class="mr-1" title="Reject"
                                        (click)="rejectApproval(row)">highlight_off</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="changeTab(row)">
                                    <mat-icon class="mr-1" title="View" (click)="changeTab(row)">visibility</mat-icon>
                                </button>


                            </td>

                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>