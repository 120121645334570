<div fxLayout="column" class="first-col">
  <mat-card>
    <form [formGroup]="inductionForm" autocomplete="off">
      <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title class="title"> Clients </mat-card-title>
     </mat-card-header>
     <div class="div-pad">
      

        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="space-between center">
            <div style="margin-bottom: 1%;padding-right: 2%;" fxLayout="row xs-column" *ngIf="arrayList.length > 0">
              <div style="padding-left: 15px;">
                
                <a title="PDF"> <img (click)="exportPDF()" src="./assets/images/icons/pdf.png"  width="20px" height="20px"></a>
        
                <span class="exlprint"> | </span>
                <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                </a>
              </div>
            </div >
            <span style="height: 70px;" fxFlex="50" fxLayoutAlign="end">
              <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
              </mat-form-field>
            </span>
          </div>
        
        <div fxLayout="column" class="table-pad">
          <div class="mat-elevation-z1 example-container ">
            <div  id="table" #table=''>
             <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef>S.No</th>
                  <td mat-cell *matCellDef="let element; let i=index;">
                      {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
              </ng-container>
  
                <!-- <ng-container matColumnDef="sid">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header >
                    Subscription ID	 </th>
                  <td mat-cell *matCellDef="let row" > {{ row.subscription }}
              </td>
                </ng-container> -->
  
                <ng-container matColumnDef="cname">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap">
                    Company Name</th>
                  <td mat-cell *matCellDef="let row" > {{ row.company_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="users">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header >
                    Users</th>
                  <td mat-cell *matCellDef="let row" > {{ row.user_count }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="modules">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header >
                    Modules</th>
                  <td mat-cell *matCellDef="let row" > {{ row.module_list }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="startdate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap">
                    Start Date</th>
                  <td mat-cell *matCellDef="let row" class="no-wrap"> {{ row.valid_from  | date:'dd-MM-yyyy'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastpaid">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap">
                    Last Paid</th>
                  <td mat-cell *matCellDef="let row" class="no-wrap"> {{ row.payment_date  | date:'dd-MM-yyyy'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap">
                    ₹ Amount</th>
                  <td mat-cell *matCellDef="let row" > {{ row.amount_paid }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header >
                    Status</th>
                  <td mat-cell *matCellDef="let row" > {{ row.status }}
                  </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="15" style="text-align: center;">No data found</td>
                </tr>
              </table>
            </div>
              <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
          </div>
         </div>
      </div>
    </div>
  
   </form>
  </mat-card>
  </div>
  