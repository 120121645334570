<div fxLayout="column" fxFill class="custom-pgr-height-auto">
  <mat-card fxFill>
    <mat-card-header class="headerbox">
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <span>
          <span (click)="Back()">
            <button mat-icon-button title="Back">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </span>
          <span class="title">Pay Slip</span>
        </span>
        <span (click)="Download()">
          <button mat-icon-button title="Download">
            <mat-icon>download</mat-icon>
          </button>
        </span>

      </div>
    </mat-card-header>
    <div id="payslip" #payslip style="padding: 10px 30px 10px;">
      <div class="flex-sb-container">
        <div class="fx-row">
          <img [src]="pic" style="width: 40px;float:right;" alt=""> &nbsp;
          <h2>{{companyName}}</h2>
        </div>

        <div class="fx-column pdr-2">
          <span><strong>{{companylocation}}</strong></span>
          <span><strong>{{companyCity}}</strong></span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <br>
      <div class="sub-hd">Payslip for the month of {{payperiod}} </div>
      <div class="flex-half-container">
        <div class="flex-half-row">
          <div style="font-size: medium;"><strong>Employee Pay Summary</strong> </div>
          <div class="flex-sb-row custom-investment-td">
            <div>Employee Name</div>
            <div>{{empname}}</div>
          </div>
          <div class="flex-sb-row custom-investment-td">
            <span>Designation</span>
            <span>{{designation}}</span>
          </div>
          <div class="flex-sb-row custom-investment-td">
            <span>Date of Joining</span>
            <span>{{dateofjoin | date}}</span>
          </div>
          <div class="flex-sb-row custom-investment-td">
            <span>Pay Period</span>
            <span>{{payperiod}}</span>
          </div>
          <div class="flex-sb-row custom-investment-td">
            <span>UAN Number</span>
            <span>{{uanumber}}</span>
          </div>
          <div class="flex-sb-row custom-investment-td">
            <span>ESI Number</span>
            <span>{{esi}}</span>
          </div>
          <div class="flex-sb-row custom-investment-td">
            <span>PAN Number</span>
            <span>{{panNumber}}</span>
          </div>

        </div>

        <div class="flex-half-row pdr-2">
          <div style="font-size: medium;"> <strong>Bank Details</strong> </div>

          <div class="flex-sb-row custom-investment-td">
            <span>Bank Name</span>
            <span>{{bankName}}</span>
          </div>

          <div class="flex-sb-row custom-investment-td">
            <span>Account Number</span>
            <span>{{accountNo}}</span>
          </div>

          <div class="flex-sb-row custom-investment-td">
            <span>IFSC Code</span>
            <span>{{ifsc}}</span>
          </div>
          <div fxLayout="column" style="margin-top: 10px;">
            <table>
              <thead>
                <tr>
                  <th scope="col" class="custom-investment-th" style="width:50%;font-size: medium;"><strong>Leave
                      Type</strong></th>
                  <th scope="col" class="custom-investment-th" style="float:right;font-size: medium;">
                    <strong>Balance</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of leavesdata;let i = index">
                  <th scope="row" class="custom-investment-td">{{item.component_name}}</th>
                  <td class="custom-investment-td" style="float:right;">{{item.data_value| number:'1.2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>
      <br>
      <div class="flex-half-container">

        <div class="flex-half-row">
          <table>
            <thead>
              <tr class="custom-investment-header">
                <th scope="col" class="custom-investment-th" style="width:50%;font-size: medium;"><strong>Gross
                    Earnings</strong></th>
                <th scope="col" class="custom-investment-th" style="float:right;font-size: medium;">
                  <strong>Amount</strong> <img src="./assets/img/currency-block.png"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of earningsdata;let i = index">
                <th scope="row" class="custom-investment-td">{{item.component_name}}</th>
                <td class="custom-investment-td" style="float:right;">{{item.data_value| number:'1.2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex-half-row pdr-2">
          <table>
            <thead>
              <tr class="custom-investment-header">
                <th scope="col" class="custom-investment-th" style="font-size: medium;"><strong>Deductions</strong></th>
                <th scope="col" class="custom-investment-th" style="float:right;font-size: medium;">
                  <strong>Amount</strong> <img src="./assets/img/currency-block.png"></th>
              </tr>

            </thead>
            <tbody>
              <tr *ngFor="let item of deductiondata;let i = index">
                <th scope="row" class="custom-investment-td">{{item.component_name}}</th>
                <td class="custom-investment-td" style="float:right;">{{item.data_value | number:'1.2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="flex-half-container">
        <div class="flex-half-row">
          <div class="flex-sb-row custom-investment-td">
            <div style="font-size: medium;"><strong>Gross Earnings</strong></div>
            <div><strong>{{grossEarnings}}</strong></div>
          </div>
        </div>

        <div class="flex-half-row pdr-2">
          <div class="flex-sb-row custom-investment-td">
            <span style="font-size: medium;"><strong>Total Deduction</strong></span>
            <span><strong>{{totalDeduction}}</strong></span>
          </div>
        </div>
      </div>


      <mat-divider></mat-divider>
      <br>
      <span style="font-size: medium;padding-left: 5px;"><strong>Net Pay</strong></span>
      <div class="flex-half-row">

        <div class="flex-sb-row custom-investment-td">
          <span>Actual Gross Earnings (Days-{{paiddays}})</span>
          <span class="pdr-2">{{actualGrossEarnings}}</span>
        </div>

        <div class="flex-sb-row custom-investment-td">
          <span>Total Deductions</span>
          <span class="pdr-2">{{totalDeduction}}</span>
        </div>

        <div class="flex-sb-row custom-investment-td">
          <span>LOP Amount (Days-{{lop}})</span>
          <span class="pdr-2">{{lopAmount}}</span>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div class="flex-sb-row sub-hd2">
        <span>Total</span>
        <span class="pdr-2">{{netamount}}</span>
      </div>

      <mat-divider></mat-divider>
      <section class="total">
        <h3>Total Net Payable ₹ {{netamount}} ( {{words}})</h3>
        <p>** Total Net Payable = Actual Gross Earnings - (Total Deductions + LOP Amount) **</p>
        <p>-- This is a system generated payslip, hence signature is not required --</p>
      </section>

    </div>
  </mat-card>
</div>
