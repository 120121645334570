<form *ngIf="showDepartmentForm" [formGroup]="Form" autocomplete="off">
    <div fxLayout="column" fxLayout.xs="column">
        <div fxLayout="row" fxLayoutAlign="center" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">

            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                <mat-label>Department</mat-label>
                <input formControlName="department" matInput placeholder="" noLeadingWhitespace required maxlength="45">
                <!-- <mat-error class="con-error" *ngIf="departmentForm.controls.department.errors?.cannotContainSpace">
            Please enter valid data
        </mat-error> -->
                <mat-error class="con-error" *ngIf="Form.controls.department.errors?.required">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="center">
            <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" (click)="setdepartment()" type="submit">
                <span>Submit</span>
            </button>

            <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                <span>Cancel</span>
            </button>
            <br />
        </div>


    </div>

</form>
<form *ngIf="!showDepartmentForm" [formGroup]="designationForm" autocomplete="off">
    <div fxLayout="column" fxLayout.xs="column">
        <div fxLayout="row" fxLayoutAlign="center" fxLayout.xs="row" style="padding-top: 1%; padding-left: 1%">


            <mat-form-field *ngIf="enableDepartmentMapping" fxFlex.xs="100" fxFlex class="mx-2 my-2"
                appearance="outline">
                <mat-label>Department</mat-label>
                <mat-select formControlName="department" [disabled]="true">
                    <mat-option *ngFor="let b of departmentData" [value]="b.id">{{ b.deptname }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                <mat-label>Designation</mat-label>
                <input formControlName="designation" matInput noLeadingWhitespace required maxlength="64" />
                <mat-error class="con-error" *ngIf="designationForm.controls.designation.errors?.required">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="center">
            <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" (click)="setdesignations()" type="submit">
                <span>Submit</span>
            </button>

            <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                <span>Cancel</span>
            </button>
            <br />
        </div>
    </div>
</form>