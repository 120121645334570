import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { PayrollService } from 'src/app/modules/payroll/payroll.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {
  bankForm!: UntypedFormGroup;
  bankData: any = [];
  CountryDetails: any = [];
  stateDetails: any = [];
  cityDetails: any = [];
  arrayValue: any = [
    { id: 'Active', value: 'Active' },
    { id: 'Inactive', value: 'Inactive' }
  ]
  companyDBName: any = environment.dbName;
  pageLoading = true;
  isAdd: boolean = false;
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['sno', 'bankName', 'ifsc', 'accountNumber', 'status', 'action'];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  msgEM1: any;
  msgEM2: any;
  messagesDataList: any = [];
  epfchecked = false;
  esichecked = false;
  ptchecked = false;
  isEdit = false;
  btnDisable: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private LMS: CompanySettingService,
    private payrollService: PayrollService, private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.getCountry();
    this.bankForm = this.formBuilder.group(
      {
        id: [""],
        bankName: ["", Validators.required],
        ifsc: ["", Validators.required],
        accountNumber: ["", [Validators.required]],
        address1: ["", [Validators.required]],
        address2: [""],
        city: ["", Validators.required],
        state: ["", Validators.required],
        country: ["", [Validators.required]],
        status: ["", [Validators.required]],
        epf: [""],
        esi: [""],
        ptax: [""],
      })
    this.bankForm
      .get('country')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.stateDetails = [];
        this.LMS.getStatesc(selectedValue).subscribe((data) => {
          this.stateDetails = data.data;
          if (this.bankData != null) {
            this.bankForm.controls.state.setValue(
              this.bankData.state
            );

          }
        });
      });
    /**get city details for residance address */
    this.bankForm.get('state')?.valueChanges.subscribe((selectedValue: any) => {
      this.bankForm.controls.city.setValue('');
      this.cityDetails = [];
      this.LMS.getCities(selectedValue).subscribe((data) => {
        this.cityDetails = data.data;
        if (this.bankData != null && selectedValue == this.bankData.state) {
          this.bankForm.controls.city.setValue(this.bankData.city);
        }
      });
    });
    this.getBankDetails();
    this.getMessages();
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.payrollService.getErrorMessages(null, 1, 1000).subscribe((result: any) => {
      if (result.status) {
        this.messagesDataList = result.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "PR1") {
            this.msgEM1 = e.message;
          }
          else
            if (e.code == "PR30") {
              this.msgEM2 = e.message;
            }
        })
      }
    }
    )
  }

  getCountry() {
    this.LMS.getCountry('countrymaster', null, 1, 10, this.companyDBName).subscribe((results) => {
      this.CountryDetails = results.data;

    })
  }
  getBankDetails() {
    this.dataSource = new MatTableDataSource();
    this.payrollService.getBankAccounts({}).subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading = false;
    });


  }
  submit() {

    if (this.bankForm.valid) {
      if (this.btnDisable) {
        return;
      }
      let data = {

        id_value: this.bankForm.controls.id.value,
        bankname_value: this.bankForm.controls.bankName.value,
        ifsccode_value: this.bankForm.controls.ifsc.value.toUpperCase(),
        address1_value: this.bankForm.controls.address1.value,
        address2_value: this.bankForm.controls.address2.value,
        city_value: this.bankForm.controls.city.value,
        state_value: this.bankForm.controls.state.value,
        country_value: this.bankForm.controls.country.value,
        accountnumber_value: this.bankForm.controls.accountNumber.value,
        status_value: this.bankForm.controls.status.value,
        is_default_to_epf_payment: this.bankForm.controls.epf.value,
        is_default_to_esi_payment: this.bankForm.controls.esi.value,
        is_default_to_pt_payment: this.bankForm.controls.ptax.value,
      }
      this.btnDisable = true;
      this.payrollService.setBankAccountsMaster(data).subscribe((result: any) => {
        let resMessage = result.data.statuscode === 0 ? 'Bank details saved successfully.' : result.data.statuscode === 1 ? 'Bank details updated successfully.' : result.data.statuscode === 2 ? 'Data already exist' : 'Unable to save data'

        if (result.status) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Payroll/BankDetailsMaster"]));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: resMessage
          });
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to submit bank detail request.'
          });
          this.btnDisable = false;
        }
      });

    }
  }

  edit(data: any) {
    this.bankData = data;
    this.isAdd = true;
    this.isEdit = true;
    this.bankForm.controls.id.setValue(data.id);
    this.bankForm.controls.bankName.setValue(data.bankname);
    this.bankForm.controls.ifsc.setValue(data.ifsccode);
    this.bankForm.controls.address1.setValue(data.address1);
    this.bankForm.controls.address2.setValue(data.address2);
    this.bankForm.controls.country.setValue(data.country);
    this.bankForm.controls.state.setValue(data.state);
    // this.bankForm.controls.city.setValue(data.city);
    this.bankForm.controls.accountNumber.setValue(data.accountnumber);
    this.bankForm.controls.status.setValue(data.status);
    if (data.is_this_default_bank_to_epf_payment == "1") {
      this.epfchecked = true
    }
    if (data.is_this_default_bank_to_esi_payment == "1") {
      this.esichecked = true
    }
    if (data.is_this_default_bank_to_pt_payment == "1") {
      this.ptchecked = true
    }
  }
  add() {
    this.isAdd = true;
  }
  cancel() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/BankDetailsMaster']));
  }
  epfchange() {
    if (this.epfchecked == true) {
      this.bankForm.controls.epf.setValue(1);
    } else {
      this.bankForm.controls.epf.setValue(0);
    }
  }
  esichange() {
    if (this.esichecked == true) {
      this.bankForm.controls.esi.setValue(1);
    } else {
      this.bankForm.controls.esi.setValue(0);
    }
  }
  ptchange() {
    if (this.ptchecked == true) {
      this.bankForm.controls.ptax.setValue(1);
    } else {
      this.bankForm.controls.ptax.setValue(0);
    }
  }

}
