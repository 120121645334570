import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from 'src/app/pages/main/main.component';
import { LMSAccessGuard } from '../../LMS-access.guard';
import { ChangePasswordComponent } from '../../pages/change-password/change-password.component';
import { FinanceDashboardComponent } from './pages/finance-dashboard/finance-dashboard.component';
import { InvestmentProofComponent } from './pages/investment-proof/investment-proof.component';
import { InvestmentProofRequestComponent } from './pages/investment-proof-request/investment-proof-request.component';
import { ProfessionalTaxComponent } from './pages/professional-tax/professional-tax.component';
import { PayGroupComponent } from './pages/pay-group/pay-group.component';
import { PayGroupRequestComponent } from './pages/pay-group-request/pay-group-request.component';
import { ProfessionalTaxRequestComponent } from './pages/professional-tax-request/professional-tax-request.component';
import { SalaryMasterComponent } from './pages/salary-master/salary-master.component';
import { PayScheduleComponent } from './pages/pay-schedule/pay-schedule.component';
import { PayScheduleRequestComponent } from './pages/pay-schedule-request/pay-schedule-request.component';
import { EarningsComponent } from './pages/earnings/earnings.component';
import { EarningsRequestComponent } from './pages/earnings-request/earnings-request.component';
import { EmployeeItDeclarationsComponent } from './pages/employee-it-declarations/employee-it-declarations.component';
import { PaySlipsComponent } from './pages/pay-slips/pay-slips.component';
import { PaySlipsViewComponent } from './pages/pay-slips-view/pay-slips-view.component';
import { EmployeeForm16Component } from './pages/employee-form16/employee-form16.component';
import { FinanceForm16Component } from './pages/finance-form16/finance-form16.component';
import { EmployeeDashboardComponent } from './pages/employee-dashboard/employee-dashboard.component';
import { EsiComponent } from './pages/esi/esi.component';
import { EpfComponent } from './pages/epf/epf.component';
import { OrganizationDetailsComponent } from './pages/organization-details/organization-details.component';
import { ProfessionalTaxMainComponent } from './pages/professional-tax-main/professional-tax-main.component';
import { AssignPayGroupComponent } from './pages/assign-pay-group/assign-pay-group.component';
import { MonthlyPayrollComponent } from './pages/monthly-payroll/monthly-payroll.component';
import { EpfReportsComponent } from './pages/epf-reports/epf-reports.component';
import { PftaxReportsComponent } from './pages/pftax-reports/pftax-reports.component';
import { EsiReportsComponent } from './pages/esi-reports/esi-reports.component';
import { MonthlyPayrollReportComponent } from './pages/monthly-payroll-report/monthly-payroll-report.component';
import { ReAssignPaygroupComponent } from './pages/re-assign-paygroup/re-assign-paygroup.component';
import { AdminPayrollReportComponent } from './pages/admin-payroll-report/admin-payroll-report.component';
import { LeaveBalanceReportManagerComponent } from './pages/leave-balance-report-manager/leave-balance-report-manager.component';
import { AdvanceSalaryApprovalComponent } from './pages/advance-salary-approval/advance-salary-approval.component';
import { AdvanceSalaryRequestComponent } from './pages/advance-salary-request/advance-salary-request.component';
import { OtherDeductionComponent } from './pages/other-deduction/other-deduction.component';
import { PaygroupOtherdeductionMappingComponent } from './pages/paygroup-otherdeduction-mapping/paygroup-otherdeduction-mapping.component';
import { ComponetWisePayrollReportComponent } from './pages/componet-wise-payroll-report/componet-wise-payroll-report.component';
import { BankDetailsComponent } from './pages/bank-details/bank-details.component';
import { BankWiseSalarySummaryComponent } from './pages/bank-wise-salary-summary/bank-wise-salary-summary.component';
import { EmployeePayGroupsComponent } from './pages/employee-pay-groups/employee-pay-groups.component';
import { IncomeTaxExemptionComponent } from './pages/income-tax-exemption-for-employee/income-tax-exemption.component';
import { EmployeeTaxRegimeSelectionComponent } from './pages/employee-tax-regime-selection/employee-tax-regime-selection.component';
import { OverTimeConfigureComponent } from './pages/over-time-configure/over-time-configure.component';
import { PayrollPoliciesComponent } from '../admin/pages/payroll-policies/payroll-policies.component';
import { PayrollFinalSettlementComponent } from './pages/payroll-final-settlement/payroll-final-settlement/payroll-final-settlement.component';
import { ITExemptionApprovalsComponent } from './pages/tax-exemption-approvals/it-exemption-approvals/it-exemption-approvals.component';
import { TaxExemptionBehalfEmployeeComponent } from './pages/tax-exemption-behalf-employee/tax-exemption-behalf-employee.component';
import { VariableDaConfigurationMasterComponent } from '../admin/subscription/variable-da-configuration-master/variable-da-configuration-master.component';
import { AdvanceSalaryReportComponent } from './pages/advance-salary-report/advance-salary-report.component';
import { PayableDaysReportComponent } from './reports/payable-days-report/payable-days-report.component';
import { VariablePayDistributionComponent } from './pages/variable-pay-distribution/variable-pay-distribution.component';
import { VariablePayScheduleComponent } from './pages/variable-pay-schedule/variable-pay-schedule.component';
import { TDSDistributionComponent } from './pages/tds-distribution/tds-distribution.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'FinanceDashboard',
        component: FinanceDashboardComponent,
        canActivate: [LMSAccessGuard],
      },
      { path: 'InvestmentProof', component: InvestmentProofComponent },
      {
        path: 'ITDeclaration',
        component: InvestmentProofRequestComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'ProfessionalTax',
        component: ProfessionalTaxComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'PayGroup',
        component: PayGroupComponent,
        canActivate: [LMSAccessGuard],
      },
      { path: 'PayGroupRequest', component: PayGroupRequestComponent },
      {
        path: 'ProfessionalTaxRequest',
        component: ProfessionalTaxRequestComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'SalaryMaster',
        component: SalaryMasterComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'PaySchedule',
        component: PayScheduleComponent,
        canActivate: [LMSAccessGuard],
      },
      { path: 'PayScheduleRequest', component: PayScheduleRequestComponent },
      { path: 'Earnings', component: EarningsComponent },
      { path: 'EarningsRequest', component: EarningsRequestComponent },
      {
        path: 'EmployeeITDeclaration',
        component: IncomeTaxExemptionComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'PaySlips',
        component: PaySlipsComponent,
        canActivate: [LMSAccessGuard],
      },
      { path: 'PaySlipsView', component: PaySlipsViewComponent },
      {
        path: 'EmployeeForm16',
        component: EmployeeForm16Component,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'FinanceForm16',
        component: FinanceForm16Component,
        canActivate: [LMSAccessGuard],
      },
      { path: 'ESI', component: EsiComponent, canActivate: [LMSAccessGuard] },
      { path: 'EPF', component: EpfComponent, canActivate: [LMSAccessGuard] },
      {
        path: 'EmployeeDashboard',
        component: EmployeeDashboardComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'ChangePassword',
        component: ChangePasswordComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'OrganizationTaxDetails',
        component: OrganizationDetailsComponent,
      },
      {
        path: 'ProfessionalTaxMain',
        component: ProfessionalTaxMainComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'AssignPayGroup',
        component: AssignPayGroupComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'MonthlyPayroll',
        component: MonthlyPayrollComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'EpfReport',
        component: EpfReportsComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'EsiReport',
        component: EsiReportsComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'ProfessionaTaxReport',
        component: PftaxReportsComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'reAssignPaygroup',
        component: ReAssignPaygroupComponent,
        canActivate: [LMSAccessGuard],
      },
      { path: 'adminPayrollReport', component: AdminPayrollReportComponent },
      {
        path: 'leaveBalanceReport',
        component: LeaveBalanceReportManagerComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'advanceSalaryApproval',
        component: AdvanceSalaryApprovalComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'advanceSalaryRequest',
        component: AdvanceSalaryRequestComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'Other-Deductions',
        component: OtherDeductionComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'paygroup-mapping',
        component: PaygroupOtherdeductionMappingComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'componentWisePayrollReport',
        component: ComponetWisePayrollReportComponent,
      },
      { path: 'BankDetailsMaster', component: BankDetailsComponent },
      {
        path: 'BankwiseSalarySummary',
        component: BankWiseSalarySummaryComponent,
      },
      { path: 'EmployeePayGroups', component: EmployeePayGroupsComponent },
      // { path: 'incomeTaxExemption', component: IncomeTaxExemptionComponent },
      {
        path: 'employeeTaxRegimeSelection',
        component: EmployeeTaxRegimeSelectionComponent,
      },
      { path: 'overTimeConfigure', component: OverTimeConfigureComponent },
      { path: 'payrollPolicies', component: PayrollPoliciesComponent },
      {
        path: 'payrollFinalSettlement',
        component: PayrollFinalSettlementComponent,
      },
      {
        path: 'ITexcemptionApprovals',
        component: ITExemptionApprovalsComponent,
      },
      {
        path: 'ITexcemptionBehalfEmployee',
        component: TaxExemptionBehalfEmployeeComponent,
      },
      { path: 'variableDaConfiguration', component: VariableDaConfigurationMasterComponent },

      { path: 'advanceSalaryReport', component: AdvanceSalaryReportComponent },

      { path: 'payabledaysreport', component: PayableDaysReportComponent },

      { path: 'variablePayDistribute', component: VariablePayDistributionComponent },
      
      { path: 'variablePaySchedule', component: VariablePayScheduleComponent },
      
      {path:"TDSDistribution",component:TDSDistributionComponent}


    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PayrollRoutingModule { }
