<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">
                Reports User Configuration
            </mat-card-title>
        </mat-card-header>
        <div fxLayout="column">
            <form [formGroup]="ReportUserForm" autocomplete="off" style="padding-left: 1%;padding-top: 1%;">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
                    <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline"
                        style="height: 60px">
                        <mat-label>Employee Name</mat-label>
                        <mat-select placeholder="" name="reportUserName" formControlName="reportUser" required>
                            <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                                class="search-align">
                            <mat-option *ngFor="let res of filteredEmpList" [value]="res.empid">
                                {{res.employee}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="ReportUserForm.controls.reportUser.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>City-Branch </mat-label>
                        <mat-select placeholder="" name="companylocation" formControlName="companylocation" required
                            multiple #locationSelect>
                            <button mat-raised-button class="mat-primary fill text-sm"
                                (click)="locationSelectAll(locationSelect, worklocationDetails, ReportUserForm.companylocation)"
                                *ngIf="!locationIshide">Select All
                            </button>
                            <button mat-raised-button class="mat-accent fill text-sm"
                                (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                                Deselect All
                            </button>
                            <input matInput [formControl]="searchControlforLocations" type="text" placeholder="  Search"
                                class="search-align">
                            <mat-option *ngFor="let res of filteredLocations" [value]="res"
                                (click)="onchangeLocation(res)">
                                {{res.cityname}}-{{res.location}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="ReportUserForm.controls.companylocation.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" type="submit" (click)="Submit()">
                        <span>Save</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </form>
        </div>
        <div fxLayout="column" class="tableP" style="margin-top: 20px">

            <div class="example-container mat-elevation-z1">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> <span style="text-wrap: nowrap;">S. No.</span></th>
                        <td mat-cell *matCellDef="let element; let i=index;">
                            {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                    </ng-container>

                    <ng-container matColumnDef="employeeName">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> Emp. Name</th>
                        <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.empname }}</td>
                    </ng-container>

                    <ng-container matColumnDef="Locations">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap">Branches</th>
                        <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.locationsdata}} </td>
                    </ng-container>

                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>

        </div>
    </mat-card>
</div>