<div fxLayout="column" class="first-col">
  <mat-card style="padding-top: 15px;" fxLayout="column">
    <mat-card-header>
      <mat-card-title style="text-align: center"> Pending Requests</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>

    <div class="mat-elevation-z1" style="margin-top: 2%;margin-left:15px;margin-bottom:20px; margin-right:10px;">
      <mat-card-content style="width:100%;overflow-x: auto">

        <table mat-table [dataSource]="dataSource" matSort>

          <!-- ID Column -->
          <ng-container matColumnDef="appliedOn">
            <th mat-header-cell *matHeaderCellDef class="no-wrap"> Applied On</th>
            <td mat-cell *matCellDef="let row">{{row.appliedon | date:'dd-MM-yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="empId">
            <th mat-header-cell *matHeaderCellDef class="no-wrap"> Emp. ID</th>
            <td mat-cell *matCellDef="let row"> {{row.employee_id}}</td>
          </ng-container>
          <ng-container matColumnDef="empName" class="no-wrap">
            <th mat-header-cell *matHeaderCellDef class="no-wrap"> Emp. Name </th>
            <td mat-cell *matCellDef="let row"> {{row.emp_name}}</td>
          </ng-container>
          <!-- Progress Column  empid-->
          <ng-container matColumnDef="leaveType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap"> Leave Type</th>
            <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.display_name}}</td>
          </ng-container>
          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef> From </th>
            <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.fromdate | date:'dd-MM-yyyy'}}</td>
          </ng-container>
          <ng-container matColumnDef="toDate">
            <th mat-header-cell *matHeaderCellDef> To </th>
            <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.todate | date:'dd-MM-yyyy'}}</td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="noOfDays">
            <th mat-header-cell *matHeaderCellDef>Days </th>
            <td mat-cell *matCellDef="let row" style="text-align: center;">
              <span *ngIf="row.same_ltype_count == null && row.lop_count != null">{{row.leavecount}} <span
                  style="color: red;"> + {{row.lop_count}} </span></span>
              <span *ngIf="row.same_ltype_count == null && row.lop_count == null">{{row.leavecount}}</span>
              <span *ngIf="row.same_ltype_count != null && row.lop_count == null">{{row.leavecount}} <span
                  style="color: red;"> + {{row.same_ltype_count}} </span></span>
              <span *ngIf="row.same_ltype_count != null && row.lop_count != null">{{row.leavecount}} + <span>
                  {{row.same_ltype_count}}</span> + {{row.lop_count}}</span>
            </td>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="pendingSince">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Pending Since </th>
            <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.pendingSince}} </td>
          </ng-container>
          <!-- Color Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row,let i = index" style="width:auto;white-space: nowrap;cursor: pointer;">
              <button mat-icon-button title="Approve" [tabindex]="[i]" (keyup.enter)="submit(row,'Approved',null)">
                <i class="material-icons mr-1" (click)="submit(row,'Approved',null)" title="Approve">check_box</i>
              </button>
              <button mat-icon-button title="Reject" [tabindex]="[i]" (keyup.enter)="leaveReject(row,'Reject',null)">
                <i class="material-icons mr-1" (click)="leaveReject(row,'Reject',null)"
                  title="Reject">cancel_presentation</i>
              </button>
              <!--<mat-icon (click)="leaveReject(row)" title="Reject" >cancel</mat-icon>-->
              <!--<mat-icon   title="view" (click)="leaveReview(row)">visibility</mat-icon>-->
              <button mat-icon-button title="Review and approve" [tabindex]="[i]" (keyup.enter)="leaveReview(row)">
                <i class="material-icons" (click)="leaveReview(row)" title="Review and approve">preview</i>
              </button>


              <!--<a routerLink="/" (click)="onApproveClick(row)">Review and Approve</a>-->
            </td>
          </ng-container>
          <!-- Color Column -->
          <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
      </mat-card-content>
    </div>
  </mat-card>
</div>
<ngx-spinner size="medium" type='ball-fussion'></ngx-spinner>