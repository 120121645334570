<div fxLayout="column" class="first-col">
  <form [formGroup]="requestform" autocomplete="off">
    <mat-card class="mat-elevation-z0">
      <mat-card-header class="headerbox">
        <mat-card-title class="title">Reassign Rotation Shift </mat-card-title>
      </mat-card-header>
      <div fxLayout="column" class="dev-Pad">
        <form [formGroup]="requestform">

            <div class="responsive-form" >
          
            <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Location</mat-label>
              <mat-select required formControlName="location">
                <input matInput [formControl]="searchControl" type="text" placeholder="Search" class="search-align">
                <ng-container>
                  <div *ngFor="let b of availableLocations">
                    <mat-option [value]="b.locationid">{{ b.location }}</mat-option>
                  </div>
                </ng-container>
              </mat-select>
              <mat-error class="con-error" *ngIf="
                    requestform.controls.location.errors?.required">
                {{ requiredOption }}
              </mat-error>
            </mat-form-field>
           
            <mat-form-field *ngIf="isShow" class="mx-2 my-2" appearance="outline">
                <mat-label>Rotation Shift</mat-label>
                <mat-select formControlName="shift" required>
                  <input matInput [formControl]="searchControlforShift" type="text" placeholder="Search"
                    class="search-align">
                  <ng-container *ngFor="let v of filteredShiftSequenceList">
                    <mat-option [value]="v.id" (onSelectionChange)="shiftChange(v.id)">{{v.shift_sequence_name}}
                    </mat-option>
                  </ng-container>
                </mat-select>

                <mat-error class="con-error" *ngIf="requestform.controls.shift.errors?.required">
                  {{requiredOption}}
                </mat-error>
              </mat-form-field>


              <mat-form-field *ngIf="isShow" class="mx-2 my-2" appearance="outline">
                <mat-label>Start Shift</mat-label>
                <mat-select formControlName="sequenceShift" required>
                  <input matInput [formControl]="searchControlforSequenceShift" type="text" placeholder="Search"
                    class="search-align">
                  <ng-container *ngFor="let v of filteredSequenceList">
                    <mat-option [value]="v.shiftId">{{v.shiftName}}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-error class="con-error" *ngIf="requestform.controls.sequenceShift.errors?.required">
                  {{requiredOption}}
                </mat-error>
              </mat-form-field>

              <mat-form-field *ngIf="isShow" class="mx-2 my-2" appearance="outline">
                <mat-label>Applicable Date</mat-label>
                <input matInput readonly [min]="minDate" [max]="maxFromDate" [matDatepicker]="fromDate"
                  formControlName="fromDate" (click)="fromDate.open()">
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
                <mat-error class="con-error" *ngIf="requestform.controls.fromDate.errors?.required">
                  {{requiredOption}}
                </mat-error>
              </mat-form-field>
          </div>


        <div *ngIf="isShow" fxLayout="column">
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"
            style="padding-top: 5px;height: 70px;">
            <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
              <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
              <mat-label>Search </mat-label>
              <input (keyup)="applyFilter2($event)" matInput placeholder="" maxlength="100" />
            </mat-form-field>
          </div>
              <div class="table-pad">
                <div class="example-container mat-elevation-z1">
                  <table mat-table [dataSource]="dataSource" matSort>
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef class="no-wrap"> S. No. </th>
                      <td mat-cell *matCellDef="let row;let i = index;"> {{ (i+1) +
                                                (paginator.pageIndex * paginator.pageSize) }}</td>
                    </ng-container>

                    <ng-container matColumnDef="empid">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp.
                        ID </th>
                      <td mat-cell *matCellDef="let row"> {{row.empcode}}</td>
                    </ng-container>

                    <ng-container matColumnDef="empname">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp.
                        Name </th>
                      <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                    </ng-container>

                    <ng-container matColumnDef="currentshift">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Current
                        Rotation</th>
                      <td mat-cell *matCellDef="let row"> {{row.shiftname}}</td>
                    </ng-container>

                    <ng-container matColumnDef="sequencename">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Rotation Shift</th>
                      <td mat-cell *matCellDef="let row"> {{row.sequence_name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="rotationShift">
                      <th mat-header-cell *matHeaderCellDef> Sequence Order </th>
                      <td mat-cell *matCellDef="let row">
                        <div [innerHTML]="getRotationOrders(row.rotationShift)"></div>
                      </td>

                    </ng-container>

                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="8" style="text-align: center;">No data found
                      </td>
                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
              </div>

            <br>
            <div fxLayoutAlign="center" *ngIf="arrayList.length > 0 ">
              <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" (click)="submit()" type="submit">
                <span>Submit</span>
              </button>

              <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                <span>Cancel</span>
              </button>
              <br />
            </div>
        </div>
    </form>

      </div>
    </mat-card>
  </form>






</div>
