<div fxLayout="column" class="first-col">

    <mat-card fxLayout="column">
        <mat-card-header class="heading">
            <mat-card-title class="headingtext">Late Attendance Report</mat-card-title>
        </mat-card-header>

        <div fxLayout="row" fxLayout.lt-lg="column" [formGroup]="searchForm" style="padding: 1%;">
            <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field fxFlex fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <mat-select formControlName="user" required tabindex="1">
                        <input matInput [formControl]="searchControl" type="search" placeholder="Search"
                            class="search-align">
                        <mat-option value="0"> All Employees </mat-option>
                        <ng-container *ngFor="let e of filteredEmployeeList">
                            <mat-option [value]="e.empid">{{e.empname}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Shift</mat-label>
                    <mat-select formControlName="shift" required tabindex="2">
                        <mat-option value="0"> All Shifts </mat-option>
                        <ng-container *ngFor="let v of shiftDataList">
                            <mat-option [value]="v.shiftid">{{v.shiftname}}</mat-option>
                        </ng-container>
                    </mat-select>

                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>From Date </mat-label>
                    <input matInput readonly required [min]="minFromDate" [max]="maxFromDate" [matDatepicker]="fromDate"
                        (dateInput)="fromDateChange('input', $event)" placeholder="From Date" formControlName="fromDate"
                        (click)="fromDate.open()" tabindex="3">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                    <mat-error class="con-error" *ngIf="searchForm.controls.fromDate.errors?.required">
                        Please select an option.
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>To Date </mat-label>
                    <input matInput readonly required [min]="minToDate" [max]="maxToDate" [matDatepicker]="toDate"
                        (dateInput)="toDateChange('input', $event)" formControlName="toDate" (click)="toDate.open()"
                        tabindex="4">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
                    <mat-error class="con-error" *ngIf="searchForm.controls.toDate.errors?.required">
                        Please select an option.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxFlex.xs="100%" fxLayoutAlign="center" class="showLine">
            <span>
                <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="5">
                    Search
                </button>
            </span>
            <!-- <span class="line" style="color:#1898D5;font-size:16px"></span> -->
            <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="6">
                <span>Clear</span>
            </button>
        </div>

        <div fxLayout="row xs-column" fxLayoutAlign="end  center" *ngIf="attendanceReportList.length > 0"
            style="margin-right: 3%;">
            <div>
                <span class="exlprint"> | </span>
                <button mat-icon-button tabindex="7" (keyup.enter)="generatePDF()">
                    <a title="PDF"> <img (click)="generatePDF()" src="./assets/images/icons/pdf.png" width="20px"
                            height="20px"></a>
                </button>
                <span class="exlprint"> | </span>
                <button mat-icon-button tabindex="8" (keyup.enter)="exportAsXLSX()">
                    <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                    </a>
                </button>
            </div>
        </div>

        <div fxLayout="column" class="table-pad">
            <div class="example-container mat-elevation-z1">
                <div id="table" #table=''>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="no-wrap">S. No.</th>
                            <td mat-cell *matCellDef="let row; let i = index;">{{i+1}}</td>
                        </ng-container>
                        <ng-container matColumnDef="empid">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. ID</th>
                            <td mat-cell *matCellDef="let row;">{{row.empid}}</td>
                        </ng-container>
                        <ng-container matColumnDef="empname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name</th>
                            <td mat-cell *matCellDef="let row;">{{row.empname}}</td>
                        </ng-container>
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef>Shift</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.shiftname}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fromdate">
                            <th mat-header-cell *matHeaderCellDef>Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                                date:'dd-MM-yyyy'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="todate">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Shift InTime</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.shiftstarttime}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="intime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">In Time</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.actual_in_time}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="latehours">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Late Hours</th>
                            <td mat-cell *matCellDef="let row"> {{row.latehours}} </td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>

    </mat-card>
</div>