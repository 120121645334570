import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { AttendanceService } from '../../attendance.service';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { environment } from 'src/environments/environment';

import * as _moment from 'moment';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
interface IdName {
  id: string;
  name: string;
}
@Component({
  selector: 'app-attendance-request-behalf',
  templateUrl: './attendance-request-behalf.component.html',
  styleUrls: ['./attendance-request-behalf.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AttendanceRequestBehalfComponent implements OnInit {
  requestform: any = UntypedFormGroup;
  minFromDate: Date;
  maxFromDate: Date | null;
  selectedEmpJoinDate: Date | null;
  minToDate: Date | null;
  maxToDate: Date;
  currentDate: Date = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe: any;
  displayedColumns: string[] = [
    'id',
    'worktype',
    'raisedbyname',
    'fromdate',
    'todate',
    'action',
  ];
  dataSource: MatTableDataSource<any> = <any>[];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  arrayList: any = [];

  workTypeData: any;
  userSession: any;
  employeeEmailData: any = [];
  shiftData: any;
  employeesData: any;
  userData: any;
  pageLoading = true;
  messagesDataList: any = [];
  requiredField: any;
  requiredOption: any;
  dataSaved: any;
  dataNotSaved: any;
  disableDates: any = [];
  datesList: any;
  weekoffs: any;
  holidays: any;
  leaves: any;
  workeddays: any;
  regularizationdays: any;
  halfdays: any;
  myDateFilter: any;
  ATT75: any;
  companyDBName: any = environment.dbName;
  attendanceid: any;
  fileURL: any;
  file: any;
  isFile: boolean = true;
  isedit: boolean = false;
  editFileName: any;
  documentId: any = null;
  documentInfo: any = null;
  pdfName: any = null;
  document: boolean = false;
  iseditDoc: boolean = true;
  formData: FormData = new FormData();
  isRequestView: boolean = false;
  isDashboard: boolean = false;
  isdisable: boolean = true;
  isFirstHalfLeave: any;
  isSecondHalfLeave: any;
  isFirstHalfAttendance: any;
  isSecondHalfAttendance: any;
  isFirstHalf: boolean = false;
  isSecondHalf: boolean = false;
  isHalfday: any;
  firsthalf_starttime: any;
  firsthalf_endtime: any;
  secondhalf_starttime: any;
  secondhalf_endtime: any;
  halfDayTimingData: any;
  fhalfDisable: boolean = false;
  shalfDisable: boolean = false;
  btnDisable: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private attendanceService: AttendanceService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private router: Router,
    private LM: LeavesService,
    private location: Location,
    private adminService: AdminService,
    private emsService: EmsService
  ) {
    this.minFromDate = new Date();
    this.minFromDate.setMonth(this.currentDate.getMonth() - 1);
    this.maxFromDate = new Date();
    this.maxFromDate.setDate(this.currentDate.getDate());
    this.minToDate = new Date();
    this.minToDate.setMonth(this.currentDate.getMonth() - 1);
    this.maxToDate = new Date();
    this.maxToDate.setDate(this.currentDate.getDate());
    this.selectedEmpJoinDate = null;
    this.userData = this.location.getState();
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getEmployeeListByManagerId();
  }
  searchControl = new FormControl('');
  workTypeSearchControl = new FormControl('');
  filteredEmployeeList: any = [];
  filteredWorkTypes: any = [];
  ngOnInit(): void {
    this.getMessagesList();
    this.todayWithPipe = this.pipe.transform(Date.now(), 'dd-MM-yyyy');
    this.requestform = this.formBuilder.group({
      appliedDate: [
        { value: this.todayWithPipe, disabled: true },
        Validators.required,
      ],
      shift: ['', Validators.required],
      fromDate: [{ value: '', disabled: true }, Validators.required],
      toDate: [{ value: '', disabled: true }, Validators.required],
      employeeName: ['', Validators.required],
      workType: [{ value: '', disabled: true }, Validators.required],
      reason: ['', [Validators.required]],
      document: [''],
      firstHalf: [''],
      secondHalf: [''],
    });
    this.getWorkypeList();
    this.getAttendanceRequestListByEmpId();

    this.requestform.get('employeeName')?.valueChanges.subscribe((selectedValue: any) => {
      this.getEmployeeEmailData(selectedValue);

      if (this.isDashboard == false) {
        for (let i = 0; i >= this.employeesData.length; i++) {
          if (selectedValue == this.employeesData[i].empid) {
            if (this.employeesData[i].dateofjoin != null) {
              this.selectedEmpJoinDate = new Date(
                this.employeesData[i].dateofjoin
              );
              this.minFromDate = new Date();
              this.minFromDate.setMonth(this.currentDate.getMonth() - 1);
              if (this.minFromDate < this.selectedEmpJoinDate) {
                this.minFromDate = this.selectedEmpJoinDate;
              }
              break;
            }
          }
        }
      }

      this.requestform.get('workType')?.enable();
      this.getEmployeeWeekoffsHolidaysForAttendance();
      this.requestform.controls.workType.setValue('');
      this.requestform.controls.fromDate.setValue('');
      this.requestform.controls.toDate.setValue('');
      this.requestform.controls.reason.setValue('');
    });

    this.requestform.get('workType')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue) {
        if (this.userData.userData != undefined) {
          this.requestform.get('fromDate')?.disable();
        } else {
          this.requestform.get('fromDate')?.enable();
        }

        if (selectedValue == '2') {
          // this.requestform.get('toDate')?.enable();
        } else {
          this.requestform.get('toDate')?.disable();
          this.requestform.get('toDate')
            ?.setValue(this.requestform.get('fromDate')?.value);
          //  this.getEmployeeShiftDetailsByIdWithDates();
        }
      }
    });
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText);
    });

    this.workTypeSearchControl.valueChanges.subscribe(searchText => {
      this.filterWorkTypes(searchText);
    });
  }

  getEmployeeListByManagerId() {
    this.attendanceService.getgetemployeesByMangerId(this.userSession.id).subscribe((res) => {
      if (res) {
        this.employeesData = res.data;
        this.filteredEmployeeList = this.employeesData;
        if (this.userData.userData != undefined) {
          this.isDashboard = true;
          this.minToDate = new Date(this.userData.userData.absent_date);
          this.requestform.controls.appliedDate.setValue(this.todayWithPipe);
          this.requestform.controls.employeeName.setValue(
            this.userData.userData.empid
          );
          this.requestform.controls.fromDate.setValue(
            this.userData.userData.absent_date
          );
          this.requestform.controls.toDate.setValue(
            this.userData.userData.absent_date
          );
          this.requestform.controls.workType.enable();
          this.getEmployeeWeekoffsHolidaysForAttendance();
          this.getEmployeeShiftDetailsByIdWithDates();
          this.checkEmployeeHalfdayAttendanceLeaveDetails();
        }
      }
    });
  }

  ngAfterViewInit() { }
  get f(): { [key: string]: AbstractControl } {
    return this.requestform.controls;
  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minToDate = event.value;
    this.checkEmployeeHalfdayAttendanceLeaveDetails();
    this.requestform.controls.toDate.setValue('');
    if (event.value !== null) {
      // this.maxToDate = new Date(
      //   event!.value.getFullYear(),
      //   event!.value.getMonth(),
      //   event!.value.getDate() + 30
      // );
    }
    if (this.requestform.get('workType')?.value == '2') {
      this.requestform.get('toDate')?.enable();
      this.fhalfDisable = false;
      this.shalfDisable = false;
    } else {
      this.requestform.get('toDate')?.setValue(event.value);
      this.getEmployeeShiftDetailsByIdWithDates();
      this.fhalfDisable = false;
      this.shalfDisable = false;
    }
  }

  toDateChange(type: string, event: any) {
    this.maxFromDate = event.value;
    if (event.value !== null) {
      // this.minFromDate = new Date(
      //   event.value['_i'].year,
      //   event.value['_i'].month,
      //   event.value['_i'].date - 31
      // );
      this.minFromDate.setMonth(this.currentDate.getMonth() - 1);
      if (
        this.selectedEmpJoinDate &&
        this.minFromDate < this.selectedEmpJoinDate
      ) {
        this.minFromDate = this.selectedEmpJoinDate;
      }
    }
    this.getEmployeeShiftDetailsByIdWithDates();
    const datePipe = new DatePipe('en-US');
    const formattedDate1 = datePipe.transform(this.requestform.controls.fromDate.value, 'medium');
    const formattedDate2 = datePipe.transform(this.requestform.controls.toDate.value, 'medium');
    if ((formattedDate1 == formattedDate2) && (this.isSecondHalf == false && this.isFirstHalf == false)) {
      this.fhalfDisable = false;
      this.shalfDisable = false;
    } else if ((formattedDate1 != formattedDate2) && (this.isSecondHalf == false && this.isFirstHalf == false)) {
      this.requestform.controls.firstHalf.setValue('');
      this.requestform.controls.secondHalf.setValue('');
      this.fhalfDisable = true;
      this.shalfDisable = true;
    }
  }

  getEmployeeShiftDetails(selectedValue: any) {
    //
    this.attendanceService.getShiftDetailsByEmpId(selectedValue).subscribe((res) => {
      if (res.status) {
        this.shiftData = res.data[0];
        this.requestform.controls.shift.setValue(this.shiftData.shiftname);
        this.requestform.controls.shift.disable();
      }
    });
  }

  getHalfdayShiftTimings() {
    this.attendanceService.getHalfdayShiftTimings(this.shiftData.shiftid).subscribe((res: any) => {
      if (res.status) {
        this.halfDayTimingData = res.data[0];
        this.firsthalf_starttime = this.halfDayTimingData.firsthalf_starttime;
        this.firsthalf_endtime = this.halfDayTimingData.firsthalf_endtime;
        this.secondhalf_starttime = this.halfDayTimingData.secondhalf_starttime;
        this.secondhalf_endtime = this.halfDayTimingData.secondhalf_endtime;
      }
    })
  }

  getEmployeeShiftDetailsByIdWithDates() {
    let data = {
      employee_id: this.requestform.controls.employeeName.value ?? '',
      fromd_date: this.pipe.transform(
        new Date(this.requestform.controls.fromDate.value ?? ''),
        'yyyy-MM-dd'
      ),
      to_date: this.pipe.transform(
        new Date(this.requestform.controls.toDate.value ?? ''),
        'yyyy-MM-dd'
      ),
    };
    this.attendanceService.getEmployeeShiftByDates(data).subscribe((res: any) => {
      if (res.status) {
        if (res.data.length > 0) {
          if (res.data.length > 1) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              // data: this.ATT75
              data: 'Unable to request. please configure the shift before request',
            });
          } else {
            this.shiftData = res.data[0];
            this.getHalfdayShiftTimings();
            this.requestform.controls.shift.setValue(this.shiftData.shiftname);
          }
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to request. please configure the shift before request',
          });
          this.requestform.controls.fromDate.reset();
          this.requestform.controls.toDate.reset();
          return;
        }
      }
    });
  }

  getEmployeeWeekoffsHolidaysForAttendance() {
    let data = {
      employee_id: this.requestform.controls.employeeName.value ?? '',
      is_it_an_edit_action: null,
    };
    this.attendanceService
      .getEmployeeWeekoffsHolidaysForAttendance(data)
      .subscribe((res: any) => {
        this.disableDates = [];
        if (res.status) {
          if (res.data.length > 0) {
            this.datesList = res.data;
            this.weekoffs = JSON.parse(this.datesList[0].weekoffs);
            this.holidays = JSON.parse(this.datesList[0].holidays);
            this.leaves = JSON.parse(this.datesList[0].leaves);
            this.workeddays = JSON.parse(this.datesList[0].workeddays);
            this.regularizationdays = JSON.parse(
              this.datesList[0].regularizationdays
            );
            this.halfdays = JSON.parse(this.datesList[0].halfdays);
            if (this.weekoffs.length > 0) {
              this.weekoffs.forEach((i: any) => {
                let date = i + ' ' + '00:00:00';
                this.disableDates.push(new Date(date));
              });
            }
            if (this.holidays.length > 0) {
              this.holidays.forEach((i: any) => {
                let date = i + ' ' + '00:00:00';
                this.disableDates.push(new Date(date));
              });
            }
            if (this.leaves.length > 0) {
              this.leaves.forEach((i: any) => {
                let date = i + ' ' + '00:00:00';
                this.disableDates.push(new Date(date));
              });
            }
            if (this.workeddays.length > 0) {
              this.workeddays.forEach((i: any) => {
                let date = i + ' ' + '00:00:00';
                this.disableDates.push(new Date(date));
              });
            }
            if (this.regularizationdays.length > 0) {
              this.regularizationdays.forEach((i: any) => {
                let date = i + ' ' + '00:00:00';
                this.disableDates.push(new Date(date));
              });
            }
            if (this.halfdays.length > 0) {
              this.halfdays.forEach((i: any) => {
                let date = i + ' ' + '00:00:00'
                this.disableDates.push(new Date(date));
              });
            }
            this.myDateFilter = (d: Date): boolean => {
              let isValid = true;
              this.disableDates.forEach((e: any) => {
                if (
                  this.pipe.transform(e, 'yyyy/MM/dd') ==
                  this.pipe.transform(d, 'yyyy/MM/dd')
                ) {
                  isValid = false;
                }
              });

              return isValid;
            };
          }
        }
      });
  }
  getWorkypeList() {
    this.attendanceService.getWorkypeList('attendancetypesmaster', 'active', 1, 100, this.companyDBName).subscribe((info) => {
      if (info.status && info.data.length != 0) {
        this.workTypeData = info.data;
        this.filteredWorkTypes = this.workTypeData;
      }
    });
  }
  getAttendanceRequestListByEmpId() {
    this.arrayList = [];
    this.attendanceService
      .getAttendanceRegularizationByManagerId(this.userSession.id)
      .subscribe((res) => {
        if (res.status) {
          this.arrayList = res.data;
          this.dataSource = new MatTableDataSource(this.arrayList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
        } else {
          this.arrayList = [];
          this.dataSource = new MatTableDataSource(this.arrayList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  submit() {
    if (this.requestform.invalid) {
      return;
    } else {
      if (this.btnDisable) {
        return;
      }
      let worktypename = '';
      this.workTypeData.forEach((e: any) => {
        if (e.id == this.requestform.controls.workType.value) {
          worktypename = e.type;
        }
      });
      if (this.isFirstHalf == false && this.isSecondHalf == false) {
        this.isHalfday = 0;
      } else if (this.isFirstHalf == true && this.isSecondHalf == false) {
        this.isHalfday = 1;
      } else if (this.isFirstHalf == false && this.isSecondHalf == true) {
        this.isHalfday = 2;
      }
      let loginTime;
      let logoutTime;
      if (this.isFirstHalf == true && this.isSecondHalf == false) {

        loginTime = this.firsthalf_starttime;
        logoutTime = this.firsthalf_endtime;

      } else if (this.isSecondHalf == true && this.isFirstHalf == false) {

        loginTime = this.secondhalf_starttime;
        logoutTime = this.secondhalf_endtime;
      } else {

        loginTime = this.shiftData.fromtime;
        logoutTime = this.shiftData.totime
      }

      let obj = {
        empid: this.requestform.controls.employeeName.value,
        shiftid: this.shiftData.shiftid,
        worktype: this.requestform.controls.workType.value,
        fromdate: this.pipe.transform(new Date(this.requestform.controls.fromDate.value ?? ''), 'yyyy-MM-dd'), //this.datePipe.transform(this.requestform.controls.fromDate.value, "y-MM-d"),
        todate: this.pipe.transform(new Date(this.requestform.controls.toDate.value ?? ''), 'yyyy-MM-dd'), //this.requestform.controls.toDate.value,
        /** while halfday save */
        logintime: loginTime,
        logouttime: logoutTime,

        // "logintime": this.shiftData.fromtime,
        // "logouttime": this.shiftData.totime,
        reason: this.requestform.controls.reason.value,
        raisedby: this.userSession.id ?? '',
        approvercomments: '',
        actionby: this.userSession.id ?? '',
        status: 'Approved',
        emails: this.employeeEmailData,
        worktypename: worktypename,
        shiftname: this.shiftData.shiftname,
        isBehalf: true,
        halfday: this.isHalfday
      };
      this.btnDisable = true;
      this.attendanceService.setEmployeeAttendanceRegularizationForWeb(obj).subscribe((res: any) => {

        if (res.status) {
          this.attendanceid = this.attendanceid != '' ? res.data.attendance_id : this.attendanceid;
          let resMessage: any;

          // validity_status 0 = update ; 1 = save ; 2 = update ; 3 = halfday record exist

          if (res.data.validity_status == 0) {
            resMessage = this.dataNotSaved;
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage
            });
            this.resetform();
          }
          else if (res.data.validity_status == 1) {
            resMessage = "Attendance on behalf of employee is applied and approved successfully.";
            if (true) {
              this.LM.getFilepathsMaster(4).subscribe((result: any) => {
                if (result && result.status) {
                  let obj = {
                    id: null,
                    employeeId: this.userSession.id,
                    filecategory: 'ATTENDANCE',
                    moduleId: 4,
                    documentnumber: '',
                    fileName: this.file.name,
                    modulecode: result.data[0].module_code,
                    requestId: this.attendanceid,
                    status: 'Submitted',
                  };
                  this.LM.setFilesMaster(obj).subscribe((data) => {
                    if (data && data.status) {
                      let info = JSON.stringify(data.data[0]);
                      this.formData.append('info', info);
                      this.formData.append('file', this.file);
                      this.LM.setProfileImage(this.formData).subscribe(
                        (data) => {
                          // this.formData.delete('file');
                          // this.formData.delete('info');

                        }
                      );
                    }
                  });
                } else {

                  this.dialog.open(ConfirmationComponent, {
                    position: { top: `70px` },
                    disableClose: true,
                    data: {
                      Message: 'Please check uploaded file.',
                    },
                  });
                  this.btnDisable = false;
                }
              });

            }
            else {
            }
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage
            });
            this.resetform();

          }
          else if (res.data.validity_status == 3) {
            resMessage = this.dataNotSaved;
            let date = this.pipe.transform(new Date(res.data.halfdaydate), 'dd-MM-yyyy');
            let halfdayType = res.data.halfdaytype;
            let type;
            halfdayType == 'L' ? type = 'Leave' : type = 'Attendance';
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Halfday " + type + " " + 'Record is exist on ' + date + " " + ', Please apply accordingly'
            });
            this.btnDisable = false;
          }
          else {
            resMessage = this.dataNotSaved;
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage
            });
            this.resetform();
          }
        }
      })
    }
  }
  getEmployeeEmailData(userid: any) {
    this.employeeEmailData = [];
    this.emsService.getEmployeeEmailDataByEmpid(userid).subscribe((res: any) => {
      this.employeeEmailData = JSON.parse(res.data[0].jsonvalu)[0];
    });
  }
  resetform() {
    if (this.userData.userData != undefined) {
      this.router.navigate(['/Attendance/ManagerDashboard']);
    } else {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/Attendance/RequestofEmployee']));
    }
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getMessagesList() {
    let data = {
      code: null,
      pagenumber: 1,
      pagesize: 100,
    };
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == 'ATT1') {
            this.requiredField = e.message;
          } else if (e.code == 'ATT2') {
            this.requiredOption = e.message;
          } else if (e.code == 'ATT11') {
            this.dataSaved = e.message;
          } else if (e.code == 'ATT12') {
            this.dataNotSaved = e.message;
          } else if (e.code == 'ATT75') {
            this.ATT75 = e.message;
          }
        });
      } else {
        this.messagesDataList = [];
      }
    });
  }
  getEmployeeInformation(id: any) {
    this.attendanceService.getEmployeeInformationforlogindate(id).subscribe((res) => {
      if (res.status) {
        var userinfo = res.data[0];
        if (userinfo.dateofjoin != null) {
          const dateofjoin = new Date(userinfo.dateofjoin);
          const mindate = new Date(this.minFromDate);
          if (mindate < dateofjoin) {
            this.minFromDate = dateofjoin;
          }
        }
      }
    });
  }
  onSelectFile(event: any) {
    if (event.target.files.length != 0) {
      if (event.target.files[0].size <= 2097152) {
        this.file = event.target.files[0];
        var pdf = this.file.name.split('.');
        if (
          pdf[pdf.length - 1] == 'pdf' ||
          pdf[pdf.length - 1] == 'jpg' ||
          pdf[pdf.length - 1] == 'png'
        ) {
          this.isFile = true;
        } else {
          this.isFile = false;
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Only PNG,JPEG,JPG & PDF format allowed',
          });
        }
      } else {
        this.isFile = false;
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Please upload less than 2MB file',
        });
      }
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        // data: this.EM18
        data: 'Invalid document',
      });
      // th
    }
  }
  deleteIcon() {
    this.isedit = false;
    this.requestform.controls.document.setValue('');
  }
  delete() {
    this.isedit = false;
  }

  requestView(event: any) {
    this.fhalfDisable = true;
    this.shalfDisable = true;
    this.isFirstHalf = false;
    this.isSecondHalf = false;
    this.attendanceid = event.attendanceid;
    this.isRequestView = true;
    this.requestform.controls.document.setValue('');
    this.fileURL = '';
    this.pdfName = '';
    this.requestform.controls.employeeName.setValue(event.empid);
    this.requestform.controls.employeeName.disable();
    this.requestform.controls.workType.setValue(event.worktype);
    this.requestform.controls.fromDate.setValue(event.fromdate);
    this.requestform.controls.fromDate.disable();
    this.requestform.controls.toDate.setValue(event.todate);
    this.requestform.controls.toDate.disable();
    this.requestform.controls.reason.setValue(event.reason);
    this.requestform.controls.reason.disable();
    if (event.halfdayattendance == 1) {
      this.isFirstHalf = true;
    } else if (event.halfdayattendance == 2) {
      this.isSecondHalf = true;
    } else {
      this.isFirstHalf = false;
      this.isSecondHalf = false;
    }
    this.getEmployeeShiftDetailsByIdWithDates();
    this.getUploadDocument();
  }
  getUploadDocument() {
    let info = {
      employeeId: this.requestform.controls.employeeName.value,
      candidateId: null,
      filecategory: 'ATTENDANCE',
      moduleId: 4,
      requestId: this.attendanceid,
      status: 'Submitted',
    };
    this.LM.getFilesMaster(info).subscribe((result) => {
      if (result && result.status && result.data.length > 0) {
        this.documentId = result.data[0].id;
        this.documentInfo = JSON.stringify(result.data[0]);
        this.pdfName = result.data[0].fname;

        result.data[0].employeeId = this.userSession.id;
        let info = result.data[0];
        this.LM.getProfileImage(info).subscribe((imageData) => {
          if (imageData.success) {
            this.document = true;
            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, '');
            var documentName = this.pdfName.split('.');
            if (documentName[documentName.length - 1] == 'pdf') {
              const file = new Blob([TYPED_ARRAY], { type: 'application/pdf' });
              this.fileURL = URL.createObjectURL(file);
            } else {
              const file = new Blob([TYPED_ARRAY], { type: 'image/png' });
              this.fileURL = URL.createObjectURL(file);
            }
          }
        });
      } else {
      }
    });
  }

  fileView() {
    window.open(this.fileURL);
  }

  editdoc() {
    this.pdfName = null;
  }

  checkEmployeeHalfdayAttendanceLeaveDetails() {
    let empId;
    if (this.userData.userData != undefined) {
      empId = this.userData.userData.empid
    } else {
      empId = this.requestform.controls.employeeName.value
    }
    let data = {
      employee_id: empId,
      check_date: this.pipe.transform(new Date(this.requestform.controls.fromDate.value ?? ''), 'yyyy-MM-dd'),
    };
    this.attendanceService.getEmployeeHalfdayAttendanceLeaveDetails(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        let value = res.data[0]
        this.isFirstHalfAttendance = value.is_first_halfday_attendance;
        this.isSecondHalfAttendance = value.is_second_halfday_attendance;
        this.isFirstHalfLeave = value.is_first_halfday_leave;
        this.isSecondHalfLeave = value.is_second_halfday_leave;

        if (this.isFirstHalfAttendance == 1 || this.isFirstHalfLeave == 1) {
          this.isSecondHalf = true;

        } else if (this.isSecondHalfAttendance == 1 || this.isSecondHalfLeave == 1) {
          this.isFirstHalf = true;

        } else {
          this.isFirstHalf = false;
          this.isSecondHalf = false;
        }

      } else {

      }
    })
  }

  firstSelected(event: MatCheckboxChange) {

    if (event.checked == true) {

      this.isFirstHalf = true;
      this.isSecondHalf = false;
      this.firsthalf_starttime = this.halfDayTimingData.firsthalf_starttime;
      this.firsthalf_endtime = this.halfDayTimingData.firsthalf_endtime;

    } else {

      this.isFirstHalf = false;
      this.isSecondHalf = false;
      this.firsthalf_starttime = '';
      this.firsthalf_endtime = '';

    }
  }

  secondSelected(event: MatCheckboxChange) {

    if (event.checked == true) {
      this.isFirstHalf = false;
      this.isSecondHalf = true;
      this.secondhalf_starttime = this.halfDayTimingData.secondhalf_starttime;
      this.secondhalf_endtime = this.halfDayTimingData.secondhalf_endtime;
    } else {
      this.isFirstHalf = false;
      this.isSecondHalf = false;
      this.secondhalf_starttime = '';
      this.secondhalf_endtime = '';
    }
  }

  filterEmployees(searchText: any) {
    this.filteredEmployeeList = this.employeesData.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase()) ||
      val.employee_code.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployeeList.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  filterWorkTypes(searchText: any) {
    this.filteredWorkTypes = this.workTypeData.filter((val: any) =>
      val.type.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredWorkTypes.length <= 0) {
      this.searchControl.setValue('');
    } else {

    }
  }


}
