<div fxLayout="column" class="first-col">
    <mat-card>
        <form [formGroup]="checklistForm" autocomplete="off">

            <div fxLayout="column">
                <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-card-title class="title"> Induction Program </mat-card-title>
                    <div style="margin-right: 2%;" *ngIf="isdata">
                        <button mat-icon-button style="padding-right: 40px;" [tabIndex]="[1]" (click)="Add()"
                            matTooltip="Add New">
                            <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                        </button>
                    </div>
                </mat-card-header>


                <div fxLayout="column" fxLayout.xs="column" class="div-pad" *ngIf="isAdd">

                    <div class="responsive-form">
                        <mat-form-field  class="mx-2 my-2" appearance="outline">
                            <mat-label>Program Type</mat-label>
                            <mat-select required formControlName="programType" (selectionChange)="onChange()">
                                <ng-container>
                                    <div *ngFor="let r of availableprogramtypes">
                                        <mat-option [value]="r.program_id">{{r.description}}</mat-option>
                                    </div>

                                </ng-container>
                            </mat-select>
                            <mat-error class="reg-error"
                                *ngIf="checklistForm.controls.programType.hasError('required')">
                                {{requiredOption}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field  class="mx-2 my-2" appearance="outline">
                            <mat-label>Department</mat-label>
                            <mat-select required formControlName="department" (selectionChange)="onChange()">
                                <ng-container>
                                    <div *ngFor="let r of availableDepartments">
                                        <mat-option [value]="r.id">{{r.department}}</mat-option>
                                    </div>

                                </ng-container>
                            </mat-select>

                            <mat-error class="con-error" *ngIf="checklistForm.controls.department.errors?.required">
                                {{requiredOption}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field  class="mx-2 my-2"
                            ngStyle.lt-lg="padding-right:4px" appearance="outline">
                            <mat-label>Conduct By</mat-label>
                            <mat-select required formControlName="conductBy" required (selectionChange)="onChange()">
                                <ng-container>
                                    <div *ngFor="let r of conductlist">
                                        <mat-option [value]="r.empid">{{r.empname}}</mat-option>
                                    </div>

                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="checklistForm.controls.conductBy.errors?.required">
                                {{requiredOption}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field  class="mx-2 my-2" appearance="outline">
                            <mat-label>Date</mat-label>
                            <input matInput readonly [matDatepicker]="date" required [min]="mdate" placeholder="Date"
                                formControlName="date" (click)="date.open()" (dateChange)="onChange()">
                            <mat-datepicker-toggle class="datepicker" matSuffix [for]="date"></mat-datepicker-toggle>
                            <mat-datepicker #date></mat-datepicker>
                            <mat-error class="con-error" *ngIf="checklistForm.controls.date.errors?.required">
                                {{requiredOption}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field  class="mx-2 my-2" appearance="outline">
                            <mat-label>Start Time</mat-label>
                            <input formControlName="starttime" matTimepicker placeholder="Time"
                                (timeChange)="onChange()" />
                            <mat-error class="con-error" *ngIf="checklistForm.controls.starttime.errors?.required">
                                {{requiredOption}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field  ngStyle.lt-lg="padding-right:4px"
                            class="mx-2 my-2" appearance="outline">
                            <mat-label>End Time</mat-label>
                            <input formControlName="endtime" matTimepicker [min]="mintime" placeholder="Time"
                                (timeChange)="onChange()" />
                            <!-- <input readOnly formControlName="endtime" matTimepicker [min]="mintime" placeholder="Time" /> -->
                            <mat-error class="con-error" *ngIf="checklistForm.controls.endtime.errors?.required">
                                {{requiredOption}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field  class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea type="text" formControlName="description" matInput placeholder="Description"
                            maxlength="500" (change)="onChange()">
                        </textarea>
                    </mat-form-field>

                    </div>
                    <div *ngIf="isCancel" class="responsive-form">
                        <mat-form-field class="mx-2 my-2"
                            appearance="outline">
                            <mat-label>Cancel Reason</mat-label>
                            <textarea type="text" formControlName="cancelReason" matInput placeholder="" maxlength="500"
                                required>
                            </textarea>
                            <mat-error class="con-error" *ngIf="checklistForm.controls.cancelReason.errors?.required">
                                {{requiredField}}
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="!isView" fxLayoutAlign="center">
                        <button *ngIf="!isRequestView && !isedit && !isCancel" class="btn btn-primary mr-2 mb-2"
                            (click)="saveRequest()" type="submit" [disabled]="btnDisable">
                            <span>Submit</span>
                        </button>
                        <button [disabled]="isdisable" *ngIf="isedit" class="btn btn-primary mr-2 mb-2" (click)="updateRequest()"
                            type="submit" [disabled]="btnDisable">
                            <span>Update</span>
                        </button>
                        <button *ngIf="isCancel" class="m-1 mat-stroked-button" style="background-color:#3f51b5;color:aliceblue" [disabled]="btnDisable" (click)="programCancelRequest()"
                        type="submit">
                        <span>Program Cancel</span>
                    </button>
                    <button *ngIf="!isCancel" class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                        <button *ngIf="isCancel" class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                            <span>Cancel</span>
                        </button>
                        <br />
                    </div>

                    <div fxLayout="column" *ngIf="isView" class="table-pad">
                        <div class="mat-elevation-z1 example-container">
                            <table mat-table [dataSource]="programViewdataSource" matSort>

                                <ng-container matColumnDef="sno">
                                    <th mat-header-cell *matHeaderCellDef><span style="text-wrap: nowrap;"> S. No.</span></th>
                                    <td mat-cell *matCellDef="let element; let i=index;">
                                        {{ (i+1) + (paginator1.pageIndex * paginator1.pageSize) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>Employee Name</th>
                                    <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef>Joining Date</th>
                                    <td mat-cell *matCellDef="let row">{{row.dateofjoin | date:'dd-MM-yyyy'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                    <td mat-cell *matCellDef="let row">{{row.status}}</td>
                                </ng-container>


                                <tr class="headerbox title" mat-header-row *matHeaderRowDef="programViewColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: programViewColumns;"></tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                                </tr>
                            </table>
                            <mat-paginator
                                [pageSizeOptions]="!pageLoading1 ? getPageSizes2() : [5, 10, 20]"></mat-paginator>

                        </div>
                        <br>
                        <div fxLayoutAlign="center">
                            <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                                <span>Back</span>
                            </button>
                            <br />
                        </div>

                    </div>

                </div>

                <!-- add checklist meet -->

                <div fxLayout="column" fxLayout.xs="column" *ngIf="isAddChecklist">
                    <br>
                    <div style="margin-left: 1%;"><b>Meeting Schedule</b></div>
                    <mat-divider></mat-divider>
                    <div class="table-pad">
                        <div class="mat-elevation-z1 example-container">
                            <table mat-table [dataSource]="dataSource2">

                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <!-- Position Column -->
                                <ng-container matColumnDef="sno">
                                    <th mat-header-cell *matHeaderCellDef> S. No.</th>
                                    <td mat-cell *matCellDef="let element; let i=index;">
                                        {{ (i+1) + (paginator2.pageIndex * paginator2.pageSize) }} </td>
                                </ng-container>


                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>Employee Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.empname}} </td>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef>Joining Date </th>
                                    <td mat-cell *matCellDef="let element"> {{element.dateofjoin | date:'dd-MM-yyyy'}}
                                    </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>Status </th>
                                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="addChecklistColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: addChecklistColumns;"
                                    (click)="selection.toggle(row)">
                                </tr>
                            </table>
                            <mat-paginator
                                [pageSizeOptions]="!pageLoading2 ? getPageSizes3() : [5, 10, 20]"></mat-paginator>
                        </div>
                    </div>
                    <br>
                    <div fxLayoutAlign="center">
                        <button *ngIf="!isRequestView && !isEditView" class="btn btn-primary mr-2 mb-2"
                            (click)="meetingSchedule()" type="submit">
                            <span>Submit</span>
                        </button>
                        <button *ngIf="isEditView" class="btn btn-primary mr-2 mb-2" (click)="updateRequest()"
                            type="submit">
                            <span>Update</span>
                        </button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                            <span>Cancel</span>
                        </button>
                        <br />
                    </div>

                </div>

                <!-- update checklist meet -->
                <div fxLayout="column" fxLayout.xs="column" *ngIf="isUpdateChecklist">
                    <br>
                    <div style="margin-left: 15px;"><b>Update Schedule Meeting</b></div>
                    <mat-divider></mat-divider>
                    <div class="table-pad">
                        <div class="mat-elevation-z1 example-container">
                            <table mat-table [dataSource]="dataSource2">

                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            *ngIf="row.status === 'Attended'" disabled="true" [checked]="true">
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            *ngIf="row.status !== 'Attended'"
                                            (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="sno">
                                    <th mat-header-cell *matHeaderCellDef> S. No. </th>
                                    <td mat-cell *matCellDef="let element; let i=index;">
                                        {{ (i+1) + (paginator2.pageIndex * paginator2.pageSize) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>Employee Name </th>
                                    <td mat-cell *matCellDef="let row"> {{row.empname}} </td>
                                </ng-container>

                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef>Joining Date </th>
                                    <td mat-cell *matCellDef="let row"> {{row.dateofjoin | date:'dd-MM-yyyy'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>Status </th>
                                    <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="addChecklistColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: addChecklistColumns;"
                                    (click)="selection.toggle(row)">
                                </tr>
                            </table>
                            <mat-paginator
                                [pageSizeOptions]="!pageLoading2 ? getPageSizes3() : [5, 10, 20]"></mat-paginator>
                        </div>
                    </div>
                    <br>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-left: 10px;">
                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                            <mat-label>Update Status</mat-label>
                            <mat-select required formControlName="updatestatus">
                                <ng-container>
                                    <div *ngFor="let b of companyList">
                                        <mat-option value={{b}}>{{b}}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>

                        </mat-form-field>
                    </div>

                    <div fxLayoutAlign="center">
                        <button *ngIf="!isRequestView && !isEditView" class="btn btn-primary mr-2 mb-2"
                            (click)="updateSchedue()" type="submit">
                            <span>Submit</span>
                        </button>
                        <button *ngIf="isEditView" class="btn btn-primary mr-2 mb-2" (click)="updateRequest()"
                            type="submit">
                            <span>Update</span>
                        </button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                            <span>Cancel</span>
                        </button>
                        <br />
                    </div>

                </div>

            </div>
            <br>
            <!-- <div fxLayout="row" fxLayout.xs="row" fxFlex.xs="100%" fxLayoutAlign="end" *ngIf="isdata">
                <span style="padding-right: 7px;">
                    <button class="btn btn-primary mr-2 mb-2"
                        (click)="Add()" type="button">
                        <span>Add</span>
                    </button>
                </span>
    
            </div> -->
            <div fxLayout="column" *ngIf="isdata" class="table-pad">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef> S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                        </ng-container>

                        <ng-container matColumnDef="ptype">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">Program
                                Type</th>
                            <td mat-cell *matCellDef="let row"> {{row.program_name}}</td>
                        </ng-container>
                        <ng-container matColumnDef="department">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
                            <td mat-cell *matCellDef="let row"> {{row.department_name}}</td>
                        </ng-container>
                        <ng-container matColumnDef="conductby">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Conduct By</th>
                            <td mat-cell *matCellDef="let row"> {{row.conducted_by_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.schedule_date |
                                date:'dd-MM-yyyy'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="stime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Start Time</th>
                            <td mat-cell *matCellDef="let row"> {{row.schedule_starttime | slice:0:5}}</td>
                        </ng-container>
                        <ng-container matColumnDef="etime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">End Time</th>
                            <td mat-cell *matCellDef="let row"> {{row.schedule_endtime | slice:0:5}}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let row"> {{row.status}}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
                                <span
                                    *ngIf="row.status !='Completed' && row.status !='Cancelled' && row.status !='Finished'">
                                    <button mat-icon-button [tabIndex]="[i+1]" title="Assign"
                                        (keyup.enter)="requestView(row)">
                                        <mat-icon class="mr-2" style="cursor: pointer;" title="Assign"
                                            (click)="requestView(row)">group_add</mat-icon>
                                    </button>
                                    <button mat-icon-button [tabIndex]="[i+1]" title="Edit"
                                        (keyup.enter)="editRequest(row)">
                                        <mat-icon class="mr-2" style="cursor: pointer;" title="Edit"
                                            (click)="editRequest(row)">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [tabIndex]="[i+1]" title="Program Cancel"
                                        (keyup.enter)="editRequest(row)">

                                        <mat-icon class="mr-2" style="cursor: pointer;" title="Program Cancel"
                                            (click)="cancelProgram(row)">cancel_presentation</mat-icon>
                                    </button>
                                </span>
                                <!-- <mat-icon class="mr-2" style="cursor: pointer;" *ngIf="row.status ==='Finished'" title="Update" (click)="updating(row)" ><img src="assets\img\update-img.png" style="height: 22px" /></mat-icon> -->
                                <button mat-icon-button [tabIndex]="[i+1]" *ngIf="row.status ==='Finished'"
                                    title="Update" (keyup.enter)="updating(row)">
                                    <mat-icon class="mr-2" style="cursor: pointer;" *ngIf="row.status ==='Finished'"
                                        title="Update" (click)="updating(row)">rate_review</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+1]"
                                    *ngIf="row.status ==='Completed' || row.status ==='Cancelled' || row.status ==='Finished'"
                                    title="Update" (keyup.enter)="viewProgram(row)">

                                    <mat-icon class="mr-2" style="cursor: pointer;"
                                        *ngIf="row.status ==='Completed' || row.status ==='Cancelled' || row.status ==='Finished'"
                                        title="View" (click)="viewProgram(row)">visibility</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                </div>


            </div>

        </form>
    </mat-card>
</div>