import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PayrollService } from '../../payroll.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe, Location } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


@Component({
  selector: 'app-advance-salary-request',
  templateUrl: './advance-salary-request.component.html',
  styleUrls: ['./advance-salary-request.component.scss']
})
export class AdvanceSalaryRequestComponent implements OnInit {
  AdvanceSalaryRequestform!: UntypedFormGroup;
  userSession: any;
  employeeId: any;
  year: any;
  month: any;
  installments: any;
  advance: any;
  btnDisable: boolean = false;

  displayedColumns: string[] = ['sno', 'appliedon', 'request_amount', 'paid_amount', 'paid_date', 'recovery_duration', 'recovered_months', 'recovered_amt', 'recovery_balance', 'recovery_status', 'request_status']
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  isadd: boolean = true;
  constructor(private router: Router, private ActivatedRoute: ActivatedRoute, private formBuilder: UntypedFormBuilder, private PR: PayrollService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.employeeId = this.userSession.id;
    this.getEmployeeAdvanceSalaryDetails(this.userSession.id)
    this.getAdvSalaryValuesForEmployee(this.employeeId);


    this.AdvanceSalaryRequestform = this.formBuilder.group(
      {
        maxAmount: [2400],
        date: [],
        requestAmount: [''],
        recovermonths: ['', [Validators.required, this.noLeadingZeros]],
        grossSalary: [''],
        paidAmount: [''],
        reqEligibleAmount: []
      });
  }
  /**Submit  Asvance salary */
  submit() {
    if (this.btnDisable) {
      return;
    }
    let value = this.AdvanceSalaryRequestform.controls.requestAmount.value;
    let remainingAmount = this.AdvanceSalaryRequestform.controls.reqEligibleAmount.value;
    if (value > remainingAmount) {
      this.AdvanceSalaryRequestform.controls.requestAmount.setValue('')
      if (remainingAmount !== null) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Request amount should not be more than ' + remainingAmount,

        });
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Pay Group is not assigned.',
        });
      }

    }
    else if (this.AdvanceSalaryRequestform.valid) {

      let maxAmount = this.AdvanceSalaryRequestform.controls.maxAmount.value;
      let rAmount = this.AdvanceSalaryRequestform.controls.requestAmount.value;
      if (this.advance == null) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: ' Advance salary can be applied only when at least one month payroll is processed.'
        });

      }
      else {
        if (Number(this.installments) >= Number(this.AdvanceSalaryRequestform.controls.recovermonths.value)) {
          if (Number(this.advance) >= Number(rAmount)) {
            let data = {
              employee_id: this.employeeId,
              year_value: this.year,
              month_value: this.month,
              amount_value: this.AdvanceSalaryRequestform.controls.requestAmount.value,
              request_status: 'Submitted',
              recovery_duration: this.AdvanceSalaryRequestform.controls.recovermonths.value,
              created_by_value: this.employeeId
            }
            this.btnDisable = true;
            this.PR.setEmployeeAdvanceSalaryRequestDetails(data).subscribe((result: any) => {
              let resMessage = result.data.statuscode === 0 ? 'Advance salary request submitted successfully.'
                : result.data.statuscode === 1 ? 'There is no previous salary generated.'
                  : result.data.statuscode === 2 ? 'Advance salary exceeded gross salary ' : 'Unable to save data'

              if (result.status) {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                  this.router.navigate(["/Payroll/advanceSalaryRequest"]));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: resMessage
                });
              }
              else {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: 'Unable to submit advance salary request.'
                });
                this.btnDisable = false;
              }
            })
          }
          else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Request Amount must less than max amount'
            });
            this.btnDisable = false;
          }


        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Entered recovery months must not be greater than company configured recovery months value'
          });

          this.btnDisable = false;
        }


      }
    }
  }


  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/advanceSalaryRequest"]));
    // this.router.navigate(['/Payroll/advanceSalaryRequest']);

  }

  Edit(data: any) {

  }

  noLeadingZeros(control: any) {
    const value = control.value;
    if (value !== '' && value.charAt(0) === '0') {
      return { leadingZero: true };
    }
    return null;
  }

  getEmployeeAdvanceSalaryDetails(empid: any) {
    let data = {
      empid: empid
    }
    this.PR.getEmployeeAdvanceSalaryDetails(data).subscribe((result: any) => {
      if (result.status) {

        this.dataSource = result.data;

        this.AdvanceSalaryRequestform.controls.grossSalary.setValue(result.res_gs[0].grossSalary);
        if (result.obj[0].paidAmount != null) {
          this.AdvanceSalaryRequestform.controls.paidAmount.setValue(result.obj[0].paidAmount);
          this.AdvanceSalaryRequestform.controls.reqEligibleAmount.setValue(result.res_gs[0].grossSalary - result.obj[0].paidAmount)
        } else if (result.obj[0].paidAmount == null) {
          this.AdvanceSalaryRequestform.controls.reqEligibleAmount.setValue(result.res_gs[0].grossSalary);

        }
        this.AdvanceSalaryRequestform.controls.paidAmount.disable();
        this.AdvanceSalaryRequestform.controls.reqEligibleAmount.disable();
      }

    })
  }

  /**getAdvSalaryValuesForEmployee */
  getAdvSalaryValuesForEmployee(empid: any) {
    let data = {
      empid: empid
    }
    this.PR.getAdvSalaryValuesForEmployee(data).subscribe((result: any) => {
      if (result.status) {
        this.year = result.data[0].year_value;
        this.month = result.data[0].month_value;
        this.advance = result.data[0].advance_limit;
        this.installments = result.data[0].installments_count
        this.AdvanceSalaryRequestform.controls.recovermonths.setValue(result.data[0].installments_count)
      }
      else {

      }
    })

  }


  /**validateAdvanceSalaryStatus */

  validateAdvanceSalaryStatus() {
    this.isadd = false;
    let data = {
      empid: this.employeeId
    }
    this.PR.validateAdvanceSalaryStatus(data).subscribe((result: any) => {
      if (result.status && result.data[0].validity == 1) {
        this.isadd = false;
      }
      else {
        this.isadd = true;
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: result.data[0].message
        });

      }
    })
  }

  // onchangeAmount() {
  //   let value = this.AdvanceSalaryRequestform.controls.requestAmount.value;
  //   let remainingAmount = this.AdvanceSalaryRequestform.controls.reqEligibleAmount.value;
  //   if (value > remainingAmount) {
  //     this.AdvanceSalaryRequestform.controls.requestAmount.setValue('')
  //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //       position: { top: `70px` },
  //       disableClose: true,
  //       data: 'Request amount should not be more than ' + remainingAmount,

  //     });

  //   }
  // }

}
