<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div fxLayout="row" fxLayoutAlign="center">
  <h1 mat-dialog-title>Details of Punch Summary Report</h1>
</div>

<div fxLayout="row" style="align-items: stretch">
  <div fxLayout="column" style="margin-top: 20px" fxFlex>
    <div fxLayout="column" style="max-width: 600px">
      <div fxLayout="row" style="margin-bottom: 5px">
        <p>
          <strong>Employee ID : {{ empid }}</strong>
        </p>
        <span>{{ empId }}</span>
      </div>
      <div fxLayout="row" style="margin-bottom: 5px">
        <p>
          <strong>Employee Name : {{ employeeName }}</strong>
        </p>
        <span>{{ empName }}</span>
      </div>
    </div>
  </div>
  <!-- <div fxLayout="column" style="margin-top: 20px;" fxFlex="50">
        <div fxLayout="column" style="max-width: 600px;">
            <div fxLayout="row" style="margin-bottom: 5px;">
                <p><strong>Present :</strong></p>
                <span>{{present}}</span>
            </div>
            <div fxLayout="row" style="margin-bottom: 5px;">
                <p><strong>Absent :</strong></p>
                <span>{{absent}}</span>
            </div>
            <div fxLayout="row" style="margin-bottom: 5px;">
                <p><strong>Total :</strong></p>
                <span>{{total}}</span>
            </div>
        </div>
    </div> -->
</div>
<div class="mat-elevation-z1 example-container">
  <table mat-table [dataSource]="dataSource" class="table-pad">
    <!--  *ngIf="List.length > 0" -->
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef>
        <span style="text-wrap: nowrap">S. No.</span>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let row" style="text-wrap: nowrap;">
        {{ row.attendancedate | date : "dd-MM-yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="intime">
      <th mat-header-cell *matHeaderCellDef>First In</th>
      <td mat-cell *matCellDef="let row">
        {{ row.firstlogintime | date : "HH:mm:ss" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="outtime">
      <th mat-header-cell *matHeaderCellDef>Last out</th>
      <td mat-cell *matCellDef="let row">
        {{ row.lastlogouttime | date : "HH:mm:ss" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalHours">
      <th mat-header-cell *matHeaderCellDef>Total Hours</th>
      <td mat-cell *matCellDef="let row">{{ row.totalhours }}</td>
    </ng-container>

    <ng-container matColumnDef="breaks">
      <th mat-header-cell *matHeaderCellDef>Punch Details</th>
      <td mat-cell *matCellDef="let row">
        <p *ngFor="let time of row.breaks">{{ time }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="breakTime">
      <th mat-header-cell *matHeaderCellDef>Break Time</th>
      <td mat-cell *matCellDef="let row">{{ row.breaktime }}</td>
    </ng-container>
    <ng-container matColumnDef="productiveHours">
      <th mat-header-cell *matHeaderCellDef>Productive Hours</th>
      <td mat-cell *matCellDef="let row">{{ row.productivehours }}</td>
    </ng-container>
    <ng-container matColumnDef="otHours">
      <th mat-header-cell *matHeaderCellDef>OT Hours</th>
      <td mat-cell *matCellDef="let row">{{ row.overtime_minutes }}</td>
    </ng-container>
    <ng-container matColumnDef="permissionHours">
      <th mat-header-cell *matHeaderCellDef>Permission Hours</th>
      <td mat-cell *matCellDef="let row">{{ row.permissionhours }}</td>
    </ng-container>

    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; sticky: true"></tr>

    <tr class="mat-row" style="text-align: center; padding-top: 20px" *matNoDataRow>
      <td *ngIf="this.List.length == 0" class="mat-cell" colspan="10">
        No data found
      </td>
    </tr>
  </table>

  <!-- </div> -->
</div>