<div fxLayout="column" fxFill class="custom-investment-of first-col">
  <mat-card fxFill>
    <!-- <mat-card-header class="headerbox" fxLayoutAlign="space-between" >
        
        <mat-card-title class="title"> Advance Salary Request</mat-card-title>
        <span *ngIf="isadd">
            <button mat-icon-button style="padding-right: 40px;" [tabIndex]="[1]" (keyup.enter)="validateAdvanceSalaryStatus()" (click)="validateAdvanceSalaryStatus()" matTooltip="Add">
              <mat-icon style="font-size: 30px;overflow: visible;color: #ffffff">add_circle</mat-icon>
            </button>
        </span>
       </mat-card-header> -->
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Advance Salary Request </mat-card-title>
      <div style="margin-right: 2%;" *ngIf="isadd">
        <button mat-icon-button style="padding-right: 40px;" (keyup.enter)="validateAdvanceSalaryStatus()"
          (click)="validateAdvanceSalaryStatus()" matTooltip="Add">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>

    <div *ngIf="!isadd">
      <form [formGroup]="AdvanceSalaryRequestform" autocomplete="off" *ngIf="!isadd">



        <div fxLayout.lt-lg="column" fxLayoutGap="15px" style="padding-left: 15px;padding-right: 15px;">

          <div fxLayout="column" fxFlex class="custom-payroll-div">
            <div fxLayout="row" fxLayout.xs="column">
              <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                <mat-label>Gross salary</mat-label>
                <input matInput placeholder="" formControlName="grossSalary" readonly />
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                <mat-label>Paid Amount</mat-label>
                <input matInput placeholder="" formControlName="paidAmount" readonly />

              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                <mat-label>Request Eligible Amount</mat-label>
                <input matInput placeholder="" formControlName="reqEligibleAmount" readonly />
              </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <br>
            <div>

              <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                <mat-label>Required advance payment amount</mat-label>
                <input matInput placeholder="" formControlName="requestAmount" required onlyNumber maxlength="6"
                  minlength="3" />
                <mat-error class="con-error" *ngIf="AdvanceSalaryRequestform.controls.requestAmount.errors?.required">
                  This field is required.
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                <mat-label>Number of months to recover this amount</mat-label>
                <input matInput placeholder="" formControlName="recovermonths" required maxlength="2" minlength="1"
                  onlyNumber />
                <mat-error class="con-error" *ngIf="AdvanceSalaryRequestform.controls.recovermonths.errors?.required">
                  This field is required
                </mat-error>
                <mat-error class="con-error"
                  *ngIf="AdvanceSalaryRequestform.controls.recovermonths.errors?.leadingZero">
                  Please enter valid value.
                </mat-error>
              </mat-form-field>

              <!-- <p style="display: -webkit-box" fxLayout="row" >
                            <b style="padding-top: 2%;"> Required advance payment amount</b>
                            
                         </p>
                         <p style="display: -webkit-box" fxLayout="row" >
                            <b style="padding-top: 2%;">Recover this amount in </b> 
                            <mat-form-field fxFlex.xs="30"   class="mx-2 my-2" appearance="outline"  fxFlex="20">
                            </mat-form-field>
                            <b style="padding-top: 2%;" >months</b>
                         </p> -->
            </div>

          </div>

        </div>
        <div class="form-group row" style="margin-top: -30px !important;">
          <div class="col-sm-6 text-right" style="padding:0px">
            <button class="btn btn-primary custom mr-2 mb-2" [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}"
              (click)="submit()" type="submit">
              <span>Submit</span>
            </button>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
              <span>Cancel</span>
            </button>
          </div>
        </div>



      </form>

    </div>
    <br>
    <div class="example-container mat-elevation-z8" *ngIf="isadd">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->

        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> <span style="text-wrap: nowrap;">S. No.</span></th>
          <td mat-cell *matCellDef="let element; let i=index;">
            <!-- {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td> -->
            {{i+1}}
          </td>
        </ng-container>
        <ng-container matColumnDef="appliedon">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Applied On </th>
          <td mat-cell *matCellDef="let row"> {{row.applied_on | date:'dd-MM-yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="request_amount" class="no-wrap">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Request Amount </th>
          <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.request_amount |
            number:'1.2-2'}}</td>
        </ng-container>
        <!-- Progress Column  empid-->
        <ng-container matColumnDef="paid_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap"> Paid Amount</th>
          <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.paid_amount |
            number:'1.2-2'}}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="requestamount">
            <th mat-header-cell *matHeaderCellDef > Requested Amount  </th>
            <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.request_amount}}</td>
          </ng-container> -->

        <ng-container matColumnDef="paid_date">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Paid Date </th>
          <td mat-cell *matCellDef="let row" class="no-wrap">
            <span *ngIf="row.paid_date !='-' ">{{row.paid_date | date:'dd-MM-yyyy'}}</span>
            <span *ngIf="row.paid_date ==='-' ">{{row.paid_date}}</span>
          </td>


        </ng-container>
        <ng-container matColumnDef="recovery_duration">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Recovery Duration </th>
          <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.recovery_duration}}</td>
        </ng-container>
        <ng-container matColumnDef="recovered_months">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Recovered Months </th>
          <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.recovered_months}}</td>
        </ng-container>

        <ng-container matColumnDef="recovered_amt">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Recovered Balance </th>
          <td mat-cell *matCellDef="let row" style="text-align: center;">
            <span *ngIf="row.recovery_balance !='-'  "> {{row.paid_amount - row.recovery_balance |
              number:'1.2-2'}}</span>
            <!-- <span *ngIf="row.recovery_balance ==='-'  " > {{row.paid_amount}}</span> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="recovery_balance">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Pending Amount </th>
          <td mat-cell *matCellDef="let row" style="text-align: center;">{{row.recovery_balance |
            number:'1.2-2'}}

          </td>
        </ng-container>

        <ng-container matColumnDef="recovery_status">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Recovery Status </th>
          <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.recovery_status}}</td>
        </ng-container>

        <ng-container matColumnDef="request_status">
          <th mat-header-cell *matHeaderCellDef class="no-wrap"> Request Status </th>
          <td mat-cell *matCellDef="let row"> {{row.request_status}}</td>
        </ng-container>
        <!-- Color Column -->

        <!-- Color Column -->
        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
        </tr>
      </table>
      <!-- <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator> -->

    </div>







  </mat-card>



</div>