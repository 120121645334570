import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PayrollService } from '../../payroll.service';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import * as XLSX from "xlsx";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { MatRadioChange } from '@angular/material/radio';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { NgxSpinnerService } from 'ngx-spinner';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

/**
 * @title Table with selection
 */

@Component({
  selector: 'app-monthly-payroll',
  templateUrl: './monthly-payroll.component.html',
  styleUrls: ['./monthly-payroll.component.scss']
})
export class MonthlyPayrollComponent implements OnInit {
  monthlyPayrollForm!: UntypedFormGroup;
  userSession: any;
  financeyears: any = [];
  monthyear: any = [];
  year_value: any;
  month_value: any;
  monthforemplist: any;
  yearforemplist: any;
  pageLoading = true;
  arrdata: any = [];
  hide: boolean = false;
  messagesList: any = [];
  PR27: any;
  PR28: any;
  PR46: any;
  generate: boolean = true;
  @ViewChild(MatPaginator) paginator: any;
  @ViewChild(MatPaginator) paginator2: any;
  @ViewChild(MatSort)
  sort: any;
  @ViewChild('table') table!: ElementRef;
  messageflag: boolean = true;
  message: any;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // displayedColumns: string[] = ['select','position','empid',  'name', 'designation', 'worklocation'];
  displayedColumns: string[] = ['select', 'sno', 'empid', 'name', 'designation', 'worklocation'];
  displayedColumns2: string[] = ['sno', 'empid', 'empname', 'amount', 'accountnumber', 'bank', 'ifsc'];
  dataSource = new MatTableDataSource<any>();
  dataSource2 = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  searchControl = new FormControl('');
  filteredLocations: any = [];


  constructor(private router: Router, private formBuilder: UntypedFormBuilder,
    private PR: PayrollService,
    private dialog: MatDialog, private companyService: CompanySettingService,
    private spinner: NgxSpinnerService,) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
  }
  worklocationDetails: any = [];
  companyName: any;
  selectedLocation: any;
  dynamicPayScheduleAllowed: boolean = false;
  ngOnInit(): void {
    this.companyName = sessionStorage.getItem("companyName");
    this.getFinancialYears();
    this.getMessagesList();
    this.getWorkLocation();
    this.monthlyPayrollForm = this.formBuilder.group(
      {
        Month_year: ['', Validators.required],
        financial_year: ['', Validators.required],
        location: ['']
      });
    this.monthlyPayrollForm.get('financial_year')?.valueChanges.subscribe((selectedValue: any) => {
      this.yearforemplist = selectedValue;
      this.Month_Year(selectedValue);
    })

    this.monthlyPayrollForm.get('Month_year')?.valueChanges.subscribe((selectedValue: any) => {
      this.selection.clear();
      this.month_value = selectedValue;
      for (let m = 0; this.monthyear.length; m++) {
        if (this.monthyear[m].month_value == this.month_value) {
          this.yearforemplist = this.monthyear[m].year_value;
          break;
        }
      }
      this.monthlyPayrollForm.updateValueAndValidity();
      if (this.monthlyPayrollForm.valid) {
        if (this.generate) {
          this.getEmployeeListForSalaryProcessing();
        }
        else {
          this.getsalaryProcessedEmployeesList(this.yearforemplist, this.month_value)
        }
      }

    })
    this.monthlyPayrollForm.get('location')?.valueChanges.subscribe((selectedValue: any) => {
      this.selection.clear();
      this.selectedLocation = selectedValue;
      this.monthlyPayrollForm.get('financial_year')?.markAsTouched();
      this.monthlyPayrollForm.get('Month_year')?.markAsTouched();
      if (this.monthlyPayrollForm.valid) {
        if (this.generate) {
          this.getEmployeeListForSalaryProcessing();
        }
        else {
          this.getsalaryProcessedEmployeesList(this.yearforemplist, this.month_value)

        }
      }
    });
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText)
    })

  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  logSelection() {
    this.selection.selected.forEach((s: any) => console.log(s.employee_code));
  }
  /** getFinancialYears Data*/
  getFinancialYears() {
    this.PR.getFinancialYears().subscribe((result: any) => {

      if (result.status && result.data.length > 0) {
        this.financeyears = result.data;
      }
      else {
        this.financeyears = []
      }
    })
  }
  /**Month and Year Data */
  Month_Year(data: any) {
    // this.monthyear=[{"month_year":"October-2022","return_value":"1"},{"month_year":"November-2022","return_value":"2"}]
    this.PR.Month_Year(data).subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.monthyear = result.data;
      }
      else {
        this.monthyear = []
      }
    })
  }

  validateSalaryProcessingDate() {
    this.selection.selected.forEach((s: any) => this.arrdata.push(s.id));
    if (this.arrdata.length > 0) {
      if (this.monthlyPayrollForm.valid) {
        for (let i = 0; i < this.monthyear.length; i++) {
          if (this.monthyear[i].month_value == this.month_value) {
            this.year_value = this.monthyear[i].year_value;
          }
        }
        let year = Number(this.year_value);
        let month = this.month_value;
        this.PR.getValidateSalaryProcessingDate(year, month).subscribe((result: any) => {
          if (result.data) {
            if (result.data[0].validity == 1) {
              this.caluculateMonthlySalary()

            }
            else {
              let date = (new Date(result.data[0].end_date).getDate() < 10 ? "0" + new Date(result.data[0].end_date).getDate() : new Date(result.data[0].end_date).getDate()) + '-' + ((new Date(result.data[0].end_date).getMonth() + 1) < 10 ? "0" + (new Date(result.data[0].end_date).getMonth() + 1) : (new Date(result.data[0].end_date).getMonth() + 1)) + '-' + new Date(result.data[0].end_date).getFullYear();
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `10%` },
                disableClose: true,
                data: 'Please processing your salary after' + ' ' + date
              });
            }
          }
          else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.PR28
            });
          }

        })

      }
    }
    else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.PR46
      });
    }


  }
  /**Caluculate salary for selected employees */
  caluculateMonthlySalary() {
    this.spinner.show();
    this.arrdata = []
    this.selection.selected.forEach((s: any) => this.arrdata.push(s.id));
    if (this.arrdata.length > 0) {
      if (this.monthlyPayrollForm.valid) {
        for (let i = 0; i < this.monthyear.length; i++) {
          if (this.monthyear[i].month_value == this.month_value) {
            this.year_value = this.monthyear[i].year_value;
          }
        }
        let data = {
          year_value: Number(this.year_value),
          month_value: this.month_value,
          financial_year_value: this.monthlyPayrollForm.controls.financial_year.value,
          created_by_value: this.userSession.id,
          employee_list: this.arrdata,
          dynamicPayScheduleAllowed: this.dynamicPayScheduleAllowed
          // employee_list:this.dataSource.data.length == this.arrdata.length?null:this.arrdata
        }

        this.PR.updateMonthlySalary(data).subscribe((result: any) => {
          if (result.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Payroll/MonthlyPayroll"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.PR27
            });
            this.spinner.hide();
          }
          else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.PR28
            });
            this.spinner.hide();
          }

        })

      }

    }
    else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.PR46
      });
      this.spinner.hide();
    }


  }
  /**Cancel requests */
  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/MonthlyPayroll"]));
  }
  /**Success and error messages list */
  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.PR.getErrorMessages(null, 1, 1000).subscribe((res: any) => {
      if (res.status && res.data && res.data.length > 0) {
        this.messagesList = res.data;
        this.messagesList.forEach((e: any) => {
          if (e.code == "PR27") {
            this.PR27 = e.message
          } else if (e.code == "PR28") {
            this.PR28 = e.message
          }
          else if (e.code == "PR46") {
            this.PR46 = e.message
          }
        })

      }

    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(this.generate == true){
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    } else {
      this.dataSource2.filter = filterValue.trim().toLowerCase();
      if (this.dataSource2.paginator) {
        this.dataSource2.paginator.firstPage();
      }
    }
  }

  /**getEmployees List for caluculate monthly salary */
  getEmployeeListForSalaryProcessing() {
    let year = this.yearforemplist;
    let month = this.month_value;
    let location = this.selectedLocation != null ? this.selectedLocation : 0
    this.dataSource = new MatTableDataSource<any>([]);
    this.PR.getEmployeeListForSalaryProcessing(year, month, location).subscribe((result: any) => {
      if (result.status && result.data[1][0].dynamicPayScheduleAllowed == 1 && result.data[1][0].paySchedule == "CALENDAR") {
        this.dynamicPayScheduleAllowed = true;
      }
      if (result.status && result.data[0].length > 0) {
        this.dataSource = new MatTableDataSource<any>(result.data[0]);
        this.generate = true;
        this.hide = true;
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        // this.pageLoading = false;
        // this.hide = true;
      }
      else {
        this.dataSource = new MatTableDataSource<any>([]);
      }
    })
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource2.paginator = this.paginator2;
  }
  /**getEmployees List after processing salary */
  getsalaryProcessedEmployeesList(year: any, month: any) {
    let data = {
      year: year,
      month: month,
      location_id: this.selectedLocation != null ? this.selectedLocation : 0
    }
    this.PR.getSalaryProcessedEmployeeList(data).subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.dataSource2 = new MatTableDataSource(result.data)
        this.dataSource2.paginator = this.paginator2;
        this.dataSource2.sort = this.sort;
        this.pageLoading = false;
        this.hide = true;
      }
      else {
        this.dataSource2 = new MatTableDataSource<any>([]);
      }
    })
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource2.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource2.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource2.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource2.data.length);
    return customPageSizeArray;
  }

  getPageSizeslist(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  exportAsXLSX() {
    if (this.messageflag) {

      var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('table'));
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Payroll_report');

      /* save to file */
      XLSX.writeFile(wb, this.month_value + '-' + this.yearforemplist + '-' + 'Monthly_Payroll_Report.xlsx');

    }
    else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message
      });

    }


  }

  public exportPDF(): void {

    if (this.messageflag) {
      const pdfTable = this.table.nativeElement;
      var html = htmlToPdfmake(pdfTable.innerHTML);
      pdfMake.createPdf({
        info: {
          title: this.month_value + '-' + this.yearforemplist + '-' + "Monthly Payroll Report",
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report'
        },
        footer: function (currentPage: any, pageCount: any) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  }
                ],
                alignment: 'center'
              }
            ]
          };
        },
        content: [
          {
            text: this.month_value + '-' + this.yearforemplist + '-' + "Monthly Payroll Report\n\n",
            style: 'header',
            alignment: 'center',
            fontSize: 14
          },
          html
        ],
        pageOrientation: 'landscape',//'portrait'
        pageSize: 'A4'
      }).download("Monthly Payroll Report.pdf");
    }
    else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message
      });
    }

  }

  radioChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.generate = true;
      this.getEmployeeListForSalaryProcessing();
    } else if (event.value == 2) {
      this.generate = false;
      this.getsalaryProcessedEmployeesList(this.yearforemplist, this.month_value)
      this.validateSalaryChallanDownload()
    }
  }

  validateSalaryChallanDownload() {
    let data = {
      month: this.month_value,
      year: this.yearforemplist,
      location_id: this.selectedLocation != null ? this.selectedLocation : 0
    }
    this.PR.validateSalaryChallanDownload(data).subscribe((result: any) => {
      if (result.status && result.data[0].validity == 0) {
        this.messageflag = false;
        this.message = result.data[0].message

      }
      else {
        this.messageflag = true;
        this.message = ''
      }

    })
  }

  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails;
    })
  }
  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredLocations.length <= 0) {
      this.searchControl.setValue('');
    }
  }
}
