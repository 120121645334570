import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  mainUrl = environment.apiUrl;
  userSession: any;
  httpOptions: any;
  companyName: any;
  constructor(private http: HttpClient) {
    this.companyName = sessionStorage.getItem('companyName');
    this.httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        "Authorization": JSON.parse(JSON.stringify(sessionStorage.getItem('token') || '')),
      })
    };
  }
  getMastertable(tableName: any, status: any, page: any, size: any, companyName: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getMastertable/' + tableName + '/' + status + '/' + page + '/' + size + '/' + this.companyName, this.httpOptions);
  }
  excelDataForAttendance(data: any, rmid: any): Observable<any> {
    // data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setEmployeeAttendance/' + this.companyName + '/' + rmid, JSON.stringify(data), this.httpOptions);
  }
  getShiftDetailsByEmpId(employee_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getemployeeshift/' + employee_id + '/' + this.companyName, this.httpOptions);
  }
  getgetemployeesByMangerId(employee_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getEmployeesByManagerId/' + employee_id + '/' + this.companyName, this.httpOptions);
  }
  getWorkypeList(tableName: any, status: any, page: any, size: any, companyName: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getMastertable/' + tableName + '/' + status + '/' + page + '/' + size + '/' + this.companyName, this.httpOptions);
  }
  setemployeeattendanceregularization(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setemployeeattendanceregularization', JSON.stringify(data), this.httpOptions);
  }
  updateAttendanceRequest(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setattendanceapprovalstatus', JSON.stringify(data), this.httpOptions);
  }
  getAttendanceRequestListByEmpId(employee_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getemployeeattendanceregularization/' + employee_id + '/' + this.companyName, this.httpOptions);
  }
  getPendingAttendanceListByManagerEmpId(employee_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getpendingattendanceregularizations/' + employee_id + '/' + this.companyName, this.httpOptions);
  }
  getAttendanceRegularizationByManagerId(manager_employee_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getAttendanceRegularizationByManagerId/'
      + manager_employee_id + '/' + this.companyName, this.httpOptions);
  }

  getemployeeattendancedashboard(data: any): Observable<any> {
    data.companyName = sessionStorage.getItem('companyName');
    return this.http.post(this.mainUrl + 'attendance/api/getemployeeattendancedashboard',
      data, this.httpOptions
    )
  }
  getAttendanceDetailsByAttendanceID(data: any): Observable<any> {
    return this.http.post(this.mainUrl + 'attendance/api/getAttendanceDetailsByAttendanceID',
      data, this.httpOptions
    )
  }
  getEmployeeAttendanceNotifications(data: any): Observable<any> {
    data.companyName = sessionStorage.getItem('companyName');
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeAttendanceNotifications',
      data, this.httpOptions
    )
  }
  getEmployeeConfigureShifts(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeConfigureShifts',
      data, this.httpOptions
    )
  }
  setEmployeeConfigureShift(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setEmployeeConfigureShift', data, this.httpOptions)
  }

  getActiveShiftIds(): Observable<any> {
    return this.http.get(this.mainUrl + 'admin/api/getActiveShiftIds/' + this.companyName,
      this.httpOptions
    )
  }
  //
  getAttendanceRegularizationsHistoryForManager(employee_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getAttendanceRegularizationsHistoryForManager/' + employee_id + '/' + this.companyName, this.httpOptions);
  }

  deleteAttendanceRequestById(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/deleteAttendanceRequestById',
      data, this.httpOptions
    )
  }
  getEmployeeShiftByDates(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeShiftByDates', data, this.httpOptions)
  }
  getEmployeeCurrentShifts(data: any): Observable<any> {
    data.companyName = sessionStorage.getItem('companyName');
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeCurrentShifts', data, this.httpOptions)
  }
  getEmployeeWeekoffsHolidaysForAttendance(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeWeekoffsHolidaysForAttendance', data, this.httpOptions)
  }
  getEmployeeInformation(id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getEmployeeInformation/' + id + '/' + this.companyName, this.httpOptions);
  }
  getEmployeeInformationforlogindate(id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getEmployeeInformationforlogindate/' + id + '/' + this.companyName, this.httpOptions);
  }

  getShiftsByEmpIdAndDates(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/getShiftsByEmpIdAndDates', data, this.httpOptions)
  }
  /** get Shift Change Request List */
  getShiftChangeRequestList(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeShiftSwapping', data, this.httpOptions)
  }
  /**set employee shift change request */
  setShiftChangeRequest(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/setEmployeeShiftSwapping', data, this.httpOptions)
  }

  /** get Shift Change Request List */
  getEmployeesListByShiftDates(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeesByShiftdates', data, this.httpOptions)
  }

  /**set employee shift change request */
  setShiftRequestApproval(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/updateEmployeeShiftSwapping', data, this.httpOptions)
  }
  /**delte employee shift change request */
  deleteShiftRequest(data: any): Observable<any> {
    data.companyName = this.companyName
    return this.http.post(this.mainUrl + 'attendance/api/deleteEmployeeShiftSwapping', data, this.httpOptions)
  }
  /**Get remaing Shifts by employee id for shift change request */
  getShiftsForChangeByEmpID(id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getShiftsForChangeByEmpID/' + id + '/' + this.companyName, this.httpOptions);
  }

  // getEmployeeAttendanceNotifications2(data: any): Observable<any> {
  //   data.companyName = sessionStorage.getItem('companyName');
  //   return this.http.post(this.mainUrl + 'attendance/api/getEmployeeAttendanceNotifications2',
  //     data, this.httpOptions
  //   )
  // }

  /**set Employee Weekoffs configuration*/
  setEmployeeWeekoffs(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setEmployeeWeekoffs', data, this.httpOptions)
  }

  /**get Employees Weekoffs configuration*/
  getEmployeeWeekoffConfigure(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeWeekoffConfigure', data, this.httpOptions)
  }
  // getEmployeeConfigureShifts2(data: any): Observable<any> {
  //   data.companyName = this.companyName;
  //   return this.http.post(this.mainUrl + 'attendance/api/getEmployeeConfigureShifts2',
  //     data, this.httpOptions
  //   )
  // }


  // setEmployeeConfigureShift2(data: any): Observable<any> {
  //   data.companyName = this.companyName;
  //   return this.http.post(this.mainUrl + 'attendance/api/setEmployeeConfigureShift2', data, this.httpOptions
  //   )
  // }

  /**get Employee Shifts History */
  getEmployeeShiftsHistory(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeShiftsHistory',
      data, this.httpOptions
    )
  }

  /**get Employee weekoff History */
  getEmployeeWeekoffsHistory(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeeWeekoffsHistory',
      data, this.httpOptions
    )
  }

  getHalfdayShiftTimings(shift_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getHalfdayShiftTimings/' + shift_id + '/' + this.companyName, this.httpOptions);

  }
  /** getLeavetypeIdForRegularization*/
  getLeavetypeIdForRegularization(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getLeavetypeIdForRegularization', data, this.httpOptions
    )
  }
  /**deleteAttendanceForRegularization */
  deleteAttendanceForRegularization(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/deleteAttendanceForRegularization', data, this.httpOptions
    )
  }
  /**getEmployeesForRegularization */
  getEmployeesForRegularization(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeesForRegularization', data, this.httpOptions)
  }
  /**setAttendanceRegularization */
  setAttendanceRegularization(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setAttendanceRegularization', data, this.httpOptions
    )
  }

  /**setLeaveRegularization */
  setLeaveRegularization(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setLeaveRegularization', data, this.httpOptions
    )
  }

  /**getPendingAttendanceRegularizationsForManager */
  getPendingAttendanceRegularizationsForManager(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getPendingAttendanceRegularizationsForManager', data, this.httpOptions
    )
  }

  /**getManagerWorkLocation */
  getManagerWorkLocation(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getManagerWorkLocation', data, this.httpOptions
    )
  }

  /**set Rotation Shift Sequence */
  setRotationShiftSequence(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setRotationShiftSequence', data, this.httpOptions
    )
  }
  /**set Employee Permission */
  setEmployeePermission(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setEmployeePermission', data, this.httpOptions
    )
  }
  /**get_permissions_for_approval */
  getPermissionsForApproval(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getPermissionsForApproval', data, this.httpOptions)
  }

  /**get_permissions_history */
  getPermissionsHistory(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getPermissionsHistory', data, this.httpOptions)
  }

  /**get_employee_permissions */
  getEmployeePermissions(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeePermissions', data, this.httpOptions)
  }

  /**get Rotation Shift Sequence */
  getRotationShiftSequence(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getRotationShiftSequences', data, this.httpOptions
    )
  }
  /**get Locations For Reporting Manager */
  getLocationsForReportingManager(manager_employee_id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'attendance/api/getLocationsForReportingManager/' + manager_employee_id + '/' + this.companyName, this.httpOptions);
  }

  /** get_employees_for_rotation_shift_assignment */
  getEmployeesForRotationShiftAssignment(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getEmployeesForRotationShiftAssignment', data, this.httpOptions
    )
  }

  /**set Employee Rotation Shift Mapping*/
  setEmployeeRotationShiftMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setEmployeeRotationShiftMapping', data, this.httpOptions
    )
  }

  /**get Assigned Employee Rotation Shift Mapping */
  getAssignedEmployeeRotationShiftMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/getAssignedEmployeeRotationShiftMapping', data, this.httpOptions
    )
  }
  /** get_employees_for_rotation_shift_assignment */
  getEmployeeRotationShiftReMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/getEmployeeRotationShiftReMapping', data, this.httpOptions
    )
  }


  /**setEmployee Rotation Shift ReMapping*/
  setEmployeeRotationShiftReMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setEmployeeRotationShiftReMapping', data, this.httpOptions
    )
  }

  /**For diabling permissionDate weekoffs and holidays*/
  getDaysToBeDisabledFromDate(id: any, leaveId: any): Promise<any> {
    return this.http.get(this.mainUrl + 'api/getdaystobedisabledfromdate/' + id + '/' + leaveId + '/' + this.companyName, this.httpOptions).toPromise();;

  }
  /**For diabling permissionDate compoff*/
  getDaysToBeDisabledForFromDateCompOff(info: any): Promise<any> {
    info.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/getDaysToBeDisabledForFromDateCompOff/', info, this.httpOptions).toPromise();;

  }

  /** set Regularization Weekoffs */
  setRegularizationWeekoffs(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setRegularizationWeekoffs', data, this.httpOptions
    )
  }

  /***get Employee Halfday Attendance Leave Details */
  getEmployeeHalfdayAttendanceLeaveDetails(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/getEmployeeHalfdayAttendanceLeaveDetails', data, this.httpOptions
    )
  }

  /**get halfday EmployeesForRegularization */
  getHalfdayEmployeesForRegularization(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getHalfdayEmployeesForRegularization', data, this.httpOptions)
  }

  setEmployeeAttendanceRegularizationForWeb(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setemployeeattendanceregularizationForWeb', JSON.stringify(data), this.httpOptions);
  }

  /** set Attendance Regularization new **/
  setAttendanceRegularizationNew(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/setAttendanceRegularizationNew', data, this.httpOptions)
  }
  getCurrentdayAttendanceSummaryReport(data: any) {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'attendance/api/getCurrentdayAttendanceSummaryReport', data, this.httpOptions)
  }

    /**get Locations For Reporting Manager */
    getShiftsForRotationSequence(rid: any): Observable<any> {
      return this.http.get(this.mainUrl + 'api/getShiftsForRotationSequence/' + rid + '/' + this.companyName, this.httpOptions);
    }
}




