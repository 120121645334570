import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AttendanceService } from '../../attendance.service';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-rotational-shift-configuration',
  templateUrl: './rotational-shift-configuration.component.html',
  styleUrls: ['./rotational-shift-configuration.component.scss']
})
export class RotationalShiftConfigurationComponent implements OnInit {
  userSession: any;
  constructor(private formBuilder: UntypedFormBuilder, private attendanceService: AttendanceService,
    private router: Router, private dialog: MatDialog) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
  }

  shiftRotationForm: any = UntypedFormGroup;
  shiftDataList: any = [];
  shiftSequenceList: any = [];
  addChecklistData: any = [];
  locationId: any;
  selectedShiftList: any = [];
  isAdd: boolean = true;
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  displayedColumns: string[] = ['sno', 'sequence', 'sequenceList'];
  btnDisable = true;
  filteredShifts: any = [];
  shiftControl = new FormControl('');
  ngOnInit(): void {
    this.shiftRotationForm = this.formBuilder.group({
      rotationName: ["", Validators.required],
      location: [""],
      shift: [""],
      duration: ["", Validators.required],
    });
    this.getActiveShiftIds();
    this.getManagerLocation();

  }

  submit() {
    if (this.btnDisable) {
      return;
    }
    if (this.addChecklistData.length > 0) {
      for (let i = 0; i < this.addChecklistData.length; i++) {
        this.selectedShiftList.push(
          {
            "shiftId": this.addChecklistData[i].shiftid,
            "duration": this.addChecklistData[i].duration
          }
        );
      }
      let data = {
        id_value: null,
        location_id_value: this.locationId,
        sequence_name_value: this.shiftRotationForm.controls.rotationName.value,
        shift_sequence_value: this.selectedShiftList,
        created_by_value: this.userSession.id,
      }
      this.btnDisable = true;
      this.attendanceService.setRotationShiftSequence(data).subscribe((res: any) => {
        if (res.status && res.data.statuscode == 0) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Attendance/ShiftRotationMaster"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Shift Rotation added successfully"
          });
        } else if (res.status && res.data.statuscode == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Shift Rotation already exist"
          });
          this.btnDisable = false;
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to add data"
          });
          this.btnDisable = false;
        }
      })

    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: "Please select atleast one shift"
      });
      this.btnDisable = false;
    }

  }

  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/ShiftRotationMaster"]));
  }
  getActiveShiftIds() {
    this.attendanceService.getActiveShiftIds().subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.filteredShifts = this.shiftDataList;
      }
    });
  }
  getManagerLocation() {
    let data = {
      manager_id_value: this.userSession.id,
    }
    this.attendanceService.getManagerWorkLocation(data).subscribe((res) => {
      if (res.status) {
        let data = res.data
        this.shiftRotationForm.controls.location.setValue(data.manager_location);
        this.locationId = data.locationid;
        this.getShiftSequenceList();
      }
    });
  }
  getShiftSequenceList() {
    let data = {
      manager_id_value: this.userSession.id,
      location_id_value: this.locationId,
    }
    this.attendanceService.getRotationShiftSequence(data).subscribe((res) => {
      if (res.status) {
        this.shiftSequenceList = res.data;
        this.dataSource = new MatTableDataSource(this.shiftSequenceList);
        this.pageLoading = false;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  getRotationOrders(seqValue: string): string {
    if (!seqValue) return '';
    const shifts = JSON.parse(seqValue);
    return shifts.map((shift: any) => `${shift.shiftName} - ${shift.duration} days`).join('<br/>');

  }
  onChange(id: any) {
    this.addChecklistData.push(id);
    this.shiftRotationForm.controls.shift.setValue('')
  }
  removeText(index: number) {
    this.addChecklistData.splice(index, 1);
    this.addChecklistData.length == 0 ? this.btnDisable = true : this.btnDisable = false;
  }

  addShift() {
    this.addValidators();
    if (!this.shiftRotationForm.valid) {
      return;
    }
    let formData = this.shiftRotationForm.value;
    let sid = formData.shift?.shiftid;
    for (let value of this.addChecklistData) {
      if (value.shiftid == sid) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Record already exist'
        });
        return;
      }
    }
    this.addChecklistData.push({
      "shiftid": formData.shift?.shiftid,
      "shiftname": formData.shift?.shiftname,
      "duration": parseInt(formData?.duration)
    });
    this.shiftRotationForm.controls.shift.setValue('');
    this.shiftRotationForm.controls.duration.setValue('');
    this.clearValidators();
    this.addChecklistData.length > 0 ? this.btnDisable = false : this.btnDisable = true;
  }

  getOptionText(option: any) {
    return option.shiftname;
  }
  newAdd() {
    this.isAdd = false;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  editEmployee(id: any, data: any) { }

  filterLocations(searchText: any) {
    this.filteredShifts = this.shiftDataList.filter(
      (val: any) =>
        val.shiftname.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredShifts.length <= 0) {
      this.shiftControl.setValue('');
    }
  }

  addValidators() {
    this.shiftRotationForm.get('shift').setValidators(Validators.required);
    this.shiftRotationForm.get('shift').updateValueAndValidity();

    this.shiftRotationForm.get('duration').setValidators(Validators.required);
    this.shiftRotationForm.get('duration').updateValueAndValidity();
  }
  clearValidators() {
    this.shiftRotationForm.get('shift').clearValidators();
    this.shiftRotationForm.get('shift').updateValueAndValidity();
    this.shiftRotationForm.get('duration').clearValidators();
    this.shiftRotationForm.get('duration').updateValueAndValidity();
  }
}
