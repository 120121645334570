import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { AttendanceService } from '../../attendance.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { environment } from 'src/environments/environment';

import * as _moment from 'moment';
import { ShitConfigureHistoryDialogComponent } from '../shit-configure-history-dialog/shit-configure-history-dialog.component';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-shift-configure',
  templateUrl: './shift-configure.component.html',
  styleUrls: ['./shift-configure.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ShiftConfigureComponent implements OnInit {
  requestform: any = UntypedFormGroup;
  minFromDate: Date;
  maxFromDate: Date | null;
  minToDate: Date | null;
  maxToDate: Date;
  currentDate: Date = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe: any;
  displayedColumns: string[] = [
    'select',
    'id',
    'empid',
    'empname',
    'shiftname',
    'fromdate',
    'todate',
    'status',
  ];
  dataSource: MatTableDataSource<any> = <any>[];
  ATT74: any;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchControl = new FormControl('');

  arrayList: any = [];
  userSession: any;
  shiftDataList: any = [];
  employeesData: any;
  weekDaysDetails: any = [];
  workingDays: any = [];
  weekoffs: any = [];
  selectedEmps: any = [];
  weekofflags: boolean = false;

  pageLoading = true;
  messagesDataList: any = [];
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  recordExist: any;
  companyDBName: any = environment.dbName;
  shifDetails: any;
  finaldate: any;
  selectedDatesDays: any = [];
  isPopup: boolean = false;
  filteredShifts: any = [];
  btnDisable: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private attendanceService: AttendanceService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private router: Router,
    private adminService: AdminService
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate());
    this.maxFromDate = new Date();
    this.maxFromDate.setDate(this.currentDate.getFullYear() + 1);
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() + 1);
    this.maxToDate = new Date();
    this.maxToDate.setDate(this.currentDate.getFullYear() + 1);
    this.arrayList = [];
    let obj = {
      manager_empid: this.userSession.id,
      department_id: null,
    };
    this.attendanceService.getEmployeeConfigureShifts(obj).subscribe((res) => {
      if (res.status) {
        this.arrayList = res.data;
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      } else {
        this.arrayList = [];
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  selection = new SelectionModel<any>(true, []);

  ngOnInit(): void {
    this.getMessagesList();
    this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
    this.requestform = this.formBuilder.group({
      shift: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
    });
    this.getActiveShiftIds();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterShift(searchText);
    });

  }
  shiftChange(shiftId: any) {
    this.shifDetails = null;
    this.requestform.controls.fromDate.setValue('');
    this.adminService.getShiftsDetailsById(shiftId).subscribe((res: any) => {
      if (res.status) {
        this.shifDetails = res.data[0];
        // Assuming you have separate date and time variables
        const date = new Date(); // Replace this with your date variable
        const formattedTime = this.shifDetails.fromtime; // Replace this with your time variable
        const graceperiod_intime = this.shifDetails.graceperiod_intime;
        // Format the date and time separately using the DatePipe
        const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');

        // Concatenate the formatted date and time
        let dateTime = formattedDate + ' ' + formattedTime;
        const dateAddTime = formattedDate + ' ' + graceperiod_intime;
        const hoursToAdd =
          new Date(dateAddTime).getHours() +
          new Date(dateTime).getHours();
        const minutesToAdd =
          new Date(dateAddTime).getMinutes() +
          new Date(dateTime).getMinutes();
        const secondsToAdd =
          new Date(dateAddTime).getSeconds() +
          new Date(dateTime).getSeconds();
        this.finaldate =
          formattedDate +
          ' ' +
          hoursToAdd +
          ':' +
          minutesToAdd +
          ':' +
          secondsToAdd;
        if (this.currentDate >= new Date(this.finaldate)) {
          // Create a new Date object
          this.minFromDate = new Date();
          // Add one day to the date
          this.minFromDate.setDate(this.minFromDate.getDate() + 1);
          //this.minFromDate.setDate(this.currentDate.getDate()+1);
        } else {
          this.minFromDate = this.currentDate;
        }
      } else {
        this.shifDetails = null;
      }
    });
  }
  ngAfterViewInit() { }
  get f(): { [key: string]: AbstractControl } {
    return this.requestform.controls;
  }

  fromDateChange(type: string, event: any) {
    this.minToDate = event.value['_d'];
    if (event.value['_d'] !== null) {
      this.maxToDate = new Date(
        event.value['_i'].year + 1,
        event.value['_i'].month,
        event.value['_i'].date - 31
        // event!.value.getFullYear()+1,
        // event!.value.getMonth(),
        // event!.value.getDate()
      );
    }
    this.requestform.controls.toDate.setValue('');
  }

  toDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    // Assuming you have two date variables: startDate and endDate
    const startDate = new Date(this.requestform.controls.fromDate.value ?? '');
    const endDate = new Date(this.requestform.controls.toDate.value ?? '');

    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

    // Convert the time difference to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Format the date to get the day name
    if (daysDifference + 1 == 1) {
      // const dayName1 = this.datePipe.transform(startDate, 'EEEE');
      this.selectedDatesDays.push(this.datePipe.transform(startDate, 'EEEE'))
    } else if (daysDifference + 1 == 2) {
      //const dayName1 = this.datePipe.transform(startDate, 'EEEE');
      //const dayName2 = this.datePipe.transform(startDate.setDate(startDate.getDate() + 1),'EEEE');
      this.selectedDatesDays.push(this.datePipe.transform(startDate, 'EEEE'));
      this.selectedDatesDays.push(this.datePipe.transform(startDate.setDate(startDate.getDate() + 1), 'EEEE'));
    } else if (daysDifference + 1 == 3) {
      this.selectedDatesDays.push(this.datePipe.transform(startDate, 'EEEE'));
      this.selectedDatesDays.push(this.datePipe.transform(startDate.setDate(startDate.getDate() + 1), 'EEEE'));
      this.selectedDatesDays.push(this.datePipe.transform(startDate.setDate(startDate.getDate() + 1), 'EEEE'));
    }
  }

  getActiveShiftIds() {
    this.attendanceService.getActiveShiftIds().subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.filteredShifts = this.shiftDataList;
      }
    });
  }
  getShiftsDetailsById(shiftid: any) { }


  getEmployeeConfigureShifts() {
    this.arrayList = [];
    let obj = {
      manager_empid: this.userSession.id,
      department_id: null,
    };
    this.attendanceService.getEmployeeConfigureShifts(obj).subscribe((res) => {
      if (res.status) {
        this.arrayList = res.data;
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.arrayList = [];
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  submit() {
    this.addValidators();
    if (this.requestform.invalid) {
      return;
    } else {
      if (this.btnDisable) {
        return;
      }
      if (this.selection.selected.length > 0) {
        this.selectedEmps = [];
        this.selection.selected.forEach((e: any) => {
          this.selectedEmps.push(e.empid);
        });
        let obj = {
          "shift_id": this.requestform.controls.shift.value,
          "from_date": this.pipe.transform(new Date(this.requestform.controls.fromDate.value ?? ''), 'yyyy-MM-dd'),
          "to_date": this.pipe.transform(new Date(this.requestform.controls.toDate.value ?? ''), 'yyyy-MM-dd'),
          // "weekoffs": JSON.stringify(this.weekoffs),
          "empids": JSON.stringify(this.selectedEmps)

        };
        this.btnDisable = true;
        this.attendanceService.setEmployeeConfigureShift(obj).subscribe((res) => {
          if (res.status) {
            let resMessage: any;
            if (res.message == "dataSaved") {
              resMessage = this.dataSave
            } else {
              resMessage = this.dataNotSave;
              this.btnDisable = false;
            }
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage,
            });
            this.resetform();
            // this. getAttendanceRequestListByEmpId();
          }
        });
      } else {
        this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Select atleast one employee.',
        });
        this.btnDisable = false;
        return;
      }
    }

  }
  resetform() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Attendance/ShiftConfigure']));
  }

  checkCheckBoxvalue(event: any, data: any) {
    if (event.checked == true) {
      this.weekoffs.push(data.id);
      if (this.weekoffs.length >= 5) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Please select atleast one working day.'
        });


      }
      this.workingDays.forEach((e: any, index: any) => {
        if (e.id == data.id) {
          this.workingDays.splice(index, 1);
        }
      });
    } else {
      this.weekoffs.forEach((a: any, index: any) => {
        if (a == data.id) {
          this.weekoffs.splice(index, 1);
        }
      });
      let obj = { id: data.id, name: data.week_name };
      this.workingDays.push(obj);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  getMessagesList() {
    let data = {
      code: null,
      pagenumber: 1,
      pagesize: 100,
    };
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == 'ATT2') {
            this.requiredOption = e.message;
          } else if (e.code == 'ATT69') {
            this.dataSave = e.message;
          } else if (e.code == 'ATT70') {
            this.dataNotSave = e.message;
          } else if (e.code == 'ATT74') {
            this.ATT74 = e.message;
          }
        });
      } else {
        this.messagesDataList = [];
      }
    });
  }
  view(data: any) {
    let givenData = {
      "empId": data.empid,
      "empCode": data.emp_code,
      "empName": data.empname,
    }
    let dialogRef = this.dialog.open(ShitConfigureHistoryDialogComponent, {
      width: '500px', position: { top: `100px` },
      disableClose: true,
      data: givenData
    });
    this.clearWorkExperience();
  }

  addValidators() {
    this.requestform.controls.shift.setValidators([Validators.required])
    this.requestform.controls.fromDate.setValidators([Validators.required])
    this.requestform.controls.toDate.setValidators([Validators.required])
    this.requestform.get('shift').updateValueAndValidity();
    this.requestform.get('fromDate').updateValueAndValidity();
    this.requestform.get('toDate').updateValueAndValidity();
  }


  clearWorkExperience() {
    this.requestform.reset();
    this.requestform.get('shift').clearValidators();
    this.requestform.get('shift').updateValueAndValidity();
    this.requestform.get('fromDate').clearValidators();
    this.requestform.get('fromDate').updateValueAndValidity();
    this.requestform.get('toDate').clearValidators();
    this.requestform.get('toDate').updateValueAndValidity();

  }
  filterShift(searchText: any) {

    this.filteredShifts = this.shiftDataList.filter((val: any) =>
      val.shiftname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredShifts.length <= 0) {
      this.searchControl.setValue('');
    } else {

    }

  }
}
