<body class="authentication-bg" data-layout-config='{"darkMode":false}'>
  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-4 col-lg-5" *ngIf="!isVerified">
          <div class="card">
            <form [formGroup]="formGroup">
              <!-- Logo -->
              <div fxLayout="column" class="card-header pt-4 pb-4 text-center bg-secondary">
                <span class="text-white"><img class="logo-img mr-2" style="height: 45px"
                    src="assets/img/spryple_trns_logo.png" alt="" /></span>
              </div>

              <div class="card-body p-4">
                <p class="text-muted mb-4">
                  <b>The company code should contain only alphabets and should be between 5 to 10 characters. This is
                    the code your employees will enter along with their credentials at the time of login.</b>

                  <!-- <b >The company code should contain only alphabets and should be between 5 and 10 characters. It is case insensitive.This is the code your employees will enter along with their credentials at the time of login.</b> -->
                </p>
                <div>
                  <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Company Code</mat-label>
                    <input matInput class="form-control" type="text" formControlName="companyname" id="company"
                    onlyAlphabets (paste)="(false)" placeholder="" minlength="5"
                      maxlength="10" (keydown.space)="$event.preventDefault()" required />
                    <mat-error class="con-error" *ngIf="formGroup.controls.companyname.errors?.required">
                      This field is required.
                    </mat-error>
                    <mat-error class="reg-error" *ngIf="
                    formGroup.controls.companyname.errors?.minlength">
                      Company Code should be minimum 5 characters
                    </mat-error>
                  </mat-form-field>

                </div>

                <div>
                  <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput class="form-control" type="email" formControlName="username" id="emailaddress" formc
                      placeholder="" maxlength="50" required />
                    <mat-error class="con-error" *ngIf="formGroup.controls.username.errors?.required">
                      This field is required.
                    </mat-error>
                    <mat-error class="con-error" *ngIf="formGroup.controls.username.errors?.email ||
                    formGroup.controls.username.errors?.pattern">
                      Please enter valid email
                    </mat-error>
                  </mat-form-field>

                </div>

                <div fxLayoutAlign="center">
                  <ngx-recaptcha2 #captchaElem class="g-recaptcha" data-type="image" siteKey="6LeAe7wpAAAAAGRBFaoM3Njf0KWzTKrlgaKJEkur 
                  " (success)="handleSuccess($event,true)" [size]="size" [hl]="lang" [theme]="theme" [type]="type">
                    <!-- previous key-6LeREpcoAAAAABA1wnQ9lMB2KyUibnOf6DTq7Tyy -->
                  </ngx-recaptcha2>
                  <!-- <mat-error class="con-error" *ngIf="issubmit&&!captchavalid">
                    <span style="font-size: 11px;">Please check captcha.</span>
                  </mat-error> -->
                </div>

              </div>
              <div class="mb-3 mb-0 text-center">
                <button class="btn btn-primary" type="submit" (click)="submit()">
                  Submit
                </button>
              </div>

            </form>
          </div>

        </div>
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="isVerified" style="height: 100%;">
          <span>
            <h2 class="text-dark-50 text-center mt-0 fw-bold" style="color: #000c18">
              A link is sent to your email. Please use that link to proceed with adding your details and purchase of the
              application.</h2>
            <div fxLayoutAlign="center center">
              <span><a href="https://spryple.devorks.com/" class="style">www.spryple.devorks.com</a></span>
            </div>

          </span>
        </div>
      </div>

    </div>

  </div>

</body>