import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { AdminShiftEmployeeDialogComponent } from 'src/app/modules/reports/pages/admin-shift-employee-dialog/admin-shift-employee-dialog.component';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { ExcelExportService } from 'src/app/services/excel-export.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

@Component({
  selector: 'app-shiftwise-report',
  templateUrl: './shiftwise-report.component.html',
  styleUrls: ['./shiftwise-report.component.scss']
})
export class ShiftwiseReportComponent implements OnInit {

  shiftReportForm: any = UntypedFormGroup;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  selectedLocations: any = [];
  companyName: any;
  managersDetails: any[] = [];
  worklocationDetails: any[] = [];
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedManagers: any = [];
  minDate = new Date('2020/01/01');
  maxDate = new Date();
  shiftDataList: any = [];
  headersList: any = [];
  userSession: any;
  searchControlforManager = new FormControl('');
  searchControl = new FormControl('');
  @ViewChild('table') table!: ElementRef;
  filteredManagerDetails: any = [];
  filteredLocations: any = [];


  constructor(
    public reportsService: ReportsService,
    public datePipe: DatePipe,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private companyService: CompanySettingService,
    private reportService: ReportsService,
    private spinner: NgxSpinnerService,
    private excelExportService: ExcelExportService,
  ) {
    this.companyName = sessionStorage.getItem("companyName");
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
  }

  ngOnInit(): void {

    this.shiftReportForm = this.formBuilder.group({
      companylocation: ["", Validators.required],
      managers: ["", Validators.required],
      fromDate: [new Date()],
      toDate: [new Date()],
    })
    this.getLocationsForReportsUser();

    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });

    this.searchControlforManager.valueChanges.subscribe(searchText => {
      this.filterManagers(searchText);
    });
  }

  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.shiftReportForm.controls.companylocation.setValue(array)
    let locations = this.shiftReportForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  getManagersList() {
    this.managersDetails = [];
    let data = {
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName
    }
    this.reportService.getManagersListByLocation(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.managersDetails = res.data;
        this.filteredManagerDetails = this.managersDetails;
      }
    })
  }

  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false
    this.selectedLocations = [];
    select.value = [];
    this.shiftReportForm.controls.companylocation.setValue('');
    this.selectedLocations = [];
    this.managersDetails = [];
  }

  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.shiftReportForm.controls.managers.setValue(array)
    let managerdata = this.shiftReportForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false
    this.selectedManagers = [];
    select.value = [];
    this.shiftReportForm.controls.managers.setValue('');
    this.selectedManagers = []
  }
  Searchform() {

    if (this.shiftReportForm.valid) {
      this.getShiftWiseReportList();
    }
  }
  getShiftWiseReportList() {
    this.shiftDataList = [];
    let fromDate = this.datePipe.transform(this.shiftReportForm.controls.fromDate.value, "y-MM-d");
    let toDate = this.datePipe.transform(this.shiftReportForm.controls.toDate.value, "y-MM-d");
    let data = {
      "rm_id_value": JSON.stringify(this.selectedManagers),
      "from_date_value": fromDate,
      "to_date_value": toDate,
      "location_id": JSON.stringify(this.selectedLocations),
    }
    this.spinner.show();
    this.reportService.getBranchWiseShiftsWiseEmployeesCountsByDates(data).subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.headersList.push(this.shiftDataList[0]);
        this.headersList.slice(1);
        this.spinner.hide();
      } else {
        this.shiftDataList = [];
      }
    })
  }
  resetform() {
    this.ngOnInit();
    this.shiftDataList = [];
    this.locationIshide = false;
    this.locationIschecked = false
    this.selectedLocations = [];
    this.mgrIshide = false;
    this.mgrIschecked = false
    this.selectedManagers = [];
  }

  removeLastColumn(table: HTMLTableElement): void {
    const rows = table.querySelectorAll('tr');
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td, th');
      if (cells.length > 0) {
        const lastCell = cells[cells.length - 1];
        row.removeChild(lastCell);
      }
    });
  }

  view(data: any) {
    let givenData = {
      // "rm_id_value":this.rmId,
      "rm_id_value": JSON.stringify(this.selectedManagers),
      'location_id': JSON.stringify(this.selectedLocations),
      "date_value": data,
    }
    let dialogRef = this.dialog.open(AdminShiftEmployeeDialogComponent, {
      width: '500px', position: { top: `100px` },
      disableClose: true,
      data: givenData

    });

  }

  getObjectKeys(obj: any): string[] { return Object.keys(obj); }

  onchangeManager(value: any) {
    this.selectedManagers = [];
    let location = this.shiftReportForm.controls.managers.value;
    location.forEach((e: any) => {
      this.selectedManagers.push(e.manager_emp_id);
    });
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.shiftReportForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  getLocationsForReportsUser() {

    this.companyService.getactiveWorkLocationForGeneralManager({ id: this.userSession.id }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails;
    })
  }

  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredLocations.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  filterManagers(searchText: any) {
    this.filteredManagerDetails = this.managersDetails.filter((val: any) =>
      val.manager_name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredManagerDetails.length <= 0) {
      this.searchControlforManager.setValue('');
    }
  }

  exportToExcel(): void {
   const dataToExport = this.shiftDataList.map((item:any, index:any) => ({
   'S.No': index + 1,
   ...item 
 }));
 this.excelExportService.exportAsExcelFile(dataToExport, 'Shift_Report');
 }


 public exportPDF(): void {
  let fdate = (this.datePipe.transform(this.shiftReportForm.controls.fromDate.value, "dd-MM-YYYY"));
  let tdate = (this.datePipe.transform(this.shiftReportForm.controls.toDate.value, "dd-MM-YYYY"));
  
  const pdfTable = this.table.nativeElement;
  this.removeLastColumn(pdfTable);

  const headers:any = [];
  pdfTable.querySelectorAll('thead tr th').forEach((header: HTMLElement) => {
      headers.push({ text: header.innerText, bold: true, fillColor: '#eeeeee', alignment: 'center' });
  });

  const body:any = [];
  pdfTable.querySelectorAll('tbody tr').forEach((row: HTMLElement) => {
      const dataRow:any = [];
      row.querySelectorAll('td').forEach((cell: HTMLElement) => {
          dataRow.push({ text: cell.innerText, alignment: 'center' });
      });
      body.push(dataRow);
  });
  const tableBody = [headers, ...body];

  // Create PDF using pdfMake
  pdfMake.createPdf({
    info: {
      title: `${fdate} To ${tdate} - Shift Wise Report`,
      author: 'Sreeb tech',
      subject: 'Theme',
      keywords: 'Report'
    },
    footer: function (currentPage, pageCount) {
      return {
        margin: 10,
        columns: [
          {
            fontSize: 9,
            text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
            alignment: 'center'
          }
        ]
      };
    },
    content: [
      {
        text: `${fdate} To ${tdate} - Shift Wise Report \n\n`,
        style: 'header',
        alignment: 'center',
        fontSize: 14
      },
      {
        table: {
          widths: Array(headers.length).fill('*'),  // Makes columns fill the width equally
          body: tableBody  // Uses manually created table content
        },
        layout: {
          hLineWidth: function (i, node) {
            return (i === 0 || i === node.table.body.length) ? 2 : 1;
          },
          vLineWidth: function (i) {
            return 1;
          },
          hLineColor: function (i) {
            return 'black';
          },
          vLineColor: function (i) {
            return 'black';
          }
        }
      }
    ],
    styles: {
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, 10]
      }
    },
    pageOrientation: 'landscape',
    pageSize: "EXECUTIVE"
  }).download("Shift Wise Report.pdf");

  this.Searchform();
}
}
