<div fxLayout="column">
    <mat-card>
        <form [formGroup]="requestform" autocomplete="off">
            <mat-card-header class="headerbox">
                <mat-card-title class="title"> Approval For Attendance </mat-card-title>
            </mat-card-header>
            <br>
            <!-- <mat-divider></mat-divider> -->
            <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Applied Date</mat-label>
                    <input matInput readonly [matDatepicker]="date" formControlName="appliedDate" [disabled]="true">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="date"></mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                </mat-form-field>

                <!-- <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Applied Date</mat-label>
                    <input formControlName="appliedDate" matInput placeholder="Applied Date" readonly>
                </mat-form-field> -->

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <input formControlName="empName" matInput placeholder="Employee Name" readonly>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
                    <mat-label>Shift</mat-label>
                    <input formControlName="shift" matInput placeholder="Shift" readonly>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Work Type</mat-label>
                    <input formControlName="workType" matInput placeholder="Work Type" readonly>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>From Date</mat-label>
                    <input matInput readonly [matDatepicker]="date1" formControlName="fromDate" [disabled]="true">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="date1"></mat-datepicker-toggle>
                    <mat-datepicker #date1></mat-datepicker>
                </mat-form-field>

                <!-- <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>From Date</mat-label>
                    <input formControlName="fromDate" matInput placeholder="From Date" readonly>
                </mat-form-field> -->
                <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
                    <mat-label>To Date</mat-label>
                    <input matInput readonly [matDatepicker]="date2" formControlName="toDate" [disabled]="true">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="date2"></mat-datepicker-toggle>
                    <mat-datepicker #date2></mat-datepicker>
                </mat-form-field>

                <!-- <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>To Date</mat-label>
                    <input formControlName="toDate" matInput placeholder="To Date" readonly>
                </mat-form-field> -->

            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field fxFlex="66" fxFlex.xs="100" fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
                    <mat-label>Reason</mat-label>
                    <textarea formControlName="reason" readonly matInput placeholder="Reason" rows="1" maxlength="250"
                        required></textarea>
                    <mat-error class="con-error" *ngIf="requestform.controls.reason.errors?.required">
                        Reason is required.
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" style="align-items: center">
                    <span *ngIf="pdfName" class="no-wrap" style="margin-left: 10px;">Upload File:</span>
                    <button mat-icon-button (click)="fileView();" class="link"
                        style="margin-left: 10px;font-size: 16px;width: 70%;" *ngIf="pdfName">
                        {{ pdfName }}
                    </button>
                    &nbsp;
                </div>
            </div>
            <div fxLayout="row" *ngIf="!isEdit">
                <mat-form-field fxFlex="66" fxFlex.xs="100" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                    <mat-label *ngIf="isStatus">Approved Reason</mat-label>
                    <mat-label *ngIf="!isStatus">Rejected Reason</mat-label>
                    <textarea formControlName="comment" readonly matInput rows="1" placeholder=""></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-checkbox style="margin-left: 10px;" formControlName="firstHalf" [checked]="isFirstHalf"
                    [disabled]="true">First Half
                </mat-checkbox>

                <mat-checkbox style="margin-left: 10px;" formControlName="secondHalf" [checked]="isSecondHalf"
                    [disabled]="true">Second Half
                </mat-checkbox>
            </div>
            <div fxLayoutAlign="center">
                <ng-container *ngIf="isEdit">
                    <button class="btn btn-primary custom mr-2 mb-2" [disabled]="btnDisable" (click)="acceptApproval()"
                        type="submit">
                        <span>Approve</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" [disabled]="btnDisable" (click)="rejectApproval()">
                        <span>Reject</span>
                    </button>
                </ng-container>

                <button class="btn btn-danger mr-2 mb-2" (click)="backClick()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>

        </form>
    </mat-card>
</div>