<div fxLayout="column" class="first-col">

    <mat-card-header style="margin-top: 15px; padding: 1.2%" class="headerbox">
      <mat-card-title class="title"> Reports</mat-card-title>
    </mat-card-header>
    <div [formGroup]="reportsList" class="tableP">
      <mat-form-field fxFlex.xs="80" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
        <mat-select formControlName="reportsName" required placeholder="Select report">
          <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
          <ng-container *ngFor="let list of filteredReports">
            <mat-option (click)="selectReport()" [value]="list.id">{{ list.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <span>
        <!-- <button class="btn btn-primary mr-2 mb-2" style="margin-top: 10px; margin-left: 10px" (click)="selectReport()">
          Search
        </button> -->
      </span>
      <span>
        <button class="btn btn-danger mr-2 mb-2" style=" margin-left: 10px" (click)="cancel()">
          <span>Cancel</span>
        </button>
      </span>
    </div>
    <div *ngIf="!isTableView" fxLayoutAlign="center">
      <h4>* Please contact your admin to configure work location *</h4>
    </div>
    <!-- Detailed Leave Report -->

    <div *ngIf="reportsToDisplay === 'leave-report'">
      <app-leave-report></app-leave-report>
    </div>

    <!--Leave summary report  -->

    <div *ngIf="reportsToDisplay === 'leave-summary-report'">
      <div [formGroup]="leaveSummaryReport" style="margin-left: 5px;">
        <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" class="dev-Pad">
          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Designation</mat-label>
            <mat-select formControlName="designation" required>
              <mat-option value="All">All</mat-option>
              <ng-container *ngFor="let b of designations">
                <mat-option [value]="b.id">{{ b.designation }} </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Employee Name and Id</mat-label>
            <mat-select formControlName="employeeId" required>
              <mat-option value="All">All</mat-option>
              <ng-container *ngFor="let b of employeeDetails">
                <mat-option [value]="b.empid">{{ b.empname }} - {{b.empcode}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.xs="100" fxFlex.xs="100" fxFlex.lt-lg="48" class="mx-2 my-2"
            appearance="outline">
            <mat-label>Years</mat-label>
            <mat-select formControlName="calenderYear" required>
              <ng-container *ngFor="let year of calengerYearsdetails">
                <mat-option [value]="year.year_value">{{
                  year.display_value
                  }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" style="justify-content: center" class="showLine">
          <span>
            <button class="btn btn-primary mr-2 mb-2" (click)="SearchLeaveSummaryReportForm()">
              Search
            </button>
          </span>
          <button class="btn btn-danger mr-2 mb-2" (click)="clearLeaveSummaryForm()">
            <span>Clear</span>
          </button>
        </div>
      </div>
      <div style="margin-bottom: 10px; padding-right: 19px" fxLayout="row xs-column" fxLayoutAlign="end  center"
        *ngIf="summaryReports.length > 0">
        <div>
          <span class="exlprint"> | </span>
          <a title="PDF">
            <img (click)="exportPDFleaveSummaryReport()" src="./assets/images/icons/pdf.png" width="20px"
              height="20px" /></a>

          <span class="exlprint"> | </span>
          <a title="Excel">
            <img (click)="exportAsXLSXleaveSummaryReport()" src="./assets/images/icons/excel.png" />
          </a>
        </div>
      </div>
      <div class="table-pad">
        <div id="tableBorder" #table="" class="example-container">
          <table class="table">
            <thead>
              <tr *ngFor="let head of summaryReportTableHeadings">
                <!--<span *ngFor="let head of summaryReportTableHeadings">-->
                <!--<th scope="col" class="space">{{head}}</th>-->
                <th class="space">{{ head.column1 }}</th>
                <th class="space">{{ head.column2 }}</th>
                <th class="space">{{ head.column3 }}</th>
                <th class="space">{{ head.column4 }}</th>
                <th class="space">{{ head.column5 }}</th>
                <th class="space">{{ head.column6 }}</th>
                <th class="space">{{ head.column7 }}</th>
                <th class="space">{{ head.column8 }}</th>
                <th class="space">{{ head.column9 }}</th>
                <th class="space">{{ head.column10 }}</th>
                <th class="space">{{ head.column11 }}</th>
                <th class="space">{{ head.column12 }}</th>
                <th class="space">{{ head.column13 }}</th>
                <th class="space">{{ head.column14 }}</th>
                <!--</span>-->
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let val of summaryReports">
                <!--<span *ngFor="let val of item">-->

                <!--<td  class="spacetd">{{val}}</td>-->
                <td class="spacetd">{{ val.column1 }}</td>
                <td class="spacetd">{{ val.column2 }}</td>
                <td class="spacetd">{{ val.column3 }}</td>
                <td class="spacetd">{{ val.column4 }}</td>
                <td class="spacetd">{{ val.column5 }}</td>
                <td class="spacetd">{{ val.column6 }}</td>
                <td class="spacetd">{{ val.column7 }}</td>
                <td class="spacetd">{{ val.column8 }}</td>
                <td class="spacetd">{{ val.column9 }}</td>
                <td class="spacetd">{{ val.column10 }}</td>
                <td class="spacetd">{{ val.column11 }}</td>
                <td class="spacetd">{{ val.column12 }}</td>
                <td class="spacetd">{{ val.column13 }}</td>
                <td class="spacetd">{{ val.column14 }}</td>
                <!---->
                <!--</span>-->
              </tr>

              <tr *ngIf="summaryReports && summaryReports.length === 0">
                <td colspan="10" style="text-align: center">No Data Found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Leave Payroll report -->

    <div *ngIf="reportsToDisplay === 'leave-payroll-report'">
      <div [formGroup]="leavePayrollReport" class="tableP">
        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline"
            style="padding-right: 3px">
            <mat-label>Employee Name and Id</mat-label>
            <mat-select fxFlex.xs="100" formControlName="employeeId" required>
              <mat-option value="All">All </mat-option>
              <ng-container *ngFor="let e of employeeDetails">
                <mat-option [value]="e.empid">{{ e.empname }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>From Date</mat-label>
            <input matInput readonly [matDatepicker]="fromDate" required formControlName="fromDate" [max]="max"
              (click)="fromDate.open()" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
            <mat-error class="con-error" *ngIf="leavePayrollReport.controls.fromDate.errors?.required">
              Please select an option.
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>To Date</mat-label>
            <input matInput readonly [matDatepicker]="picker" required formControlName="toDate" [min]="minDate"
              [max]="max" (click)="picker.open()" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error class="con-error" *ngIf="leavePayrollReport.controls.toDate.errors?.required">
              Please select an option.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" style="justify-content: center" class="showLine">
          <span>
            <button class="btn btn-primary mr-2 mb-2" (click)="SearchLeavePayrollReportForm()">
              Search
            </button>
          </span>
          <button class="btn btn-danger mr-2 mb-2" (click)="clearLeavePayrollReportForm()">
            <span>Clear</span>
          </button>
        </div>
        <div style="margin-bottom: 1%; padding-right: 2%" fxLayout="row xs-column" fxLayoutAlign="end  center"
          *ngIf="payrollReportList.length > 0">
          <div>
            <span class="exlprint"> | </span>
            <a title="PDF">
              <img (click)="exportPDFleavePayrollReport()" src="./assets/images/icons/pdf.png" width="20px"
                height="20px" /></a>

            <span class="exlprint"> | </span>
            <a title="Excel">
              <img (click)="exportAsXLSXleavePayrollReport()" src="./assets/images/icons/excel.png" />
            </a>
          </div>
        </div>
        <div class="tablePadding">
          <div class="mat-elevation-z1 example-container">
            <div id="table" #table="">
              <table id="table" mat-table [dataSource]="dataSourceForPayrollReport" matSort>
                <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef>S.No</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="empid">
                  <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                    Employee Id
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span>{{ row.empid }} </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="empname">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap">
                    Employee Name
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span>{{ row.empname }} </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="lossofpay">
                  <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                    Loss of Pay Count
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span>{{ row.lopcount == null ? 0 : row.lopcount }} </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="totalleaves">
                  <th mat-header-cell *matHeaderCellDef>Absents</th>
                  <td mat-cell *matCellDef="let row">
                    <span>{{ row.absents == null ? 0 : row.absents }} </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="totalleavebalance">
                  <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                    Leaves Approved
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span>{{
                      row.approved_leaves == null ? 0 : row.approved_leaves
                      }}
                    </span>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="
                    displayedColumnsForLeavePayrollReport;
                    sticky: true
                  "></tr>
                <tr mat-row *matRowDef="
                    let row;
                    columns: displayedColumnsForLeavePayrollReport
                  "></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="6" style="text-align: center">
                    No data found
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Leave Balance Report  -->

    <div *ngIf="reportsToDisplay === 'leave-balance-report'">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end" style="padding-top: 1%">
        <div style="padding-right: 2%" fxLayout="row" fxLayoutAlign="end">
          <span> | </span>&nbsp;
          <a title="PDF">
            <img (click)="exportPDFleaveBalanceReport()" src="./assets/images/icons/pdf.png" width="20px"
              height="20px" /></a>&nbsp;

          <span> | </span>&nbsp;
          <a title="Excel">
            <img (click)="exportAsXLSXleaveBalanceReport()" src="./assets/images/icons/excel.png" />&nbsp;
          </a>
        </div>
      </div>
      <div class="tableLeaveBalanceReport" style="margin-left: 10px;">
        <div class="example-container" style="padding: 10px" #table>
          <table style="width: 100%" *ngIf="dataForLeaveBalanceReport.length > 0" id="table">
            <thead class="theader">
              <tr>
                <th *ngFor="let header of getHeaders()" class="th">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataForLeaveBalanceReport">
                <td class="td" *ngFor="let key of getHeaders()">
                  {{ item[key] }}
                </td>
              </tr>
            </tbody>
          </table>

          <div style="text-align: center; padding-top: 20px" *ngIf="dataForLeaveBalanceReport.length === 0">
            No data found.
          </div>
        </div>
      </div>
    </div>

    <!-- EPF Report -->

    <div *ngIf="reportsToDisplay === 'epf'">
      <!-- <div [formGroup]="EpfReport" class="tableP" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Month and Year</mat-label>
            <input matInput readonly [matDatepicker]="fromDateForEPF" placeholder="From Date" [formControl]="date"
              [min]="minDateForEPF" [max]="maxDateForEPF" (click)="fromDateForEPF.open()" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDateForEPF"></mat-datepicker-toggle>
            <mat-datepicker #fromDateForEPF startView="multi-year"
              (monthSelected)="setMonthAndYear($event, fromDateForEPF)" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div style="margin-top: 10px; padding-right: 3%" fxLayout="row xs-column" fxLayoutAlign="end  center">
          <div>
            <span class="exlprint"> | </span>
            <a title="Text">
              <img (click)="exportTextEPF()" src="./assets/images/icons/text.png" width="20px" height="20px" /></a>

            <span class="exlprint"> | </span>
            <a title="Excel">
              <img (click)="exportAsXLSXEPF()" src="./assets/images/icons/excel.png" />
            </a>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div style="padding-top: 5px; padding-right: 2%" fxLayout="row xs-column" fxLayoutAlign="end">
        <div>
          <span class="exlprint">
            <mat-form-field appearance="outline">
              <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
              <mat-label>Search</mat-label>
              <input (keyup)="applyFilterForEPF($event)" matInput placeholder="" maxlength="100" />
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="tablePadding">
        <div class="mat-elevation-z1 example-container">
          <div id="table" #table="">
            <table id="table" mat-table [dataSource]="dataSourceForEPF" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                </td>
              </ng-container>
              <ng-container matColumnDef="uan">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  UAN No
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.UAN }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap">
                  Employee Name
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.Employee_Name }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="gross">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  Gross
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.gross_salary }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="epf">
                <th mat-header-cell *matHeaderCellDef>EPF</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.employee_epf_value }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="eps">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  EPS
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.eps_wage }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="edil">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  EDLI
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.edli_wage }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="epsvalue">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  EPS
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.employer_eps_value }} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="ee">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  EE
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.employee_epf_value }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="er">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  ER
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.epf_eps_difference }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="ncp">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  NCP
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.ncp_days == null ? 0 : row.ncp_days }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="refunds">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  Refunds
                </th>
                <td mat-cell *matCellDef="let row"><span>0 </span></td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsforEPF"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsforEPF"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8" style="text-align: center">
                  No data found
                </td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="
                !pageLoading ? getPageSizesforEPF() : [5, 10, 20]
              "></mat-paginator>
          </div>
        </div>
      </div> -->
      <app-epf-reports></app-epf-reports>
    </div>

    <!-- ESI report -->

    <div *ngIf="reportsToDisplay === 'esi'">
      <!-- <div [formGroup]="ESIReport" class="tableP" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Month and Year</mat-label>
            <input matInput readonly [matDatepicker]="fromDateForESI" placeholder="From Date" [formControl]="dateESI"
              [min]="minDateForESI" [max]="maxDateForESI" (click)="fromDateForESI.open()" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDateForESI"></mat-datepicker-toggle>
            <mat-datepicker #fromDateForESI startView="multi-year"
              (monthSelected)="setMonthAndYearESI($event, fromDateForESI)" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex class="mx-2 my-2">
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
              <ng-container>
                <div *ngFor="let b of stateslistESI">
                  <mat-option value="{{ b.state_id }}">{{
                    b.state
                    }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="margin-top: 10px; padding-right: 2%" fxLayout="row xs-column" fxLayoutAlign="end  center">
          <div>
            <span class="exlprint"> | </span>
            <a title="PDF">
              <img (click)="exportPDFforESI()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>

            <span class="exlprint"> | </span>
            <a title="Excel">
              <img (click)="exportAsXLSXforESI()" src="./assets/images/icons/excel.png" />
            </a>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="tablePadding">
        <div class="mat-elevation-z1 example-container">
          <div id="table" #table="">
            <table id="table" mat-table [dataSource]="dataSourceESI" matSort>
              <ng-container matColumnDef="ipnumber">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  ESI Number
                </th>
                <td mat-cell *matCellDef="let row">
                  <span style="font-size: smaller" *ngIf="row.ESI == 'Grand Total'"><b>{{ row.ESI }}</b>
                  </span>
                  <span style="font-size: smaller" *ngIf="row.ESI != 'Grand Total'">{{ row.ESI }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="ipname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap">
                  Name
                </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.Employee_Name }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="nofdays">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  Paid Days
                </th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.ESI != 'Grand Total'">{{ row.paid_days == null ? 0 : row.paid_days }}
                  </span>
                  <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.paid_days == null ? 0 : row.paid_days }}</b>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="totawages">
                <th mat-header-cell *matHeaderCellDef>Gross Salary</th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.ESI != 'Grand Total'">{{ row.Gross_Salary }}
                  </span>
                  <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.Gross_Salary }}</b>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="ipcontribution">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  Employee Contribution
                </th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.ESI != 'Grand Total'">{{ row.employee_esi_value }}
                  </span>
                  <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.employee_esi_value }}</b></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="reasonforzeroworkingdays">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  Employer Contribution
                </th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.ESI != 'Grand Total'">{{ row.employer_esi_value }}
                  </span>
                  <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.employer_esi_value }}</b></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="lastworkingdays">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                  Total Contribution
                </th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.ESI != 'Grand Total'">{{ row.total_contribution }}
                  </span>
                  <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.total_contribution }}</b></span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsForESI"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsForESI"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7" style="text-align: center">
                  No data found
                </td>
              </tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizesESI() : [5, 10, 20]"></mat-paginator>
        </div>
      </div> -->
      <app-esireports></app-esireports>
    </div>


    <!-- employee payment reports -->

    <div *ngIf="reportsToDisplay === 'employee-payment-report'">
      <app-employee-payment-report></app-employee-payment-report>
    </div>

    <!-- Detailed Payroll report  -->
    <div *ngIf="reportsToDisplay==='detailed-payroll-report'">
      <app-detailed-payroll-report></app-detailed-payroll-report>
    </div>

    <!-- Professional Tax Report -->
    <div *ngIf="reportsToDisplay==='professional-Tax-Report'">
      <app-professional-tax-report></app-professional-tax-report>
    </div>

    <!-- Late attendance Report -->

    <div *ngIf="reportsToDisplay==='late-attendance-report'">
      <app-attendance-late-report></app-attendance-late-report>
    </div>

    <!-- Detailed report -->

    <div *ngIf="reportsToDisplay==='detailed-attendance-report'">
      <app-attendance-detailed-report></app-attendance-detailed-report>
    </div>

    <!-- Attendance Summary Report -->

    <div *ngIf="reportsToDisplay === 'attendance-summary-report'">
      <app-attendance-summary-report></app-attendance-summary-report>
    </div>

    <!-- Employee Reports -->
    <div *ngIf="reportsToDisplay === 'employee-reports'">
      <app-employee-details></app-employee-details>
    </div>
    <!-- Shift wise report -->
    <div *ngIf="reportsToDisplay ==='shiftwise-report'">
      <app-shiftwise-report></app-shiftwise-report>
    </div>
    <!-- checkin-out summary report -->
    <div *ngIf="reportsToDisplay ==='checkin/out-summary-report'">
      <app-checkinoutsummaryreport></app-checkinoutsummaryreport>
    </div>
    <!-- current day attendance report -->
    <div *ngIf="reportsToDisplay==='currendayAttReport'">
      <app-currentday-attendance-report [screenForReportUser]="isReportUser"></app-currentday-attendance-report>
    </div>

</div>