import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import { DialogDetailComponent } from 'src/app/modules/reports/pages/dialog-detail/dialog-detail.component';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PdfHeaderService } from 'src/app/services/pdf-header-service/pdf-header.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-attendance-summary-report',
  templateUrl: './attendance-summary-report.component.html',
  styleUrls: ['./attendance-summary-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AttendanceSummaryReportComponent implements OnInit {
  List: any[] = [];
  @ViewChild('table') table!: ElementRef;

  employeelist: any;
  Users: any;
  minDate = new Date('1950/01/01');
  today = new Date();
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
  minToDate: any;
  pageLoading = true;
  displayLength: boolean = false;
  searchControl = new FormControl('');
  filteredEmployeeList: any = [];
  constructor(
    public reportsService: ReportsService,
    public datePipe: DatePipe,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private pdfService: PdfHeaderService,
  ) { }

  @ViewChild(MatTable) tableOne: MatTable<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  filter = new UntypedFormControl();
  userSession: any;
  monthdata: any;
  year: any;
  months = [
    { id: 0, month: 'Jan' },
    { id: 1, month: 'Feb' },
    { id: 2, month: 'Mar' },
    { id: 3, month: 'Apr' },
    { id: 4, month: 'May' },
    { id: 5, month: 'Jun' },
    { id: 6, month: 'Jul' },
    { id: 7, month: 'Aug' },
    { id: 8, month: 'Sep' },
    { id: 9, month: 'Oct' },
    { id: 10, month: 'Nov' },
    { id: 11, month: 'Dec' },
  ];
  searchForm = this.formBuilder.group({
    fromDate: [this.maxDate],
    toDate: [this.maxDate],
    Users: ['0'],
  });
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = [
    'sno',
    'empname',
    'attendancedate',
    'firstlogintime',
    'lastlogouttime',
    'totalhours',
    'productivehours',
    'ot',
    'ph',
    'action',
  ];
  isLoading = false;
  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.Searchform();
    this.getEmployeelist();
    this.searchForm
      .get('fromDate')
      ?.valueChanges.subscribe((selectedValue: any) => {
        if (selectedValue != null) {
          this.minToDate = selectedValue._d;
        }
      });
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText);
    });
  }

  getEmployeelist() {
    let obj = {
      remployee_id: this.userSession.id,
    };
    this.reportsService
      .getReportsuserEmployeesList(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeelist = [];
          this.employeelist = res.data;
          this.filteredEmployeeList = this.employeelist;
          this.searchForm.controls.user.setValue('0');
        }
      });
  }
  //All Employees API
  Searchform() {
    this.List = [];
    this.dataSource = new MatTableDataSource(this.List);

    let fromDate = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'y-MM-d'
    );
    let toDate = this.datePipe.transform(
      this.searchForm.controls.toDate.value,
      'y-MM-d'
    );
    let userId = this.searchForm.controls.Users.value;
    if (userId == '0') {
      userId = null;
    }
    let data = {
      rempid: this.userSession.id,
      employee: userId,
      fromdate: fromDate,
      todate: toDate,
    };
    this.isLoading = true;
    this.reportsService
      .getAttendanceSummaryReportForReportsuser(data)
      .subscribe(
        (res: any) => {
          this.List = res.data;
          if (res.status) {
            this.List.forEach((e: any) => {
              if (e.breaks != null) {
                e.breaks = e.breaks.split(',');
              }
            });
            this.displayLength = true
          }

          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.List);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
        },
        (error) => {
          this.isLoading = false;
          error.error.text;
        }
      );
  }

  resetform() {
    this.dataSource.data = [];
    this.searchForm.reset();
    this.searchForm.controls.fromDate.setValue(this.maxDate);
    this.searchForm.controls.toDate.setValue(this.maxDate);
    this.searchForm.controls.Users.setValue('0');
  }
  openDialog(item: any): void {
    const dialogRef = this.dialog.open(DialogDetailComponent, {
      width: '1200px',
      position: { top: `70px` },
      data: { attendanceid: item.id },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  exportAsXLSX() {
    let fromdate = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'dd-MM-YYYY'
    );
    let todate = this.datePipe.transform(
      this.searchForm.controls.toDate.value,
      'dd-MM-YYYY'
    );
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('table')
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendance_Summary_Report');
    /* save to file */
    XLSX.writeFile(
      wb,
      fromdate + ' - ' + todate + ' - ' + 'Attendance_Summary_Report.xlsx'
    );
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  filterEmployees(searchText: any) {
    this.filteredEmployeeList = this.employeelist.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployeeList.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  generatePDF() {
  
    const doc = new jsPDF({ orientation: 'landscape' });
    const reportName = 'Attendance summary report';
    let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"));
    let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"));

    let manager = '';
    let locationName = '';
    let reportDate = fromdate +' to '+ todate;
    this.pdfService.generatePDFHeader(doc, reportName,locationName,manager,reportDate,false);
    const tableData = this.List.map((emp:  { empname: any;    attendancedate: any;   firstlogintime: any ;
                                             lastlogouttime: any;   totalhours: any; productivehours: any; overtime_minutes: any;
                                             permissionhours: any}, index: number) => [
      index + 1,
      emp.empname,
      emp.attendancedate,
      emp.firstlogintime,
      emp.lastlogouttime,
      emp.totalhours,
      emp.productivehours,
      emp.overtime_minutes,
      emp.permissionhours,
    ]);
          autoTable(doc, {
            head: [['S.No.','Emp. Name', 'Date','First In','Last Out','Total Hrs','Productive Hrs','OT Hrs','Permission Hrs']],
            body:tableData,
            startY: 40,
            styles: { fontSize: 10 },
            headStyles: { fontSize: 12 }
         });
       doc.save('attendance_summary_report.pdf');
  } 
}

