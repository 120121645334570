import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { PayrollService } from '../../payroll.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-over-time-configure',
  templateUrl: './over-time-configure.component.html',
  styleUrls: ['./over-time-configure.component.scss'],
})
export class OverTimeConfigureComponent implements OnInit {

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;


  OTConfigForm: any = UntypedFormGroup;
  displayedColumns: string[] = ['sno', 'stateName', 'slabquotient'];
  dataSource: MatTableDataSource<any> = <any>[];
  pageLoading = true;
  stateId: any = [];
  Overtime: any;
  IsOvertime: boolean | undefined;
  userSession: any;
  count: any = 0;
  filteredStates: any = [];
  searchControl = new FormControl('');
  btnDisable: boolean = false;


  constructor(
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    private Payroll: PayrollService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.OTConfigForm = this.formBuilder.group({
      stateId: ['', Validators.required],
      slabPercentage: ['', Validators.required],
    });

    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getStateId();
    this.getOvertimeCompanySetting();
    this.getPayrollClientOtSlabs();

    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterStates(searchText)
    })

  }
  onchangeSlabQuotient() {
    let value = this.OTConfigForm.controls.slabPercentage.value;
    if (value > 99.99) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: "Permitted upto 99.99 only!"
      });
      this.OTConfigForm.controls.permissionDuration.setValue('')
    }
  }

  getStateId() {

    this.Payroll.getStateId().subscribe((result) => {
      this.stateId = result.data;
      this.filteredStates = this.stateId;
    });
  }

  radioChange(event: MatRadioChange) {
    if (event.value == 0) {
      this.navigationToOt();
      this.IsOvertime = true;
      this.Overtime = 0;
      this.overtimeChangeSettings();

    } else if (event.value == 1) {

      this.IsOvertime = false;
      this.Overtime = 1;
      this.overtimeChangeSettings();
    }
  }

  ConfigureOT() {
    if (this.OTConfigForm.valid) {
      if (this.btnDisable) {
        return;
      }
      let data = {
        state_id_value: this.OTConfigForm.controls.stateId.value,
        slab_quotient_value: this.OTConfigForm.controls.slabPercentage.value,
        ot_setting_value: this.Overtime,
        user_id: this.userSession.id,
      };
      this.btnDisable = true;
      this.Payroll.setPayrollClientOtSlabs(data).subscribe((result: any) => {
        if (result.status) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "SlabQuotient is added successfully."
          });
          this.navigationToOt();;
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to submit"
          });
          this.btnDisable = false;
        }
      });
    }
    this.navigationToOt();
  }
  cancel() {
    this.navigationToOt();
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getOvertimeCompanySetting() {

    this.Payroll.getOvertimeCompanySetting().subscribe((result: any) => {
      this.Overtime = result.data[0].rulevalue;
      if (this.Overtime === '0') {
        this.IsOvertime = true;
      } else {
        this.IsOvertime = false;
      }
    });

  }

  getPayrollClientOtSlabs() {

    this.Payroll.getPayrollClientOtSlabs().subscribe((result: any) => {

      if (result.status) {
        this.dataSource = new MatTableDataSource(result.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }

    })
  }

  navigationToOt() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/overTimeConfigure']));
  }
  overtimeChangeSettings() {

    let data = {
      state_id_value: null,
      slab_quotient_value: null,
      ot_setting_value: this.Overtime,
      user_id: null,
    }
    this.Payroll.setPayrollClientOtSlabs(data).subscribe((result: any) => {
      if (result.status) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Overtime configuration settings changed successfully."

        });

      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Unable to change Overtime configuration Settings"
        });
      }
    });

  }
  filterStates(searchText: any) {
    this.filteredStates = this.stateId.filter((val: any) =>
      val.statename.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredStates.length <= 0) {
      this.searchControl.setValue('');
    }
  }
}
