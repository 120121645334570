import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from 'src/environments/environment';

import * as _moment from 'moment';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-rotational-shift-assign',
  templateUrl: './rotational-shift-assign.component.html',
  styleUrls: ['./rotational-shift-assign.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RotationalShiftAssignComponent implements OnInit {

  requestform: any = UntypedFormGroup;
  searchform: any = UntypedFormGroup;
  displayedColumns: string[] = ["select", 'id', 'empid', 'empname', 'desig'];
  displayedColumns2: string[] = ['sno', 'empcode', 'empname', 'location', 'shift','sequencename','rotationShift'];
  dataSource: MatTableDataSource<any> = <any>[];
  dataSource2: MatTableDataSource<any> = <any>[];
  ATT74: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatPaginator) paginator2!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatSort) sort2!: MatSort;
  searchControl = new FormControl('');
  searchControlforShift = new FormControl('');
  searchControlforSequenceShift = new FormControl('');
  today = new Date();
  minDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1);
  arrayList: any = [];
  userSession: any;
  shiftDataList: any = [];
  employeesData: any;
  selectedEmps: any = [];

  pageLoading = true;
  pageLoading2 = true;
  messagesDataList: any = [];
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  recordExist: any;
  companyDBName: any = environment.dbName;
  availableLocations: any = [];
  assignedRotationEmployeeList: any = [];
  isShow = false;
  isAdd: boolean = true;
  filteredAvaliableLocations: any = [];
  btnDisable: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private attendanceService: AttendanceService,
    public dialog: MatDialog, public datePipe: DatePipe, private router: Router,
    private companyService: CompanySettingService, private adminService: AdminService
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
  }
  selection = new SelectionModel<any>(true, []);
  shifDetails: any;
  finaldate: any;
  //******************** */
  managerId: any;
  rotationShiftList: any = [];
  shiftSequenceList: any = [];
  filteredShiftSequenceList: any = [];
  filteredSequenceList: any = [];
  locationId: any
  ngOnInit(): void {
    this.managerId = this.userSession.id;
    this.getMessagesList();
    this.searchform = this.formBuilder.group(
      {
        location: ['', Validators.required],
      });
    this.requestform = this.formBuilder.group(
      {
        shift: ['', Validators.required],
        sequenceShift: ['', Validators.required],
        fromDate: [this.minDate],
        location: ['', Validators.required],
      });
    this.getLocations();
    this.requestform.get('location')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue) {
        this.locationId = selectedValue;
        this.getShiftSequenceList();
        this.filteredSequenceList = [];
        this.getEmployeesForRotationShiftAssignment();
        this.requestform.controls.sequenceShift.setValue('');
        this.requestform.controls.fromDate.setValue(this.minDate);
      }
    });
    this.getAssignedEmployeeRotationShiftMapping();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterAvaliableLocations(searchText);
    });
    this.searchControlforShift.valueChanges.subscribe(searchText => {
      this.filterShift(searchText);
    })

    this.searchControlforSequenceShift.valueChanges.subscribe(searchText => {
      this.filterSequenceShift(searchText);
    })
  }

  ngAfterViewInit() {
  }
  get f(): { [key: string]: AbstractControl } {
    return this.requestform.controls;
  }

  shiftChange(shiftId: any) {
    this.shiftSequenceList = [];
    this.attendanceService.getShiftsForRotationSequence(shiftId).subscribe((res) => {
      if (res.status) {
        this.shiftSequenceList = JSON.parse(res.data[0].shift_sequence);
        this.filteredSequenceList = this.shiftSequenceList
      }
    });
  }

  getShiftSequenceList() {
    // if(this.searchform.valid){
    this.isShow = true;
    let data = {
      manager_id_value: this.userSession.id,
      location_id_value: this.locationId,
    }
    this.attendanceService.getRotationShiftSequence(data).subscribe((res) => {
      if (res.status) {
        this.rotationShiftList = res.data;
        this.filteredShiftSequenceList = this.rotationShiftList
      }
    });
    // }

  }
  getEmployeesForRotationShiftAssignment() {
    this.isShow = true;
    let data = {
      manager_employee_id: this.userSession.id,
      location_id_value: this.locationId,
    }
    this.attendanceService.getEmployeesForRotationShiftAssignment(data).subscribe((res) => {
      if (res.status) {
        this.arrayList = res.data;
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.pageLoading = false;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();

    if (this.dataSource2.paginator) {
      this.dataSource2.paginator.firstPage();
    }
  }

  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  submit() {
    this.addValidators();
    if (this.requestform.valid) {
      if (this.btnDisable) {
        return;
      }
      if (this.selection.selected.length > 0) {
        this.selectedEmps = [];
        this.selection.selected.forEach((e: any) => {
          this.selectedEmps.push(e.id);
        });
        let info = {
          "crssm_id_value": this.requestform.controls.shift.value,
          "employee_id_value": JSON.stringify(this.selectedEmps),
          "user_id_value": this.userSession.id,
          "start_shift_id_value": this.requestform.controls.sequenceShift.value,
          "effective_date_value": this.datePipe.transform(this.requestform.controls.fromDate.value, "y-MM-d")

        };
        this.btnDisable = true;
        this.attendanceService.setEmployeeRotationShiftMapping(info).subscribe((res) => {
          if (res.status) {
            let resMessage: any;
            if (res.data.successstate == "0") {
              resMessage = "Rotation shift assigned successfully"
            } else {
              resMessage = "Unable to assign rotation shift"
              this.btnDisable = false;
            }
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage
            });
            this.resetform();
          }
        })
      } else {
        this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Select atleast one employee."
        });
        this.btnDisable = false;
        return;
      }
    }
  }

  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/AssignShiftRotation"]));

  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 100
    }
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "ATT2") {
            this.requiredOption = e.message
          } else if (e.code == "ATT69") {
            this.dataSave = e.message
          } else if (e.code == "ATT70") {
            this.dataNotSave = e.message
          } else if (e.code == "ATT74") {
            this.ATT74 = e.message
          }
        })
      }
      else {
        this.messagesDataList = [];
      }

    })
  }

  getLocations() {
    this.attendanceService.getLocationsForReportingManager(this.managerId).subscribe(res => {
      if (res.status) {
        this.availableLocations = res.data;
        this.filteredAvaliableLocations = this.availableLocations;
      }
    })
  }

  getAssignedEmployeeRotationShiftMapping() {
    let info = {
      "location_id_value": 0,
      "manager_id_value": this.managerId,
    }
    this.attendanceService.getAssignedEmployeeRotationShiftMapping(info).subscribe(res => {
      if (res.status) {
        this.assignedRotationEmployeeList = res.data;
        this.dataSource2 = new MatTableDataSource(this.assignedRotationEmployeeList);
        this.pageLoading2 = false;
        this.dataSource2.paginator = this.paginator2;
        this.dataSource2.sort = this.sort2;
      }
    })
  }

  newAdd() {
    this.isAdd = false;
  }

  addValidators() {
    this.requestform.controls.shift.setValidators([Validators.required])
    this.requestform.get('shift').updateValueAndValidity();

    this.requestform.controls.sequenceShift.setValidators([Validators.required])
    this.requestform.get('sequenceShift').updateValueAndValidity();

  }


  clearValidators() {
    this.requestform.reset();
    this.requestform.get('shift').clearValidators();
    this.requestform.get('shift').updateValueAndValidity();

  }
  getPageSizes2(): number[] {
    var customPageSizeArray2 = [];
    if (this.dataSource2.data.length > 5) {
      customPageSizeArray2.push(5);
    }
    if (this.dataSource2.data.length > 10) {
      customPageSizeArray2.push(10);
    }
    if (this.dataSource2.data.length > 20) {
      customPageSizeArray2.push(20);
    }
    customPageSizeArray2.push(this.dataSource2.data.length);
    return customPageSizeArray2;
  }
  filterAvaliableLocations(searchText: any) {
    this.filteredAvaliableLocations = this.availableLocations.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase()))
    if (this.filteredAvaliableLocations.length <= 0) {
      this.searchControl.setValue('')
    }
  }
  filterShift(searchText: any) {
    this.filteredShiftSequenceList = this.rotationShiftList.filter((val: any) =>
      val.shift_sequence_name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredShiftSequenceList.length <= 0) {
      this.searchControlforShift.setValue('');
    }
  }

  filterSequenceShift(searchText: any) {
    this.filteredSequenceList = this.shiftSequenceList.filter((val: any) =>
      val.shift_sequence_name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredSequenceList.length <= 0) {
      this.searchControlforShift.setValue('');
    }
  }

  getRotationOrders(seqValue: string, id: any) {
    if (!seqValue) return '';
    if (id != 0) {
      const shifts = JSON.parse(seqValue);
      return shifts.map((shift: any) => `${shift.shiftName} - ${shift.duration} days`).join('<br/>');
    } else {
      return seqValue;
    }


  }
}