import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  mainUrl = environment.apiUrl;
  userSession: any;
  httpOptions: any;

  companyName: any;

  constructor(private http: HttpClient) {
    this.companyName = sessionStorage.getItem('companyName');
    this.httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        Authorization: JSON.parse(
          JSON.stringify(sessionStorage.getItem('token') || '')
        ),
      }),
    };
  }

  getTotalEmployeslist(): Observable<any> {
    return this.http.get(
      this.mainUrl + 'attendance/api/getallemployeeslist/' + this.companyName,
      this.httpOptions
    );
  }
  getTotalEmployeslistByManagerId(data: any): Observable<any> {
    data.companyName = sessionStorage.getItem('companyName');
    return this.http.post(
      this.mainUrl + 'attendance/api/getallemployeeslistByManagerId',
      data,
      this.httpOptions
    );
  }
  // ALL USER-WISE COLLECTION DATA API
  getAttendanceSummaryReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceSummaryReport',
      data,
      this.httpOptions
    );
  }
  getAttendanceDetailsByAttendanceId(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceDetailsByAttendanceId',
      data,
      this.httpOptions
    );
  }
  getAttendanceMonthlyReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceMonthlyReport',
      data,
      this.httpOptions
    );
  }
  getEmployeeLateAttendanceReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getEmployeeLateAttendanceReport',
      data,
      this.httpOptions
    );
  }
  /**get Shifts Wise Employees Counts By Dates */
  getShiftsWiseEmployeesCountsByDates(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getShiftsWiseEmployeesCountsByDates',
      data,
      this.httpOptions
    );
  }
  /**get Shifts wise Employees Detail Report By Date */
  getShiftsEmployeesDetailReportByDate(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getShiftsEmployeesDetailReportByDate',
      data,
      this.httpOptions
    );
  }

  getGrossSalaryDetails(info: any) {
    return this.http.post(
      this.mainUrl + 'api/getGrossSalaryDetails',
      info,
      this.httpOptions
    );
  }

  getManagersListByLocation(data: any) {
    if (!data.companyName)
    data.companyName = this.companyName;
    return this.http.post( this.mainUrl + 'api/getManagersForBranches', data, this.httpOptions );
  }

  getEmployeeListByManagerId(data: any) {
    return this.http.post(
      this.mainUrl + 'api/getEmployeesForManagers',
      data,
      this.httpOptions
    );
  }

  /**get branch  Shifts Wise Employees Counts By Dates */
  getBranchWiseShiftsWiseEmployeesCountsByDates(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl +
      'attendance/api/getBranchWiseShiftsWiseEmployeesCountsByDates',
      data,
      this.httpOptions
    );
  }
  /**get branch wise attendance monthly report */
  getBranchwiseAttendanceMonthlyReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getBranchwiseAttendanceMonthlyReport',
      data,
      this.httpOptions
    );
  }
  /**get branch wise Shifts wise Employees Detail Report By Date */
  getBranchWiseShiftsEmployeesDetailReportByDate(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl +
      'attendance/api/getShiftsEmployeesLocationWiseDetailReportByDate',
      data,
      this.httpOptions
    );
  }

  getLocationWiseEmployeesForManagers(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getLocationWiseEmployeesForManagers',
      data,
      this.httpOptions
    );
  }

  // getBranchwiseMonthlySummaryReport
  getLocationWiseAttendanceSummaryReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getLocationWiseAttendanceSummaryReport',
      data,
      this.httpOptions
    );
  }

  // getEmployeesForReportsRoleAssignment
  getEmployeesForReportsRoleAssignment(): Observable<any> {
    return this.http.get(
      this.mainUrl +
      'api/getEmployeesForReportsRoleAssignment/' +
      this.companyName,
      this.httpOptions
    );
  }

  // setReportsUserLocationsMapping
  setReportsUserLocationsMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'api/setReportsUserLocationsMapping',
      data,
      this.httpOptions
    );
  }
  //  getReportsuserLocationsMapping
  getReportsuserLocationsMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'api/getReportsuserLocationsMapping',
      data,
      this.httpOptions
    );
  }
  // getTotalEmployeslistByManagerId
  getReportsuserEmployeesList(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getReportsuserEmployeesList',
      data,
      this.httpOptions
    );
  }
  // getEmployeeLateAttendanceReport
  getEmployeeLateAttendanceReportForReportsuser(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl +
      'attendance/api/getEmployeeLateAttendanceReportForReportsuser',
      data,
      this.httpOptions
    );
  }

  // getAttendanceMonthlyReportForReportsuser
  getAttendanceMonthlyReportForReportsuser(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceMonthlyReportForReportsuser',
      data,
      this.httpOptions
    );
  }
  //getAttendanceSummaryReportForReportsuser
  getAttendanceSummaryReportForReportsuser(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceSummaryReportForReportsuser',
      data,
      this.httpOptions
    );
  }

  //getAttendanceSummaryReportForReportsuser
  getReportsuserLocationMappingStatus(empId: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getReportsuserLocationMappingStatus/' + empId + '/' + this.companyName, this.httpOptions);
  }

  getFutureDatesShiftReports(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getFutureDatesShiftReports',
      data,
      this.httpOptions
    );
  }

  /**get Shifts wise Employees Detail Report pdf */
  getShiftsEmployeesDetailReportPdf(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/getShiftsEmployeesDetailReportPdf',
      data, {
      responseType: 'blob',
      ...this.httpOptions
    });
  }

  getCurrentdayAttendanceDetailsReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getCurrentdayAttendanceDetailsReport',
      data,
      this.httpOptions
    );
  }

  getEmployeslistByLocationId(data: any): Observable<any> {
    data.companyName = sessionStorage.getItem('companyName');
    return this.http.post(
      this.mainUrl + 'attendance/api/getEmployeslistByLocationId',
      data,
      this.httpOptions
    );
  }

  getShiftwiseAttendanceReportPdf(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/getShiftwiseAttendanceReportPdf',
      data, {
      responseType: 'blob',
      ...this.httpOptions
    });
  }

  /**OT Report API Servive calls */
  /**getAttendanceOTReport */
  getAttendanceOTReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceOTReport',
      data,
      this.httpOptions
    );
  }
  getAttendanceDetailedMonthlyReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceDetailedMonthlyReport',
      data,
      this.httpOptions
    );
  }
  getAttendanceDetailedSummaryReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceDetailedSummaryReport',
      data,
      this.httpOptions
    );
  }

  getLocationWiseDetailedAttendanceMonthlyReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getLocationWiseDetailedAttendanceMonthlyReport',
      data,
      this.httpOptions
    );
  }
  getAttendanceDetailedMonthlyReportForReportsuser(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getAttendanceDetailedMonthlyReportForReportsuser',
      data,
      this.httpOptions
    );
  }
  /**getMissedOutPunchesReport */
  getMissedOutPunchesReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getMissedOutPunchesReport',
      data,
      this.httpOptions
    );
  }
  /**getMissedOutPunchesReportHR */
  getMissedOutPunchesReportHR(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainUrl + 'attendance/api/getMissedOutPunchesReportHR',
      data,
      this.httpOptions
    );
  }

    /**get Working Payable Days Report Pdf */
    getWorkingPayableDaysReportPdf(data: any): Observable<any> {
      data.companyName = this.companyName;
      return this.http.post(this.mainUrl + 'api/getWorkingPayableDaysReportPdf',
        data, {
        responseType: 'blob',
        ...this.httpOptions
      });
    }
    /**get Calendar Payable Days Report Pdf */
    getCalendarPayableDaysReportPdf(data: any): Observable<any> {
      data.companyName = this.companyName;
      return this.http.post(this.mainUrl + 'api/getCalendarPayableDaysReportPdf',
        data, {
        responseType: 'blob',
        ...this.httpOptions
      });
    }
}
