
export const environment = {
  production: true,

  /** spryple sreeb AWS */
   apiUrl: 'https://sreeb.spryple.com:6060/', 
  // apiUrl: 'http://43.205.12.16:4040/',

  /**-------------------------------------------------- */

  dbName: sessionStorage.getItem('companyName'),
};
