<div fxLayout="column" class="first-col">
  <mat-card>
    <form [formGroup]="mappingIdsForm" autocomplete="off">

      <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title class="title"> Mapping IDs </mat-card-title>
        <div style="margin-right: 2%;" *ngIf="isdata">
          <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
            <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
          </button>
        </div>
      </mat-card-header>

      <div fxLayout="row" fxLayout.lt-lg="column" class="dev-Pad" *ngIf="isAdd">
        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex.xs="100" fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Bio-Metric Id</mat-label>
            <input formControlName="biometricid" required matInput minlength="1" maxlength="10"
              placeholder="Bio-Metric Id">
            <mat-error class="con-error" *ngIf="mappingIdsForm.controls.biometricid.errors?.required">
              This field is required.
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100%" fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Employee Name</mat-label>
            <mat-select formControlName="empid" required>
              <!-- <mat-option value="0"> All Employees </mat-option> -->
              <ng-container *ngFor="let e of employeelist">
                <mat-option [value]="e.id">{{e.emp_name}}</mat-option>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="mappingIdsForm.controls.empid.errors?.required">
              Please select an option.
            </mat-error>
          </mat-form-field>
        </div>
        <span style="margin-top: 0.7%;" fxLayoutAlign.lt-lg="center">
          <button class="btn btn-primary mr-2 mb-2" (click)="saveMappingIds()">
            Submit
          </button>

          <span class="line" style="color:#1898D5;font-size:16px"></span>
          <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
            <span>Clear</span>
          </button>
        </span>

      </div>
    </form>

    <div fxLayout="column" *ngIf="isdata">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"
        style="padding-top: 5px;height: 70px;">
        <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
          <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
          <mat-label>Search </mat-label>
          <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100" />
        </mat-form-field>
      </div>
      <div fxLayout="column" class="table-pad">
        <div class="mat-elevation-z1 example-container">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef class="no-wrap"> S. No.</th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>


            <ng-container matColumnDef="biometricid">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Biometric Id </th>
              <td mat-cell *matCellDef="let row"> {{row.deviceempid}}</td>
            </ng-container>


            <ng-container matColumnDef="empid">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp. ID </th>
              <td mat-cell *matCellDef="let row"> {{row.boonempid}} </td>
            </ng-container>


            <ng-container matColumnDef="empname">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp. Name </th>
              <td mat-cell *matCellDef="let row"> {{row.empname}} </td>
            </ng-container>

            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4" style="text-align: center;">No data found</td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

          <!-- <mat-paginator   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
        </div>
      </div>
    </div>

  </mat-card>

</div>