<div fxLayout="column" class="first-col">
    <mat-card>
        <form [formGroup]="requestform" autocomplete="off">
            <mat-card-header class="headerbox">
                <mat-card-title class="title">
                    Request on Behalf of Employee
                </mat-card-title>
            </mat-card-header>
            <div class="tableP">
                <div fxLayout="row" fxLayout.xs="column">

                    <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Employee Name</mat-label>
                        <mat-select formControlName="employeeName" required>
                            <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                                matInput>
                            <mat-option *ngFor="let list of filteredEmployeeList"
                                [value]="list.empid">{{list.employee_code}} {{'-'}} {{ list.empname }} </mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="requestform.controls.employeeName.errors?.required">
                            {{ requiredOption }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">

                    <mat-form-field *ngIf="!isRequestView" fxFlex fxFlex.xs="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Work Type</mat-label>

                        <mat-select formControlName="workType" required>
                            <input class="search-align" type="text" placeholder="Search"
                                [formControl]="workTypeSearchControl" matInput>
                            <mat-option *ngFor="let list of filteredWorkTypes" [value]="list.id">{{ list.type
                                }}</mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="requestform.controls.workType.errors?.required">
                            {{ requiredOption }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="isRequestView" fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Work Type</mat-label>
                        <input formControlName="workType" class="no-wrap" readonly required matInput />
                    </mat-form-field>

                    <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>From Date </mat-label>
                        <input matInput readonly required [min]="minFromDate" [max]="maxFromDate"
                            [matDatepicker]="fromDate" [matDatepickerFilter]="myDateFilter"
                            (dateInput)="fromDateChange('input', $event)" placeholder="DD-MM-YYYY"
                            (click)="fromDate.open()" formControlName="fromDate" [tabIndex]="3">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                        <mat-datepicker #fromDate></mat-datepicker>
                        <mat-error class="con-error" *ngIf="requestform.controls.fromDate.errors?.required">
                            {{requiredOption}}
                        </mat-error>
                    </mat-form-field>

                    <!-- <mat-checkbox style="margin-left: 10px;" formControlName="firstHalf"
                 (change)="firstSelected($event)" [checked]="isFirstHalf" [disabled]="isdisable">First Half
                </mat-checkbox> -->



                    <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>To Date </mat-label>
                        <input matInput readonly required [min]="minToDate" [max]="maxToDate" [matDatepicker]="toDate"
                            [matDatepickerFilter]="myDateFilter" placeholder="DD-MM-YYYY"
                            (dateInput)="toDateChange('input', $event)" formControlName="toDate" (click)="toDate.open()"
                            [tabIndex]="4">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>
                        <mat-error class="con-error" *ngIf="requestform.controls.toDate.errors?.required">
                            {{requiredOption}}
                        </mat-error>
                    </mat-form-field>

                    <!-- <mat-checkbox style="margin-left: 10px;" formControlName="secondHalf" 
                (change)="secondSelected($event)" [checked]="isSecondHalf" [disabled]="isdisable">Second Half
            </mat-checkbox> -->


                </div>


                <div fxLayout="row" fxLayoutAlign.xs="center center">
                    <mat-form-field fxFlex="65.5" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Reason </mat-label>
                        <textarea formControlName="reason" rows="1" noLeadingWhitespace required maxlength="250"
                            matInput placeholder="Reason"></textarea>
                        <mat-error class="con-error" *ngIf="requestform.controls.reason.errors?.required ">
                            {{ requiredField }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- half day attendance -->


                <div fxLayout="column">
                    <mat-checkbox style="margin-left: 10px;" formControlName="firstHalf"
                        (change)="firstSelected($event)" [checked]="isFirstHalf" [disabled]="fhalfDisable">First Half
                    </mat-checkbox>

                    <mat-checkbox style="margin-left: 10px;" formControlName="secondHalf"
                        (change)="secondSelected($event)" [checked]="isSecondHalf" [disabled]="shalfDisable">Second Half
                    </mat-checkbox>
                </div>

                <div fxLayout="row" style="align-items: center">
                    <span *ngIf="pdfName" class="no-wrap" style="margin-left: 10px">Upload File:</span>
                    <button class="toolbar-button" mat-button (click)="fileView()" class="link"
                        style="margin-left: 10px; font-size: 16px" *ngIf="pdfName">
                        {{ pdfName }}
                    </button>
                    &nbsp;
                    <input *ngIf="!pdfName" style="margin-left: 10px" type="file" formControlName="document"
                        (change)="onSelectFile($event)" single accept="pdf" />
                    &nbsp;
                    <mat-icon title="change" *ngIf="pdfName && !isRequestView" (click)="editdoc()">edit</mat-icon>
                    &nbsp;
                    <span>
                        <mat-icon title="Delete" *ngIf="requestform.controls.document.value && iseditDoc"
                            (click)="deleteIcon()">delete</mat-icon>
                    </span>
                    &nbsp;
                </div>

                <div fxLayoutAlign="center">
                    <button *ngIf="!isRequestView" class="btn btn-primary mr-2 mb-2" [ngClass]="{'disable':btnDisable}"
                        [disabled]="btnDisable" (click)="submit()" type="submit">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </div>
        </form>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end"
            style="height: 70px; margin-top: 5px">
            <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline"
                style="padding-right: 16px">
                <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100" />
            </mat-form-field>
        </div>
        <div class="tablePadding">
            <div class="mat-elevation-z1 example-container">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap">S. No.</th>
                        <td mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="applieddate">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Applied On </th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.applieddate | date:'dd-MM-yyyy'}}</td>
                </ng-container> -->

                    <ng-container matColumnDef="worktype">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap">
                            Work Type
                        </th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap">
                            {{ row.worktype }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="raisedbyname">
                        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                            Emp. Name
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.empname }}</td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="shift">
                    <th mat-header-cell *matHeaderCellDef > Shift </th>
                    <td mat-cell *matCellDef="let row"> {{row.shift}}</td>
                </ng-container> -->

                    <ng-container matColumnDef="fromdate">
                        <th mat-header-cell *matHeaderCellDef>From Date</th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap">
                            {{ row.fromdate | date : "dd-MM-yyyy" }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="todate">
                        <th mat-header-cell *matHeaderCellDef>To Date</th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap">
                            {{ row.todate | date : "dd-MM-yyyy" }}
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef> Reason </th>
                    <td mat-cell *matCellDef="let row" style="word-break: break-all;padding-right: 20px;"> {{row.reason}} </td>
                </ng-container> -->

                    <!-- <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.status"> {{row.status}} </td>
                </ng-container> -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap">
                            <button mat-icon-button [tabIndex]="12" (keyup.enter)="requestView(row)">
                                <mat-icon class="mr-2" title="view" (click)="requestView(row)">
                                    visibility</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="8" style="text-align: center">
                            No data found
                        </td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                <!-- <mat-paginator  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
        </div>
    </mat-card>
</div>