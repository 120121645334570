import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup, FormGroup,
  Validators
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { AttendanceService } from '../../attendance.service';
import { UserData } from '../../models/EmployeeData';
import { LeavePoliciesDialogComponent } from 'src/app/modules/admin/dialog/leave-policies-dialog/leave-policies-dialog.component';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { LeavePolicyDeductionComponent } from 'src/app/modules/leaves/dialog/leave-policy-deduction/leave-policy-deduction.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgxSpinnerService } from 'ngx-spinner';

export interface source {
  id: number,
  attendancedate: string;
  empname: string;
  shiftname: string;
  logintime: string,
  logouttime: string,
  shift_end_time: string,
  workedHours: string,
  attendanceid: number,

}

@Component({
  selector: 'app-employee-attendance-regularaization',
  templateUrl: './employee-attendance-regularaization.component.html',
  styleUrls: ['./employee-attendance-regularaization.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmployeeAttendanceRegularaizationComponent implements OnInit {
  leaveRequestForm: any = UntypedFormGroup;
  attendanceRequestForm: any = UntypedFormGroup;
  displayedColumns: string[] = [
    'sno',
    'empoyeeid',
    'empname',
    'shift',
    'intime',
    'outtime',
    // 'workedHours',
    'action',
    'leaveRequest'
  ];
  dataSource: MatTableDataSource<UserData> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  isleaveadd: boolean = false;
  arrayList: UserData[] = [];
  userSession: any;
  titleName: string = '';
  reason: any;
  requestData: any;
  leavesTypeData: any = [];
  isreg: boolean = true;
  isFirstHalf: boolean = true;
  isSecondHalf: boolean = true;
  isDisableFirstHalf: boolean = false;
  isDisableSecondHalf: boolean = false;
  pipe = new DatePipe('en-US');
  toMaxDate: any;
  pdfName: any = null;
  document: boolean = false;
  isValidateLeave: boolean = false;
  compofftdatehide: boolean = false;
  originalleavecount: any;
  msgLM79: any;
  msgLM76: any;
  msgLM1: any;
  msgLM3: any;
  msgLM7: any;
  msgLM119: any;
  msgLM140: any;
  msgLM141: any;
  msgLM142: any;
  roleValue: any;
  minDate: any;
  maxDate: any;
  fromMaxDate: any;
  nextLeaveDate: any;
  compOffApprovedDates: any = [];
  isCompOff: boolean = true;
  employeeRelations: any = [];
  maxCountPerTermValue: any;
  isFile: boolean = true;
  formData: any;
  leaveInfo: any;
  leaveData: any;
  submitted: boolean = false;
  documentId: any = null;
  documentInfo: any = null;
  employeeEmailData: any = [];
  employeeId: any;
  isedit: boolean = false;
  zerodisplayname: any;
  onedisplayname: any;
  zeroleavecount: any;
  oneleavecount: any;
  twoleavecount: any;
  twoleavedisplayname: any = null;
  iseditDoc: boolean = true;
  isnormalleave: boolean = false;
  isnormalflag: boolean = false;
  isedited: boolean = false;
  leavedeductionpolicydata: any;
  leavedeductionpolicydata2: any;
  enable: any = null;
  iseditouttime: boolean = false;
  isSave: boolean = false;
  isAdd: boolean = false;
  myDateFilter: any;
  workedHours: any;
  minFromDate: Date | undefined;
  maxFromDate: Date | null | undefined;
  panelOpenState = false;
  expandedRows: { [key: number]: boolean } = {};
  expandedElement: boolean = true;
  expandedElementrow: source | null = null;
  source: any;
  addNewRegularization: any = UntypedFormGroup;
  forms: { [key: string]: FormGroup } = {};
  isAddEnable: any;
  attendanceDate: any;
  outTime: any;
  inTime: any;
  totalHours: any = 0;
  selectedEmps: any = [];
  btnDisable: boolean = false;
  nightShift: boolean = false;
  finalSubmit: boolean = false;
  editSubmit: boolean = false;
  OHHIn: any;
  OSSIn: any;
  OMMIn: any;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private formBuilder: UntypedFormBuilder,
    private LM: LeavesService,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getEmployeesForRegularization(1);
  }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');

    this.attendanceRequestForm = this.formBuilder.group({
      intime: [''],
      outtime: [''],
    });
    this.leaveRequestForm = this.formBuilder.group({
      leaveTypeId: [''],
      compoffApprovedDate: [''],
      relation: [''],
      fromDate: [''],
      toDate: [''],
      toDateHalf: [''],
      fromDateHalf: [''],
      leaveCount: [''],
      // reason: ['', [Validators.required]],
      contact: [this.userSession.contactnumber],
      document: [''],
    });
    this.leaveRequestForm
      .get('leaveTypeId')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.setValidateLeave();
      });

    this.addNewRegularization = this.formBuilder.group({
      date: [''],
      addLogin: ['', Validators.required],
      addLogout: ['', Validators.required],
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  async addRidToData(data: any) {
    return data.map((item: any, index: any) => ({
      ...item,
      rid: index + 1
    }));
  }
  /**For getting employees list */
  getEmployeesForRegularization(roleid: any) {
    let data = {
      empid: this.userSession.id,
      roleid: roleid,
      location_id: null
    };
    this.spinner.show();
    this.attendanceService.getEmployeesForRegularization(data).subscribe(async (result: any) => {
   
      if (result.status) {
        let resData = await this.addRidToData(result.data)
        this.dataSource = new MatTableDataSource(resData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
    this.source = this.dataSource.filteredData;
  }
  changeTab(elment: UserData) {
    this.router.navigate(['/Attendance/Approval'], {
      state: { userData: elment, url: 'ApprovedHistory' },
    });
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  setAttendanceRegularization(data: any) { }
  setLeaveRegularization(data: any) {
    let dialogRef = this.dialog.open(LeavePoliciesDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: {
        message: 'Are you sure you want to delete attendance request?',
        YES: 'YES',
        NO: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'YES') {
        this.deleteattendance(data);
        this.getLeaveTypesForDeductionList();
      } else {
      }
    });
  }
  edit(data: any) {

    this.enable = data.attendanceid;
    this.iseditouttime = true;
    this.isAdd = false;
    this.source = false;
    // this.isSave = true;
    this.attendanceRequestForm.controls.intime.setValue(data.logintime)
  }
  canceledit() {
    this.enable = null;
    this.iseditouttime = false;
    this.btnDisable = false;
  }


  // saveRegularization(data: any) {

  //   let valid = this.attendanceRequestForm.controls.outtime.value;
  //   let intimeValid = this.attendanceRequestForm.controls.intime.value;
  //   let lenintimeValid = intimeValid.length;
  //   // (intimeValid != null && intimeValid != '' && intimeValid != '000000')
  //   if ((valid != null && valid != '' && valid != '000000') && ((intimeValid != null && intimeValid != '' && intimeValid != '000000'))) {
  //     let set = data.shift_end_time.split(':');
  //     let OHH = Number(
  //       this.attendanceRequestForm.controls.outtime.value[0] +
  //       this.attendanceRequestForm.controls.outtime.value[1]
  //     );
  //     let OMM = Number(
  //       this.attendanceRequestForm.controls.outtime.value[2] +
  //       this.attendanceRequestForm.controls.outtime.value[3]
  //     );
  //     let OSS = Number(
  //       this.attendanceRequestForm.controls.outtime.value[4] +
  //       this.attendanceRequestForm.controls.outtime.value[5]
  //     );
  //     let intimeset = data.shift_start_time.split(':');
  //     if (lenintimeValid == 6) {
  //       this.OHHIn = Number(
  //         this.attendanceRequestForm.controls.intime.value[0] +
  //         this.attendanceRequestForm.controls.intime.value[1]
  //       );
  //       this.OMMIn = Number(
  //         this.attendanceRequestForm.controls.intime.value[2] +
  //         this.attendanceRequestForm.controls.intime.value[3]
  //       );
  //       this.OSSIn = Number(
  //         this.attendanceRequestForm.controls.intime.value[4] +
  //         this.attendanceRequestForm.controls.intime.value[5]
  //       );
  //     } else {
  //       this.OHHIn = Number(
  //         this.attendanceRequestForm.controls.intime.value[0] +
  //         this.attendanceRequestForm.controls.intime.value[1]
  //       );
  //       this.OMMIn = Number(
  //         this.attendanceRequestForm.controls.intime.value[3] +
  //         this.attendanceRequestForm.controls.intime.value[4]
  //       );
  //       this.OSSIn = Number(
  //         this.attendanceRequestForm.controls.intime.value[6] +
  //         this.attendanceRequestForm.controls.intime.value[7]
  //       );
  //     }

  //     if (data.shift_start_time > data.shift_end_time) {
  //       this.nightShift = true;
  //     }
  //     if (!this.nightShift) {
  //       if (OHH <= 24 && OMM <= 60 && OSS <= 60 && this.OMMIn <= 60 && this.OHHIn <= 24 && this.OSSIn <= 60) {

  //         if (this.OHHIn < set[0] && Number(intimeset[0]) <= this.OHHIn) {
  //           // Number(set[0]) >= OHH && -for limiting outtime
  //           if (this.OHHIn <= OHH) {
  //             if (Number(intimeset[1]) >= this.OMMIn || (intimeset[0] <= this.OHHIn && intimeset[1] <= this.OMMIn)) {
  //               // (Number(set[1]) >= OMM || (set[0] > OHH && set[1] <= OMM)) &&  -for limiting outtime

  //               if (Number(intimeset[2]) >= this.OSSIn || (this.OHHIn >= intimeset[0] && intimeset[2] <= this.OSSIn)) {
  //                 // (Number(set[2]) >= OSS || (set[0] > OHH && set[2] <= OSS)) && -for limiting outtime

  //                 this.editSubmit = true;
  //               } else {
  //                 let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //                   position: { top: `70px` },
  //                   disableClose: true,
  //                   data: 'Selected Time must be within the shift time.',
  //                 });
  //               }
  //             } else {
  //               let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //                 position: { top: `70px` },
  //                 disableClose: true,
  //                 data: 'Selected Time must be within the shift time.',
  //               });
  //             }
  //           } else {
  //             let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //               position: { top: `70px` },
  //               disableClose: true,
  //               data: 'Intime is less than Outtime.',
  //             });
  //           }
  //         } else {
  //           let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //             position: { top: `70px` },
  //             disableClose: true,
  //             data: 'Selected Time must be within the shift time.',
  //           });
  //         }

  //       } else {
  //         let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //           position: { top: `70px` },
  //           disableClose: true,
  //           data: 'Please enter valid time.',
  //         });
  //       }

  //     } else {

  //       if (this.OHHIn < Number(intimeset[0]) && this.OHHIn > Number(set[0])) {
  //         let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //           position: { top: `70px` },
  //           disableClose: true,
  //           data: 'Selected Time must be within the shift time.',
  //         });

  //       } else {
  //         this.editSubmit = true;

  //       }
  //     }
  //     if (this.editSubmit) {

  //       if (this.btnDisable) {
  //         return;
  //       }
  //       this.selectedEmps = [];
  //       let setdata = {
  //         emp_id: data.empid,
  //         shiftid: data.shiftid,
  //         fromdate: data.attendancedate,
  //         todate: data.attendancedate,
  //         logintime: this.OHHIn + ':' + this.OMMIn + ':' + this.OSSIn,
  //         logouttime: OHH + ':' + OMM + ':' + OSS,
  //         worktype: data.worktypeid,
  //         raisedby: this.userSession.id,
  //         approvercomments: '',
  //         actionby: this.userSession.id,
  //         status: 'Submitted',
  //         halfday: data.halfday == null ? 0 : data.halfday,
  //         locationid: data.locationid,
  //       };
  //       this.selectedEmps.push(setdata);

  //       let obj = {
  //         input_data: this.selectedEmps,
  //       }
  //       console.log("insubmit")

  //       // this.spinner.show();
  //       // this.btnDisable = true;
  //       // this.attendanceService.setAttendanceRegularizationNew(obj).subscribe((result: any) => {

  //       //   if (result.status && result.data[0].validity_status == 1) {
  //       //     this.router.navigateByUrl('/', { skipLocationChange: true })
  //       //       .then(() => this.router.navigate(['/Attendance/employeeAttendanceRegularization',]));
  //       //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //       //       position: { top: `70px` },
  //       //       disableClose: true,
  //       //       data: 'Attendance Regularization updated successfully',
  //       //     });
  //       //     this.spinner.hide();
  //       //   } else if (result.status && result.data[0].validity_status == 0) {
  //       //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //       //       position: { top: `70px` },
  //       //       disableClose: true,
  //       //       data: 'Record already exist',
  //       //     });
  //       //     this.btnDisable = false;
  //       //     this.spinner.hide();
  //       //   } else {
  //       //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //       //       position: { top: `70px` },
  //       //       disableClose: true,
  //       //       data: "Unable to save Attendance Regularization"
  //       //     });
  //       //     this.btnDisable = false;
  //       //     this.spinner.hide();
  //       //   }
  //       // });
  //     }

  //   } else {
  //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //       position: { top: `70px` },
  //       disableClose: true,
  //       data: 'Please enter valid out time.',
  //     });
  //   }
  // }


  saveRegularization(data: any) {
    let valid = this.attendanceRequestForm.controls.outtime.value;
    let intimeValid = this.attendanceRequestForm.controls.intime.value;
    let lenintimeValid = intimeValid.length;
    // (intimeValid != null && intimeValid != '' && intimeValid != '000000')
    if ((valid != null && valid != '' && valid != '000000') && ((intimeValid != null && intimeValid != '' && intimeValid != '000000'))) {
      let set = data.shift_end_time.split(':');
      let OHH = Number(
        this.attendanceRequestForm.controls.outtime.value[0] +
        this.attendanceRequestForm.controls.outtime.value[1]
      );
      let OMM = Number(
        this.attendanceRequestForm.controls.outtime.value[2] +
        this.attendanceRequestForm.controls.outtime.value[3]
      );
      let OSS = Number(
        this.attendanceRequestForm.controls.outtime.value[4] +
        this.attendanceRequestForm.controls.outtime.value[5]
      );
      let intimeset = data.shift_start_time.split(':');
      if (lenintimeValid == 6) {
        this.OHHIn = Number(
          this.attendanceRequestForm.controls.intime.value[0] +
          this.attendanceRequestForm.controls.intime.value[1]
        );
        this.OMMIn = Number(
          this.attendanceRequestForm.controls.intime.value[2] +
          this.attendanceRequestForm.controls.intime.value[3]
        );
        this.OSSIn = Number(
          this.attendanceRequestForm.controls.intime.value[4] +
          this.attendanceRequestForm.controls.intime.value[5]
        );
      } else {
        this.OHHIn = Number(
          this.attendanceRequestForm.controls.intime.value[0] +
          this.attendanceRequestForm.controls.intime.value[1]
        );
        this.OMMIn = Number(
          this.attendanceRequestForm.controls.intime.value[3] +
          this.attendanceRequestForm.controls.intime.value[4]
        );
        this.OSSIn = Number(
          this.attendanceRequestForm.controls.intime.value[6] +
          this.attendanceRequestForm.controls.intime.value[7]
        );
      }
      if (data.shift_start_time > data.shift_end_time) {
        this.nightShift = true;
      }
      if (!this.nightShift) {
        if (OHH <= 24 && OMM <= 60 && OSS <= 60 && this.OMMIn <= 60 && this.OHHIn <= 24 && this.OSSIn <= 60) {
          if(this.OHHIn !== OHH || (this.OHHIn == OHH && this.OMMIn<OMM)){
            if (this.OHHIn < Number(set[0])) {
              // Number(set[0]) >= OHH && -for limiting outtime
              if (this.OHHIn <= OHH) {
                this.editSubmit = true;
              } else {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: 'Intime should be less than the Outtime.',
                });
              }
            } else {
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: 'Selected InTime must be within the shift end time.',
              });
            }
          }else{
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'In time and out time should not be the same.',
            });
          }
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Please enter valid time.',
          });
        }

      } else {
        if(OHH <= 24 && OMM <= 60 && OSS <= 60 && this.OMMIn <= 60 && this.OHHIn <= 24 && this.OSSIn <= 60){
          if(this.OHHIn !== OHH || (this.OHHIn == OHH && this.OMMIn<OMM)){
            if ((this.OHHIn > Number(set[0]) && OHH<=12 && this.OHHIn >=12) || (this.OHHIn > Number(set[0]) && OHH>=12 && this.OHHIn>=12) || (this.OHHIn < Number(set[0]) && OHH<=12 && this.OHHIn<=12 ) ) {     
              if(this.OHHIn <= OHH){
                this.editSubmit = true;
              }else{
                if(OHH<12 && this.OHHIn>12){
                  this.editSubmit = true;
                }else{
                  let dialogRef = this.dialog.open(ReusableDialogComponent, {
                    position: { top: `70px` },
                    disableClose: true,
                    data: 'Intime should be less than the Outtime.',
                  });
                }
              }
            } else {
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: 'Selected InTime must be within the shift end time.',
              });
            }
          }else{
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'In time and outtime should not be the same.',
            });        
          }      
        }else{
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Please enter valid time.',
          });
        }  
      }
      if (this.editSubmit) {

        if (this.btnDisable) {
          return;
        }
        this.selectedEmps = [];
        let setdata = {
          emp_id: data.empid,
          shiftid: data.shiftid,
          fromdate: data.attendancedate,
          todate: data.attendancedate,
          logintime: this.OHHIn + ':' + this.OMMIn + ':' + this.OSSIn,
          logouttime: OHH + ':' + OMM + ':' + OSS,
          worktype: data.worktypeid,
          raisedby: this.userSession.id,
          approvercomments: '',
          actionby: this.userSession.id,
          status: 'Submitted',
          halfday: data.halfday == null ? 0 : data.halfday,
          locationid: data.locationid,
        };
        this.selectedEmps.push(setdata);

        let obj = {
          input_data: this.selectedEmps,
        }
        this.spinner.show();
        this.btnDisable = true;
        this.attendanceService.setAttendanceRegularizationNew(obj).subscribe((result: any) => {

          if (result.status && result.data[0].validity_status == 1) {
            this.router.navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/Attendance/employeeAttendanceRegularization',]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Attendance Regularization updated successfully',
            });
            this.spinner.hide();
          } else if (result.status && result.data[0].validity_status == 0) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Record already exist',
            });
            this.btnDisable = false;
            this.spinner.hide();
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Unable to save Attendance Regularization"
            });
            this.btnDisable = false;
            this.spinner.hide();
          }
        });
      }

    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please enter valid out time.',
      });
    }
  }

  /**delete_attendance_for_regularization */
  deleteattendance(datas: any) {
    let data = {
      empid: datas.empid,
      regularaizedate: datas.attendancedate,
    };

    this.isleaveadd = true;
    this.isreg = false;

    this.leaveRequestForm.controls.fromDate.setValue(datas.attendancedate);
    this.leaveRequestForm.controls.fromDate.disable();
    this.leaveRequestForm.controls.toDate.setValue(datas.attendancedate);
    this.leaveRequestForm.controls.toDate.disable();
  }
  setApplyLeave() {
    if (this.leaveRequestForm.valid) {
      if (this.btnDisable) {
        return;
      }
      let data = {
        employee_id: this.userSession.id,
        leavetype: this.leaveRequestForm.controls.leaveTypeId.value,
        fromdate: this.leaveRequestForm.controls.fromDate.value,
        todate: this.leaveRequestForm.controls.toDate.value,
        fromhalfdayleave: '',
        tohalfdayleave: '',
        // fromhalfdayleave:this.leaveRequestForm.controls.fromhalfdayleave.value ,
        // tohalfdayleave:this.leaveRequestForm.controls.tohalfdayleave.value ,
        leavecount: this.leaveRequestForm.controls.leaveCount.value,
        leavestatus: 'Submitted',
        contactnumber: '',
        regularization_date: this.leaveRequestForm.controls.fromDate.value,
      };
      this.btnDisable = true;
      this.attendanceService.setLeaveRegularization(data).subscribe((result: any) => {
        if (result.status) {
          this.router.navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/Attendance/employeeAttendanceRegularization',])
            );
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Leave request applied successfully',
          });
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to applied leave',
          });
        }
        this.btnDisable = false;
      });
    }
  }
  cancel() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigate(['/Attendance/employeeAttendanceRegularization'])
      );
  }

  getLeaveTypesForDeductionList() {
    this.adminService
      .getLeavesForDeductionConfigurationforRegularization(this.userSession.id)
      .subscribe((res: any) => {
        if (res.status) {
          this.leavesTypeData = res.data;
        }
      });
  }

  /**
   * setLeavePolicyDeduction function used for sand wich leave scenario
   * Procedure used here set_leave_policy_deduction
   * This is not used for Event Based leaves
   */
  setLeavePolicyDeduction() {
    this.LM.setLeavePolicyDeduction(this.leavedeductionpolicydata).subscribe(
      (result: any) => {
        if (result.status) {
          this.leavedeductionpolicydata2 != null
            ? this.LM.setLeavePolicyDeductionSecond(
              this.leavedeductionpolicydata2
            ).subscribe((resultts: any) => { })
            : '';
        } else {
        }
      }
    );
  }

  setValidateLeave() {
    var errorMessage = [];
    let obj = {
      empid: this.userSession.id,
      fromDate: this.pipe.transform(
        this.leaveRequestForm.controls.fromDate.value,
        'yyyy-MM-dd'
      ),
      toDate: this.pipe.transform(
        this.leaveRequestForm.controls.toDate.value,
        'yyyy-MM-dd'
      ),
      fromDateHalf: this.leaveRequestForm.controls.fromDateHalf.value,
      toDateHalf: this.leaveRequestForm.controls.toDateHalf.value,
      leaveTypeId: this.leaveRequestForm.controls.leaveTypeId.value,
      document: false,
      leaveId: null,
    };
    this.originalleavecount = '';
    this.LM.setValidateLeave(obj).subscribe(async (result: any) => {
      if (result && result.status) {
        var validLeave = JSON.parse(result.data[0].count_json);
        this.leaveRequestForm.controls.leaveCount.setValue(
          JSON.parse(result.data[0].count_json)[0].leavecount
        );
        this.isedited = result.data[0].edit_flag == 1 ? true : false;
        this.leavedeductionpolicydata = {};
        this.leavedeductionpolicydata2 = {};

        //result.data[0].display_name //result.data[0].leave_count  //result.data[1].display_name  //result.data[1].leave_count

        /**
         * This condition is used for sandwich leave
         */
        if (result.data[0].is_normal_leave == 0) {
          this.originalleavecount = result.data[0].leave_count;
          this.zerodisplayname = result.data[0].display_name;
          this.onedisplayname = result.data[1].display_name;
          this.zeroleavecount = result.data[0].leave_count;
          this.oneleavecount = result.data[1].leave_count;
          this.twoleavedisplayname =
            result.data.length == 3 ? result.data[2].display_name : null;
          this.twoleavecount =
            result.data.length == 3 ? result.data[2].leave_count : null;

          this.isnormalflag = false;
          this.isnormalleave = true;
          this.leavedeductionpolicydata = {
            employee_id: result.data[0].empid,
            leavetype_id_original: result.data[0].ltype_id,
            fromdate_original: result.data[0].fdate,
            todate_original: result.data[0].tdate,
            leavetype_id: result.data[1].ltype_id,
            fromdate: result.data[1].fdate,
            todate: result.data[1].tdate,
            leavecount: result.data[1].leave_count,
            leavestatus: 'Submitted',
            action_reason: '',
          };
          result.data.length == 3
            ? (this.leavedeductionpolicydata2 = {
              employee_id: result.data[0].empid,
              leavetype_id_original: result.data[0].ltype_id,
              fromdate_original: result.data[0].fdate,
              todate_original: result.data[0].tdate,
              leavetype_id: result.data[2].ltype_id,
              fromdate: result.data[2].fdate,
              todate: result.data[2].tdate,
              leavecount: result.data[2].leave_count,
              leavestatus: 'Submitted',
              action_reason: '',
            })
            : (this.leavedeductionpolicydata2 = null);
          if (this.submitted) {
            let dialogRef = this.dialog.open(LeavePolicyDeductionComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: {
                content:
                  "Based on your company's leave policies, the following leaves will be deducted from your leave balance.",
                message0:
                  result.data[0].display_name +
                  ' : ' +
                  result.data[0].leave_count,
                message1:
                  result.data[1].display_name +
                  ' : ' +
                  result.data[1].leave_count,
                message2:
                  result.data[2].display_name + ' : ' + this.twoleavecount,
                query: 'Do you wish to continue?',
                YES: 'YES',
                NO: 'No',
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result == 'YES') {
                let leavetypename = '';
                this.leavesTypeData.forEach((e: any) => {
                  if (
                    e.id == this.leaveRequestForm.controls.leaveTypeId.value
                  ) {
                    leavetypename = e.display_name;
                  }
                });
              }
            });
          }
        }
      }
    });
  }
  add(data: source) {

    this.isAdd = true;
    this.isAddEnable = data.attendanceid;
    this.expandedElement = !this.expandedElement;
    this.source = this.source === data ? null : data;
    this.enable = null;
    this.iseditouttime = false;
    this.attendanceDate = this.pipe.transform(data.attendancedate, 'dd-MM-yyyy');
    this.addNewRegularization.controls.date.setValue(this.attendanceDate)
    this.addNewRegularization.controls.date.disable();
  }
 // code for intime to be greater than shift start time
  // addRegularization(obj: any) {
  //   this.inTime = this.pipe.transform(this.addNewRegularization.controls.addLogin.value, 'HH:mm:ss');
  //   this.outTime = this.pipe.transform(this.addNewRegularization.controls.addLogout.value, 'HH:mm:ss');
  //   if (obj.shift_start_time > obj.shift_end_time) {
  //     this.nightShift = true;
  //   }
  //   if (this.addNewRegularization.valid) {
  //     let rdata = obj;
  //     this.inTime = this.pipe.transform(this.addNewRegularization.controls.addLogin.value, 'HH:mm:ss');
  //     this.outTime = this.pipe.transform(this.addNewRegularization.controls.addLogout.value, 'HH:mm:ss');
  //     if (!this.nightShift) {
  //       if (this.inTime < obj.shift_start_time) {
  //         let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //           position: { top: `70px` },
  //           disableClose: true,
  //           data: 'Your applied shift timings are incorrect. Please verify and reapply.',
  //         });
  //       }
  //       else if ((this.inTime >= obj.logintime && this.inTime <= obj.logouttime) || (this.outTime >= obj.logintime && this.outTime <= obj.logouttime)) {
  //         let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //           position: { top: `70px` },
  //           disableClose: true,
  //           data: 'The shift timings you applied for are already in the records. Please select a different time and reapply.',
  //         });
  //       } else if ((obj.logintime > this.inTime && obj.logintime < this.outTime) || (obj.logouttime > this.inTime && obj.logouttime < this.outTime)) {
  //         let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //           position: { top: `70px` },
  //           disableClose: true,
  //           data: 'The applied shift timings overlaps with an existing record. Please adjust and reapply.',
  //         });
  //       } else {
  //         this.finalSubmit = true;
  //       }
  //     }
  //     if (this.nightShift) {
  //       let test = obj.logouttime.split(":")[0];
  //       if ((this.inTime > obj.shift_start_time && this.inTime < obj.logintime) && (this.outTime > obj.shift_start_time && this.outTime < obj.logintime)) {
  //         this.finalSubmit = true;
  //       }
  //       else if ((this.inTime < obj.shift_end_time && this.inTime > obj.logouttime)) {
  //         this.finalSubmit = true;
  //       } else {
  //         if ((this.pipe.transform(this.addNewRegularization.controls.addLogin.value, 'HH') == '00') && (test == '23' || '22' || '21' || '20')) {
  //           this.finalSubmit = true;

  //         } else {
  //           let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //             position: { top: `70px` },
  //             disableClose: true,
  //             data: 'The shift timings you applied for are already in the records. Please select a different time and reapply.',
  //           });
  //         }

  //       }
  //     } else {
  //       this.finalSubmit = true;
  //     }
  //     if (this.finalSubmit) {
  //       this.selectedEmps = [];
  //       if (this.btnDisable) {
  //         return;
  //       }

  //       let setdata = {
  //         emp_id: rdata.empid,
  //         shiftid: rdata.shiftid,
  //         fromdate: rdata.attendancedate,
  //         todate: rdata.attendancedate,
  //         logintime: this.inTime,
  //         logouttime: this.outTime,
  //         worktype: rdata.worktypeid,
  //         raisedby: this.userSession.id,
  //         approvercomments: '',
  //         actionby: this.userSession.id,
  //         status: 'Submitted',
  //         halfday: rdata.halfday == null ? 0 : rdata.halfday,
  //         locationid: rdata.locationid,
  //       };
  //       this.selectedEmps.push(setdata);

  //       let sub = {
  //         input_data: this.selectedEmps,
  //       }
  //       this.btnDisable = true;

  //       this.attendanceService.setAttendanceRegularizationNew(sub).subscribe((result: any) => {
  //         if (result.status) {
  //           this.router.navigateByUrl('/', { skipLocationChange: true })
  //             .then(() => this.router.navigate(['/Attendance/employeeAttendanceRegularization',]));
  //           let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //             position: { top: `70px` },
  //             disableClose: true,
  //             data: 'Attendance Regularization updated successfully',
  //           });
  //         }
  //         this.btnDisable = false;

  //       });
  //     }}
  //   }

  addRegularization(obj: any) {  

    if (obj.shift_start_time > obj.shift_end_time) {
      this.nightShift = true;
    }
    if (this.addNewRegularization.valid) {
      let rdata = obj;
      this.inTime = this.pipe.transform(this.addNewRegularization.controls.addLogin.value, 'HH:mm:ss');
      this.outTime = this.pipe.transform(this.addNewRegularization.controls.addLogout.value, 'HH:mm:ss');
  
      if (!this.nightShift) {

        if (this.inTime > obj.shift_end_time) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Your applied shift timings are incorrect. Please verify and reapply.',
          });
        } else if ((this.inTime >= obj.logintime && this.inTime <= obj.logouttime) || (this.outTime >= obj.logintime && this.outTime <= obj.logouttime)) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'The shift timings you applied for are already in the records. Please select a different time and reapply.',
          });
        } else if ((obj.logintime > this.inTime && obj.logintime < this.outTime) || (obj.logouttime > this.inTime && obj.logouttime < this.outTime)) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'The applied shift timings overlaps with an existing record. Please adjust and reapply.',
          });
        } else {
          this.finalSubmit = true;
        }
      }
      else {
        if (obj.logintime < obj.logouttime) {
          if ((this.inTime > obj.logouttime && ((this.outTime > obj.logouttime && this.inTime<this.outTime) || this.outTime < obj.logintime)) || (
            this.inTime < obj.logintime && this.outTime < obj.logintime && this.inTime < this.outTime
          )) {  
              this.finalSubmit = true;  
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'The shift timings you applied for are already in the records. Please select a different time and reapply.',
            });
          }
        } else {
          if (this.inTime > obj.logouttime && this.inTime < obj.logintime && this.outTime<obj.logintime && this.inTime<this.outTime) {
            this.finalSubmit = true;
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'The shift timings you applied for are already in the records. Please select a different time and reapply.',
            });
       
          }
        }
      }
   
      if (this.finalSubmit) {
        this.selectedEmps = [];
        if (this.btnDisable) {
          return;
        }

        let setdata = {
          emp_id: rdata.empid,
          shiftid: rdata.shiftid,
          fromdate: rdata.attendancedate,
          todate: rdata.attendancedate,
          logintime: this.inTime,
          logouttime: this.outTime,
          worktype: rdata.worktypeid,
          raisedby: this.userSession.id,
          approvercomments: '',
          actionby: this.userSession.id,
          status: 'Submitted',
          halfday: rdata.halfday == null ? 0 : rdata.halfday,
          locationid: rdata.locationid,
        };
        this.selectedEmps.push(setdata);

        let sub = {
          input_data: this.selectedEmps,
        }

        this.btnDisable = true;

        this.attendanceService.setAttendanceRegularizationNew(sub).subscribe((result: any) => {
          if (result.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/Attendance/employeeAttendanceRegularization',]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Attendance Regularization updated successfully',
            });
        }
          this.btnDisable = false;

        });
       
      }
    }

  }
}



