import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { CompanySettingService } from '../companysetting.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PdfHeaderService {
companyDBName: any = environment.dbName;
companyDetails:any;
cmpName:any;
cmpAddress:any;
  constructor(private css: CompanySettingService) {
    this.getCompanyInformation();
  }
  getCompanyInformation(){
    this.css.getCompanyInformation('companyinformation',null,1,10,this.companyDBName).subscribe((data:any)=>{
      if(data.status && data.data.length!=0) {
       this.companyDetails=data.data[0];
       this.cmpName = this.companyDetails.companyname;
       this.cmpAddress = this.companyDetails.address1 + ',' + this.companyDetails.city;
      }else {
        }

    })

  }
  generatePDFHeader(doc: jsPDF, reportName: string, location: string, managerName: string,rtDate:any, islocation:boolean): void {
    const pageWidth = doc.internal.pageSize.getWidth();
    const fontSize = doc.getFontSize();

    // Company Name (centered)
    const companyNameWidth = doc.getStringUnitWidth(this.cmpName) * fontSize / doc.internal.scaleFactor;
    doc.text(this.cmpName, (pageWidth - companyNameWidth) / 2, 15); 


    // // Company Address (centered)
    // const companyAddressWidth = doc.getStringUnitWidth(this.cmpAddress) * fontSize / doc.internal.scaleFactor;
    // doc.text(this.cmpAddress, (pageWidth - companyAddressWidth) / 2, 25);

    // Report Name (left-aligned)
    doc.setFontSize(13);
    doc.text(reportName, 14, 28);

    // Date (right-aligned)
    const formattedDate =  `Date: ${rtDate}` ; 
    const dateWidth = doc.getStringUnitWidth(formattedDate) * doc.getFontSize() / doc.internal.scaleFactor;
    doc.text(formattedDate, pageWidth - dateWidth - 13, 28);

if(islocation){
    // Location (left-aligned, same row as Manager Name)
    doc.setFontSize(12);
    doc.text(`Location: ${location}`, 14, 34);

    // Manager Name (right-aligned, same row as Location)
    const managerNameText = `Manager: ${managerName}`;
    const managerNameWidth = doc.getStringUnitWidth(managerNameText) * doc.getFontSize() / doc.internal.scaleFactor;
    doc.text(managerNameText, pageWidth - managerNameWidth - 14, 34);
}
    // Line after header
    doc.setLineWidth(0.5);
    doc.line(14, 36, pageWidth - 14, 36);
 }

}
