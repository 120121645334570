import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { Moment } from 'moment';
import { UserData } from 'src/app/modules/attendance/models/EmployeeData';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { PayrollService } from '../../payroll.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-tds-distribution',
  templateUrl: './tds-distribution.component.html',
  styleUrls: ['./tds-distribution.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TDSDistributionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  date:any;
  searchForm:any = UntypedFormGroup;
  max:any;
  minDate:any= new Date('2022-01-01');
  worklocationDetails:any=[];
  filteredLocations:any=[];
  companyName: any;
  searchControlforLoc = new FormControl('');
  selectedEmps:any;
  tdsAmounts: FormControl[] = [];
  UserSession:any;
  dataSource1: MatTableDataSource<UserData> = <any>[];
  dataSource:any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'sno',
    'employeeid',
    'empname',
    'amount',
  ];
  displayedColumns1:string[]=[
    'sno',
    'employeeid',
    'empname',
    'amount'
  ]
  branchSelected:boolean = true;
 
  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanySettingService,
    private payroll:PayrollService,
  ) { 
    this.companyName = sessionStorage.getItem("companyName");
    this.UserSession = JSON.parse(sessionStorage.getItem('user') ?? '')
    this.date = new UntypedFormControl(moment().subtract(1, "month"))
    let prevDate = new Date();
    prevDate.setDate(0)
    this.max = prevDate;
    this.searchForm = this.formBuilder.group({
      companylocation : ['',Validators.required],
      fromDate:[prevDate],
      employees: this.formBuilder.array([])
    }); 
  }

  initEmployeeFormArray() {
    const employeeArray = this.searchForm.get('employees') as FormArray;
    this.dataSource.forEach((employee :any)=> {
      employeeArray.push(this.formBuilder.group({
        employeeid: employee.employeeid,
        empname: employee.empname,
        tdsAmount:  ['', [Validators.required, Validators.min(0)]]
      }));
    });
  }

  getTdsAmountControl(index: number): FormControl {
    return (this.searchForm.get('employees') as FormArray).at(index).get('tdsAmount') as FormControl;
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue); 
    datepicker.close();
  }

  ngOnInit(): void {  
    this.getWorkLocation();
    this.searchControlforLoc.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    }); 
  
  }

  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails;
    })
  }

  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControlforLoc.setValue('');
    }
  }

  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.forEach((row:any) => this.selection.select(row));
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.length < 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.length);
    return customPageSizeArray;
  }

  submit(){
    if(this.isFormValid()){
      const selectedEmployees = this.selection.selected;
      const employeesFormArray = this.searchForm.get('employees') as FormArray;
      const submittedData = selectedEmployees.map(selected => {
        const formGroup = employeesFormArray.controls.find(control => 
          control.get('employeeid')?.value === selected.employeeid
        );
        
        return {
          empid: selected.employeeid,
          empname: selected.empname,
          amount: formGroup?.get('tdsAmount')?.value || 0
        };
      });
       let obj = {
        year_value: this.date.value._d.getFullYear(),
        month_value:this.date.value._d.getMonth() + 1 ,
        employeedata: JSON.stringify(submittedData),
        user_id_value:this.UserSession.id,
       }
       console.log('employeedata',obj)
      //  this.payroll.setEmpTdsMonthlydeduction(obj).subscribe((res:any)=>{
      //   console.log('data submitted!!!',res)
      //  })
    
    }
  }

  displayEmployeeEligibleForTDS(){
    console.log('employeeist')
    this.branchSelected=false;
    this.dataSource = [
      {
       sno: 1 , employeeid:'EM001',empname:'Pranitha'
     },
     {
       sno: 2 , employeeid:'EM002',empname:'Mahesh'
     }]
    this.dataSource.forEach(() => {
      this.tdsAmounts.push(new FormControl(''));  // Initialize with empty values or set default amounts
    });
    this.initEmployeeFormArray();
  }

  isFormValid(): boolean {
    if (!this.searchForm.get('companylocation')?.valid || !this.searchForm.get('fromDate')?.valid) {
      return false;
    }
    const selectedEmployees = this.selection.selected;
    const employeesFormArray = this.searchForm.get('employees') as FormArray;
    for (let selected of selectedEmployees) {
      const formGroup = employeesFormArray.controls.find(control => 
        control.get('employeeid')?.value === selected.employeeid
      );
      if (formGroup && !formGroup.get('tdsAmount')?.valid) {
        return false;
      }
    }
    return true;
  }

  cancel(){
    this.dataSource =[]
    this.searchForm.controls.companylocation.setValue('')
  }

}
