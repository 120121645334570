import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { startTransition } from 'preact/compat';
import { environment } from 'src/environments/environment';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { PayrollService } from '../../payroll.service';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';
export interface UserData {
  deptname: string;
  status: string;
  depthead: string;
  headcount: number;
  id: number;
  total: number;
}

@Component({
  selector: 'app-paygroup-otherdeduction-mapping',
  templateUrl: './paygroup-otherdeduction-mapping.component.html',
  styleUrls: ['./paygroup-otherdeduction-mapping.component.scss']
})
export class PaygroupOtherdeductionMappingComponent implements OnInit {
  deductionMappingForm!: UntypedFormGroup;
  deduction: any;
  userSession: any;
  pipe = new DatePipe('en-US');
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  enable: any = null;
  valid: boolean = false;
  msgEM1: any;
  msgEM84: any;
  msgEM85: any;
  msgEM88: any;
  msgEM89: any;
  msgEM90: any
  msgEM91: any;
  msgEM92: any;
  msgEM151: any;
  msgEM152: any;
  todayDate: any = new Date();
  displayedColumns: string[] = ['sno', 'paygroupname', 'deductionname', 'deductionamount', 'effectivdate', 'Action'];

  // displayedColumns: string[] = ['sno','deductionname','deductionamount', 'status', 'Action'];
  deductionData: any = [];
  arrayValue: any;
  dataSource: MatTableDataSource<UserData>;
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  companyDBName: any = environment.dbName;
  messagesDataList: any = [];
  activeOtherDeductions: any;
  activePayGroupsData: any;
  deletedata: any;
  titleName: any;
  reason: any;
  btnDisable: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router,
    private dialog: MatDialog, private LM: CompanySettingService, private ts: LoginService,
    private emsService: EmsService, private PR: PayrollService) {
    // this.getOtherDeductions();
    this.getActiveOtherDeductions();
    this.getActivePayGroupsData();
    this.getOtherDeductionPayGroupMappingDetails();
    this.dataSource = new MatTableDataSource(this.deductionData);
  }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getMessages();
    this.deductionMappingForm = this.formBuilder.group(
      {
        deduction: [],
        paygroup: []

      },
    );
  }

  setMapping() {
    if (this.btnDisable) {
      return;
    }
    var data = {
      deduction: this.deductionMappingForm.controls.deduction.value,
      payGroup: this.deductionMappingForm.controls.paygroup.value,
      created_by: this.userSession.id,
      status: 1,
      created_on: this.pipe.transform(new Date(), 'yyyy-MM-dd') + ' ' + this.pipe.transform(new Date(), 'HH:mm:ss'),
    }
    this.btnDisable = true;
    this.PR.setOtherDeductionPayGroupMapping(data).subscribe((res) => {
      this.valid = false;
      if (res.status && res.data.statuscode == 0) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/Payroll/paygroup-mapping"]));
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Deduction mapping added successfully."
        });

      } else if (res.status && res.data.statuscode == 2) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Deduction mapping updated successfully."
        });
        this.btnDisable = false;
      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'The selected deduction is unable to add for this pay group,Please try again .'
        });
        this.btnDisable = false;
      }
    })
  }

  Add() {
    this.isAdd = true;
    this.isdata = false;
    this.deductionMappingForm.controls.paygroup.setValue('');
    this.deductionMappingForm.controls.deduction.setValue('')
  }
  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/paygroup-mapping"]));
  }

  removeDeduction(w: any, i: any) {

    this.deletedata = i;
    this.titleName = "Are you sure you want to remove this deduction from this pay group?"
    this.openDialogdelete();
  }
  /** */
  openDialogdelete(): void {
    let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: { message: "Are you sure you want to remove this deduction from this pay group?", YES: 'YES', NO: 'No' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'YES') {
        this.deletedata.actionreason = result.reason;
        var data = {
          deduction: this.deletedata.od_id,
          payGroup: this.deletedata.pay_group_id,
          created_by: this.userSession.id,
          status: 0,
          created_on: this.pipe.transform(new Date(), 'yyyy-MM-dd') + ' ' + this.pipe.transform(new Date(), 'HH:mm:ss'),
        }
        this.PR.setOtherDeductionPayGroupMapping(data).subscribe((res) => {
          this.valid = false;
          if (res.status && res.data.statuscode == 1) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Payroll/paygroup-mapping"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Deduction mapping removed successfully."
            });


          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'This deduction is unable to remove from this pay group,Please try again'
            });
          }
        })
      }
    });


  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.emsService.getMessagesListApi(data).subscribe((result: any) => {
      if (result.status) {
        this.messagesDataList = result.data;
        this.messagesDataList.forEach((e: any) => {

          if (e.code == "EM1") {
            this.msgEM1 = e.message
          }
          else if (e.code == "EM84") {
            this.msgEM84 = e.message;
          }
          else if (e.code == "EM85") {
            this.msgEM85 = e.message
          }
          else if (e.code == "EM88") {
            this.msgEM88 = e.message
          }
          else if (e.code == "EM89") {
            this.msgEM89 = e.message
          }
          else if (e.code == "EM90") {
            this.msgEM90 = e.message
          }
          else if (e.code == "EM91") {
            this.msgEM91 = e.message
          }

          else if (e.code == "EM92") {
            this.msgEM92 = e.message
          }

          else if (e.code == "EM151") {
            this.msgEM151 = e.message
          }

          else if (e.code == "EM152") {
            this.msgEM152 = e.message
          }

        })
      } else {
        this.messagesDataList = [];
      }
    })
  }

  /**Search functionality */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  /** */
  getActiveOtherDeductions() {
    this.PR.getActiveOtherDeductions().subscribe((result: any) => {
      if (result.status) {
        this.activeOtherDeductions = result.data;
      }
    });

  }
  /** */
  getActivePayGroupsData() {
    this.PR.getActivePayGroupsData().subscribe((result: any) => {
      if (result.status) {
        this.activePayGroupsData = result.data;
      }
    });

  }
  /** */
  getOtherDeductionPayGroupMappingDetails() {
    this.PR.getOtherDeductionPayGroupMappingDetails().subscribe((result: any) => {
      if (result.status) {
        this.deductionData = result.data;
        this.dataSource = new MatTableDataSource(this.deductionData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    });

  }

}
