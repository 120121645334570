<div fxLayout="column" class="first-col">
    <mat-card>

        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="start">
            <mat-card-title class="title"> Payroll Policies </mat-card-title>

        </mat-card-header>


        <div fxLayout="column">
            <form [formGroup]="payrollPoliciesForm" autocomplete="off">
                <h2 style="padding-top: 5px;padding-left: 10px;">Common rules for all Payroll types</h2>
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef> <span style="text-wrap: nowrap;">S. No.</span> </th>
                            <td mat-cell *matCellDef="let data; let i = index;"> {{i+1}} </td>

                        </ng-container>

                        <ng-container matColumnDef="configurationrules">
                            <th mat-header-cell *matHeaderCellDef> Configuration Rules </th>
                            <td mat-cell *matCellDef="let data" style="width: 70%;">{{data.description}}</td>
                        </ng-container>

                        <ng-container matColumnDef="data">
                            <th mat-header-cell *matHeaderCellDef>Value</th>
                            <td mat-cell *matCellDef="let data ;let i = index;" style="padding-top: 1%;">

                                <div *ngIf="i==0">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input formControlName="noOfInstallments" onlyNumber matInput required>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="i==1">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input formControlName="monthsToResumeLastRecovery" onlyNumber matInput
                                            required>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="i==2">

                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select formControlName="otAmountIncludeSalary"
                                            [disabled]="!isEditDefaultRules" style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of defaultDropdownList">
                                                    <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="i==3">

                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select formControlName="isCompanyCoveredUnderGratuityAct"
                                            [disabled]="!isEditDefaultRules" style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of defaultDropdownList">
                                                    <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="i==4">

                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select formControlName="isGratuityMonthlyPaid"
                                            [disabled]="!isEditDefaultRules" style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of defaultDropdownList">
                                                    <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="i==5">

                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select formControlName="isLOPdeductionBasedOnCalendar"
                                            [disabled]="!isEditDefaultRules" style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of defaultDropdownList">
                                                    <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="i==6">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select formControlName="isAbsentConsiderLop"
                                            [disabled]="!isEditDefaultRules" style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of defaultDropdownList">
                                                    <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="i==7">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select formControlName="isDynamicPayrollApplicable"
                                            [disabled]="!isEditDefaultRules" style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of defaultDropdownList">
                                                    <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="i==8">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input formControlName="leavesThresholdForDynamicPayroll" onlyNumber matInput>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="i==9">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select formControlName="esiAndEpfContributionIndependentOfLOP"
                                            [disabled]="!isEditDefaultRules" style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of defaultDropdownList">
                                                    <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </td>
                        </ng-container>


                        <!-- <ng-container matColumnDef="addditionalinformation">
                                    <th mat-header-cell *matHeaderCellDef > Additional Information </th>
                                    <td mat-cell *matCellDef="let data" style="padding-left: 1%;">{{data.data}}</td>
    
                                </ng-container> -->


                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>

                    </table>

                </div><br>
                <div fxLayoutAlign="center">
                    <button *ngIf="isEditDefaultRules" class=" btn btn-primary mr-2" [disabled]="btnDisable"
                        [ngClass]="{'disable':btnDisable}" (click)="saveDefaultrules()">Update
                    </button>
                    <button *ngIf="isEditDefaultRules" class="btn btn-danger mr-2" (click)="cancelDefaultRules()">Cancel
                    </button>
                    <button *ngIf="!isEditDefaultRules" style="float: right" class=" btn btn-primary"
                        (click)="editDefaultRules()">Edit
                    </button>
                </div>

            </form>

        </div>


    </mat-card>
</div>