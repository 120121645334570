<div fxLayout="column" fxFill class="custom-investment-of first-col">
    <mat-card fxFill>
        <mat-card-header class="headerbox">
            <mat-card-title class="title"> Advance Salary Report</mat-card-title>
        </mat-card-header>

        <form [formGroup]="advancesalaryreportForm" autocomplete="off" style="margin-top: 20px;">

            <div fxLayout="row xs-column">
                <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Locations</mat-label>
                    <mat-select formControlName="location" required>
                        <ng-container>
                            <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                                matInput>
                            <!-- <mat-option value="0">All Locations</mat-option> -->
                            <mat-option [value]="a.id"
                                *ngFor="let a of filteredLocations">{{a.location}}-{{a.cityname}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="advancesalaryreportForm.controls.location.error?.required">This
                        field is required.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Month and Year</mat-label>
                    <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                        [min]="minDate" [max]="maxDate" (click)="fromDate.open()">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                        panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <mat-select formControlName="empname" tabIndex="3">
                        <input class="search-align" type="text" placeholder="Search" [formControl]="searchControlemp"
                            matInput>
                        <mat-option value="0"> All Employees </mat-option>
                        <mat-option *ngFor="let list of filteredEmployees" [value]="list.id">{{list.ename}}</mat-option>
                    </mat-select>

                </mat-form-field>
            </div>

            <div fxLayoutAlign="center">
                <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
                    <span>Search</span>
                </button>
                <button class="btn btn-danger mr-2 mb-2" (click)="clear()" type="submit">
                    <span>Clear</span>
                </button>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row xs-column" fxLayoutAlign="space-between center">
                <div fxFlex="50" fxLayout="row">
                    <div *ngIf="List.length > 0">
                        <!-- <div> -->
                        <button mat-icon-button>
                            <a title="PDF" [tabIndex]="4" (keyup.enter)="generatePDF()">
                                <img (click)="generatePDF()" src="./assets/images/icons/pdf.png" width="20px"
                                    height="20px" /></a>
                        </button>
                        <span class="exlprint"> | </span>
                        <button mat-icon-button>
                            <a title="Excel" [tabIndex]="5" (keyup.enter)="exportAsXLSX()">
                                <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png" />
                            </a>
                        </button>
                    </div>
                </div>

                <div style="padding-top: 1%;padding-right: 1%;" fxFlex="50" fxLayoutAlign="end">
                    <mat-form-field fxFlex="50" fxFlex.xs="100" appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                    </mat-form-field>
                </div>
            </div>


<div  fxLayout="column" class="table-pad">
    <div class="example-container mat-elevation-z1">
      <table   mat-table [dataSource]="dataSource">

                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef><span style="text-wrap: nowrap;">S. No.</span></th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="empid">
                                <th mat-header-cell *matHeaderCellDef> Emp. ID</th>
                                <td mat-cell *matCellDef="let row">{{row.empcode}}</td>
                            </ng-container>

                            <ng-container matColumnDef="empname">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name</th>
                                <td mat-cell *matCellDef="let row;">{{row.empname}}</td>
                            </ng-container>

                            <ng-container matColumnDef="advanceamount">
                                <th mat-header-cell *matHeaderCellDef>Adv. Sal. Amt</th>
                                <td mat-cell *matCellDef="let row">{{row.advamount}}</td>
                            </ng-container>

                            <ng-container matColumnDef="tenure">
                                <th mat-header-cell *matHeaderCellDef>Tenure</th>
                                <td mat-cell *matCellDef="let row">{{row.tenure}}</td>
                            </ng-container>

                            <ng-container matColumnDef="installmentamount">
                                <th mat-header-cell *matHeaderCellDef>EMI</th>
                                <td mat-cell *matCellDef="let row">{{row.emiamount}}</td>
                            </ng-container>

                            <ng-container matColumnDef="recoveredamount">
                                <th mat-header-cell *matHeaderCellDef> Recovered Amt</th>
                                <td mat-cell *matCellDef="let row">{{row.recoveredamount}}</td>
                            </ng-container>

                            <ng-container matColumnDef="installmentspaid">
                                <th mat-header-cell *matHeaderCellDef>Installments Paid</th>
                                <td mat-cell *matCellDef="let row">{{row.recoveredmonths}}</td>
                            </ng-container>

                            <ng-container matColumnDef="remainingbalanceamount">
                                <th mat-header-cell *matHeaderCellDef>Remaining Amt</th>
                                <td mat-cell *matCellDef="let row">{{row.amounttoberecovered}}</td>
                            </ng-container>

                            <tr class="headerbox title" mat-header-row
                                *matHeaderRowDef="displayedColumns; sticky:true "></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                            </tr>

      </table>
      <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
    </div>
  </div>
        </form>
    </mat-card>
</div>