

<div fxLayout="column" class="first-col">
    <mat-card class="mat-elevation-z0 mt-card" >
      <mat-card-header class="mt-card-header">
        <mat-card-title class="mt-card-title">Variable Pay Distribution</mat-card-title>
      </mat-card-header>
      <form fxLayout="column" [formGroup]="searchForm" style="margin-top: 10px">
        <div class="responsive-form" >

            <mat-form-field class="mx-2 my-2" appearance="outline">
                <mat-label>Month and Year</mat-label>
                <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                    [min]="minDate" [max]="max" (click)="fromDate.open()" required>
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                    panelClass="example-month-picker">
                </mat-datepicker>
            </mat-form-field>

            <mat-form-field  class="mx-2 my-2" appearance="outline">
              <mat-label>Locations</mat-label>
              <mat-select formControlName="location" required>
                  <ng-container>
                      <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                          matInput>
                      <mat-option value='0'>All Locations</mat-option>
                      <mat-option [value]="a.id"
                          *ngFor="let a of filteredLocations">{{a.location}}-{{a.cityname}}</mat-option>
                  </ng-container>
              </mat-select>
              <mat-error class="con-error" *ngIf="searchForm.controls.location.error?.required">This
                  field is required.</mat-error>
          </mat-form-field>

            <mat-form-field class="mx-2 my-2" appearance="outline">
                <mat-label>Manager Name</mat-label>
                <mat-select formControlName="employees" required>
                    <input class="search-align" type="text" placeholder="Search" [formControl]="empSearchControl"
                        matInput>
                        <mat-option value= '0' > All Managers </mat-option>
                    <mat-option *ngFor="let a of filteredEmployees" [value]="a.manager_emp_id">{{a.manager_name}}</mat-option>
                </mat-select>
                <mat-error class="con-error" *ngIf="searchForm.controls.employees.errors?.required">This field is
                    required.</mat-error>
            </mat-form-field>

          </div>
  
        <div fxLayout="row">
          <div fxFlex="100" fxLayout="row" [fxLayoutAlign]="arrayList.length > 0 ? 'center' : 'center'">
            <button class="btn btn-primary mr-2 mb-2" (click)="getSearchForm()" tabIndex="2">
              Search
            </button>
            <span class="line" style="color: #1898d5; font-size: 16px"></span>
            <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
              <span>Clear</span>
            </button>
          </div>
  
          <!-- <div *ngIf="arrayList.length > 0" fxFlex="50" fxLayoutAlign="end">
            <div style="padding-right: 2%">
              <span class="exlprint"> | </span>
              <button mat-icon-button>
                  <a title="PDF" [tabIndex]="4" (keyup.enter)="downloadPDF()"> <img
                          (click)="downloadPDF()" src="./assets/images/icons/pdf.png" width="20px"
                          height="20px"></a>
              </button>
              <span class="exlprint"> | </span>
              <button mat-icon-button>
                  <a title="Excel" [tabIndex]="5" (keyup.enter)="exportAsXLSX()"> <img
                          (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                  </a>
              </button>
            </div>
          </div> -->
        </div>
  
        <div fxLayout="column" class="mtb-pd1">
          <!-- calendar days table --> 
            <div *ngIf="isCalenderDaysPayroll" id="table" #table='' class="mtb-container mat-elevation-z1" [ngClass]="{'scroll-container': arrayList.length > 0}">

                <table mat-table [dataSource]="dataSource" matSort>
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> S. No. </th>
                        <td mat-cell *matCellDef="let row;let i = index;"> {{ (i+1) +
                            (paginator.pageIndex * paginator.pageSize) }}</td>
                    </ng-container>

                    <ng-container matColumnDef="empid">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> Emp. ID
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.empCode}}</td>
                    </ng-container>

                    <ng-container matColumnDef="empname">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> Emp. Name
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.empName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> Amount
                            </th>
                        <td mat-cell *matCellDef="let row"> {{row.empName}}</td>
                    </ng-container>

                 
                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns ;sticky:true "></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                    </tr>
                </table>
              <mat-card *ngIf="isLoading"
              style="display: flex; justify-content: center; align-items: center; opacity:0.5">
              <mat-progress-spinner color="primary" [diameter]="70" mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
              <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
          </div>
      </form>
    </mat-card>
  </div>