<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">
                <span *ngIf="isAdd" (click)="cancel()"> <button mat-icon-button matTooltip="Back">
                        <mat-icon>arrow_back</mat-icon></button></span>
                <span> Checklist </span>
            </mat-card-title>

            <div style="margin-right: 2%;" *ngIf="isdata">
                <button mat-icon-button style="padding-right: 40px;" (click)="AddData()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px; overflow: visible">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <!-- <mat-card-header >
            <mat-card-title>Checklist</mat-card-title>
            <span *ngIf="isAdd"  (click)="cancel()" style="padding-right: 10px;">
                <button mat-icon-button matTooltip="Back"> <mat-icon>arrow_back</mat-icon></button>
               </span>
         </mat-card-header> -->

        <form [formGroup]="checklistForm" autocomplete="off">
            <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                    <span style="padding-top: 20px;padding-left: 20px;">
                        <mat-radio-group formControlName="onboardCategory" (change)="onChangeRadio()">
                            <mat-radio-button class="mx2 my-2 mr-2" value="Onboarding"
                                [checked]='true'>Onboarding</mat-radio-button>
                            <mat-radio-button class="mx2 my-2 mr-2" value="Offboarding">Offboarding</mat-radio-button>
                        </mat-radio-group>
                    </span>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
                    class="tableP">
                    <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="43" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Department</mat-label>
                        <mat-select required formControlName="department">
                            <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                                class="search-align">
                            <ng-container>
                                <div *ngFor="let r of filteredDep">
                                    <mat-option [value]="r.id"
                                        (onSelectionChange)="departmentChange()">{{r.deptname}}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="checklistForm.controls.department.errors?.required">
                            {{EM2}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="43" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Checklist Name</mat-label>
                        <input type="text" formControlName="description" matInput noLeadingWhitespace
                            placeholder="Checklist Name" maxlength="100" required>
                        <mat-error class="con-error" *ngIf="checklistForm.controls.description.errors?.required">
                            {{EM1}}
                        </mat-error>
                    </mat-form-field>
                    <button mat-icon-button type="submit">
                        <mat-icon class="addBtn" title="Add" fxLayoutAlign="end" (click)=" add()">add_box
                        </mat-icon>
                    </button>

                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                    <mat-list *ngIf="addChecklistData.length > 0">
                        <mat-list-item class="list" *ngFor="let list of addChecklistData; let i = index">
                            <mat-icon style="color:#28acaf;">label_important</mat-icon>
                            <span style="padding-left: 5%;
                            padding-right: 20%;
                            font-size: initial;">{{list.description}}</span>
                            <a (click)="removeText(i)"><mat-icon style="color: rgb(247, 115, 115);
                                font-size: 23px;" title="Delete">delete_forever</mat-icon></a>
                        </mat-list-item>
                    </mat-list>
                </div>

                <div fxLayoutAlign="center" *ngIf="addChecklistData.length > 0">
                    <button [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}" class="btn btn-primary mr-2 mb-2"
                        type="submit" (click)="submit()">
                        <span>Submit</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </div>

            <div fxLayout="column" *ngIf="isdata">

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" class="dev-Pad"
                    fxLayoutAlign="space-between center">
                    <span fxFlex="50">
                        <mat-radio-group formControlName="onboardCategory">
                            <mat-radio-button class="mx2 my-2 mr-2" value="Onboarding"
                                [checked]='true'>Onboarding</mat-radio-button>
                            <mat-radio-button class="mx2 my-2 mr-2" value="Offboarding">Offboarding</mat-radio-button>
                        </mat-radio-group>
                    </span>
                    <span style="padding-top: 5px;height: 70px;" fxFlex="50" fxLayoutAlign="end">
                        <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                            appearance="outline">
                            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                            <mat-label>Search</mat-label>
                            <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                        </mat-form-field>
                    </span>
                </div>

                <div fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef><span style="text-wrap: nowrap;">S. No.</span></th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="department">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{row.department_name}} </span>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="checklistname">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Checklist Name </th>


                                <td mat-cell *matCellDef="let row">
                                    <span *ngIf="enable != row.id">{{row.description}} </span>
                                    <span *ngIf="enable === row.id">
                                        <mat-form-field appearance="outline">
                                            <input formControlName="description" matInput maxlength="100"
                                                placeholder="">
                                            <mat-error class="con-error"
                                                *ngIf="checklistForm.controls.description.errors?.required ">
                                                {{EM1}}
                                            </mat-error>
                                        </mat-form-field>
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 60px"> Status
                                </th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <mat-radio-group>
                                        <mat-radio-button class="mr-2" color="primary"
                                            *ngFor="let value of statusValues;index as i;"
                                            (change)="statusChange(value.name,row)" [checked]="value.name == row.status"
                                            [value]="value.name">
                                            {{value.name}}</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <button mat-icon-button *ngIf="enable != row.id" (keyup.enter)="edit($event,row)">
                                        <mat-icon (click)="edit($event,row)" title="Edit">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="updateData(checklistForm.controls.description.value,row)">
                                        <mat-icon (click)="updateData(checklistForm.controls.description.value,row)"
                                            title="Save">save</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="canceledit($event,row.id)">
                                        <mat-icon (click)="canceledit($event,row.id)" title="Cancel">cancel</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="5" style="text-align: center;">No data found</td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>