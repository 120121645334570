


<div fxLayout="column" class="first-col">
    <mat-card class="mat-elevation-z0 mt-card" >
      <mat-card-header class="mt-card-header">
        <mat-card-title class="mt-card-title">Variable Pay Schedule</mat-card-title>
      </mat-card-header>
      <form fxLayout="column" [formGroup]="scheduleForm" style="margin-top: 10px">

  
        <div fxLayout="column" class="md-pd1">

            <div class="mtb-container mat-elevation-z1">

                <table mat-table [dataSource]="dataSource" matSort>
                   
                   <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> S. No. </th>
                        <td mat-cell *matCellDef="let row;let i = index;"> {{ (i+1) }}</td>
                    </ng-container>

                    <ng-container matColumnDef="year">
                        <th mat-header-cell *matHeaderCellDef >Year </th>
                        <td mat-cell *matCellDef="let row"> {{row.year}} </td>
                    </ng-container>

                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> Duration Type </th>
                        <td mat-cell *matCellDef="let row"> {{row.duration}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef > Status   </th>
                        <td mat-cell *matCellDef="let row"> {{row.status}}</td>
                    </ng-container>

                    <ng-container matColumnDef="isallow">
                        <th mat-header-cell *matHeaderCellDef > Allow/Denied  </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-form-field appearance="outline" style="width:150px" >
                                <mat-select style="width:120px" [(value)]="row.value" (selectionChange)="onStatusChange($event, row)">
                                  <mat-option *ngFor="let res of permisionList" [value]="res.value">
                                    {{ res.value }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef > Value      </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-form-field appearance="outline" style="width:200px" >
                                <mat-select style="width:180px" [(value)]="row.value" (selectionChange)="onTypeChange($event, row)">
                                  <mat-option *ngFor="let res of variablePayTypeList" [value]="res.value">
                                    {{ res.value }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef > Action      </th>
                        <td mat-cell *matCellDef="let row">
                            <button  mat-icon-button (keyup.enter)="editRequest(row)">
                            <mat-icon class="mr-2" title="Save" (click)="editRequest(row)">
                                edit</mat-icon>
                        </button>
                        <button  mat-icon-button  (keyup.enter)="reset()">
                            <mat-icon class="mr-2" title="Cancel"
                                (click)="reset()">cancel</mat-icon>
                        </button>
                  
                        </td>
                    </ng-container>

                 
                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns ;sticky:true "></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" style="text-align: center;">No data found</td>
                    </tr>
                </table>
            </div>
          </div>
      </form>
    </mat-card>
  </div>