



<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
             <mat-card-title class="title"> Device Approvals </mat-card-title>
            </mat-card-header>
        <div fxLayout="column" *ngIf="isEditView">
          <form [formGroup]="devicerequestform" autocomplete="off"  style="padding-left: 1%;padding-top: 1%;">
            <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center" >
    
               <mat-form-field fxFlex="33" fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name </mat-label>
                        <input formControlName="empname" [attr.disabled]="true" [readonly]="true" required matInput placeholder="Employee" />
                    <mat-error *ngIf="devicerequestform.controls['empname'].invalid">
                        {{requiredOption}}
                    </mat-error>
                 </mat-form-field>
                 <mat-form-field fxFlex="33" fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline">
                    <mat-label>Device Name </mat-label>
                        <input formControlName="device_changed" [attr.disabled]="true" [readonly]="true" required matInput placeholder="Employee" />
                    <mat-error *ngIf="devicerequestform.controls['device_changed'].invalid">
                        {{requiredOption}}
                    </mat-error>
                 </mat-form-field>

                <div fxLayout="column" fxFlex="33">
                 <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline" style="height: 60px;">
                    <mat-label>Request Date </mat-label>
                    <input matInput readonly required [matDatepicker]="requestDate"
                     [matDatepickerFilter]="myDateFilter"  placeholder="DD-MM-YYYY"  (click)="requestDate.open()"
                        formControlName="requestDate" [tabIndex]="3">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="requestDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                    <mat-error class="con-error" *ngIf="devicerequestform.controls.requestDate.errors?.required">
                       {{requiredOption}}
                    </mat-error>
                    </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-lg="row wrap" fxFlexFill fxLayout.xs="column" fxLayoutAlign.xs="center">
                   
                    <mat-form-field fxFlex fxFlex.lt-lg="47" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Reason</mat-label>
                        <textarea formControlName="reason" maxlength="250" matInput noLeadingWhitespace
                            placeholder="Reason" [tabIndex]="6" rows="1">
                        </textarea>
                        <mat-error class="con-error" *ngIf="devicerequestform.controls.reason.errors?.required">
                          Please enter reason
                        </mat-error>
                    </mat-form-field>
                                       
               </div>

               <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-lg="start">
                
                <span  fxLayoutAlign.lt-lg="end">
                  <mat-radio-group>
                    <mat-radio-button tabIndex="3" (keyup.enter)="approval_status = 'Approve'" class="mx2 my-2 mr-2" value="self" [checked]='true' (click)="approval_status = 'Approve'" >
                      <span class="radioBtn">Approve</span>
                    </mat-radio-button>
                    <!-- <mat-radio-button tabIndex="4"  (keyup.enter)="approval_status = 'Primary'" class="mx2 my-2 mr-2" value="team" (click)="approval_status = 'Primary'">
                      <span class="radioBtn">Make this Device as Primary</span>
                    </mat-radio-button> -->
                    <mat-radio-button tabIndex="4" (keyup.enter)="approval_status = 'Reject'" class="mx2 my-2 mr-2" value="team" (click)="approval_status = 'Reject'" >
                        <span class="radioBtn">Reject</span>
                      </mat-radio-button>
                  </mat-radio-group>
                </span>
              </div>
            <div fxLayoutAlign="center">
                <button [tabIndex]="7" *ngIf="isEditView"class="btn btn-primary mr-2 mb-2" (click)="saveRequest()" type="submit">
                    <span>Submit</span>
                </button>
                <button [tabIndex]="8" class="btn btn-danger mr-2 mb-2" (click)="resetform()" type="submit">
                    <span>Cancel</span>
                </button>
            </div>
          </form>
        </div>
        
        <div *ngIf="isRequestView"> 
          <form fxLayout="column" [formGroup]="searchForm">
            <div fxLayout="row" fxLayout.lt-lg="column">
                <div fxLayout="row" fxFlex="100" fxLayout.xs="column" *ngIf="is_super_admin">
                  <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                      <mat-label>City-Branch </mat-label>
                      <mat-select placeholder="" name="companylocation" formControlName="companylocation" required
                          multiple #locationSelect>
                          <button mat-raised-button class="mat-primary fill text-sm"
                              (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.companylocation)"
                              *ngIf="!locationIshide">Select All
                          </button>
                          <button mat-raised-button class="mat-accent fill text-sm"
                              (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                              Deselect All
                          </button>
                          <mat-option *ngFor="let res of worklocationDetails" [value]="res"
                              (click)="onchangeLocation(res)">
                              {{res.cityname}}-{{res.location}}
                          </mat-option>
                      </mat-select>
                      <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
                          Please select option
                      </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                      <mat-label>Manager</mat-label>
                      <mat-select placeholder="" name="managers" formControlName="managers" required multiple
                          #managerSelect>
                          <button mat-raised-button class="mat-primary fill text-sm"
                              (click)="managerSelectAll(managerSelect, managersDetails, searchForm.managers)"
                              *ngIf="!mgrIshide">Select All
                          </button>
                          <button mat-raised-button class="mat-accent fill text-sm"
                              (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                              Deselect All
                          </button>
                          <mat-option *ngFor="let res of managersDetails" [value]="res" (click)="onchangeManager(res)">
                              {{res.manager_name}}
                          </mat-option>
                      </mat-select>
                      <mat-error class="con-error" *ngIf="searchForm.controls.managers.errors?.required">
                          Please select option
                      </mat-error>
                  </mat-form-field>
              </div>
            
          </div>

            <!-- <div fxLayout="row" fxLayoutAlign="space-between center"  *ngIf="is_super_admin">
                <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
                    <button class="btn btn-primary mr-2 mb-2" (click)="SearchformForSuperAdmin()" tabIndex="2">
                        Search
                    </button>
                    <span class="line" style="color: #1898d5; font-size: 16px"></span>
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
                        <span>Clear</span>
                    </button>
                </div>
            </div> -->
        </form>
          <!-- <mat-divider></mat-divider>
          <div   fxLayout="column" fxLayout.lt-lg="column" fxLayoutAlign.xs="center" class="dev-Pad" [formGroup]="searchForm" >
            <div fxLayout="row" fxflex="100" fxLayout.xs="column" fxLayout.lt-lg="row wrap"   fxLayoutAlign="space-between">
              <div  fxLayoutAlign="space-between" *ngIf="is_super_admin" > 
                
              </div>
              <div  fxLayoutAlign="end">
                <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline" style="padding-right: 16px;">
                  <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                  <mat-label>Search</mat-label>
                  <input (keyup)="applyFilter($event)" matInput [tabIndex]="9" maxlength="100">
                </mat-form-field>
              </div>
            </div>
            
          </div> -->
              <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end" style="height: 70px;padding-top: 1%;">
                  <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline" style="padding-right: 16px;">
                  <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                  <mat-label>Search</mat-label>
                  <input (keyup)="applyFilter($event)" matInput [tabIndex]="9" maxlength="100">
                </mat-form-field>
              </div> -->
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-lg="start" style="margin-left: 25px;padding-top: 10px;">
                <span  fxLayoutAlign.lt-lg="end">
                  <mat-radio-group>
                    <mat-radio-button tabIndex="4"   class="mx2 my-2 mr-2" value="Pending" [checked]='getStatus("Pending")' (click)='Searchform("Pending")' *ngIf="canManagerApproveDevices" >
                      <span class="radioBtn">Pending Approvals</span>
                    </mat-radio-button>
                    <mat-radio-button tabIndex="3"   class="mx2 my-2 mr-2" value="Approved"  [checked]='getStatus("Approved")' (click)='Searchform("Approved")' >
                      <span class="radioBtn">Approved</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </span>
              </div>
              <div fxLayout="column" class="tableP">
                <div class="example-container mat-elevation-z1">
                  <table mat-table [dataSource]="dataSource" matSort>
                    <tr>
                    <ng-container matColumnDef="id" >
                        <th mat-header-cell *matHeaderCellDef> S.No</th>
                        <td mat-cell *matCellDef="let element; let i=index;">
                        {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                    </ng-container>   
        
                    <ng-container matColumnDef="emp_name" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap"> Employee </th>
                        <td mat-cell *matCellDef="let row"> {{row.emp_name}}</td>
                    </ng-container>        
        
                    <ng-container matColumnDef="requestDate">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> Request Date</th>
                        <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.requestDate | date:'dd-MM-yyyy'}} </td>
                    </ng-container>        
        
                    <ng-container matColumnDef="device_changed">
                        <th mat-header-cell *matHeaderCellDef class="no-wrap"> Device </th>
                        <td mat-cell *matCellDef="let row" class="no-wrap"> {{row.device_changed}} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let row" [style.color]="row.status"> {{row.status}} </td>
                    </ng-container>

                    <ng-container matColumnDef="reason">
                      <th mat-header-cell *matHeaderCellDef> Reason </th>
                      <td mat-cell *matCellDef="let row" style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;">
                          {{row.reason}} </td>
                  </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row" class="no-wrap">
                            <button mat-icon-button [tabIndex]="10"  *ngIf="row.approval_status === null" (keyup.enter)="editRequest(row)">
                            <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                                edit</mat-icon>
                            </button>
                            <!-- <button *ngIf="row.status!='Submitted'" mat-icon-button [tabIndex]="12" (keyup.enter)="requestView(row)">
                                <mat-icon class="mr-2" title="View" (click)="requestView(row)">
                                visibility</mat-icon>
                            </button> -->
                        </td>
                    </ng-container>
                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
              </div>
        </div>
        <div *ngIf="!canManagerApproveDevices">
          <ul>
            <li>
              <span class="text-warning">
                Approval for any device change request can only be granted by the Super Administrator.
              </span>
            </li>
          </ul>
      </div>
      </mat-card>
      
      </div>
