import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { changePassword } from 'src/app/models/changepassword';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { LoginService } from 'src/app/services/login.service';
import { PasswordService } from 'src/app/services/password.service';

@Component({
  selector: 'app-employee-reset-password',
  templateUrl: './employee-reset-password.component.html',
  styleUrls: ['./employee-reset-password.component.scss']
})
export class EmployeeResetPasswordComponent implements OnInit {

  passwordResetForm: any = UntypedFormGroup;
  changePasswordAddObj!: changePassword;
  employeeList: any = [];
  hide1 = true;
  hide2 = true;
  hide3 = true;
  worklocationDetails: any = [];
  companyCode: any;
  availableDepartments: any = [];
  searchControl = new FormControl();
  filteredLocationList: any = [];
  resetOptions: any = [
    { id: 1, name: 'User Name' },
    { id: 2, name: 'Password' },
    { id: 3, name: 'User Name & Password' },
  ]
  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanySettingService,
    private dialog: MatDialog,
    private loginService: LoginService,
    private router: Router,
    private password: PasswordService
  ) { }
  empId: any;
  onlyUserName: boolean = false;
  onlyPassword: boolean = false;
  onlyUserNamePwd: boolean = false;
  @ViewChild('usernameInput')
  usernameInput!: ElementRef;
  @ViewChild('passwordInput')
  passwordInput!: ElementRef;
  isView: boolean = false;
  empEmail: any;
  empName: any;
  companyFullName: any;
  btnDisable:boolean = false;
  isEmailNotAvailable:boolean = false;
  filteredEmployees: any = [];
  empSearchControl = new FormControl('');
  usernameStatus:boolean = false;
  passwordStatus:boolean = false;
  ngOnInit(): void {
    this.companyCode = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.companyFullName = sessionStorage.getItem('companyFullName');
    this.passwordResetForm = this.formBuilder.group({
      department: [''],
      location: [''],
      empname: [''],
      existingUsername: [''],
      username: ['', [Validators.minLength(3), Validators.maxLength(50)]],
      oldPassword: [''],
      newPassword: [''],
      confirmPassword: [''],
      resetOptions: [''],
    })
    this.getWorkLocation();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });
    this.empSearchControl.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText);
    });
    this.passwordResetForm.get('empname')?.valueChanges.subscribe((selectedValue: any) => {
      this.empId = selectedValue;
    })

    this.passwordResetForm.get('resetOptions')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue == 1) {
        this.onlyPassword = false;
        this.onlyUserName = true;
        this.passwordStatus = false;
        if(this.isEmailNotAvailable){
          this.usernameStatus = true;
        }
      } else if (selectedValue == 2) {
        this.onlyUserName = false;
        this.onlyPassword = true;
        this.usernameStatus = false;
        let password = this.password.generateRandomPassword(8);
        this.passwordResetForm.controls.newPassword.setValue(password);
       
        if(this.isEmailNotAvailable){
          this.passwordStatus = true;
        }
      } else if (selectedValue == 3) {
        this.onlyUserName = true;
        this.onlyPassword = true;
        this.usernameStatus = false;
        this.passwordStatus = false;
        let password = this.password.generateRandomPassword(8);
        this.passwordResetForm.controls.newPassword.setValue(password);
        if(this.isEmailNotAvailable){
          this.usernameStatus = true;
          this.passwordStatus = true;
        }
      }
    })
  }
  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyCode }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocationList = this.worklocationDetails;
    })
  }
  getDepartmentsMaster() {
    this.companyService.getMastertable('departmentsmaster', 1, 1, 1000, this.companyCode).subscribe(data => {
      if (data.status) {
        this.availableDepartments = data.data;
      }
    })
  }
  enableDepartments() {
    this.passwordResetForm.controls.department.setValue('');
    this.getDepartmentsMaster();
    this.optionsOnchange();
    this.passwordResetForm.controls.empname.setValue('');

  }
  departmentChange() {
    this.employeeList = [];
    this.optionsOnchange();
    let data = {
      location_id: this.passwordResetForm.controls.location.value,
      department_id: this.passwordResetForm.controls.department.value,
    }
    this.companyService.getEmployeeListBasedonlocationIdandDepartmentId(data).subscribe((result: any) => {
      let value = result.data;
      this.employeeList = value;
      this.filteredEmployees = this.employeeList
    })
  }

  employeeChange() {
    this.isEmailNotAvailable = false;
    this.passwordResetForm.controls.resetOptions.setValue('');
    this.passwordResetForm.controls.username.setValue('');
    this.optionsOnchange();
    this.companyService.getEmployeeLoginDataById(this.empId).subscribe((result: any) => {
      if(result.data.length >0 ){
        let value = result.data[0];
        this.passwordResetForm.controls.existingUsername.setValue(value.login);
        this.passwordResetForm.controls.oldPassword.setValue(value.password);
        this.empName = value.empname;
        this.empEmail = value.officeemail;
         if(value.officeemail == '' || value.officeemail == undefined){
          this.isEmailNotAvailable = true;
        }
        this.isView = true;
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "The selected employee does not have an User Login."
        });
      }

    });
   
  }

  submit() {
    if (this.passwordResetForm.valid) {
      if (this.btnDisable) {
        return;
      }
      var data;
      let formdata = this.passwordResetForm.value;
      if (this.onlyUserName == true && this.onlyPassword == false) {
        this.usernameInput.nativeElement.focus();
        if (formdata.username == '') {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Please enter new username."
          });
          this.btnDisable = false;
          return;
        } else {
          data = {
            'empid': this.empId,
            'username': this.passwordResetForm.controls.username.value,
            'password': null,
            'type': 'username',
            'empEmail': this.empEmail,
            'empName': this.empName,
            'companyfullname': this.companyFullName,
            'companyCode':this.companyCode
          }
        }

      } else if (this.onlyUserName == false && this.onlyPassword == true) {
        data = {
          'empid': this.empId,
          'username': null,
          'password': this.passwordResetForm.controls.newPassword.value,
          'type': 'password',
          'empEmail': this.empEmail,
          'empName': this.empName,
          'companyfullname': this.companyFullName,
          'companyCode':this.companyCode
        }
      } else if (this.onlyUserName == true && this.onlyUserName == true) {
        this.usernameInput.nativeElement.focus();
        if (formdata.username == '') {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Please enter new username."
          });
          this.btnDisable = false;
          return;
        } else {
          data = {
            'empid': this.empId,
            'username': this.passwordResetForm.controls.username.value,
            'password': this.passwordResetForm.controls.newPassword.value,
            'type': 'both',
            'empEmail': this.empEmail,
            'empName': this.empName,
            'companyfullname': this.companyFullName,
            'companyCode':this.companyCode
          }
        }
      }

      this.btnDisable = true;
      this.loginService.updateEmployeeLoginsForAdmin(data).subscribe((res: any) => {
        var statusCode = res.data.statuscode;
        var statusMsg;
        switch (statusCode) {
          case 0:
            statusMsg = 'Username updated successfully.'
            break;
          case 1:
            statusMsg = 'Username already exist.'
            break;
          case 2:
            statusMsg = 'Password updated successfully.'
            break;
          case 3:
            statusMsg = 'The new password must not match with last 3 passwords.'
            break;
          case 4:
            statusMsg = 'Username and Password updated successfully.'
            break;
          case 5:
            statusMsg = 'The new password must not match with last 3 passwords.'
            break;
          case 6:
            statusMsg = 'Username and Password already exist.'
            break;
          default:
            statusMsg = 'Unable to updated data';
            break;
        }
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: statusMsg
        });
        this.cancel();
      });
    }
  }

  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Admin/adminPasswordReset"]));
  }

  filterLocations(searchText: any) {
    this.filteredLocationList = this.worklocationDetails.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase()) ||
      val.cityname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocationList.length <= 0) {
      this.searchControl.setValue('');
    } else {

    }
  }

  optionsOnchange() {
    this.isView = false;
    this.onlyUserName = false;
    this.onlyPassword = false;
    this.passwordResetForm.controls.existingUsername.setValue('');
    this.passwordResetForm.controls.oldPassword.setValue('');
    this.usernameStatus = false;
    this.passwordStatus = false;
    this.isEmailNotAvailable =false;
  }

  filterEmployees(searchText: any) {
    this.filteredEmployees = this.employeeList.filter((val:any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase())
    );
    if(this.filteredEmployees.length<=0){
      this.empSearchControl.setValue('');
    }else{
      
    }
  }
}
