import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ReportsService } from '../../reports.service';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PdfHeaderService } from 'src/app/services/pdf-header-service/pdf-header.service';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-manager-missed-punch-out-report',
  templateUrl: './manager-missed-punch-out-report.component.html',
  styleUrls: ['./manager-missed-punch-out-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})



export class ManagerMissedPunchOutReportComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  searchForm: any = UntypedFormGroup;
  employeelist: any = [];
  arrayList: any = [];
  minFromDate: Date | undefined;
  maxFromDate: Date | undefined;
  minToDate: Date | null;
  maxToDate: Date | undefined;
  currentDate: Date = new Date();
  List: any[] = [];
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['sno', 'empid', 'empname','attendancedate','intime','location'];
  pageLoading = true;
  searchControl = new FormControl('');
  filteredEmployees: any = [];
  userSession: any;
  isLoading = false;
  locationList: any = [];
  filteredLocations: any = [];
  companyDBName: any;
  date: any;
  minDate: any = new Date('2000-01-01');
  max = this.getEndOfMonth(new Date());
  monthfirstDay: any;
  monthLastDay: any;
  /**Here select Month and Date from month year picker */
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  months = [{ id: 0, month: 'Jan' }, { id: 1, month: 'Feb' }, { id: 2, month: 'Mar' }, { id: 3, month: 'Apr' }, { id: 4, month: 'May' }, { id: 5, month: 'Jun' }, { id: 6, month: 'Jul' }, { id: 7, month: 'Aug' }, { id: 8, month: 'Sep' }, { id: 9, month: 'Oct' }, { id: 10, month: 'Nov' }, { id: 11, month: 'Dec' }]


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public datePipe: DatePipe,
    private reportsService: ReportsService,
    public dialog: MatDialog,
    private pdfService: PdfHeaderService,
  ) {

    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate() - 90);
    this.maxFromDate = new Date();
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() - 90);
    this.maxToDate = new Date();

  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      employees: ['0', Validators.required],
      fromDate: [new Date(), Validators.required],
      toDate: [this.max, Validators.required],
    });
  
  
   
   
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getEmployeelist();
    this.companyDBName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.date = new UntypedFormControl(moment())
    let prevDate = new Date();
    prevDate.setDate(0);
    this.Searchform();
  }

  /** Function to get the end of the current month*/
  getEndOfMonth(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0); // Last day of the current month
  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minToDate = event.value;
    if (event.value !== null) {
      this.maxToDate = new Date(
      );
    }
  }
  /**Missed Out Punches Report Search Functionality */
  Searchform() {
    var firstDay = new Date((this.date.value._d).getFullYear(), (this.date.value._d).getMonth(), 1);
    var lastDay = new Date((this.date.value._d).getFullYear(), (this.date.value._d).getMonth() + 1, 0);
    this.monthfirstDay = this.datePipe.transform(firstDay, "yyyy-MM-dd")
    this.monthLastDay = this.datePipe.transform(lastDay, "yyyy-MM-dd")
    let data = {
      "remployee_id":this.searchForm.controls.employees.value=='0' ?this.userSession.id :null,
      "employee_id":this.searchForm.controls.employees.value=='0'?null:parseInt(this.searchForm.controls.employees.value),
      "calendar_date": this.monthfirstDay
    }
    this.reportsService.getMissedOutPunchesReport(data).subscribe((res: any) => {
      if (res.status) {
        this.arrayList = res.data
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    })

  }
  /**Clear Functionality */
  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/ManagerMissedPunchOutReport"]));
  }

  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  /**Drop Down Employees Filter  */
  filterEmployees(searchText: any) {
    this.filteredEmployees = this.employeelist.filter(
      (val: any) =>
        val.empcode.toLowerCase().includes(searchText.toLowerCase()) ||
        val.empname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployees.length <= 0) {
      this.searchControl.setValue('');
    }
  }
  /** Get Employees List*/
  getEmployeelist() {
    let obj = {
      rm_id: this.userSession.id,
    };
    this.reportsService
      .getTotalEmployeslistByManagerId(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeelist = [];
          this.employeelist = res.data;
          this.filteredEmployees = this.employeelist;
          this.searchForm.controls.employees.setValue('0');
        }
      });
  }

  /**Table Search Functioanlity */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**Download Excel functionality */
  exportAsXLSX() {
    /** */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Missed_Out_Punches_Report');

    /* save to file */
    XLSX.writeFile(wb,  'Missed_Out_Punches_Report for ' +this.datePipe.transform(new Date(), 'MMM yyyy') +'.xlsx');

  }

  generatePDF() {
  
    const doc = new jsPDF();
    const reportName = 'Missed Out Punch Report';
    let formattedDate: string;
    formattedDate = this.datePipe.transform(this.date.value._d, 'MM-yyyy') || '';
  
    let manager = '';
    let locationName = '';
    let reportDate = formattedDate;
    this.pdfService.generatePDFHeader(doc, reportName,locationName,manager,reportDate,false);
    const tableData = this.arrayList.map((emp:  { 
      empcode: any;    
      empname: any;    
      attendancedate: any;  
      firstlogintime: any ;
      locationid: any; 
    }, index: number) => [
     
        index + 1,
      emp.empcode,
      emp.empname,
      emp.attendancedate,
      emp.firstlogintime,
      emp.locationid,
      ]);
          autoTable(doc, {
            head: [['S.No.','Emp.ID','Emp. Name', 'Date','CheckIn Time','Attendance Source']],
            body:tableData,
            startY: 40,
            styles: { fontSize: 10 },
            headStyles: { fontSize: 12 }
         });
       doc.save('missed_out_punches_report.pdf');
  } 
}
