<div fxLayout="column" class="first-col">


  <mat-card fxLayout="column">
    <mat-card-header class="headerbox">
      <mat-card-title class="title" *ngIf="generate">Monthly Payroll</mat-card-title>
      <mat-card-title class="title" *ngIf="!generate">Monthly Payroll Report</mat-card-title>
    </mat-card-header>
    <div fxLayout="column" class="div-pad">


      <form [formGroup]="monthlyPayrollForm" autocomplete="off" style="padding-top: 1%;" ngStyle.xs="padding-top: 5%;">
        <div class="responsive-form" >

          <mat-form-field class="mx-2 my-2" appearance="outline">
            <mat-label>Financial Year</mat-label>
            <mat-select formControlName="financial_year" required>
              <ng-container>
                <div *ngFor="let fy of financeyears">
                  <mat-option [value]="fy.return_value">{{fy.financial_year}}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="monthlyPayrollForm.controls.financial_year.errors?.required ||
                                                monthlyPayrollForm.controls.financial_year.errors?.touched ">
              Please select an option.
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mx-2 my-2" appearance="outline">
            <mat-label>Month and Year</mat-label>
            <mat-select formControlName="Month_year" required>
              <ng-container>
                <div *ngFor="let my of monthyear">
                  <mat-option [value]="my.month_value">{{my.display_value}}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="monthlyPayrollForm.controls.Month_year.errors?.required ||
                                                monthlyPayrollForm.controls.Month_year.errors?.touched ">
              Please select an option.
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mx-2 my-2" appearance="outline">
            <mat-label>Location</mat-label>
            <mat-select formControlName="location">
              <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
              <ng-container *ngFor="let e of filteredLocations">
                <mat-option [value]="e.id">{{e.cityname}}-{{e.location}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" class="dev-Pad"
        fxLayoutAlign="space-between center">
        <span fxFlex="50">
          <mat-radio-group (change)="radioChange($event)">
            <mat-radio-button value="1" [checked]="true"><b>Generate Payroll</b></mat-radio-button>&nbsp;
            <mat-radio-button value="2"><b>View Generated Payroll </b></mat-radio-button>
          </mat-radio-group>
        </span>
        <span style="padding-top: 5px;height: 70px;" fxFlex="50" fxLayoutAlign="end">
          <mat-form-field fxFlex="56" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput placeholder="" maxlength="100">
          </mat-form-field>
        </span>
      </div>
      </form>

      <div *ngIf="generate">
        <div *ngIf="hide">
          <div class="mat-elevation-z1 example-container">
            <table mat-table [dataSource]="dataSource">

              <!-- Checkbox Column -->
              <ng-container matColumnDef="select" class="mat-elevation-z8">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;">
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Position Column -->
              <!-- <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;">  Emp ID  </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                      </ng-container> -->
              <!-- Emp Name -->
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) }} </td>
              </ng-container>
              <ng-container matColumnDef="empid">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;">
                  Emp ID </th>
                <td mat-cell *matCellDef="let element"> {{element.employee_code}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;">
                  Name </th>
                <td mat-cell *matCellDef="let element"> {{element.employee_name}} </td>
              </ng-container>

              <!-- Designation Column -->
              <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;">
                  Designation </th>
                <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
              </ng-container>

              <!-- worklocation Column -->
              <ng-container matColumnDef="worklocation">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;">
                  Work Location </th>
                <td mat-cell *matCellDef="let element"> {{element.location}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                <!-- (click)="selection.toggle(row)" -->
              </tr>
            </table>
            <!-- <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizeslist() : [5, 10, 20]"></mat-paginator> -->
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
          </div>
          <div class="form-group row">
            <div class="col-sm-6 text-right" style="padding:0px">
              <button class="btn btn-primary custom mr-2 mb-2" (click)="validateSalaryProcessingDate()" type="submit">
                <span>Calculate Salary</span>
              </button>
            </div>
            <div class="col-sm-6">
              <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>






    <div *ngIf="!generate">

      <div style="margin-bottom: 1%;padding-right: 2%;" fxLayout="row xs-column" fxLayoutAlign="end  center">
        <div>
          <a title="PDF"> <img (click)="exportPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px"></a>
          <span class="exlprint"> | </span>
          <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
          </a>
        </div>
      </div>

      <!-- id, employee_code, employee_name, designation, location, total_net_salary, bank_account_number, bankname, branchname, ifsccode -->
      <div fxLayout="column" class="table-pad">
        <div class="example-container mat-elevation-z1">
          <div id="table" #table="">
            <table mat-table [dataSource]="dataSource2" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S.No </th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }} </td>
              </ng-container>

              <ng-container matColumnDef="empid">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp ID</th>
                <td mat-cell *matCellDef="let row"><span>{{row.employee_code}} </span></td>
              </ng-container>
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp Name </th>
                <td mat-cell *matCellDef="let row"><span>{{row.employee_name}} </span></td>
              </ng-container>
              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Amount </th>
                <td mat-cell *matCellDef="let row"><span>{{row.total_net_salary}} </span></td>
              </ng-container>
              <ng-container matColumnDef="accountnumber">
                <th mat-header-cell *matHeaderCellDef>Account Number </th>
                <td mat-cell *matCellDef="let row"><span>{{row.bank_account_number}} </span></td>
              </ng-container>
              <ng-container matColumnDef="bank">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Bank</th>
                <td mat-cell *matCellDef="let row"><span>{{row.bankname}} </span></td>
              </ng-container>
              <ng-container matColumnDef="ifsc">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">IFSC</th>
                <td mat-cell *matCellDef="let row"><span>{{row.ifsccode}} </span></td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns2;sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
              </tr>
            </table>
          </div>
          <mat-paginator #paginatorRef [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>

      </div>
    </div>
    <br><br>
  </mat-card>
</div>
<!-- <div>
        <button  mat-raised-button (click)="logSelection()">Console Log Selected</button>
      </div> -->