import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from '../../reports.service';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PdfHeaderService } from 'src/app/services/pdf-header-service/pdf-header.service';
@Component({
  selector: 'app-admin-shift-employee-dialog',
  templateUrl: './admin-shift-employee-dialog.component.html',
  styleUrls: ['./admin-shift-employee-dialog.component.scss']
})
export class AdminShiftEmployeeDialogComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'shift', 'employee', 'status'];
  dataSource: any = [];
  date: any;
  rmId: any;
  locationId: any;
  constructor(public dialogRef: MatDialogRef<AdminShiftEmployeeDialogComponent>,
    private reportService: ReportsService,private excelExportService: ExcelExportService,
    private pdfService: PdfHeaderService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.date = data.date_value;
    this.rmId = data.rm_id_value;
    this.locationId = data.location_id;
    }
  employeesList: any = [];
  ngOnInit(): void {
    this.viewData();
  }

  viewData() {
    let dateValue = this.date.split("-").reverse().join("-");
    this.employeesList = [];
     let data = {
      "rm_id_value":this.rmId,
      'location_id': this.locationId,
      "date_value":dateValue,
     }
    this.reportService.getBranchWiseShiftsEmployeesDetailReportByDate(data).subscribe((res) => {
      if (res.status) {
        this.employeesList = res.data;
        this.dataSource = this.employeesList;
         } else {
           this.employeesList = [];
         }
    })
  }

  downloadPDF() {
    let data = {
      'list': this.employeesList,
      'report_date':this.date
    }
    this.reportService.getShiftsEmployeesDetailReportPdf(data).subscribe(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'shift_wise_report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  titleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
    exportToExcel(): void {
      const columnsToRemove = ['id'];
      const dataToExport = this.employeesList.map((item:any, index:any) => {
      const newItem: any = {
          'S.No': index + 1
        };
    
        for (const key in item) {
          if (item.hasOwnProperty(key) && !columnsToRemove.includes(key)) {
            const titleCasedKey = this.titleCase(key); 
            newItem[titleCasedKey] = item[key];
          }
        }
        return newItem;
      });
    
      this.excelExportService.exportAsExcelFile(dataToExport, 'Shift_Report');
    }

    generatePDF() {
  
  
      const doc = new jsPDF();
      const reportName = 'Shift Wise Employee Report';
      let manager = '';
      let locationName = '';
      let reportDate = this.date;
      this.pdfService.generatePDFHeader(doc, reportName,locationName,manager,reportDate,false);
      const tableData = this.employeesList.map((emp: { shiftname: any; empname: any; status: any }, index: number) => [
        index + 1,
        emp.shiftname,
        emp.empname,
        emp.status,
      ]);
            // Continue with table or other content
            autoTable(doc, {
              head: [['S.No.', 'Shift', 'Emp. Name', 'Status']],
              body:tableData,
              startY: 47,
              styles: { fontSize: 10 },
              headStyles: { fontSize: 12 }
           });
         doc.save('Shiftwise_Employee_Report.pdf');
    } 
}
