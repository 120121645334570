<div fxLayout="column" fxFill class="custom-investment-of custom-pgr-height-auto first-col">
    <mat-card fxFill>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Employee State Insurance</mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isadd">
                <button mat-icon-button [tabIndex]="1" (keyup.enter)="editdata()" (click)="editdata()"
                    matTooltip="Edit">
                    <i class="fa fa-edit" aria-hidden="true" style="font-size: 25px;"></i>
                </button>
            </div>
        </mat-card-header>
        <div fxLayout="row" fxLayout.xs="column" class="custom-card">
            <form [formGroup]="esiRequestForm" autocomplete="off" class="rowPad">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
                    <mat-form-field appearance="outline" fxFlex fxFlex.lt-lg="48">
                        <mat-label>Deduction Cycle</mat-label>
                        <input formControlName="deductionCycle" [ngClass]="{'disable':true}" [readonly]="true" matInput
                            placeholder="" [tabIndex]="2">
                        <mat-error class="con-error" *ngIf="  esiRequestForm.controls.deductionCycle.errors?.required">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex fxFlex.lt-lg="48">
                        <mat-label>Employee Contribution</mat-label>
                        <input type="text" matInput class="form-control" [ngClass]="{'disable':true}"
                            formControlName="employeeContribution" placeholder="" readonly [tabIndex]="3">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex fxFlex.lt-lg="48">
                        <mat-label>Employer's Contribution</mat-label>
                        <input type="text" matInput class="form-control" [ngClass]="{'disable':true}"
                            formControlName="employersContribution" placeholder="" readonly [tabIndex]="4">
                    </mat-form-field>

                </div>
                <div style="padding-top:10px;">

                    <mat-checkbox [disabled]="enable" [tabIndex]="5" formControlName="includectc">Include Employer's
                        Contribution in the CTC.
                    </mat-checkbox>

                </div>
                <div>
                    Note: ESI deductions will be made only if employee's monthly salary is less than or equal to <i
                        class="fa fa-inr" aria-hidden="true"></i>{{salary}}.
                    If the employee gets a salary revision which increases their monthly salary above <i
                        class="fa fa-inr" aria-hidden="true"></i>{{salary}}, they would
                    have to continue making ESI contribution till the end of the contribution period in which the salary
                    was revised (April-September or October-March).
                </div>
                <div fxLayoutAlign="center" *ngIf="!enable " style="padding-top: 2%;">
                    <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}"
                        (click)="setCompanyEsiValues()" [tabIndex]="6" (keyup.enter)="setCompanyEsiValues()"
                        type="submit" *ngIf="isadd">
                        <span>Save</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" [tabIndex]="7" (keyup.enter)="cancel()" (click)="cancel()"
                        *ngIf="isadd">
                        <span>Cancel</span>
                    </button>
                </div>
                <br>

                <hr class="line_break">
                <div fxLayout="column">
                    <form [formGroup]="companyEsiRequestForm" autocomplete="off" *ngIf="isadd">
                        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column" fxLayoutGap="10px"
                            fxLayoutAlign.xs="center">
                            <div fxLayout="row" fxLayoutGap="5px" fxLayout.xs="column">
                                <mat-form-field appearance="outline" fxFlex="50" *ngIf="!isedit">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="state" required [tabIndex]="8">
                                        <ng-container>
                                            <div *ngFor="let a of getStateEsiDetails">
                                                <mat-option [value]="a.id">{{a.state}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="con-error"
                                        *ngIf="  companyEsiRequestForm.controls.state.errors?.required">
                                        Please select an option.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" *ngIf="isedit">
                                    <input formControlName="statesdata" [ngClass]="{'disable':true}" matInput
                                        [readonly]="true" required [tabIndex]="8">


                                </mat-form-field>


                                <mat-form-field appearance="outline" fxFlex="50">
                                    <mat-label>ESI Number</mat-label>
                                    <input formControlName="esiNumber" matInput mask="00-00-000000-000-0000" required
                                        [tabIndex]="9">
                                    <mat-error class="con-error"
                                        *ngIf=" companyEsiRequestForm.controls.esiNumber.errors?.required">
                                        {{PR1}}
                                    </mat-error>
                                    <mat-error class="con-error"
                                        *ngIf="companyEsiRequestForm.controls.esiNumber.errors?.pattern ">
                                        {{PR39}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <span style="padding-top: 1%;" fxLayoutAlign.xs="center" fxLayoutAlign.lt-lg="center">
                                <button class="btn btn-primary mr-2 mb-2" [tabIndex]="10"
                                    (keyup.enter)="setEsiForState()" (click)="setEsiForState()" type="submit">
                                    <span *ngIf="!isedit">Save</span>
                                    <span *ngIf="isedit ">Update</span>
                                </button>
                                <button class="btn btn-danger mr-2 mb-2" [tabIndex]="11" (keyup.enter)="cancel()"
                                    (click)="cancel()">
                                    <span>Clear</span>
                                </button>
                            </span>
                        </div>
                    </form>
                    <br>

                    <div fxLayout="column">
                        <div class="example-container mat-elevation-z1">
                            <table mat-table [dataSource]="dataSource">
                                <ng-container matColumnDef="sno">
                                    <th mat-header-cell *matHeaderCellDef
                                        style="background-color: #28acaf;color: white;font-size: medium;"><span style="text-wrap: nowrap;">S. No.</span></th>
                                    <td mat-cell *matCellDef="let element; let i=index;">
                                        {{ (i+1) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="state">
                                    <th mat-header-cell *matHeaderCellDef
                                        style="background-color: #28acaf;color: white;font-size: medium;"
                                        class="no-wrap"> State </th>
                                    <td mat-cell *matCellDef="let element" class="no-wrap"> {{element.state}} </td>
                                </ng-container>

                                <ng-container matColumnDef="esi">
                                    <th mat-header-cell *matHeaderCellDef
                                        style="background-color: #28acaf;color: white;font-size: medium;"
                                        class="no-wrap">ESI Number </th>
                                    <td mat-cell *matCellDef="let element" class="no-wrap"> {{element.value}} </td>
                                </ng-container>
                                <ng-container matColumnDef="config">
                                    <th mat-header-cell *matHeaderCellDef
                                        style="background-color: #28acaf;color: white;font-size: medium;"
                                        class="no-wrap"></th>
                                    <td mat-cell *matCellDef="let element;let i = index;" class="no-wrap">
                                        <button mat-icon-button matTooltip="Edit" [tabindex]="[i+12]"
                                            (keyup.enter)="edit(element)">
                                            <mat-icon title="Edit" (click)="edit(element)" *ngIf="true">edit</mat-icon>
                                            <!-- <mat-icon   title="Edit" (click)="edit(element)">visbility</mat-icon> -->
                                        </button>
                                    </td>
                                </ng-container>



                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>

                <!-- <div class="mob-port-show">
                <div fxLayout="column" fxFlex="100" class="custom-payroll-div">
                    <div style="    margin-left: 10px; margin-right: 10px;">
                        
                        <p>
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>ESI Number</mat-label>
                                <input type="text" matInput class="form-control" formControlName="esiNumber" placeholder="" >
                                <mat-error class="con-error" *ngIf="  esiRequestForm.controls.esiNumber.errors?.required">
                                    Please enter valid data.
                                </mat-error>
                            </mat-form-field>
                            
                        </p>
                        <p>                        
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Deduction Cycle</mat-label>
                                <mat-select formControlName="deductionCycle">
                                    <mat-option value="Monthly">Monthly</mat-option>
                                    <mat-option value="Quaterly">Quaterly</mat-option>
                                    <mat-option value="Yearly">Yearly</mat-option>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="  esiRequestForm.controls.deductionCycle.errors?.required">
                                    Please select an option.
                                </mat-error>                                    
                            </mat-form-field>
                        </p>
                        <p style="line-height: 0;margin-bottom: 34px;">
                            <mat-form-field appearance="outline" style="width: 75%;">
                                <mat-label>Employee Contribution</mat-label>
                                <input type="text" matInput class="form-control" formControlName="employeeContribution" placeholder="" readonly>                                
                            </mat-form-field>
                            of Gross Pay
                        </p>   
                        <p style="line-height: 0;margin-bottom: 34px;">
                            <mat-form-field appearance="outline" style="width: 82%;">
                                <mat-label>Employer's Contribution</mat-label>
                                <input type="text" matInput class="form-control" formControlName="employersContribution" placeholder="" readonly>                                
                            </mat-form-field>
                            of Gross Pay
                        </p>   
                        <p>
                            <mat-checkbox formControlName="employerCTCContribution">Include Employer's Contribution in the CTC.
                            </mat-checkbox>
                        </p>
                        <p>Note: ESI deductions will be made only if employee's monthly salary is less than or equal to <i class="fa fa-inr" aria-hidden="true"></i>{{salary}}. 
                           If the employee gets a salary revision which increases their monthly salary above <i class="fa fa-inr" aria-hidden="true"></i>{{salary}}, they would
                           have to continue making ESI contribution till the end of the contribution period in which the salary 
                           was revised (April-September or October-March).
                        </p>   
                        <p>                           
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Effective Date</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="effective_date">                                
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker> 
                            </mat-form-field>
                        </p>                                                                   
                        <div fxLayoutAlign="center">
                            <button class="btn btn-primary mr-2 mb-2" (click)="setPayGroup()" type="submit">
                            <span>Save</span>
                          </button>
                  
                          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                            <span>Cancel</span>
                          </button>
                          <br />
                        </div>                        
                    </div>
                </div> 
            </div>    -->

                <br>
            </form>
        </div>
    </mat-card>
</div>