

<div fxLayout="column" class="first-col">
  <mat-card class="mat-elevation-z0" >
    <mat-card-header class="headerbox">
      <mat-card-title class="title">Payable Days Report</mat-card-title>
    </mat-card-header>
    <form fxLayout="column" [formGroup]="searchForm" style="margin-top: 10px">
      <div class="responsive-form" >

          <mat-form-field class="mx-2 my-2" appearance="outline">
              <mat-label>Month and Year</mat-label>
              <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                  [min]="minDate" [max]="max" (click)="fromDate.open()" required>
              <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
              <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                  panelClass="example-month-picker">
              </mat-datepicker>
          </mat-form-field>

          <mat-form-field  class="mx-2 my-2" appearance="outline">
            <mat-label>Locations</mat-label>
            <mat-select formControlName="location" required>
                <ng-container>
                    <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                        matInput>
                    <mat-option value='0'>All Locations</mat-option>
                    <mat-option [value]="a.id"
                        *ngFor="let a of filteredLocations">{{a.location}}-{{a.cityname}}</mat-option>
                </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="searchForm.controls.location.error?.required">This
                field is required.</mat-error>
        </mat-form-field>

          <mat-form-field class="mx-2 my-2" appearance="outline">
              <mat-label>Manager Name</mat-label>
              <mat-select formControlName="employees" required>
                  <input class="search-align" type="text" placeholder="Search" [formControl]="empSearchControl"
                      matInput>
                      <mat-option value= '0' > All Managers </mat-option>
                  <mat-option *ngFor="let a of filteredEmployees" [value]="a.manager_emp_id">{{a.manager_name}}</mat-option>
              </mat-select>
              <mat-error class="con-error" *ngIf="searchForm.controls.employees.errors?.required">This field is
                  required.</mat-error>
          </mat-form-field>

        </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="100" fxLayout="row" [fxLayoutAlign]="arrayList.length > 0 ? 'end' : 'center'">
          <button class="btn btn-primary mr-2 mb-2" (click)="getSearchForm()" tabIndex="2">
            Search
          </button>
          <span class="line" style="color: #1898d5; font-size: 16px"></span>
          <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
            <span>Clear</span>
          </button>
        </div>

        <div *ngIf="arrayList.length > 0" fxFlex="50" fxLayoutAlign="end">
          <div style="padding-right: 2%">
            <span class="exlprint"> | </span>
            <button mat-icon-button>
                <a title="PDF" [tabIndex]="4" (keyup.enter)="generatePDF()"> <img
                        (click)="generatePDF()" src="./assets/images/icons/pdf.png" width="20px"
                        height="20px"></a>
            </button>
            <span class="exlprint"> | </span>
            <button mat-icon-button>
                <a title="Excel" [tabIndex]="5" (keyup.enter)="exportAsXLSX()"> <img
                        (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                </a>
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" *ngIf="arrayList.length > 0">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"
          style="padding-top: 5px;height: 70px;">
          <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
            <mat-label>Search </mat-label>
            <input (keyup)="applyFilter($event)" [tabIndex]="1" matInput placeholder="" maxlength="100" />
          </mat-form-field>
        </div>
        </div>

      <div fxLayout="column" class="table-pad">
        <!-- calendar days table --> 
          <div *ngIf="isCalenderDaysPayroll" id="table" #table='' class="example-container mat-elevation-z1" [ngClass]="{'scroll-container': arrayList.length > 0}">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="no-wrap" ><span style="text-wrap: nowrap;">S. No.</span></th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
              </ng-container>
              <ng-container matColumnDef="empid">
                <th mat-header-cell *matHeaderCellDef class="no-wrap"> Emp. ID </th>
                <td mat-cell *matCellDef="let row">{{row.empCode}}</td>
              </ng-container>
  
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef class="no-wrap" >Emp. Name </th>
                <td mat-cell *matCellDef="let row" class="no-wrap">{{row.empName}} 
                </td>
              </ng-container>
              <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef>Department </th>
                <td mat-cell *matCellDef="let row">{{row.department}}  </td>
              </ng-container>
  
              <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef> Designation</th>
                <td mat-cell *matCellDef="let row" class="no-wrap">{{row.designation}}  </td>
              </ng-container>
  
              <ng-container matColumnDef="presents">
                  <th mat-header-cell *matHeaderCellDef> Presents</th>
                  <td mat-cell *matCellDef="let row">{{row.presents}}  </td>
              </ng-container>

              <ng-container matColumnDef="absents">
                  <th mat-header-cell *matHeaderCellDef> Absents</th>
                  <td mat-cell *matCellDef="let row">{{row.absents}}  </td>
              </ng-container>

              <ng-container matColumnDef="leaves">
                  <th mat-header-cell *matHeaderCellDef> Leaves</th>
                  <td mat-cell *matCellDef="let row">{{row.leavesCount}}  </td>
              </ng-container>
             
              <ng-container matColumnDef="paidweekoff">
                  <th mat-header-cell *matHeaderCellDef class="no-wrap"> Paid Week-off</th>
                  <td mat-cell *matCellDef="let row">{{row.weekOffs}}  </td>
              </ng-container>

              <ng-container matColumnDef="paiddays">
                  <th mat-header-cell *matHeaderCellDef class="no-wrap"> Paid Days</th>
                  <td mat-cell *matCellDef="let row">{{row.payableDays}}  </td>
              </ng-container>

              <ng-container matColumnDef="lopdays">
                  <th mat-header-cell *matHeaderCellDef class="no-wrap"> LOP Days</th>
                  <td mat-cell *matCellDef="let row">{{row.lopdays}}  </td>
              </ng-container>
  
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="calendarColumns ;sticky:true "></tr>
              <tr mat-row *matRowDef="let row; columns: calendarColumns;"></tr>
            
              <tr class="mat-row" *matNoDataRow>
                <td *ngIf="!isLoading" class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                <td *ngIf="isLoading" class="mat-cell" colspan="10" style="text-align: center;">Loading...</td>
              </tr>
            </table>
            <mat-card *ngIf="isLoading"
            style="display: flex; justify-content: center; align-items: center; opacity:0.5">
            <mat-progress-spinner color="primary" [diameter]="70" mode="indeterminate">
            </mat-progress-spinner>
          </mat-card>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
          </div>

        <!-- working days table -->
          <div *ngIf="!isCalenderDaysPayroll" class="example-container mat-elevation-z1" [ngClass]="{'scroll-container': arrayList.length > 0}">
            <table mat-table [dataSource]="dataSource2" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef  class="no-wrap">S. No.</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
              </ng-container>
              <ng-container matColumnDef="empid">
                <th mat-header-cell *matHeaderCellDef class="no-wrap"> Emp. ID </th>
                <td mat-cell *matCellDef="let row">{{row.empCode}}</td>
              </ng-container>
  
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef class="no-wrap" >Emp. Name </th>
                <td mat-cell *matCellDef="let row">{{row.empName}} 
                </td>
              </ng-container>
              <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef>Department </th>
                <td mat-cell *matCellDef="let row">{{row.department}}  </td>
              </ng-container>
  
              <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef> Designation</th>
                <td mat-cell *matCellDef="let row">{{row.designation}}  </td>
              </ng-container>
  
              <ng-container matColumnDef="presents">
                  <th mat-header-cell *matHeaderCellDef> Presents</th>
                  <td mat-cell *matCellDef="let row">{{row.presents}}  </td>
              </ng-container>

              <ng-container matColumnDef="absents">
                  <th mat-header-cell *matHeaderCellDef> Absents</th>
                  <td mat-cell *matCellDef="let row">{{row.absents}}  </td>
              </ng-container>

              <ng-container matColumnDef="leaves">
                  <th mat-header-cell *matHeaderCellDef> Leaves</th>
                  <td mat-cell *matCellDef="let row">{{row.leavesCount}}  </td>
              </ng-container>
             
              <ng-container matColumnDef="workdays">
                  <th mat-header-cell *matHeaderCellDef> Working Days</th>
                  <td mat-cell *matCellDef="let row">{{row.workingDays}}  </td>
              </ng-container>

              <ng-container matColumnDef="paiddays">
                  <th mat-header-cell *matHeaderCellDef> Paid Days</th>
                  <td mat-cell *matCellDef="let row">{{row.payableDays}}  </td>
              </ng-container>

              <ng-container matColumnDef="lopdays">
                  <th mat-header-cell *matHeaderCellDef> LOP Days</th>
                  <td mat-cell *matCellDef="let row">{{row.lopdays}}  </td>
              </ng-container>
  
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="workingDaysColumns ;sticky:true "></tr>
              <tr mat-row *matRowDef="let row; columns: workingDaysColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;">No data found</td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes2() : [5, 10, 20]"></mat-paginator>
          </div>
        </div>
    </form>
  </mat-card>
</div>