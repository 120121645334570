import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../../reports.service';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-checkin-out-summary-report-dialog',
  templateUrl: './checkin-out-summary-report-dialog.component.html',
  styleUrls: ['./checkin-out-summary-report-dialog.component.scss'],
})
export class CheckinOutSummaryReportDialogComponent implements OnInit {
  empId: any;
  empName: any;
  present: any;
  absent: any;
  total: any;
  dataSource: MatTableDataSource<any> = <any>[];
  List: any = [];
  empid: any;
  employeeName: any;
  displayedColumns: any = [
    'sno',
    'date',
    'intime',
    'outtime',
    'totalHours',
    'breaks',
    'breakTime',
    'productiveHours',
    'otHours',
    'permissionHours',
  ];
  constructor(
    private reportsService: ReportsService,
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getAttendanceDetailedSummaryReport();
    this.empid = this.data.employeeId;
    this.employeeName = this.data.empname;
  }
  getAttendanceDetailedSummaryReport() {
    let obj = {
      empname: this.data.employeeId,
      calenderDate: this.data.calenderDate,
    };
    this.List = [];
    this.reportsService
      .getAttendanceDetailedSummaryReport(obj)
      .subscribe((res: any) => {
        if (res.status) {
          let responseData = res.data.map((item: any) => {
            return {
              ...item,
              breaks: item.breaks
                ?.split(',')
                ?.map((each: any) => each?.split(' ')[1]),
            };
          });

          this.List = responseData;
          console.log('data', this.List)
          this.dataSource = new MatTableDataSource(this.List);
        }
      });
  }
}
