
<div fxLayout="column">
    <mat-card>
      <mat-card-header class="headerbox">
        <mat-card-title class="title">  Help Desk-Employee</mat-card-title>
    </mat-card-header>
  
     <div *ngIf="isView" fxLayout="column">
      <form [formGroup]="employeeTicketForm" >
                <br>
                <mat-card fxLayout="column">
                  <h2>Ticket ID: &nbsp; <span>{{ticketCode}}</span> </h2>
                  <div fxLayout="row" fxLayout.lt-md="column">
                
                  <div fxFlex="50">
                    <h4>Issue Raised Date: &nbsp; <span class="p_txt" >{{issueRaisedDate | date :'dd-MM-yyyy h:mm a '}}</span> </h4> 
                    <h4>Company Name:&nbsp; <span class="p_txt">{{companyName}}</span> </h4>
                    <h4>Employee Name:&nbsp; <span class="p_txt">{{raisedEmployee}}</span> </h4>
                  </div>
                  <div fxFlex="50">
                    <h4>Module: &nbsp; <span class="p_txt">{{moduleName}}</span></h4>
                    <h4>Screen: &nbsp; <span class="p_txt">{{screenName}}</span></h4>
                    <h4>Attachment: &nbsp; <span class="p_txt">{{ticketCode}}</span></h4>
                  </div>
                </div>
                <div>
                  <h4>Description:&nbsp; <span class="p_txt">{{empDescription}}</span></h4>
                  <mat-divider></mat-divider>
                  <br>
                  <h4>Manager Remarks:&nbsp; <span class="p_txt">{{mngrDescription}}</span></h4>
                </div>
                </mat-card>
         


                <br>
                  <div fxLayout="row">
                     <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Issue Status</mat-label>
                        <mat-select formControlName="status" required>
                          <ng-container>
                            <span *ngFor="let list of statusList">
                              <mat-option [value]="list.id" >{{list.name}}</mat-option>
                            </span>
                          </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="employeeTicketForm.controls.status.errors?.required">
                          Please select an option
                        </mat-error>
                      </mat-form-field>
                  </div>
                  <div fxLayout="row">
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Remarks</mat-label>
                        <textarea formControlName="empRemarks" required matInput maxlength="500" placeholder="Write something here" ></textarea>
                        <mat-error class="con-error"
                          *ngIf="employeeTicketForm.controls.empRemarks.errors?.required || employeeTicketForm.controls.empRemarks.errors?.['whitespace']">
                         This field is required
                        </mat-error>
                      </mat-form-field>
                 </div>
                  <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" style="padding: 0 10px" (click)="submit()">
                      <span>Submit</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
                      <span>Cancel</span>
                    </button>
                    <br />
                  </div>
                </form>   
        </div>

 
     <div *ngIf="!isView" fxLayout="column">
      <form [formGroup]="ticketHistoryForm">
      <br>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
     
        <div fxLayout="row" fxLayout.xs="column">
            <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                <mat-label>From Date</mat-label>
                <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                    placeholder="From Date" formControlName="fromDate" (click)="fromDate.open()" tabindex="2"/>
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                <mat-label>To Date</mat-label>
                <input matInput readonly [matDatepicker]="toDate" [min]="minDate" [max]="maxDate"
                    placeholder="From Date" formControlName="toDate" (click)="toDate.open()" tabindex="2"/>
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign.lt-lg="center center" fxLayoutAlign.xs="center center">
            <span ngStyle.gt-md="margin-top: 5%">
                <button class="btn btn-primary mr-2 mb-2" (click)="searchForm()" tabindex="3">
                    Search
                </button>
                <span class="line" style="color: #1898d5; font-size: 16px"></span>
                <button class="btn btn-danger mr-2 mb-2" (click)="clear()" tabindex="4">
                    <span>Clear</span>
                </button>
            </span>
        </div>
  </div>
</form>   
  <mat-divider></mat-divider>
      <br>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" class="table-pad"
        fxLayoutAlign="space-between center">
        <span fxFlex="50">
          <mat-radio-group (change)="radioChange($event)">
            <mat-radio-button value="1" [checked]="true"><b>All</b></mat-radio-button>&nbsp;
            <mat-radio-button value="2"><b>Self</b></mat-radio-button>&nbsp;
            <mat-radio-button value="3"><b>Assigned</b></mat-radio-button>
          </mat-radio-group>
        </span>
        <span style="height: 70px;" fxFlex="50" fxLayoutAlign="end">
          <mat-form-field fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput placeholder="" maxlength="100">
          </mat-form-field>
        </span>
      </div>


        <div fxLayout="column" class="table-pad">
          <div class="example-container mat-elevation-z1">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
              </ng-container>

              <ng-container matColumnDef="ticketno">
                <th mat-header-cell *matHeaderCellDef> Ticket ID </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.ticketCode}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="issuedate">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Raised date </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span>{{row.issueDate | date:'dd-MM-yyyy'}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="module">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Module </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span>{{row.module}} </span>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="screen">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Screen </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span>{{row.screen}} </span>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.ticketStatus}} </span>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Details</th>
                <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
                  <button mat-icon-button matTooltip="View" (keyup.enter)="editEmployee(row)"    >
                    <mat-icon (click)="editEmployee(row)">visibility</mat-icon>
                  </button>
              </td>
              </ng-container>
  
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
          </div>
        </div>
     </div>
  
    </mat-card>

</div>