import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import * as jsPDF from 'jspdf';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
// import jspdf from 'jspdf';
@Injectable({
  providedIn: 'root',
})
export class PayrollService {
  mainBeUrl = environment.apiUrl;
  userSession: any;
  companyName: any;
  httpOptions: any;
  doc: any = new jsPDF();

  constructor(private http: HttpClient) {
    this.companyName = sessionStorage.getItem('companyName')
      ? sessionStorage.getItem('companyName')
      : null;
    this.httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        Authorization: JSON.parse(
          JSON.stringify(sessionStorage.getItem('token') || '')
        ),
      }),
    };
  }
  // getEmployeeAttendanceNotifications(data:any):Observable<any>{
  //   data.companyName =  this.companyName;
  //     return this.http.post(this.mainBeUrl +'attendance/api/getEmployeeAttendanceNotifications',data,this.httpOptions)
  // }
  getesidetails(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getesidetails/' + this.companyName,
      this.httpOptions
    );
  }
  getemployerprofessionaltax(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/employerprofessionaltax/' + this.companyName,
      this.httpOptions
    );
  }
  getemployeeprofessionaltax(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/employeeprofessionaltax/' + this.companyName,
      this.httpOptions
    );
  }
  setincomegroup(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setincomegroup',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getearningsalarycomponent(data: any): Observable<any> {
    return this.http.post(
      this.mainBeUrl +
      'api/getearningsalarycomponent/' +
      data +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getdeductionsalarycomponent(data: any): Observable<any> {
    return this.http.post(
      this.mainBeUrl +
      'api/getdeductionsalarycomponent/' +
      data +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getpayrollsections(): Observable<any> {
    return this.http.post(
      this.mainBeUrl + 'api/getpayrollsections/' + this.companyName,
      this.httpOptions
    );
  }
  getsalarycomponentsforpaygroup(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getsalarycomponentsforpaygroup',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getpayrollincomegroups(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getpayrollincomegroups/' + this.companyName,
      this.httpOptions
    );
  }
  getEmployeeProfessionalTaxDetails(): Observable<any> {
    return this.http.post(
      this.mainBeUrl + 'api/getEmployeeProfessionalTaxDetails',
      this.httpOptions
    );
  }
  getErrorMessages(errorCode: any, page: any, size: any): Observable<any> {
    this.companyName = sessionStorage.getItem('companyName')
      ? sessionStorage.getItem('companyName')
      : null;
    return this.http.get(
      this.mainBeUrl +
      'payroll/api/getErrorMessages/' +
      errorCode +
      '/' +
      page +
      '/' +
      size +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  setErrorMessages(info: any): Observable<any> {
    // info.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'payroll/api/setErrorMessages/' + this.companyName,
      JSON.stringify(info),
      this.httpOptions
    );
  }
  getEmployeeDurationsForSalaryDisplay(userid: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeeDurationsForSalaryDisplay/' +
      userid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEmployeeDurationsForSalaryDisplayForCTC(userid: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeeCtcDurations/' +
      userid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getCtcDetails(eid: any, ctcid: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getCtcDetails/' +
      eid +
      '/' +
      ctcid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEmployeeInvestments(empid: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeeInvestments/' +
      empid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  setEmployeeInvestments(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setEmployeeInvestments',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getComponentEditableConfigurations(id: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getComponentEditableConfigurations/' +
      id +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  configurePayGroupComponent(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/configurePayGroupComponent/',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  editPayGroupComponent(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/editPayGroupComponent/',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getPayGroupComponentValues(id: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getPayGroupComponentValues/' +
      id +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEmployeesListForInvestmentsApproval(): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeesListForInvestmentsApproval/' +
      this.companyName,
      this.httpOptions
    );
  }
  // getEmployeeEpfContributionOptions
  getEmployeeEpfContributionOptions(): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeeEpfContributionOptions/' +
      this.companyName,
      this.httpOptions
    );
  }
  // getEmployerEpfContributionOptions
  getEmployerEpfContributionOptions(): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployerEpfContributionOptions/' +
      this.companyName,
      this.httpOptions
    );
  }
  //setCompanyEpfValues
  setCompanyEpfValues(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setCompanyEpfValues/',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  //getStatutoryMaxPfWageForEmployerContribution
  getStatutoryMaxPfWageForEmployerContribution(): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getStatutoryMaxPfWageForEmployerContribution/' +
      this.companyName,
      this.httpOptions
    );
  }
  // updateEpfWages
  updateEpfWages(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/updateEpfWages/',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  // calculateMonthlyEpfValues
  calculateMonthlyEpfValues(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/calculateMonthlyEpfValues/',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  deleteEmployeeInvestments(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/deleteEmployeeInvestments/',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  // //getFilepathsMaster
  // getFilepathsMaster(moduleId:any):Observable<any>{
  //     return this.http.get(this.mainBeUrl + 'api/getFilepathsMaster/'+moduleId, this.httpOptions);
  // }
  // setFilesMaster(info:any): Observable<any> {
  //     return this.http.post(this.mainBeUrl + 'api/setFilesMaster/', info, this.httpOptions);
  // }
  // setProfileImage(data: FormData,path:any): Observable<any> {

  //     return this.http.post(this.mainBeUrl + 'api/setProfileImage/'+encodeURI(path), data);
  // }

  getFileMasterForEMS(input: any) {
    input.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'ems/api/getFileMasterForEMS',
      JSON.stringify(input),
      this.httpOptions
    );
  }

  getFilecategoryMasterForEMS(input: any) {
    input.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'ems/api/getFilecategoryMasterForEMS',
      JSON.stringify(input),
      this.httpOptions
    );
  }
  getFilepathsMasterForEMS(moduleId: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'ems/api/getFilepathsMasterForEMS/' +
      moduleId +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }

  setFilesMasterForEMS(info: any): Observable<any> {
    info.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'ems/api/setFilesMasterForEMS/',
      info,
      this.httpOptions
    );
  }
  setDocumentOrImageForEMS(data: FormData, path: any): Observable<any> {
    return this.http.post(
      this.mainBeUrl +
      'ems/api/setDocumentOrImageForEMS/' +
      encodeURI(path) +
      '/' +
      this.companyName,
      data,
      this.httpOptions
    );
  }
  getDocumentsForEMS(input: any) {
    input.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'ems/api/getDocumentsForEMS',
      JSON.stringify(input),
      this.httpOptions
    );
  }
  getDocumentOrImagesForEMS(info: any): Observable<any> {
    info.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'ems/api/getDocumentOrImagesForEMS/',
      info,
      this.httpOptions
    );
  }
  removeDocumentOrImagesForEMS(info: any): Observable<any> {
    return this.http.delete(
      this.mainBeUrl +
      'ems/api/removeDocumentOrImagesForEMS/' +
      encodeURI(info),
      this.httpOptions
    );
  }
  deleteFilesMaster(id: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'ems/api/deleteFilesMaster/' +
      id +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getCompanyPaySchedule(): Observable<any> {
    this.companyName = sessionStorage.getItem('companyName')
      ? sessionStorage.getItem('companyName')
      : null;
    return this.http.get(
      this.mainBeUrl + 'api/getCompanyPaySchedule/' + this.companyName,
      this.httpOptions
    );
  }
  setCompanyPaySchedule(input: any) {
    input.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setCompanyPaySchedule',
      JSON.stringify(input),
      this.httpOptions
    );
  }
  updateMonthlySalary(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/updateMonthlySalary',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getFinancialYears(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getFinancialYears/' + this.companyName,
      this.httpOptions
    );
  }
  Month_Year(Month_Year: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/MonthYear/' + Month_Year + '/' + this.companyName,
      this.httpOptions
    );
  }
  getEpfDetails(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getEpfDetails/' + this.companyName,
      this.httpOptions
    );
  }
  getEmployeeListForSalaryProcessing(Month: any, year: any, location_id: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getEmployeeListForSalaryProcessing/' + Month + '/' + year + '/' + location_id + '/' + this.companyName, this.httpOptions);
  }
  getEmployeesForAssignPaygroup(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getEmployeesForAssignPaygroup/' + this.companyName,
      this.httpOptions
    );
  }
  getPayGroupsForCtc(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getPayGroupsForreassignCtc',
      JSON.stringify(data),
      this.httpOptions
    );
    // return this.http.get(this.mainBeUrl + 'api/getPayGroupsForCtc/'+data.ctc+'/'+data.companyName,this.httpOptions);
  }
  getActiveComponentsValuesForPayGroup(id: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getActiveComponentsValuesForPayGroup/' +
      id +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  assignPayGroup(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/assignPayGroup',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getComponentWiseValuesForPayGroupAssignment(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getComponentWiseValuesForPayGroupAssignment/' +
      data.CTC +
      '/' +
      data.paygroupid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEmployeePaySlips(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeePaySlips/' +
      data.fyear +
      '/' +
      data.id +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEmployeePayslipDetails(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeePayslipDetails/' +
      data.id +
      '/' +
      data.empid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEmployeeEpfDetails(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeeEpfDetails/' +
      data +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getMonthlyPayrollData(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getMonthlyPayrollData/' +
      data.month +
      '/' +
      data.year +
      '/' +
      data.deptid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getMonthlyPayrollDataForGraph(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getMonthlyPayrollDataForGraph/' +
      data.month +
      '/' +
      data.year +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getComponentConfiguredValuesForPayGroup(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getComponentConfiguredValuesForPayGroup/' +
      data.pgmid +
      '/' +
      data.flat +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  otherAllowancePopup(pgid: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/otherAllowancePopup/' +
      pgid +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getStatesForEsi(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getStateForEsi/' + this.companyName,
      this.httpOptions
    );
  }
  setEsiForState(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setEsiForState',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  setCompanyEsiValues(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setCompanyEsiValues',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getCompanyEsiValues(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getCompanyEsiValues/' + this.companyName,
      this.httpOptions
    );
  }
  getEsiEmployerContribution(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getEsiEmployerContribution/' + this.companyName,
      this.httpOptions
    );
  }
  getValidateSalaryProcessingDate(month: any, year: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/validateSalaryProcessingDate/' +
      month +
      '/' +
      year +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEpfValuesForChallan(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEpfValuesForChallan',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getESIValuesForChallan(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getESIValuesForChallan',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getProfessionalTaxValuesForChallan(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getProfessionalTaxValuesForChallan',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  monthlyPayrollReportChallan(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/monthlyPayrollReportChallan',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  getStatesForProfessionalTax(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getStatesForProfessionalTax',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  validateEpfChallanDownload(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/validateEpfChallanDownload',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  validateEsiChallanDownload(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/validateEsiChallanDownload',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  validateSalaryChallanDownload(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/validateSalaryChallanDownload',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getSalaryProcessedEmployeeList(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getSalaryProcessedEmployeeList',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getEmployeeEsiDetails(data: any): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getEmployeeEsiDetails/' +
      data +
      '/' +
      this.companyName,
      this.httpOptions
    );
  }
  getEmployeesForChangePayGroup(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getEmployeesForChangePayGroup/' + this.companyName,
      this.httpOptions
    );
  }
  getPayGroupsForCtcReassign(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getPayGroupsForreassignCtc',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  modifyCtcOfAnEmployee(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/modifyCtcOfAnEmployee',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  validateCtcWithPayGroupLimit(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/validateCtcWithPayGroupLimit',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  getEmployeesLeaveBalanceReport(): Observable<any> {
    return this.http.get(this.mainBeUrl + 'api/getLeaveBalanceReport/' + this.companyName, this.httpOptions);
  }

  /**Advance Salary  request and approve Service calls */
  setEmployeeAdvanceSalaryRequestDetails(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setEmployeeAdvanceSalaryRequestDetails',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  setEmployeeAdvanceSalaryApprovalByManager(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setEmployeeAdvanceSalaryApprovalByManager',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getEmployeeAdvanceSalaryRequests(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEmployeeAdvanceSalaryRequests',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getAdvSalaryValuesForEmployee(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getAdvSalaryValuesForEmployee',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  validateAdvanceSalaryStatus(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/validateAdvanceSalaryStatus',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getEmployeeAdvanceSalaryDetails(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEmployeeAdvanceSalaryDetails',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  setOtherDeduction(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setOtherDeduction',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getOtherDeductions(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getOtherDeductions',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  getActiveOtherDeductions(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getActiveOtherDeductions/' + this.companyName,
      this.httpOptions
    );
  }
  getActivePayGroupsData(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getActivePayGroupsData/' + this.companyName,
      this.httpOptions
    );
  }
  setOtherDeductionPayGroupMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setOtherDeductionPayGroupMapping',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getOtherDeductionPayGroupMappingDetails(): Observable<any> {
    return this.http.get(
      this.mainBeUrl +
      'api/getOtherDeductionPayGroupMappingDetails/' +
      this.companyName,
      this.httpOptions
    );
  }
  getProfessionalTaxDetailReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getProfessionalTaxDetailReport',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getComponentWiseSalaryDetails(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/getComponentWiseSalaryDetails', JSON.stringify(data),
      this.httpOptions
    );
  }
  generatePDF(columns: string[], data: any[], fileName: string) {
    const doc: any = new jsPDF({
      orientation: 'landscape', // Set the orientation to landscape
    });

    doc.text('Payroll Detail Report', 20, 10);
    doc.autoTable({
      head: [columns],
      body: data.map((row) => columns.map((col) => row[col])),
    });

    doc.save(`${fileName}.pdf`);
  }
  setBankAccountsMaster(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setBankAccountsMaster',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  getBankAccounts(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getBankAccounts',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  getPayrollBankPaymentsMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getPayrollBankPaymentsMapping',
      data,
      this.httpOptions
    );
  }

  setPayrollBankPaymentsMapping(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setPayrollBankPaymentsMapping',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  /*** get Payroll Bank Payments Mapping Search */
  getPayrollBankPaymentsMappingSearch(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getPayrollBankPaymentsMappingSearch',
      data,
      this.httpOptions
    );
  }

  /*** get Employee Salaries By Pay Groups */
  getEmployeeSalariesByPayGroups(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEmployeeSalariesByPayGroups',
      data,
      this.httpOptions
    );
  }

  /** get employee details by paygroups*/
  getEmployeeDetailsByPaygroup(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/payroll/getEmployeeDetailsByPaygroup',
      data,
      this.httpOptions
    );
  }

  /**get overtime company setting */
  getOvertimeCompanySetting(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getOvertimeCompanySetting/' + this.companyName,
      this.httpOptions
    );
  }

  /**getPayrollClientOtSlabs */
  getPayrollClientOtSlabs(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getPayrollClientOtSlabs/' + this.companyName,
      this.httpOptions
    );
  }

  /**setPayrollClientOtSlabs */

  setPayrollClientOtSlabs(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setPayrollClientOtSlabs',
      data,
      this.httpOptions
    );
  }

  /**getStateId */

  getStateId(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getStateId/' + this.companyName,
      this.httpOptions
    );
  }

  /**get Payroll Rules */
  getPayrollPolicies(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getPayrollPolicies/' + this.companyName,
      this.httpOptions
    );
  }

  /**set payroll Rule Values */
  setPayrollRuleValues(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/setPayrollRuleValues',
      data,
      this.httpOptions
    );
  }

  // getLeaveBalanceReportForReportsuser

  getLeaveBalanceReportForReportsuser(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/payroll/getLeaveBalanceReportForReportsuser',
      data,
      this.httpOptions
    );
  }

  // getEpfValuesForChallanForReportuser

  getEpfValuesForChallanForReportuser(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEpfValuesForChallanForReportuser',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  // getEsiValuesForChallanForReportsuser
  getEsiValuesForChallanForReportsuser(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEsiValuesForChallanForReportsuser',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  // getProfessionalTaxValuesForChallanForReportsuser
  getProfessionalTaxValuesForChallanForReportsuser(data: any) {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getProfessionalTaxValuesForChallanForReportsuser',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  // getProfessionalTaxDetailReportForReportsuser
  getProfessionalTaxDetailReportForReportsuser(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getProfessionalTaxDetailReportForReportsuser',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  // getComponentWisePayrollReportsForReportuser
  getComponentWisePayrollReportsForReportuser(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getComponentWisePayrollReportsForReportuser',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  // getIncomeTaxSections
  getIncomeTaxSections(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getIncomeTaxSections/',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  // getEmployeeTaxSavings

  getEmployeeTaxSavings(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEmployeeTaxSavings',
      JSON.stringify(data),
      this.httpOptions
    );
  }
  // getIncomeTaxCategory
  getIncomeTaxCategory(): Observable<any> {
    return this.http.get(
      this.mainBeUrl + 'api/getIncomeTaxCategory/' + this.companyName,
      this.httpOptions
    );
  }

  // getEmployeeSectionWiseSavings
  getEmployeeSectionWiseSavings(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getEmployeeSectionWiseSavings',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  // setPayrollEmployeeTaxSavings
  setPayrollEmployeeTaxSavings(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/setPayrollEmployeeTaxSavings', data, this.httpOptions
    );
  }

  getAdvanceSalaryReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(
      this.mainBeUrl + 'api/getAdvanceSalaryReport',
      JSON.stringify(data),
      this.httpOptions
    );
  }

  //  getReportsuserLocationsMapping
  getEmployeeWiseAdvanceSalaryReportAdmin(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/getEmployeeWiseAdvanceSalaryReportAdmin', data,this.httpOptions);
  }

    // getIncomeTaxCategory
    getPayableDaysConfiguration(): Observable<any> {
      return this.http.get( this.mainBeUrl + 'api/getPayableDaysConfiguration/' + this.companyName, this.httpOptions);
    }
  
  //  getReportsuserLocationsMapping
  getPayableDaysReport(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/getPayableDaysReport', data,this.httpOptions);
  }

    //  get Variable Pay Schedule
    getVariablePaySchedule(): Observable<any> {
      return this.http.get(this.mainBeUrl + 'api/getVariablePaySchedule/' + this.companyName,this.httpOptions);
    }
  setEmpTdsMonthlydeduction(data: any): Observable<any> {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/setEmpTdsMonthlydeduction', data, this.httpOptions
    );
  }
}
