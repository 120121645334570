<div fxLayout="column" class="first-col">
    <mat-card>

        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Pay Group Deduction Mapping </mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isdata">
                <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <form [formGroup]="deductionMappingForm" autocomplete="off">
            <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
                <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                    <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Deduction Name</mat-label>
                    <input formControlName="deduction" matInput placeholder="" appRemoveSpace required maxlength="64">
                    
                  <mat-error class="con-error" *ngIf="deductionMappingForm.controls.deduction.errors?.required ||deductionMappingForm.controls.deduction.errors?.['whitespace']">
                    {{msgEM1}}
                </mat-error>
                </mat-form-field> -->

                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Pay Group</mat-label>
                        <mat-select required formControlName="paygroup" required>
                            <ng-container>
                                <div *ngFor="let b of activePayGroupsData">
                                    <mat-option [value]="b.id">{{ b.group_name }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="deductionMappingForm.controls.paygroup.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Deduction </mat-label>
                        <mat-select required formControlName="deduction" required>
                            <ng-container>
                                <div *ngFor="let b of activeOtherDeductions">
                                    <mat-option [value]="b.id">{{ b.deduction_name}}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="deductionMappingForm.controls.deduction.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary custom mr-2 mb-2" [disabled]="btnDisable"
                        [ngClass]="{'disable':btnDisable}" (click)="setMapping()" type="submit">
                        <span>Add this Deduction</span>
                    </button>

                    <button class="btn btn-danger  mr-2 mb-2" (click)="cancel()">
                        <span>Clear</span>
                    </button>
                    <br />
                </div>

            </div>
        </form>
        <!-- Table -->
        <div fxLayout="column" *ngIf="isdata">
            <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                    <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                    </mat-form-field>
                </span>
            </div>
            <form [formGroup]="deductionMappingForm" autocomplete="off">
                <div fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1  example-container">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef><span style="text-wrap: nowrap;">S. No.</span></th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>
                            <ng-container matColumnDef="paygroupname">
                                <th mat-header-cell *matHeaderCellDef>Paygroup Name</th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.group_name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="deductionname">
                                <th mat-header-cell *matHeaderCellDef>Deduction Name</th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.deduction_name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="deductionamount">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Deduction Amount </th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.deduction_amount}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="effectivdate">
                                <th mat-header-cell *matHeaderCellDef>Effective Date</th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.effective_from_date | date:'dd-MM-yyyy'}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <!-- <td mat-cell *matCellDef="let row">
                            <a [routerLink]="[]" (click)="removeDeduction($event,row)"> Remove this deduction</a>
                     </td> -->
                                <td mat-cell *matCellDef="let row">
                                    <button mat-icon-button (keyup.enter)="removeDeduction($event,row)">
                                        <mat-icon (click)="removeDeduction($event,row)"
                                            title="Remove this deduction">cancel</mat-icon>
                                    </button>
                                </td>
                            </ng-container>


                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
                            </tr>
                        </table>

                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </form>
        </div>


    </mat-card>
</div>