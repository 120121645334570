import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CrmRoutingModule } from './crm-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular'; 
 import dayGridPlugin from '@fullcalendar/daygrid'; 
 import interactionPlugin from '@fullcalendar/interaction'; 
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';
import { CrmAddClientComponent } from './pages/crm-add-client/crm-add-client.component';
import { CrmUserDashboardComponent } from './pages/crm-user-dashboard/crm-user-dashboard.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CrmClientListComponent } from './pages/crm-client-list/crm-client-list.component';
import { CrmClientEditAndUpdateComponent } from './pages/crm-client-edit-and-update/crm-client-edit-and-update.component';
import { CrmDemoStatusComponent } from './pages/crm-demo-status/crm-demo-status.component';
import { CrmMastersForStatusComponent } from './pages/crm-masters-for-status/crm-masters-for-status.component';
import { CrmLiveTrackingComponent } from './pages/crm-live-tracking/crm-live-tracking.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CrmClientReportComponent } from './pages/crm-client-report/crm-client-report.component';
import { CrmSalesVisitReportComponent } from './pages/crm-sales-visit-report/crm-sales-visit-report.component';
import { CrmClientStatusMasterComponent } from './pages/crm-client-status-master/crm-client-status-master.component';
import { CrmRptcolumnsPopupComponent } from './pages/crm-rptcolumns-popup/crm-rptcolumns-popup.component';
import { CrmSearchLocationComponent } from './pages/crm-search-location/crm-search-location.component';
import { SummaryTrackingReportComponent } from './pages/summary-tracking-report/summary-tracking-report.component';
import { CrmDeviceApprovalsComponent } from './pages/crm-device-approvals/crm-device-approvals.component';
import { CrmDetailedTrackingReportComponent } from './pages/crm-detailed-tracking-report/crm-detailed-tracking-report.component';
import { CrmClientAssociateConfiguresComponent } from './pages/crm-client-associate-configures/crm-client-associate-configures.component';
import { CrmClientAssociateReportComponent } from './pages/crm-client-associate-report/crm-client-associate-report.component';
import { CrmAddTransactionComponent } from './pages/crm-add-transaction/crm-add-transaction.component';
import { GetLocationComponent } from './pages/get-location/get-location.component';
import { SharedModule } from 'src/app/shared/shared.module';
//import { CrmTravelApprovalsComponent } from './pages/crm-travel-approvals/crm-travel-approvals.component';

// module-name.module.ts

@NgModule({
  declarations: [/* list of components, directives, and pipes in this module */
    CrmAddClientComponent, 
    CrmUserDashboardComponent, 
   
    CrmClientListComponent, 
    CrmClientEditAndUpdateComponent, 
    CrmDemoStatusComponent, 
    CrmMastersForStatusComponent, 
    CrmLiveTrackingComponent, 
    CrmClientReportComponent, 
    CrmSalesVisitReportComponent, 
    CrmClientStatusMasterComponent,
     CrmRptcolumnsPopupComponent, 
    CrmSearchLocationComponent,
     SummaryTrackingReportComponent,
     CrmDeviceApprovalsComponent,
     CrmDetailedTrackingReportComponent,
     CrmClientAssociateConfiguresComponent,
     CrmClientAssociateReportComponent,
     CrmAddTransactionComponent,
     GetLocationComponent,
    // CrmTravelApprovalsComponent,
  ],
  imports: [CrmRoutingModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FlexLayoutModule,
    GoogleMapsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule
    ],
  exports: [/* list of components, directives, and pipes to be used outside this module */],
  providers: [/* list of services available within this module */],
})

export class CrmModule { }
