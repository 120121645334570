import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { UserData } from '../../models/EmployeeData';
import { AttendanceService } from '../../attendance.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-permissions-approve',
  templateUrl: './permissions-approve.component.html',
  styleUrls: ['./permissions-approve.component.scss'],
})
export class PermissionsApproveComponent implements OnInit {
  dataSource: MatTableDataSource<UserData> = <any>[];
  displayedColumns: string[] = [
    'id',
    'usage_date',
    'employee_code',
    'employee_name',
    'designation',
    'permission_duration',
    'action',
  ];
  pageLoading = true;
  userSession: any;
  isEdit: boolean = false;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  enable: any = null;
  permissionsForm: any = UntypedFormGroup;
  isSubmit = true;
  btnDisable: boolean = false;

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private AS: AttendanceService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getPermissionsForApproval(this.userSession.id);
    this.permissionsForm = this.formBuilder.group({
      permissionDuration: ['', [Validators.required]],
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }


  editRequest(id: any, data: any) {
    var min = data.permission_duration.split(':');
    let minutes = Number(min[0]) * 60 + Number(min[1]);
    this.isSubmit = false;
    this.permissionsForm.controls.permissionDuration.setValue(Number(minutes));
    this.enable = data.id;
  }

  acceptApproval(id: any, data: any) {
    if (this.btnDisable) {
      return;
    }
    let savedata = {
      id: data.id,
      employee_id_value: data.empid,
      usage_date_value: data.usage_date,
      permission_duration_value: data.duration,
      remarks_value: data.remarks,
      submission_status_value: 'Approved',
      applied_by_value: data.empid,
      approved_by_value: this.userSession.id,
    };
    this.btnDisable = true;
    this.AS.setEmployeePermission(savedata).subscribe((result: any) => {
      if (result.status && result.data[0].statuscode == 1) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Permission request is Approved successfully.',
        });
        this.navigationToPermissionApproveScreen();
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Unable to Approve.',
        });
        this.btnDisable = false;
      }
    });
  }

  rejectApproval(id: any, data: any) {
    if (this.btnDisable) {
      return;
    }
    let deleteddata = {
      id: data.id,
      employee_id_value: data.empid,
      usage_date_value: data.usage_date,
      permission_duration_value: data.duration,
      remarks_value: data.remarks,
      submission_status_value: 'Rejected',
      applied_by_value: data.empid,
      approved_by_value: this.userSession.id,
    };
    this.btnDisable = true;
    this.AS.setEmployeePermission(deleteddata).subscribe((res: any) => {
      if (res.status && res.data[0].statuscode == 2) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Permission request is rejected successfully.',
        });
        this.navigationToPermissionApproveScreen();
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Unable to reject.',
        });
        this.btnDisable = false;
      }
    });
  }

  saveApproval(data: any) {

    var min = data.permission_duration.split(':');
    let minutes = Number(min[0]) * 60 + Number(min[1]);
    if (
      minutes >= Number(this.permissionsForm.controls.permissionDuration.value)
    ) {
      if (this.btnDisable) {
        return;
      }
      let savedata = {
        id: data.id,
        employee_id_value: data.empid,
        usage_date_value: data.usage_date,
        permission_duration_value: Number(
          this.permissionsForm.controls.permissionDuration.value
        ),
        remarks_value: data.remarks,
        submission_status_value: 'Approved',
        applied_by_value: data.empid,
        approved_by_value: this.userSession.id,
      };
      this.btnDisable = true;
      this.AS.setEmployeePermission(savedata).subscribe((result: any) => {
        if (result.status && result.data[0].statuscode == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Permission request is edited and approved successfully.',
          });
          this.navigationToPermissionApproveScreen();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to edit.',
          });
          this.btnDisable = false;
        }
      });
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data:
          'Please enter permission duration less than ' + minutes + ' minutes.',
      });
      this.btnDisable = false;
    }
  }

  cancel() {
    this.navigationToPermissionApproveScreen();
  }
  getPermissionsForApproval(id: any) {
    let data = {
      manager_id: id,
    };
    this.AS.getPermissionsForApproval(data).subscribe((res: any) => {
      if (res.status) {
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    });
  }
  navigationToPermissionApproveScreen() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Attendance/permissionsApprove']));
  }
}
