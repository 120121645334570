<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header>
            <mat-card-title> Approval History </mat-card-title>
        </mat-card-header>
        <div fxLayout="column">
            <!-- <mat-divider></mat-divider> -->
            <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end"
                style="height: 70px;padding-top: 1%;">
                <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" [tabIndex]="1" placeholder="Search" #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <div class="tableP">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="no-wrap"> S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="applieddate">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Applied On </th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.applieddate  | date:'dd-MM-yyyy'}}</td>
                </ng-container> -->

                        <ng-container matColumnDef="worktype">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Work Type </th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.worktype}}</td>
                        </ng-container>
                        <ng-container matColumnDef="raisedbyname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp. Name </th>
                            <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                        </ng-container>
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef> Shift </th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.shift}}</td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="fromdate">
                            <th mat-header-cell *matHeaderCellDef> From Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.fromdate |
                                date:'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="todate">
                            <th mat-header-cell *matHeaderCellDef> To Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.todate |
                                date:'dd-MM-yyyy'}} </td>
                        </ng-container>
                        <!-- Color Column -->
                        <!-- <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Reason </th>
                    <td mat-cell *matCellDef="let row" style="width: 30%; word-break: break-all;padding-right: 20px;"> {{row.reason}} </td>
                </ng-container> -->
                        <!-- Color Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let row" [style.color]="row.status">
                                {{row.status}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button [tabIndex]="2" (keyup.enter)="changeTab(row)">
                                    <mat-icon class="mr-1" title="View" (click)="changeTab(row)">visibility</mat-icon>
                                </button>
                            </td>

                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                </div>
            </div>
        </div>
    </mat-card>
</div>