<div fxLayout="column" class="first-col">
    <mat-card fxLayout="column">
        <mat-card-header class="heading">
            <mat-card-title class="headingtext">Late Attendance Report</mat-card-title>
        </mat-card-header>
        <div fxLayout="row" fxLayout.lt-lg="column" [formGroup]="searchForm" style="padding:1%; margin-top: 10px; ">
            <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field fxFlex fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>From Date</mat-label>
                    <input matInput required [min]="minFromDate" [max]="maxFromDate" [matDatepicker]="fromDate"
                        placeholder="From Date" formControlName="fromDate" (click)="fromDate.open()" tabindex="1">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                    <mat-error class="con-error" *ngIf="searchForm.controls.fromDate.errors?.required">
                        Please select a date.
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>To Date</mat-label>
                    <input matInput required [min]="minToDate" [max]="maxToDate" [matDatepicker]="toDate"
                        placeholder="To Date" formControlName="toDate" (click)="toDate.open()" tabindex="2">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
                    <mat-error class="con-error" *ngIf="searchForm.controls.toDate.errors?.required">
                        Please select a date.
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxFlex.xs="100%" fxLayoutAlign="center" class="showLine" style="margin:8px">
                <span>
                    <button class="btn btn-primary mr-2 mb-2" (click)="searchform()" tabindex="3">
                        Search
                    </button>
                </span>
                <span>
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="4">
                        <span>Clear</span>
                    </button>
                </span>
            </div>
        </div>
        <div fxLayout="column">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"
                style="padding-top: 5px;height: 70px;">
                <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                    <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                    <mat-label>Search </mat-label>
                    <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100" />
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row xs-column" fxLayoutAlign="end center" *ngIf="attendanceReportList.length>0"
            style="margin-right:3%">
            <div>
                <span class="exlprint">|</span>
                <button mat-icon-button tabindex="5" (keyup.enter)="generatePDF()">
                    <a title="PDF"><img (click)="generatePDF()" src="./assets/images/icons/pdf.png" width="20px"
                            height="20px"></a>
                </button>
                <span class="exlprint">|</span>
                <button mat-icon-button tabindex="6" (keyup.enter)="exportAsXLSX()">
                    <a title="Excel"><img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png"></a>
                </button>
            </div>
        </div>
        <div fxLayout="column" class="table-pad">
            <div class="example-container mat-elevation-z1">
                <div id="table" #table=''>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="no-wrap">S. No.</th>
                            <td mat-cell *matCellDef="let row; let i = index;">{{i+1}}</td>
                        </ng-container>
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef>Shift</th>
                            <td mat-cell *matCellDef="let row;">{{row.shiftname}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Date">
                            <th mat-header-cell *matHeaderCellDef>Date</th>
                            <td mat-cell *matCellDef="let row;">{{row.attendancedate | date:'dd-MM-yyyy'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="shiftInTime">
                            <th mat-header-cell *matHeaderCellDef>Shift InTime</th>
                            <td mat-cell *matCellDef="let row;">{{row.shiftstarttime}}</td>
                        </ng-container>
                        <ng-container matColumnDef="InTime">
                            <th mat-header-cell *matHeaderCellDef>In Time</th>
                            <td mat-cell *matCellDef="let row;">{{row.actual_in_time}}</td>
                        </ng-container>
                        <ng-container matColumnDef="lateHours">
                            <th mat-header-cell *matHeaderCellDef>Late Hours</th>
                            <td mat-cell *matCellDef="let row;">{{row.latehours}}</td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>
    </mat-card>
</div>