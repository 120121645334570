import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, AbstractControl, FormArray, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmsService } from '../../ems.service';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AnnouncementsComponent implements OnInit {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text ",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };
  pipe = new DatePipe('en-US');
  announcementForm: any = UntypedFormGroup;
  designations: any = [];
  min: any = new Date();
  max: any = new Date();
  issubmit: boolean = false;
  isview: boolean = true;
  ishide: boolean = false;
  isedit: boolean = false;
  ispublish: boolean = true;
  isrepublish: boolean = false;
  isviewdata: boolean = false;
  iseditingdata: boolean = false;
  announcementlist: any = [];
  pageLoading = true;
  mindate: any;
  istodate: boolean = true;
  minAnounceDate: any = new Date();

  issavedraft: boolean = true;
  displayedColumns: string[] = ['sno', 'topic', 'title', 'publishedon', 'publishedTo', 'status', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  userSession: any;
  EM1: any;
  EM2: any;
  EM3: any;
  EM24: any;
  EM25: any;
  EM30: any;
  EM31: any;
  EM32: any;
  EM33: any;
  EM44: any;
  EM45: any;
  filteredList: any = [];
  searchControl = new FormControl('');

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private ES: EmsService, public dialog: MatDialog, private EM: AdminService) { }
  btnDisable:boolean = false;
  ngOnInit(): void {
    this.getAnnouncements();
    this.getActiveAnnouncementsTopics();
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getMessageList();
    this.announcementForm = this.formBuilder.group(
      {
        topic: ["", Validators.required],
        title: ["", [Validators.required]],
        description: ["", Validators.required],
        fromdate: ["", Validators.required],
        todate: ["", Validators.required],
        id: [null,],
      });

    this.announcementForm.get('fromdate')?.valueChanges.subscribe((selectedValue: any) => {
      this.istodate = false;
      this.minAnounceDate = selectedValue._d == undefined ? this.minAnounceDate : selectedValue._d;
      this.announcementForm.controls.todate.setValue('');
    })
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterList(searchText)
    })
  }

  Add() {
    this.ishide = true;
    this.isview = false;
  }
  close() {
    this.ishide = false;
    this.isview = true;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Admin/announcement"]));
  }
  edit(event: any, data: any) {
    this.minAnounceDate = new Date(data.fromdate)
    this.iseditingdata = true;
    if (data.status == 'Published') {
      this.issavedraft = false;
      this.ispublish = true;
    }
    else {
      this.issavedraft = true;
      this.ispublish = true;

    }
    this.ishide = true;
    this.isview = false;
    this.isedit = false;
    this.isviewdata = false;

    // this.announcementForm.controls.topic.setvalue(data.topicid)
    this.announcementForm.controls.topic.setValue(data.topicid);
    this.announcementForm.controls.title.setValue(data.title);
    this.announcementForm.controls.fromdate.setValue(new Date(data.fromdate));
    this.announcementForm.controls.todate.setValue(new Date(data.todate));
    this.announcementForm.controls.description.setValue(data.description);
    this.announcementForm.controls.id.setValue(data.id)
  }
  delete(event: any, data: any) {

    let deletedata = {
      id: data.id,
      topicid: data.topicid,
      title: data.title,
      fromdate: this.pipe.transform(data.fromdate, 'yyyy-MM-dd'),
      todate: this.pipe.transform(data.todate, 'yyyy-MM-dd'),
      description: data.description,
      status: 'Deleted',
      actionby: this.userSession.id

    }
    this.ES.setAnnouncements(deletedata).subscribe((res: any) => {

      if (res.status) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/Admin/announcement"]));
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Announcement deleted successfully'
        });

      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Unable to delete announcement.'
        });

      }


    })

  }
  getActiveAnnouncementsTopics() {
    this.ES.getActiveAnnouncementsTopics().subscribe((res: any) => {
      if (res.status) {
        this.announcementlist = res.data;
        this.filteredList = this.announcementlist
      }
    })

  }
  getAnnouncements() {
    this.ES.getAnnouncements(null).subscribe((res: any) => {
      if (res.status) {
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })

  }
  submit() {
    this.issubmit = true;
    if (this.announcementForm.valid) {
      if (this.btnDisable) {
        return;
      }
      let data = {
        id: this.announcementForm.controls.id.value,
        topicid: this.announcementForm.controls.topic.value,
        title: this.announcementForm.controls.title.value,
        fromdate: this.pipe.transform(this.announcementForm.controls.fromdate.value, 'yyyy-MM-dd'),
        todate: this.pipe.transform(this.announcementForm.controls.todate.value, 'yyyy-MM-dd'),
        // description:"hi",
        description: this.announcementForm.controls.description.value,
        status: 'Published',
        actionby: this.userSession.id

      }
      this.btnDisable = true;
      this.ES.setAnnouncements(data).subscribe((res: any) => {
        if (this.iseditingdata) {
          if (res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Admin/announcement"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Announcement Updated successfully'
            });

          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to update announcement.'
            });
            this.btnDisable = false;
          }

        }
        else {
          if (res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Admin/announcement"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM24
            });

          }
          else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM25
            });
            this.btnDisable = false;
          }
        }

      })

    }


  }
  draft() {
    this.issubmit = true;
    if (this.announcementForm.valid) {
      if (this.btnDisable) {
        return;
      }
      let data = {
        id: this.announcementForm.controls.id.value,
        topicid: this.announcementForm.controls.topic.value,
        title: this.announcementForm.controls.title.value,
        fromdate: this.pipe.transform(this.announcementForm.controls.fromdate.value, 'yyyy-MM-dd'),
        todate: this.pipe.transform(this.announcementForm.controls.todate.value, 'yyyy-MM-dd'),
        // description:"hi",
        description: this.announcementForm.controls.description.value,
        status: 'Saved as draft',
        actionby: this.userSession.id

      }
      this.btnDisable = true;
      this.ES.setAnnouncements(data).subscribe((res: any) => {
        if (this.isedit) {
          if (res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Admin/announcement"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM30
            });

          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM31
            });
            this.btnDisable = false;
          }

        }
        else {
          if (res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Admin/announcement"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM32
            });

          }
          else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM33
            });
            this.btnDisable = false;
          }
        }

      })

    }


  }
  stop(event: any, data: any) {

    let deletedata = {
      id: data.id,
      topicid: data.topicid,
      title: data.title,
      fromdate: this.pipe.transform(data.fromdate, 'yyyy-MM-dd'),
      todate: this.pipe.transform(data.todate, 'yyyy-MM-dd'),
      description: data.description,
      status: 'Cancelled',
      actionby: this.userSession.id

    }
    this.ES.setAnnouncements(deletedata).subscribe((res: any) => {

      if (res.status) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/Admin/announcement"]));
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.EM44
        });

      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.EM45
        });

      }

    })

  }
  republish(event: any, data: any) {
    if (data.status == 'Publish') {
      this.issavedraft = false;
      this.ispublish = false;
      this.isrepublish = true;
    }
    else {
      this.issavedraft = false;
      this.ispublish = true;
      this.isrepublish = false;

    }

    this.ishide = true;
    this.isview = false;
    this.isedit = false;
    this.isviewdata = false;
    this.iseditingdata = true

    // this.announcementForm.controls.topic.setvalue(data.topicid)
    this.announcementForm.controls.topic.setValue(data.topicid);
    this.announcementForm.controls.title.setValue(data.title);
    this.announcementForm.controls.fromdate.setValue('');
    this.announcementForm.controls.todate.setValue('');
    this.announcementForm.controls.description.setValue(data.description);
    this.announcementForm.controls.id.setValue(data.id)
  }
  view(event: any, data: any) {
    this.ishide = true;
    this.isview = false;
    this.isedit = false;
    this.isviewdata = true;
    this.ispublish = false;
    this.isrepublish = false;
    this.issavedraft = false;
    this.iseditingdata = true

    // this.announcementForm.controls.topic.setvalue(data.topicid)
    this.announcementForm.controls.topic.setValue(data.topicid);
    this.announcementForm.controls.title.setValue(data.title);
    this.announcementForm.controls.fromdate.setValue(new Date(data.fromdate));
    this.announcementForm.controls.todate.setValue(new Date(data.todate));
    this.announcementForm.controls.description.setValue(data.description);
    this.announcementForm.controls.id.setValue(data.id)
  }
  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  getMessageList() {
    let info = {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.EM.getEMSMessagesList(info).subscribe((result: any) => {
      if (result && result.status) {
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].code == 'EM1') {
            this.EM1 = result.data[i].message;

          } else if (result.data[i].code == 'EM2') {
            this.EM2 = result.data[i].message;

          } else if (result.data[i].code == 'EM3') {
            this.EM3 = result.data[i].message;


          }

          else if (result.data[i].code == 'EM24') {
            this.EM24 = result.data[i].message;


          }
          else if (result.data[i].code == 'EM25') {
            this.EM25 = result.data[i].message;

          }
          else if (result.data[i].code == 'EM30') {
            this.EM30 = result.data[i].message;


          } else if (result.data[i].code == 'EM31') {
            this.EM31 = result.data[i].message;


          }
          else if (result.data[i].code == 'EM32') {
            this.EM32 = result.data[i].message;


          } else if (result.data[i].code == 'EM33') {
            this.EM33 = result.data[i].message;


          }
          else if (result.data[i].code == 'EM44') {
            this.EM44 = result.data[i].message;


          } else if (result.data[i].code == 'EM45') {
            this.EM45 = result.data[i].message;


          }
        }

      }

    })

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  filterList(searchText: any) {
    this.filteredList = this.announcementlist.filter((val: any) =>
      val.topic.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredList.length <= 0) {
      this.searchControl.setValue('');
    }
  }

}
