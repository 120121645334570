<div fxLayout="column" class="first-col"fxFlex="100" >
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Pending Approvals </mat-card-title>
              <!-- <div style="margin-right: 2%;" >
                 <button mat-icon-button style="padding-right: 40px;"(click)="Add()"  matTooltip="Add New">
                      <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                    </button>
                 </div> -->
         </mat-card-header>

        <!-- <form [formGroup]="requestForm" autocomplete="off"  fxLayout="column" *ngIf="isAdd">
            <div  fxLayout="row" fxFlex="100%">
                <mat-form-field  fxFlex="25%"  class="mx-2 my-2" appearance="outline"  style="padding-top: 1%;padding-left: 1%;">
                    <mat-label>Employee Id </mat-label>
                    <input formControlName="emp_id"  [disabled]= "true"  matInput placeholder="" required >
                </mat-form-field>

                <mat-form-field  fxFlex="25%"  class="mx-2 my-2" appearance="outline"  style="padding-top: 1%;padding-left: 1%;">
                    <mat-label>Employee Name </mat-label>
                    <input formControlName="emp_name"  [disabled]= "true"  matInput placeholder="" required >
                </mat-form-field>

                <mat-form-field  fxFlex="25%"  class="mx-2 my-2" appearance="outline" aria-disabled="true"  style="padding-top: 1%;padding-left: 1%;">
                    <mat-label>Request Date</mat-label>
                    <input matInput [matDatepicker]="applied_on"  [disabled]= "isView" placeholder="" formControlName="applied_on"  (keypress)="false" required>
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="applied_on"></mat-datepicker-toggle>
                    <mat-datepicker #applied_on></mat-datepicker>
                </mat-form-field>

                <mat-form-field  fxFlex="25%" class="mx-2 my-2" appearance="outline"  style="padding-top: 1%;padding-left: 1%;">
                    <mat-label>Reimbursement Type</mat-label>
                        <mat-select required formControlName="rbs_type_id">
                        <div *ngFor="let b of rbsData"> <mat-option [value]="b.id">{{ b.rbs_type }}</mat-option> </div>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="requestForm.controls.rbs_type_id.errors?.required"> Please select Reimbursement Type </mat-error>
                </mat-form-field>

                <mat-form-field  fxFlex="25%"  class="mx-2 my-2" appearance="outline" aria-disabled="true"  style="padding-top: 1%;padding-left: 1%;">
                    <mat-label>Bill Date</mat-label>
                    <input matInput [matDatepicker]="bill_date"  [disabled]= "isView"  [min]="minDate" placeholder="" formControlName="bill_date"  (keypress)="false" required>
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="bill_date"></mat-datepicker-toggle>
                    <mat-datepicker #bill_date></mat-datepicker>
                </mat-form-field>
                    
                <mat-form-field  fxFlex="25%"  class="mx-2 my-2" appearance="outline"  style="padding-top: 1%;padding-left: 1%;">
                    <mat-label>Bill Number </mat-label>
                    <input formControlName="bill_number"  [disabled]= "isView"  matInput placeholder="" appRemoveSpace required maxlength="64">
                    <mat-error class="con-error" *ngIf="requestForm.controls.bill_number.errors?.required ||requestForm.controls.bill_number.errors?.['whitespace']">
                        Please enter bill number
                    </mat-error>
                </mat-form-field>
                
                <mat-form-field  fxFlex="25%"  class="mx-2 my-2" appearance="outline"  style="padding-top: 1%;padding-left: 1%;">
                    <mat-label>Bill Amount </mat-label>
                    <input formControlName="bill_amount"  [disabled]= "isView"  matInput placeholder="" appRemoveSpace required maxlength="64">
                    <mat-error class="con-error" *ngIf="requestForm.controls.bill_amount.errors?.required ||requestForm.controls.bill_amount.errors?.['whitespace']">
                        Please enter amount
                    </mat-error>
                </mat-form-field>
                
            
            </div>
            <div fxLayout="row" fxFlex="100%">
                
                    <mat-form-field  fxFlex="100%" class="mx-2 my-2" appearance="outline"  style="padding-top: 1%;padding-left: 1%;">
                        <mat-label>Description</mat-label>
                        <textarea formControlName="notes"  [disabled]= "isView"  matInput maxlength="250" placeholder="Description"></textarea>
                    <mat-error class="con-error" *ngIf="requestForm.controls.notes.errors?.required ||requestForm.controls.notes.errors?.['whitespace']">
                        Please enter notes
                    </mat-error>
                    </mat-form-field>
              
            </div>
                 
            <div fxLayout="row" style="align-items: center;">
                <span *ngIf="pdfName" class="no-wrap" style="margin-left: 7px;">Upload File:</span>&nbsp;
                <button class="toolbar-button" ngClass.lt-md="mob_file" mat-button (click)="fileView();" class="link" style="font-size: 16px;text-align: start;" *ngIf="pdfName">
                   {{ pdfName }}
                </button>
                &nbsp;
                  <input *ngIf="!pdfName" style="margin-left: 0px" type="file" formControlName="document"
                    (change)="onSelectFile($event)" single />
                  &nbsp;
                  <mat-icon class="toolbar-button" mat-button title="Edit" *ngIf="pdfName && !isRequestView" (click)="editdoc()">edit</mat-icon>
                  &nbsp;
                    <span>
                  <mat-icon title="Delete" *ngIf="requestForm.controls.document.value && iseditDoc"
                    (click)="deleteIcon()">delete</mat-icon>
                </span>
                  &nbsp;
            </div> 
            <div >    
                <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center"  style="padding-top: 1%;padding-left: 1%;">
                    <div fxLayoutAlign="center" *ngIf="isAdd">
                        <button class="btn btn-primary mr-2 mb-2" [disabled]= "isView" (click)="AddData()" type="submit"><span>Submit</span></button>
                        <button class="btn btn-danger mr-2 mb-2"   (click)="cancel()"><span >Cancel</span></button>
                        <br />
                    </div>
                </div>
            </div>
        </form> -->
        <form [formGroup]="requestForm"   fxLayout="column" *ngIf="isAdd">
            <div  fxLayout="row" fxFlex="100%">
                <div  fxFlex="40%" class="mx-2 my-2" >
                    <mat-form-field appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Employee Id  </mat-label>
                        <input formControlName="emp_code"    matInput placeholder="" appRemoveSpace required maxlength="50">
                    </mat-form-field>
                 
                </div>
                <div  fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Employee Name  </mat-label>
                        <input formControlName="emp_name"    matInput placeholder="" appRemoveSpace required maxlength="50">
                    </mat-form-field>
                </div>
                
                <div  fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field appearance="outline" aria-disabled="true"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Applied On</mat-label>
                        <input matInput [matDatepicker]="applied_on" placeholder="" formControlName="applied_on"  (keypress)="false" required>
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="applied_on"></mat-datepicker-toggle>
                        <mat-datepicker #applied_on></mat-datepicker>
                            <!-- <mat-error class="con-error" *ngIf="requestForm.controls.applied_on.errors?.required">
                                Please enter bill date
                            </mat-error> -->
                    </mat-form-field>
                </div>
                    
            </div>    
            <div  fxLayout="row" fxFlex="100%"> 
                <div  fxFlex="40%" class="mx-2 my-2" >
                    <mat-form-field  appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Reimbursement Type</mat-label>
                            <mat-select required formControlName="rbs_type_id">
                            <div *ngFor="let b of rbsData"> <mat-option [value]="b.id" >{{ b.rbs_type }}</mat-option> </div>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="requestForm.controls.rbs_type_id.errors?.required"> Please select Reimbursement Type </mat-error>
                    </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field  appearance="outline" aria-disabled="true"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Bill Date</mat-label>
                        <input matInput [matDatepicker]="bill_date"    [min]="minDate" placeholder="" formControlName="bill_date"  (keypress)="false" required>
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="bill_date"></mat-datepicker-toggle>
                        <mat-datepicker #bill_date></mat-datepicker>
                            <mat-error class="con-error" *ngIf="requestForm.controls.bill_date.errors?.required">
                                Please enter bill date
                            </mat-error>
                        </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2" >

                </div>
            </div>
            <div  fxLayout="row" fxFlex="100%"> 
                <div fxFlex="40%" class="mx-2 my-2" >
                    <mat-form-field appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Bill Number </mat-label>
                        <input formControlName="bill_number"    matInput placeholder="" appRemoveSpace required maxlength="50">
                        <mat-error class="con-error" *ngIf="requestForm.controls.bill_number.errors?.required ||requestForm.controls.bill_number.errors?.['whitespace']">
                            Please enter bill number
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field  appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Bill Amount </mat-label>
                        <input formControlName="bill_amount"    matInput placeholder="" appRemoveSpace required maxlength="64">
                        <mat-error class="con-error" *ngIf="requestForm.controls.bill_amount.errors?.required ||requestForm.controls.bill_amount.errors?.['whitespace']">
                            Please enter amount
                        </mat-error>
                    </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field  appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Approve Amount </mat-label>
                        <input formControlName="approved_amount" matInput placeholder="" >
                    </mat-form-field>
                </div>
                 
            </div>
            <div fxLayout="row" fxFlex="100%">
                <div  fxFlex="70%" class="mx-2 my-2" >
                    <mat-form-field   appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Description</mat-label>
                        <textarea formControlName="notes" noLeadingWhitespace   matInput maxlength="250" placeholder="Description"></textarea>
                    <mat-error class="con-error" *ngIf="requestForm.controls.notes.errors?.required">
                        Please enter notes
                    </mat-error>
                    </mat-form-field>
                </div>
                <div  fxFlex="30%" class="mx-2 my-2" >
                    <mat-form-field appearance="outline"  style="padding-top: 1%;padding-left: 1%;width: 100%;">
                        <mat-label>Verify Comments</mat-label>
                        <textarea formControlName="verify_comments"  matInput  placeholder="Comments"></textarea>
                    </mat-form-field>
                </div>
            </div>
                 
            <div fxLayout="row" style="align-items: center;">
                <span *ngIf="pdfName" class="no-wrap" style="margin-left: 7px;">Upload File:</span>&nbsp;
                <button class="toolbar-button" ngClass.lt-md="mob_file" mat-button (click)="fileView();" class="link" style="font-size: 16px;text-align: start;" *ngIf="pdfName">
                   {{ pdfName }}
                </button>
                &nbsp;
                  <input *ngIf="!pdfName" style="margin-left: 0px" type="file" formControlName="document"
                    (change)="onSelectFile($event)" single />
                  &nbsp;
                  <mat-icon class="toolbar-button" mat-button title="Edit" *ngIf="pdfName && !isRequestView" (click)="editdoc()">edit</mat-icon>
                  &nbsp;
                    <span>
                  <mat-icon title="Delete" *ngIf="requestForm.controls.document.value && iseditDoc"
                    (click)="deleteIcon()">delete</mat-icon>
                </span>
                  &nbsp;
            </div>
            <!-- <div  fxLayout="row" fxFlex="100%" style="padding-top: 1%;padding-left: 1%;"> 
                    <div style="padding-bottom: 1.34375em; margin-left: 10px">
                        <label style="margin-left: 10px">Supporting Document <mat-icon title="Only pdf file upto 1MB can be uploaded" class="iconstyle">info</mat-icon></label><br /> 
                        <button class="link" style="margin-left: 10px" (click)="fileView()" *ngIf="pdfName">{{ pdfName }} </button>
                        <input *ngIf="!pdfName" style="margin-left: 10px" type="file" formControlName="document"  (change)="onSelectFile($event)" required single accept="pdf" /> 
                        <img *ngIf="pdfName" class="img-fluid custom-image" style="width: 75%;"   [src]="'data:image/png;base64,' + base64textString">
                       <mat-icon title="Edit" *ngIf="pdfName" (click)="editdoc()">edit</mat-icon>           
                        <mat-icon title="Delete" *ngIf="requestForm.controls.document.value" (click)="delete()">delete</mat-icon> 
                        <mat-error style="margin-left: 10px" *ngIf="submitted && requestForm.controls.document.errors?.required" class="invalid-feedback">
                            error
                        </mat-error>
                    </div>
            </div>      -->
            <div >    
                <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center"  style="padding-top: 1%;padding-left: 1%;">
                    <div fxLayoutAlign="center" *ngIf="isAdd">
                        <button class="btn btn-primary mr-2 mb-2"  (click)="AddData()" type="submit"><span>Submit</span></button>
                        <button class="btn btn-danger mr-2 mb-2"   (click)="cancel()"><span >Cancel</span></button>
                        <br />
                    </div>
                </div>
            </div>
        </form>

    <!-- <mat-divider></mat-divider> -->
    <div fxLayout="column" *ngIf="isdata">
        <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
            <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
              <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
              </mat-form-field>
            </span>
          </div>
<form  autocomplete="off" >
        <div fxLayout="column" class="table-pad">
         <div class="mat-elevation-z1  example-container">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef style="text-align: right;">S.No </th>
                    <td mat-cell *matCellDef="let element; let i=index;" style="text-align: right;">
                        {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                </ng-container>
                <ng-container matColumnDef="emp_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">Employee Id </th>
                    <td mat-cell *matCellDef="let row"> <span  style="text-align: center;">{{row.emp_id}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="emp_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">Employee Name </th>
                    <td mat-cell *matCellDef="let row"> <span  style="text-align: center;">{{row.emp_name}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="request_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">Request Date </th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> <span  style="text-align: center;">{{row.created_on | date: 'dd-MM-yyyy'}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="rbs_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Reimbursement Type  </th>
                    <td mat-cell *matCellDef="let row" > <span style="text-align: center;">{{row.reimbursement_type}}</span> </td>
                </ng-container>
              
                <ng-container matColumnDef="bill_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">Bill Date  </th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> <span style="text-align: center;" >{{row.bill_date | date: 'dd-MM-yyyy'}} </span> </td>
                </ng-container>
                <!-- <ng-container matColumnDef="bill_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Bill Number </th>
                    <td mat-cell *matCellDef="let row" > <span style="text-align: center;">{{row.bill_number}}</span> </td>
                </ng-container> -->

                <ng-container matColumnDef="bill_amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Bill Amount  </th>
                    <td mat-cell *matCellDef="let row"  style="text-align: center;"> <span style="text-align: center;"> {{row.bill_amount}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="approved_amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Approved Amount  </th>
                    <td mat-cell *matCellDef="let row"  style="text-align: center;"> <span style="text-align: center;">{{row.approved_amount?row.approved_amount:''}} </span> </td>
                </ng-container>
                <ng-container matColumnDef="claim_status">
                    <th mat-header-cell *matHeaderCellDef  style="text-align: center;"> Status  </th>
                    <td mat-cell *matCellDef="let row"> <span  style="white-space: nowrap;text-align: center;">{{row.claim_status}} </span> </td>
                </ng-container>


                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                        <!-- <button mat-icon-button><mat-icon (click)="view(row)"  title="View">visibility</mat-icon> </button> -->
                        <!-- <button mat-icon-button><mat-icon (click)="edit(row)"   title="Edit"  *ngIf="row.is_employee_revisable || row.is_revisable" >edit   </mat-icon> </button> -->
                        
                     </td>
                </ng-container>
                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
    </div>
</form>
    </div>


</mat-card>
</div>


 