import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from 'src/environments/environment';

import * as _moment from 'moment';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { ShitConfigureHistoryDialogComponent } from 'src/app/modules/attendance/pages/shit-configure-history-dialog/shit-configure-history-dialog.component';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-admin-shift-configuration',
  templateUrl: './admin-shift-configuration.component.html',
  styleUrls: ['./admin-shift-configuration.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminShiftConfigurationComponent implements OnInit {

  requestform: any = UntypedFormGroup;
  searchform: any = UntypedFormGroup;
  minFromDate: Date;
  maxFromDate: Date | null;
  minToDate: Date | null;
  maxToDate: Date;
  currentDate: Date = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe: any;
  displayedColumns: string[] = ["select", 'id', 'empid', 'empname', 'shiftname', 'fromdate', 'todate', 'status'];
  dataSource: MatTableDataSource<any> = <any>[];
  ATT74: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchControl = new FormControl('');

  arrayList: any = [];
  userSession: any;
  shiftDataList: any = [];
  employeesData: any;
  weekDaysDetails: any = [];
  workingDays: any = [];
  weekoffs: any = [];
  selectedEmps: any = [];

  pageLoading = true;
  messagesDataList: any = [];
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  recordExist: any;
  companyDBName: any = environment.dbName;
  availableDepartments: any = [];
  isShow = false;
  filteredDepartments: any = [];
  btnDisable: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private attendanceService: AttendanceService,
    public dialog: MatDialog, public datePipe: DatePipe, private router: Router,
    private companyService: CompanySettingService, private adminService: AdminService
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate());
    this.maxFromDate = new Date();
    this.maxFromDate.setDate(this.currentDate.getFullYear() + 1);
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() + 1);
    this.maxToDate = new Date();
    this.maxToDate.setDate(this.currentDate.getFullYear() + 1);
    this.arrayList = [];

  }
  selection = new SelectionModel<any>(true, []);
  shifDetails: any;
  finaldate: any;

  ngOnInit(): void {
    this.getMessagesList();
    this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
    this.searchform = this.formBuilder.group(
      {
        department: ['', Validators.required],
      });
    this.requestform = this.formBuilder.group(
      {
        shift: ['', Validators.required],
        fromDate: ['', Validators.required],
        toDate: ['', Validators.required],
      });
    this.getDepartmentsMaster();
    this.getActiveShiftIds();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterDepartments(searchText);
    });
  }

  ngAfterViewInit() {
  }
  get f(): { [key: string]: AbstractControl } {
    return this.requestform.controls;
  }

  shiftChange(shiftId: any) {
    this.shifDetails = null;
    this.requestform.controls.fromDate.setValue('');
    this.adminService.getShiftsDetailsById(shiftId).subscribe((res: any) => {
      if (res.status) {
        this.shifDetails = res.data[0];
        // Assuming you have separate date and time variables
        const date = new Date(); // Replace this with your date variable
        const formattedTime = this.shifDetails.fromtime; // Replace this with your time variable
        const graceperiod_intime = this.shifDetails.graceperiod_intime;
        // Format the date and time separately using the DatePipe
        const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');

        // Concatenate the formatted date and time
        let dateTime = formattedDate + ' ' + formattedTime;
        const dateAddTime = formattedDate + ' ' + graceperiod_intime;
        const hoursToAdd =
          new Date(dateAddTime).getHours() +
          new Date(dateTime).getHours();
        const minutesToAdd =
          new Date(dateAddTime).getMinutes() +
          new Date(dateTime).getMinutes();
        const secondsToAdd =
          new Date(dateAddTime).getSeconds() +
          new Date(dateTime).getSeconds();
        this.finaldate =
          formattedDate +
          ' ' +
          hoursToAdd +
          ':' +
          minutesToAdd +
          ':' +
          secondsToAdd;
        if (this.currentDate >= new Date(this.finaldate)) {
          // Create a new Date object
          this.minFromDate = new Date();
          // Add one day to the date
          this.minFromDate.setDate(this.minFromDate.getDate() + 1);
          //this.minFromDate.setDate(this.currentDate.getDate()+1);
        } else {
          this.minFromDate = this.currentDate;
        }
      } else {
        this.shifDetails = null;
      }
    });
  }

  fromDateChange(type: string, event: any) {
    this.minToDate = event.value['_d']
    if (event.value['_d'] !== null) {
      this.maxToDate = new Date(
        event.value['_i'].year + 1,
        event.value['_i'].month,
        event.value['_i'].date - 31
      );
    }
    this.requestform.controls.toDate.setValue('');
  }

  toDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
  }

  getActiveShiftIds() {
    this.attendanceService.getActiveShiftIds().subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;

      }
    })
  }

  getEmployeeConfigureShifts() {
    if (this.searchform.valid) {
      this.isShow = true;
      this.arrayList = [];
      let obj = {
        "manager_empid": null,
        "department_id": this.searchform.controls.department.value,
      }
      this.attendanceService.getEmployeeConfigureShifts(obj).subscribe((res) => {
        if (res.status) {

          this.arrayList = res.data;
          this.dataSource = new MatTableDataSource(this.arrayList);
          this.pageLoading = false;
          this.dataSource.paginator = this.paginator;
          this.dataSource.paginator.firstPage();
          this.dataSource.sort = this.sort;

        } else {
          this.arrayList = [];
          this.dataSource = new MatTableDataSource(this.arrayList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      })
    } else {

    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  saveShiftConfiguration() {
    this.addValidators();

    if (this.requestform.invalid) {
      return;
    } else {
      if (this.btnDisable) {
        return;
      }
      if (this.selection.selected.length > 0) {
        this.selectedEmps = [];
        this.selection.selected.forEach((e: any) => {
          this.selectedEmps.push(e.empid);
        });
        let obj = {
          "shift_id": this.requestform.controls.shift.value,
          "from_date": this.pipe.transform(new Date(this.requestform.controls.fromDate.value ?? ''), 'yyyy-MM-dd'),
          "to_date": this.pipe.transform(new Date(this.requestform.controls.toDate.value ?? ''), 'yyyy-MM-dd'),
          // "weekoffs": JSON.stringify(this.weekoffs),
          "empids": JSON.stringify(this.selectedEmps)

        };
        this.btnDisable = true;
        this.attendanceService.setEmployeeConfigureShift(obj).subscribe((res) => {
          if (res.status) {
            let resMessage: any;
            if (res.message == "dataSaved") {
              resMessage = this.dataSave

            } else {
              resMessage = this.dataNotSave
              this.btnDisable = false;
            }
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage
            });
            this.resetform();

          }
        })
      } else {
        this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Select atleast one employee."
        });
        this.btnDisable = false;
        return;
      }
    }
  }

  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Admin/admin-shift"]));

  }

  checkCheckBoxvalue(event: any, data: any) {

    if (event.checked == true) {
      this.weekoffs.push(data.id);
      this.workingDays.forEach((e: any, index: any) => {
        if (e.id == data.id) {
          this.workingDays.splice(index, 1);
        }
      });

    } else {
      this.weekoffs.forEach((a: any, index: any) => {
        if (a == data.id) {
          this.weekoffs.splice(index, 1);
        }
      })
      let obj = { "id": data.id, "name": data.week_name }
      this.workingDays.push(obj);
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 100
    }
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "ATT2") {
            this.requiredOption = e.message
          } else if (e.code == "ATT69") {
            this.dataSave = e.message
          } else if (e.code == "ATT70") {
            this.dataNotSave = e.message
          } else if (e.code == "ATT74") {
            this.ATT74 = e.message
          }
        })
      }
      else {
        this.messagesDataList = [];
      }

    })
  }

  getDepartmentsMaster() {
    this.companyService.getMastertable('departmentsmaster', 1, 1, 1000, this.companyDBName).subscribe(data => {
      if (data.status) {
        this.availableDepartments = data.data;
        this.filteredDepartments = this.availableDepartments;
      }
    })
  }

  view(data: any) {
    let givenData = {
      "empId": data.empid,
      "empCode": data.emp_code,
      "empName": data.empname,
    }
    let dialogRef = this.dialog.open(ShitConfigureHistoryDialogComponent, {
      width: '500px', position: { top: `100px` },
      disableClose: true,
      data: givenData
    });
    this.clearValidators();
  }

  addValidators() {
    this.requestform.controls.shift.setValidators([Validators.required])
    this.requestform.controls.fromDate.setValidators([Validators.required])
    this.requestform.controls.toDate.setValidators([Validators.required])
    this.requestform.get('shift').updateValueAndValidity();
    this.requestform.get('fromDate').updateValueAndValidity();
    this.requestform.get('toDate').updateValueAndValidity();
  }


  clearValidators() {
    this.requestform.reset();
    this.requestform.get('shift').clearValidators();
    this.requestform.get('shift').updateValueAndValidity();
    this.requestform.get('fromDate').clearValidators();
    this.requestform.get('fromDate').updateValueAndValidity();
    this.requestform.get('toDate').clearValidators();
    this.requestform.get('toDate').updateValueAndValidity();

  }
  filterDepartments(searchText: any) {
    this.filteredDepartments = this.availableDepartments.filter((val: any) =>
      val.deptname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredDepartments.length <= 0) {
      this.searchControl.setValue('');
    } else {

    }
  }
}
