<div fxLayout="column" class="first-col">
    <mat-card fxLayout="column">
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Shift </mat-card-title>
            <div style="margin-right: 2%" *ngIf="isdata">
                <button mat-icon-button style="padding-right: 40px" (click)="Add()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <form [formGroup]="shiftForm" autocomplete="off" *ngIf="isAdd">
            <div class="tableP">
                <div class="responsive-form">
                    <mat-form-field class="mx-2 my-2" appearance="outline">
                        <mat-label>Shift Name</mat-label>
                        <input formControlName="shift" matInput maxlength="64" placeholder="Shift Name"
                            noLeadingWhitespace />
                        <mat-error class="con-error" *ngIf="shiftForm.controls.shift.errors?.required">
                            {{ requiredField }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mx-2 my-2" appearance="outline">
                        <mat-label>Shift Description</mat-label>
                        <textarea formControlName="description" matInput maxlength="250"
                            placeholder="Description"></textarea>
                    </mat-form-field>
                </div>

                <div class="column">
                    <mat-label class="mx-2"><b>Shift Timings</b></mat-label>
                    <div class="responsive-form">
                        <mat-form-field class="mx-2 my-2" appearance="outline">
                            <mat-label>Start Time</mat-label>
                            <input formControlName="startTime" matTimepicker />
                            <mat-error class="con-error" *ngIf="shiftForm.controls.startTime.errors?.required">
                                {{ requiredField }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="mx-2 my-2" appearance="outline">
                            <mat-label>End Time</mat-label>
                            <input formControlName="endTime" matTimepicker [min]="mintime" />
                            <mat-error class="con-error" *ngIf="shiftForm.controls.endTime.errors?.required">
                                {{ requiredField }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="mx-2 my-2" appearance="outline">
                            <mat-label>Total Hours</mat-label>
                            <input formControlName="totalHours" required matInput placeholder="HH:MM" />
                            <mat-error class="con-error" *ngIf="shiftForm.controls.totalHours.errors?.required">
                                {{ requiredField }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="column">
                    <mat-label class="mx-2"><b> Grace Period</b></mat-label>
                    <div class="responsive-form">
                        <mat-form-field class="mx-2 my-2" appearance="outline">
                            <mat-label>In Time </mat-label>
                            <input formControlName="graceInTime" matTimepicker />
                            <mat-error class="con-error" *ngIf="shiftForm.controls.graceInTime.errors?.required">
                                {{ requiredField }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="mx-2 my-2" appearance="outline">
                            <mat-label>Out Time</mat-label>
                            <input formControlName="graceOutTime" matTimepicker />
                            <mat-error class="con-error" *ngIf="shiftForm.controls.graceOutTime.errors?.required">
                                {{ requiredField }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- <div fxLayout="row wrap">
                    <mat-label class="mx-4 my-4">When number of lates is more than
                    </mat-label>
                    <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline" style="max-width: 120px">
                        <input type="text" onlyNumber formControlName="noofTimes" matInput placeholder="" minlength="1"
                            maxlength="2" />
                        <mat-error class="con-error" *ngIf="shiftForm.controls.noofTimes.errors?.required">
                            {{ requiredField }}
                        </mat-error>
                    </mat-form-field>
                    <mat-label class="mx-4 my-4">per month.</mat-label>
                </div>

                <div fxLayout="row wrap">
                    <mat-label class="mx-4 my-4">Deduct</mat-label>
                    <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline" style="max-width: 135px">
                        <mat-select required formControlName="noofDays" class="mat-select1">
                            <ng-container *ngFor="let b of daysList">
                                <mat-option value="{{ b }}">{{ b }}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="shiftForm.controls.noofDays.errors?.required">
                            {{ requiredOption }}
                        </mat-error>
                    </mat-form-field>
                    <mat-label class="mx-4 my-4">days from the leave balance leaves.</mat-label>
                </div> -->

                <div fxLayout="column">
                    <mat-label class="mx-2"><b>Overtime Duration</b></mat-label>
                    <div fxLayout="row" fxLayout.xs="column">
                        <span style="padding-top: 2%; padding-left: 1%;">Allow minimum number of work hours per
                            day</span>

                        <!-- <mat-label class="mx-4 my-4"></mat-label> -->
                        <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                            <mat-label>Over Time</mat-label>
                            <mat-select formControlName="overTime">
                                <ng-container *ngFor="let b of overTimeList">
                                    <mat-option value="{{ b.id }}">{{ b.name }}</mat-option>
                                </ng-container>
                            </mat-select>

                            <mat-error class="con-error" *ngIf="shiftForm.controls.overTime.errors?.required">
                                {{ requiredOption }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayoutAlign="center">
                    <button *ngIf="!isEdit" [disabled]="btnDisable" class="btn btn-primary mr-2 mb-2"
                        (click)="saveShiftData()" type="submit">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </form>

        <!-- <hr class="line_break"> -->

        <div fxLayout="column" *ngIf="isdata" class="table-pad">
            <div fxLayout="row" fxLayoutAlign="end" style="height: 50px;">
                <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100%" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <br />
            <div fxLayout="column">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef><span style="text-wrap: nowrap;">S. No.</span></th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="shiftname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                                Shift Name
                            </th>
                            <td mat-cell *matCellDef="let row">{{ row.shiftname }}</td>
                        </ng-container>

                        <ng-container matColumnDef="starttime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Start Time</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">{{ row.fromtime }}</td>
                        </ng-container>

                        <ng-container matColumnDef="endtime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">End Time</th>
                            <td mat-cell *matCellDef="let row">{{ row.totime }}</td>
                        </ng-container>
                        <ng-container matColumnDef="workinghours">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Working Hours</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">{{ row.totalhours }}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef style="padding-left: 60px">
                                Status
                            </th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <mat-radio-group>
                                    <mat-radio-button class="mr-2" color="primary"
                                        *ngFor="let halfs of arrayValue; index as i"
                                        (change)="status(halfs.id, row.shiftid)" [checked]="halfs.id == row.status"
                                        [value]="halfs.id">{{ halfs.name }}</mat-radio-button>
                                </mat-radio-group>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>

                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button (keyup.enter)="view(row)">
                                    <mat-icon (click)="view(row)" title="View">visibility</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="7" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>