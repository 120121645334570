<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="title">Missed Punch Report</mat-card-title>
        </mat-card-header>
        <div fxLayout="column" [formGroup]="searchForm" style="padding: 1%;">
            <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field fxFlex="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Month and Year</mat-label>
                    <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                        [min]="minDate" [max]="max" (click)="fromDate.open()" required>
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                        panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>


                <mat-form-field fxFlex="45" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <mat-select formControlName="employees" required>
                        <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                            matInput>
                        <mat-option value="0"> All Employees </mat-option>
                        <mat-option *ngFor="let a of filteredEmployees" [value]="a.empid">{{a.empname}}</mat-option>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="searchForm.controls.employees.errors?.required">This field is
                        required.</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxFlex.xs="100%" fxLayoutAlign="center" class="showLine">
                <span>
                    <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="5">
                        Search
                    </button>
                </span>
                <!-- <span class="line" style="color:#1898D5;font-size:16px"></span> -->
                <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="6">
                    <span>Clear</span>
                </button>
            </div>

        </div>
        <!-- For DownLoad PDF and Excel -->
        <!-- <div style="margin-bottom: 1%;padding-right: 2%;" fxLayout="row xs-column" fxLayoutAlign="end  center"
            *ngIf="arrayList.length > 0">
            <div>
                <span class="exlprint"> | </span>
                <a title="PDF"> <img (click)="generatePDF()" src="./assets/images/icons/pdf.png" width="20px"
                        height="20px"></a>

                <span class="exlprint"> | </span>
                <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                </a>
            </div>
        </div> -->
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end"
            style="height: 70px;padding-top: 1%;">
            <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline"
                style="padding-right: 16px;">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput maxlength="100">
            </mat-form-field>
        </div>
        <div fxLayout="column" class="table-pad">
            <div class="mat-elevation-z1 example-container">
                <div id="table" #table=''>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="no-wrap">S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}
                                <!-- {{element.id}} -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="empid">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. ID</th>
                            <td mat-cell *matCellDef="let row;">{{row.empcode}}</td>

                        </ng-container>
                        <ng-container matColumnDef="empname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name</th>
                            <td mat-cell *matCellDef="let row;">{{row.empname}}</td>
                          
                        </ng-container>
                        <ng-container matColumnDef="attendancedate">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Date</th>
                            <td mat-cell *matCellDef="let row;">{{row.attendancedate | date:'dd-MM-yyyy'}}</td>

                        </ng-container>

                        <ng-container matColumnDef="intime">
                            <th mat-header-cell *matHeaderCellDef>CheckIn Time</th>
                            <td mat-cell *matCellDef="let row"> {{row.firstlogintime | date:'dd-MM-yyyy HH:mm:ss'}} </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="source">
                            <th mat-header-cell *matHeaderCellDef>Attendance Source</th>
                            <td mat-cell *matCellDef="let row"> {{row.attendancesource}} </td>
                        </ng-container> -->
                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef>Location</th>
                            <td mat-cell *matCellDef="let row"> {{row.locationid}} </td>
                        </ng-container>


                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                        </tr>

                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>

    </mat-card>
</div>