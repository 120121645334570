
<div fxLayout="column" class="first-col">
    <mat-card>
      <mat-card-header class="headerbox">
          <mat-card-title class="title">Employee Tracking Log Report</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div   fxLayout="column" fxLayout.lt-lg="column" fxLayoutAlign.xs="center" class="dev-Pad" [formGroup]="searchForm" >
          <div fxLayout="row" fxflex="100" fxLayout.xs="column" fxLayout.lt-lg="row"   fxLayoutAlign="space-between">
            <div  fxLayoutAlign="space-between" *ngIf="is_super_admin && !redirected" > 
              <mat-form-field  class="mx-2 my-2" appearance="outline">
                <mat-label>City-Branch </mat-label>
                <mat-select placeholder="" name="companylocation" formControlName="companylocation" required  #locationSelect>
                    <!-- <button mat-raised-button class="mat-primary fill text-sm"
                        (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.companylocation)"
                        *ngIf="!locationIshide">Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm"
                        (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                        Deselect All
                    </button> -->
                    <mat-option *ngFor="let res of worklocationDetails" [value]="res"
                        (click)="onchangeLocation(res)">
                        {{res.cityname}}-{{res.location}}
                    </mat-option>
                </mat-select>
                <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
                    Please select option
                </mat-error>
              </mat-form-field>

              <mat-form-field   class="mx-2 my-2" appearance="outline">
                <mat-label>Manager</mat-label>
                <mat-select placeholder="" name="managers" formControlName="managers" required #managerSelect>
                    <!-- <button mat-raised-button class="mat-primary fill text-sm"
                        (click)="managerSelectAll(managerSelect, managersDetails, searchForm.managers)"
                        *ngIf="!mgrIshide">Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm"
                        (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                        Deselect All
                    </button> -->
                    <mat-option *ngFor="let res of managersDetails" [value]="res" (click)="onchangeManager(res)">
                        {{res.manager_name}}
                    </mat-option>
                </mat-select>
                <mat-error class="con-error" *ngIf="searchForm.controls.managers.errors?.required">
                    Please select option
                </mat-error>
              </mat-form-field>
            </div>
            <div  fxLayoutAlign="space-between"> 
              <mat-form-field   fxFlex.lt-lg="48"  class="mx-2 my-2" appearance="outline">
                <mat-label>Select Employee</mat-label>
                <mat-select formControlName="Users" required tabIndex="3">
                  <ng-container *ngFor="let e of employeelist">
                    <mat-option [value]="e.empid">{{e.empname}} </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <mat-form-field  class="mx-2 my-2" appearance="outline">
                <mat-label>From Date</mat-label>
                <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder="From Date"
                  formControlName="fromDate" (click)="fromDate.open()" tabIndex="1">
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
              </mat-form-field>
            </div>
            
          </div>
          <div fxLayout="row"  fxLayoutAlign="center">
            <div  fxLayout="row" fxLayoutAlign="center">
              <span style="margin-left: auto;">
                <button  [tabIndex]="4" class="btn btn-primary mr-2 mb-2" (click)="Searchform()"> Search </button>
                <span class="line" style="color:#1898D5;font-size:16px"></span>
                <button  [tabIndex]="5" class="btn btn-danger mr-2 mb-2" (click)="resetform()">  <span>Clear</span> </button>
              </span>
            </div>
          </div>
          <div *ngIf="emp_attendance && emp_attendance.length" style="padding-top: 5px;">
            <h3 style="font-weight: 500;" class="table-pad">
              Employee Summary Details
            </h3>
            <div class="table-pad" style="margin-bottom: 2%;">
              <div class="mat-elevation-z1 example-container">
                <div id="table" #table=''>
                  <table class="mat-elevation-z2" mat-table [dataSource]="empAttendanceDataSource" matSort>
                  <!-- Attendance Date Column -->
                  <ng-container matColumnDef="attendancedate">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Attendance Date</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">{{ row.attendancedate }}</td>
                  </ng-container>

                  <!-- First Login Time Column -->
                  <ng-container matColumnDef="firstlogintime">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">First Login Time</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;"> {{getFormattedTime(row.firstlogintime)}}</td>
                  </ng-container>

                  <!-- Last Logout Time Column -->
                  <ng-container matColumnDef="lastlogouttime">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Last Logout Time</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">{{getFormattedTime(row.lastlogouttime)}}</td>
                  </ng-container>

                  <!-- New Visits Column -->
                  <ng-container matColumnDef="new_visits">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">New Visits</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">{{ row.new_visits }}</td>
                  </ng-container>

                  <!-- New Clients Column -->
                  <ng-container matColumnDef="new_clients">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">New Clients</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">{{ row.new_clients }}</td>
                  </ng-container>

                  <!-- Total Visits Column -->
                  <ng-container matColumnDef="visits_total">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Total Visits</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">{{ row.visits_total }}</td>
                  </ng-container>
              
                  <!-- Worked Hours Column -->
                  <ng-container matColumnDef="workedhours">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Worked Hours</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">{{ row.workedhours }}</td>
                  </ng-container>

                  <!-- KM Travelled Column -->
                  <ng-container matColumnDef="km_travelled">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">KMS Travelled</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">{{ row.Km_travelled }}</td>
                  </ng-container>
                  <!-- Total Activities Column -->
                  <ng-container matColumnDef="act_total">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Total Activites</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;">
                      <span >{{ row.activities_total }}
                        <button mat-icon-button *ngIf="row.activities_total > 0"> <a [ngClass]="{'active-button': selectedButton === 'totalActivities', 'inactive-button': selectedButton !== 'totalActivities'}" (click)="showActivities()"  style="color:#00A9E0 !important"> {{showActivitiesTable? '(hide)':'(show)' }}</a>
                        </button></span> </td>
                  </ng-container>
                  <!-- Header and Row Definitions -->
                  <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                  <!-- No Data Row -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                  </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
            <!-- <h3 style=" font-weight: 500;" class="table-pad">
              Employee Activity Details
            </h3> -->
          <div *ngIf="showActivitiesTable"> 
            <div fxLayout="row" fxLayoutGap="10px" class="table-pad" style="margin-bottom: 10px;">
              <h3 style="color: #28acaf; font-weight: 500; width: 150px;" class="status-heading">Activities</h3>
               <!-- Activity Totals -->
              <div fxLayout="row" fxLayoutGap="10px" >
                <button
                  mat-raised-button
                  [ngClass]="{'active-button': selectedButton === 'totalActivities', 'inactive-button': selectedButton !== 'totalActivities'}"
                  class="totals-button"
                  (click)="showAllActivities('')"
                >
                  Total <span class="count-number">({{ getTotalActivityCount() }})</span>
                </button>
              </div>
              <h5 style="color: #28acaf; font-weight: 500; width: 150px;" class="status-heading">
                <button  mat-raised-button [ngClass]="{'active-button': (show_type == 'Client Status'), 'inactive-button': !(show_type == 'Client Status')}" class="totals-button" (click)="showAllActivities('Client Status')" > 
                  <span class="count-number">Client Status</span>
                </button>
              </h5>
              <h5 style="color: #28acaf; font-weight: 500; width: 150px;" class="status-heading">
                <button  mat-raised-button [ngClass]="{'active-button': (show_type == 'Activity Status'), 'inactive-button': !(show_type == 'Activity Status')}" class="totals-button" (click)="showAllActivities('Activity Status')" > 
                  <span class="count-number">Activity Status</span>
                </button>
              </h5>
              <h5 style="color: #28acaf; font-weight: 500; width: 150px;" class="status-heading">
                <button  mat-raised-button [ngClass]="{'active-button': (show_type == 'Activity Type'), 'inactive-button': !(show_type == 'Activity Type')}" class="totals-button"   (click)="showAllActivities('Activity Type')" > 
                  <span class="count-number">Activity Type</span>
                </button>
              </h5>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" class="table-pad" style="margin-bottom: 10px;">  
                        
              <!-- Client Status -->
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="show_type == 'Client Status'" >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <h3 style="color: #28acaf; font-weight: 500; width: 150px;" class="status-heading">{{show_type}}</h3>
                </div> 
                <div fxLayout="row" fxLayoutGap="10px" >
                  <ng-container *ngFor="let status of getClientStatuses()">
                    <button
                      mat-raised-button
                      [ngClass]="{'active-button': clientStatusSelected[status], 'inactive-button': !clientStatusSelected[status]}"
                      class="totals-button" (click)="toggleClientStatus(status)"> <span class="count-number"> {{ status }} ({{ clientStatusCounts[status] || 0 }})</span>
                    </button>
                  </ng-container>
                </div>
              </div>
            
              <!-- Activity Status -->
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="show_type == 'Activity Status'" >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <h3 style="color: #28acaf; font-weight: 500; width: 150px;" class="status-heading">{{show_type}}</h3>
                </div> 
               
                <div fxLayout="row" fxLayoutGap="10px">
                  <ng-container *ngFor="let status of getActivityStatuses()">
                    <button
                      mat-raised-button
                      [ngClass]="{'active-button': activityStatusSelected[status], 'inactive-button': !activityStatusSelected[status]}"
                      class="totals-button"
                      (click)="toggleActivityStatus(status)"
                    >
                       <span class="count-number">{{ status }}({{ activityStatusCounts[status] || 0 }})</span>
                    </button>
                  </ng-container>
                </div>
              </div>
            
              <!-- Activity Type -->
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="show_type == 'Activity Type'" >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <h3 style="color: #28acaf; font-weight: 500; width: 150px;" class="status-heading">{{show_type}}</h3>
                </div> 
               
                <div fxLayout="row" fxLayoutGap="10px">
                  <ng-container *ngFor="let type of getActivityTypes()">
                    <button
                      mat-raised-button
                      [ngClass]="{'active-button': activityTypeSelected[type], 'inactive-button': !activityTypeSelected[type]}"
                      class="totals-button"
                      (click)="toggleActivityType(type)"
                    >
                       <span class="count-number">{{ type }}({{ activityTypeCounts[type] || 0 }})</span>
                    </button>
                  </ng-container>
                </div>
              </div>
            
            </div>

            <div class="table_pad" style="margin-bottom: 2%;">
              <!-- Employee Activities Table -->
              <div class="example-container mat-elevation-z2" id="table1">
                <table mat-table [dataSource]="dataSource1" matSort>      
                   <!-- Activity Type Column -->
                   <ng-container matColumnDef="activity_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Activity Type </th>
                    <td mat-cell *matCellDef="let row" class="single-line"> {{ row.activity_type }} </td>
                  </ng-container>                              
                  <!-- Client Name Column -->
                  <ng-container matColumnDef="client_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name </th>
                    <td mat-cell *matCellDef="let row" class="single-line"> 
                      <span ><a (click)="selectClient($event,row)" style="color:darkslateblue; cursor: pointer;">  {{ row.client_name }} </a> </span>
                    </td>
                  </ng-container>

                  <!-- Client Contact Column -->
                  <ng-container matColumnDef="client_contact">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Contact </th>
                    <td mat-cell *matCellDef="let row" class="single-line"> {{ row.client_contact }} </td>
                  </ng-container>

                  <!-- Activity Status Column -->
                  <ng-container matColumnDef="activity_status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Activity Status </th>
                    <td mat-cell *matCellDef="let row" class="single-line"> {{ row.activity_status }} </td>
                  </ng-container>

                  <!-- Client Status Column -->
                  <ng-container matColumnDef="client_status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Status </th>
                    <td mat-cell *matCellDef="let row" class="single-line"> {{ row.client_status }} </td>
                  </ng-container>
                  <!-- Received Amount Column -->
                  <ng-container matColumnDef="received_amount" style="text-align: right;">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Received Amount </th>
                    <td mat-cell *matCellDef="let row" class="single-line" style="text-align: right;"> {{
                      row.received_amount }} </td>
                  </ng-container>

                  <!-- Target Price Column -->
                  <ng-container matColumnDef="target_price" style="align-items: right;">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: right;"> Target Price </th>
                    <td mat-cell *matCellDef="let row" class="single-line" style="text-align: right;"> {{ row.target_price | currency:'INR' }} </td>
                  </ng-container>

                  <!-- Final Price Column -->
                  <ng-container matColumnDef="final_price" style="text-align: right;">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: right;"> Final Price </th>
                    <td mat-cell *matCellDef="let row" class="single-line" style="text-align: right;"> {{ (row.final_price||0) | currency:'INR' }}
                    </td>
                  </ng-container>

                  <!-- Next Follow Up On Column -->
                  <ng-container matColumnDef="next_follow_up_on">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Next Follow Up On </th>
                    <td mat-cell *matCellDef="let row" class="single-line">
                      <span  matTooltip="{{ row.notes }}" matTooltipPosition="right" matTooltipClass="custom-tooltip"> {{ row.next_follow_up_on }}</span>
                    </td>
                  </ng-container>                

                  <!-- Header and Row Definitions -->
                  <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns1;" [ngClass]="{
                    'highlight-client': row.highlightClient,
                    'highlight-activity': row.highlightActivity,
                    'highlight-type': row.highlightType
                  }"></tr>
                  <!-- No Data Row -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="13" style="text-align: center;">No data found</td>
                  </tr>
                </table>
              </div>
            </div>

          </div>
          <div  fxLayout="column" class="table-pad">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayoutAlign="start">
                <h3 style=" font-weight: 500;">
                  Employee Tracking Details  {{route_type? '('+route_type+')':'' }}
                </h3>
              </div>
              <div fxflex="30" fxLayout="row xs-column" fxLayoutAlign="end" style="margin-right: 3%;">
                <div *ngIf="List && List.length > 0">
                  <button mat-icon-button>
                    <a title="PDF" [tabIndex]="6" (keyup.enter)="exportPDF()">
                      <img (click)="exportPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px">
                    </a>
                  </button>

                  <span class="exlprint"> | </span>
                  <button mat-icon-button>
                    <a title="Excel" [tabIndex]="7" (keyup.enter)="exportAsXLSX()">
                      <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                    </a>
                  </button>
                </div>
                <div>
                  <mat-form-field appearance="outline">
                    <mat-label>Search</mat-label>
                    <input (keyup)="applyFilter($event)" matInput placeholder="Search" maxlength="100" [tabIndex]="4">
                    <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="mat-elevation-z1 example-container">
              <div id="table" #table=''>
              <table   mat-table [dataSource]="dataSource" style="">
                <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef style="text-align: right;">S.No</th>
                  <td mat-cell *matCellDef="let element; let i=index;" style="text-align: right;">
                      {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
              </ng-container>
                <ng-container matColumnDef="start_time">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;white-space:unset ;"> Started At</th>
                  <td mat-cell *matCellDef="let row;" style="white-space:nowrap;">{{getFormattedTime(row.start_time)}}</td>
                </ng-container>
                <ng-container matColumnDef="start_address">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Start Location</th>
                  <td mat-cell *matCellDef="let row" style="text-align: center;white-space: initial;min-width: 125px;" > {{getAddress(row,'start')}} </td>
                </ng-container>
                <ng-container matColumnDef="details">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Details</th>
                  <td mat-cell *matCellDef="let row" style="text-align: center;min-width: 125px;"> 
                      <div fxLayout="column" *ngFor="let det of row.details" > 
                          <div fxLayout="column" *ngIf="det.client_name && det.client_name.toString().length" style="width: 190px;" [ngStyle]="{'color': det.markerColor }" (click)="changeDisplay(row,det)" > 
                            <span  style="width: 190px;"> <b>{{det.activity_type ? (det.activity_type ):''}}</b> </span> 
                          </div>
                          <div fxLayout="column" *ngIf="det.client_name && det.client_name.toString().length" style="width: 190px;" [ngStyle]="{'color': det.markerColor }" (click)="changeDisplay(row,det)" > 
                            <span  style="width: 190px;"> <b>{{det.client_name}}</b> </span> 
                          </div>
                          <div fxLayout="column" *ngIf="det.attendance_type && det.attendance_type.toString().length" style="width: 190px;"  [ngStyle]="{'color': det.markerColor }" (click)="changeDisplay(row,det)" > 
                            <span  style="width: 190px;"> <b>{{det.attendance_type}}</b> </span> 
                          </div>
                          <div class="container" fxLayout="column" *ngIf="det.imageData && det.imageData.image && det.display" >
                            <img  [src]="det.imageData.image" style="width: 75px;margin: auto;" >
                          </div>
                          <div fxLayout="column" *ngIf="det.notes && det.notes.toString().length && det.display" style="width: 190px;"  [ngStyle]="{'color': det.markerColor }" > 
                            <span style="font-size:smaller;word-break: break-all;">{{det.notes.toString().substring(0,100) }}<b>{{(det.notes.toString().length && det.notes.toString().length > 100) ?'...':''}}</b> </span>
                          </div>
                      </div> 
                  </td>
                </ng-container>
                <ng-container matColumnDef="spent_time">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Stay Duration</th>
                  <td mat-cell *matCellDef="let row" style="text-align: center;"> {{getFormattedTimeDifference(row.spent_time,'Stay')}} </td>
                </ng-container>
                <ng-container matColumnDef="travel_time">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">Travel Duration</th>
                  <td mat-cell *matCellDef="let row"  style="text-align: center;"> {{getFormattedTimeDifference(row.travel_time,'Travel')}} </td>
                </ng-container>
                <ng-container matColumnDef="distance_km">
                  <th mat-header-cell *matHeaderCellDef style="text-align: right;white-space:unset;">KMS Traveled</th>
                  <td mat-cell *matCellDef="let row" style="white-space:unset;text-align: center;" >
                    <span  style="text-align: rigth;white-space:nowrap;"> {{convertToKms(row.distance).val}} </span>
                </td>
                </ng-container>
                <ng-container matColumnDef="end_time">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;white-space:nowrap;">Reached At</th>
                  <td mat-cell *matCellDef="let row"  style="text-align: center;white-space:nowrap;"> {{getFormattedTime(row.end_time)}} </td>
                </ng-container>
                <ng-container matColumnDef="end_address">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">End Location</th>
                  <td mat-cell *matCellDef="let row"  style="text-align: center;white-space: initial;min-width: 125px;"> {{getAddress(row,'end')}} </td>
                </ng-container>
                <ng-container matColumnDef="route">           
                  <th mat-header-cell *matHeaderCellDef style="white-space: wrap;"><div fxLayout="row" fxLayoutAlign="end"> Google display</div></th>
                  <td mat-cell *matCellDef="let row">
                    <div fxLayout="row" fxLayoutAlign="end" *ngIf="row.lat && row.lat.toString().length>0 && row.lng && row.lng.toString().length>0 && row.trace_end_lat && row.trace_end_lat.toString().length>0 && row.trace_end_lng && row.trace_end_lng.toString().length>0">
                      <span><a [routerLink]="[]" (click)="displayRouteInGoogle(row)" style="color:#00A9E0 !important"><mat-icon>directions</mat-icon></a></span>
                    </div>
                  </td> 
                </ng-container>
                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="10" style="text-align: center;" >No data found</td>
                </tr>
              </table>
              </div>
              <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-card-content>
      <div class="important-note">
        <ul >
          <li >  <span class="icon">  Tracking will start only when the employee is logged into the CRM application on their mobile device, with location permissions granted and an active internet connection. </span> </li>
          <li >  <span class="icon">  <b>Point to point:&nbsp;</b> The route map will be displayed only when the employee punches in, punches out, or performs visit activities, such as navigating to client locations. This ensures accurate logging of visits and minimizes unnecessary travel routes. </span> </li>
          <li >  <span class="icon">  <b>Live Tracking:&nbsp;</b> The route map will capture the employee's movements from punch-in to punch-out, including all visit activities, ensuring comprehensive live tracking data. </span> </li>
        </ul>
      </div>
    </mat-card>
  
</div>
  
