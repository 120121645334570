import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from 'src/app/pages/main/main.component';
import { CrmAddClientComponent } from './pages/crm-add-client/crm-add-client.component';
import { CrmUserDashboardComponent } from './pages/crm-user-dashboard/crm-user-dashboard.component';
import { CrmClientListComponent } from './pages/crm-client-list/crm-client-list.component';
import { CrmClientEditAndUpdateComponent } from './pages/crm-client-edit-and-update/crm-client-edit-and-update.component';
import { CrmDemoStatusComponent } from './pages/crm-demo-status/crm-demo-status.component';

import { CrmLiveTrackingComponent } from './pages/crm-live-tracking/crm-live-tracking.component';
import { SummaryReportComponent } from '../reports/pages/summary-report/summary-report.component';
import { DetailReportComponent } from '../reports/pages/detail-report/detail-report.component';
import { CrmClientReportComponent } from './pages/crm-client-report/crm-client-report.component';
import { CrmSalesVisitReportComponent } from './pages/crm-sales-visit-report/crm-sales-visit-report.component';
import { CrmClientStatusMasterComponent } from './pages/crm-client-status-master/crm-client-status-master.component';
import {CrmSearchLocationComponent} from './pages/crm-search-location/crm-search-location.component';
import { SummaryTrackingReportComponent } from './pages/summary-tracking-report/summary-tracking-report.component';
import { CrmDeviceApprovalsComponent } from './pages/crm-device-approvals/crm-device-approvals.component';
import { CrmDetailedTrackingReportComponent } from './pages/crm-detailed-tracking-report/crm-detailed-tracking-report.component';
import {PendingApprovalsComponent} from 'src/app/modules/leaves/pages/pending-approvals/pending-approvals.component';
import { CrmClientAssociateConfiguresComponent } from './pages/crm-client-associate-configures/crm-client-associate-configures.component';
import { CrmClientAssociateReportComponent } from './pages/crm-client-associate-report/crm-client-associate-report.component';
//import { CrmTravelApprovalsComponent } from './pages/crm-travel-approvals/crm-travel-approvals.component';


// "./pages/pending-approvals/pending-approvals.component";
const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
        { path: 'CrmAddClient', component: CrmAddClientComponent},
        {path:'CrmUserDashboard',component:CrmUserDashboardComponent},
        {path:'Emp/CrmClientList',component:CrmClientListComponent},
        {path:'CrmClientEditAndUpdate',component:CrmClientEditAndUpdateComponent},
        {path:'CrmDemoStatus',component:CrmDemoStatusComponent},
        {path:'CrmMastersForStatus',component:CrmClientStatusMasterComponent},
        {path:'CrmLiveTracking',component:CrmLiveTrackingComponent},
        {path:'Mgr/CrmClientList',component:CrmClientListComponent},
        {path:'CrmSummaryReport',component:SummaryReportComponent},
        {path:'CrmDetailReport',component:DetailReportComponent},
        {path:'CrmClientReport',component:CrmClientReportComponent},
        {path:'CrmSalesVisitReport',component:CrmSalesVisitReportComponent},
        {path:'CrmSearchLocation',component:CrmSearchLocationComponent},
        {path:'CrmSummaryTrackingReport',component:SummaryTrackingReportComponent},
        {path:'CrmDeviceApprovals',component:CrmDeviceApprovalsComponent},
        {path:'CrmDetailedTrackingReport',component:CrmDetailedTrackingReportComponent},
        {path: 'PendingLeaveApprovals', component: PendingApprovalsComponent},
        {path: 'CrmClientAssociateConfigures', component: CrmClientAssociateConfiguresComponent},
        {path: 'CrmClientAssociateReport', component: CrmClientAssociateReportComponent},
       // {path: 'CrmTravelApprovals', component: CrmTravelApprovalsComponent},
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrmRoutingModule { }
