import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, ValidationErrors, ValidatorFn, AbstractControl, } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AttendanceService } from '../../attendance.service';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { DialogComponent } from '../../dialog/dialog.component';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-shift-change-approve',
  templateUrl: './shift-change-approve.component.html',
  styleUrls: ['./shift-change-approve.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ShiftChangeApproveComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder, private router: Router, public datePipe: DatePipe,
    private adminService: AdminService, public spinner: NgxSpinnerService,
    private dialog: MatDialog, public attendanceService: AttendanceService) {
  }
  shiftApproveForm: any = UntypedFormGroup;
  userSession: any;
  pipe = new DatePipe('en-US');
  maxDate = new Date();
  minDate = new Date();
  swapEmployeesList: any = []
  messagesDataList: any = [];
  requiredField: any;
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  isvalid: boolean = false;
  EM43: any;
  EM55: any;
  companyDBName: any = environment.dbName;

  displayedColumns: string[] = ['sno', 'date', 'name', 'fromshift', 'toshift', 'status', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;

  isNew: boolean = false;
  isUpdate: boolean = false;
  todayWithPipe: any;
  employeeId: any;
  shiftId: any;
  shiftDataList: any = [];
  employeesList: any = [];
  minTodate: any;
  requestId: any;
  toShiftId: any;
  rmId: any;
  swapEmpShiftId: any;
  swapEmployeeId: any;
  titleName: string = '';
  reason: string = '';
  ATT84: any;
  ATT87: any;
  ATT88: any;
  ATT89: any;
  btnDisable: boolean = false;
  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.rmId = this.userSession.id;
    this.todayWithPipe = this.pipe.transform(Date.now(), 'dd-MM-yyyy');
    this.shiftApproveForm = this.formBuilder.group(
      {

        appliedDate: [{ disabled: true }],
        employee: [''],
        fromShift: [''],
        toShift: [''],
        fromDate: [''],
        toDate: [''],
        reason: [''],
        assignEmployee: ["", Validators.required],
        description: [""],

      });
    this.getMessagesList();
    this.getShiftRequestList();
    // this.shiftApproveForm.get('assignEmployee')?.valueChanges.subscribe((selectedValue:any) => {
    // })
  }

  newRequest() {
    this.isNew = true;
  }

  getShiftRequestList() {
    this.shiftDataList = [];
    let data = {
      "empid_value": null,
      "rm_id_value": this.rmId,
    }
    this.attendanceService.getShiftChangeRequestList(data).subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      } else {
        this.shiftDataList = [];
        this.dataSource = new MatTableDataSource(this.shiftDataList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  submit() {

    if (this.shiftApproveForm.valid) {
      if (this.btnDisable) {
        return;
      }
      this.spinner.show()
      let data = {
        "id_value": this.requestId,
        "swap_empid_value": this.swapEmployeeId,
        "swap_emp_shiftid": this.swapEmpShiftId,
        "approvercomments_value": this.shiftApproveForm.controls.description.value,
        "actionby_value": this.rmId,
        "status_value": "Approved",
      };
      this.btnDisable = true;
      this.attendanceService.setShiftRequestApproval(data).subscribe((res: any) => {
        if (res.status && res.data == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `100px` },
            disableClose: true,
            data: this.ATT87
          });
          this.spinner.hide();
          this.resetForm();

        } else if (res.status && res.data == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `100px` },
            disableClose: true,
            data: this.ATT84
          });
          this.spinner.hide();
          this.resetForm();

        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `100px` },
            disableClose: true,
            data: this.ATT88
          });
          this.btnDisable = false;
          this.spinner.hide();
        }
      })
    } else {
      this.spinner.hide();
    }
  }


  editRequest(id: any, data: any) {
    this.isUpdate = false,
      this.isNew = true,
      this.requestId = data.id;
    this.employeeId = data.employee_id;
    this.toShiftId = data.to_shiftid;
    this.shiftApproveForm.controls.appliedDate.setValue(data.applieddate);
    this.shiftApproveForm.controls.appliedDate.disable();
    this.shiftApproveForm.controls.fromDate.setValue(data.fromdate);
    this.shiftApproveForm.controls.fromDate.disable();
    this.shiftApproveForm.controls.toDate.setValue(data.todate);
    this.shiftApproveForm.controls.toDate.disable();
    this.shiftApproveForm.controls.fromShift.setValue(data.fromshift);
    this.shiftApproveForm.controls.toShift.setValue(data.toshift);
    this.shiftApproveForm.controls.reason.setValue(data.reason);
    this.shiftApproveForm.controls.employee.setValue(data.employee_name);
    this.getEmployeeListByShiftDates();
  }

  view(value: any) {
    this.isUpdate = true,
      this.isNew = true,
      this.shiftApproveForm.controls.appliedDate.setValue(value.applieddate);
    this.shiftApproveForm.controls.appliedDate.disable();
    this.shiftApproveForm.controls.fromDate.setValue(value.fromdate);
    this.shiftApproveForm.controls.fromDate.disable();
    this.shiftApproveForm.controls.toDate.setValue(value.todate);
    this.shiftApproveForm.controls.toDate.disable();
    this.shiftApproveForm.controls.fromShift.setValue(value.fromshift);
    this.shiftApproveForm.controls.toShift.setValue(value.toshift);
    this.shiftApproveForm.controls.reason.setValue(value.reason);
    this.shiftApproveForm.controls.employee.setValue(value.employee_name);
    this.shiftApproveForm.controls.assignEmployee.setValue(value.swap_emp_name);
    this.shiftApproveForm.controls.description.setValue(value.approvercomments);
    // this.shiftApproveForm.controls.description.disable();
  }
  back() {
    this.isNew = false;
    this.isUpdate = false;
  }

  getEmployeeListByShiftDates() {
    let fromDate = this.datePipe.transform(this.shiftApproveForm.controls.fromDate.value, "y-MM-d");
    let toDate = this.datePipe.transform(this.shiftApproveForm.controls.toDate.value, "y-MM-d");
    let data = {
      "empid_value": this.employeeId,
      "to_shiftid_value": this.toShiftId,
      "rm_id_value": this.rmId,
      "fromdate_value": fromDate,
      "todate_value": toDate,
    }
    this.attendanceService.getEmployeesListByShiftDates(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.swapEmployeesList = res.data;
      }
    })
  }
  onChange(value: any) {
    this.swapEmpShiftId = value.config_shiftid;
    this.swapEmployeeId = value.id;
  }
  reject() {
    this.titleName = "Reject"
    this.openDialog();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px', position: { top: `100px` },
      data: { name: this.titleName, reason: this.reason, }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {
        if (result !== true) {
          this.reason = result.reason;
          this.saveReject();
        }
      }
    });
  }
  saveReject() {
    this.spinner.show()
    let data = {
      "id_value": this.requestId,
      "swap_empid_value": null,
      "swap_emp_shiftid": null,
      "approvercomments_value": this.reason,
      "actionby_value": this.rmId,
      "status_value": "Rejected",
    };
    this.btnDisable = true;
    this.attendanceService.setShiftRequestApproval(data).subscribe((res: any) => {
      if (res.status && res.data == 0) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `100px` },
          disableClose: true,
          data: this.ATT89
        });
        this.spinner.hide();
        this.resetForm();

      } else if (res.status && res.data == 1) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `100px` },
          disableClose: true,
          data: this.ATT84
        });
        this.spinner.hide();
        this.resetForm();

      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `100px` },
          disableClose: true,
          data: this.ATT88
        });
        this.btnDisable = false;
        this.spinner.hide();
      }
    })

  }
  resetForm() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/shift-change-approve"]));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "ATT84") {
            this.ATT84 = e.message
          } else if (e.code == "ATT87") {
            this.ATT87 = e.message
          } else if (e.code == "ATT88") {
            this.ATT88 = e.message
          } else if (e.code == "ATT89") {
            this.ATT89 = e.message
          }
        })
      }
      else {
        this.messagesDataList = [];
      }

    })
  }


  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }


}
