<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> Separation Pending Approvals </mat-card-title>
    </mat-card-header>

    <div *ngIf="ishide" class="table-pad">
      <br>
      <div class="mat-elevation-z1 example-container">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef><span style="text-align: nowrap;">S. No.</span></th>
            <td mat-cell *matCellDef="let element; let i=index;">
              {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-wrap">
              Emp. Name
            </th>
            <td mat-cell *matCellDef="let row" class="no-wrap">
              <span>{{ row.empname }} </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row">
              <span>{{ row.status }} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef></th>

            <td mat-cell *matCellDef="let row">
              <!-- <mat-icon title="Edit"  (click)="edit($event,row)" >edit</mat-icon> -->
              <button mat-icon-button [tabIndex]="[1]" (keyup.enter)="view($event, row)">
                <mat-icon title="View and Approve" (click)="view($event, row)">visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4" style="text-align: center">
              No data found
            </td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5,10, 20]"></mat-paginator>
      </div>
    </div>

    <div *ngIf="isview" class="dev-Pad" ngStyle.xs="padding-top:5%">
      <form [formGroup]="pendingapprovalForm" autocomplete="off">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Employee Id</mat-label>
            <input formControlName="empid" matInput placeholder="" readonly />
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Employee Name</mat-label>
            <input formControlName="empname" matInput placeholder="" readonly />
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Date of Request Separation </mat-label>
            <input matInput [readonly]="true" placeholder="DD-MM-YYYY" formControlName="appliedDate" [disabled]="true"
              [matDatepicker]="date" [min]="mindate" [max]="maxdate" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
            </mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Notice Period</mat-label>
            <input formControlName="noticeperiod" matInput placeholder="" readonly />
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Relieving Date </mat-label>
            <input matInput readonly [disabled]="true" [matDatepicker]="date2" [min]="mindate" [max]="maxdate"
              placeholder="DD-MM-YYYY" formControlName="releivingdate" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="date2">
            </mat-datepicker-toggle>
            <mat-datepicker #date2></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label> Request Exit Date </mat-label>
            <input matInput readonly [disabled]="true" [matDatepicker]="date3" [min]="mindate" [max]="maxdate"
              placeholder="DD-MM-YYYY" formControlName="requestedDate" required />

            <mat-datepicker-toggle class="datepicker" matSuffix [for]="date3">
            </mat-datepicker-toggle>
            <mat-datepicker #date3></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex="65" class="mx-2 my-2" appearance="outline">
            <mat-label>Reason</mat-label>
            <textarea formControlName="reason" matInput placeholder="" readonly>
          </textarea>
          </mat-form-field>

          <mat-form-field *ngIf="pendingapprovalForm.controls.actualRelievingDate.value" fxFlex.xs="100" fxFlex
            class="mx-2 my-2" appearance="outline">
            <mat-label> Actual Relieving Date </mat-label>
            <input matInput readonly [disabled]="true" [matDatepicker]="date3" [min]="mindate" [max]="maxdate"
              placeholder="DD-MM-YYYY" formControlName="actualRelievingDate" />

            <mat-datepicker-toggle class="datepicker" matSuffix [for]="date3">
            </mat-datepicker-toggle>
            <mat-datepicker #date3></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" *ngIf="!isAproverCmt" fxLayout.xs="column" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex="62" class="mx-2 my-2" appearance="outline">
            <mat-label>Approver Reason</mat-label>
            <textarea formControlName="approverReason" matInput placeholder="" maxlength="250" readonly>
          </textarea>
          </mat-form-field>
          <!--<mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">-->
          <!--<mat-label> Actual Relieving Date  </mat-label>-->
          <!--<input matInput readonly [disabled]="true"-->
          <!--placeholder="DD-MM-YYYY" formControlName="actualRelievingDate"  required >-->

          <!--&lt;!&ndash;<mat-datepicker-toggle class="datepicker" matSuffix [for]="date3">&ndash;&gt;-->
          <!--&lt;!&ndash;</mat-datepicker-toggle>&ndash;&gt;-->
          <!--&lt;!&ndash;<mat-datepicker #date3></mat-datepicker>&ndash;&gt;-->
          <!--</mat-form-field>-->
        </div>
      </form>
      <div fxLayoutAlign="center">
        <button *ngIf="pendingapprovalForm.controls.status.value == 'Submitted'"
          class="btn btn-primary custom mr-2 mb-2" (click)="approve()">
          Approve
        </button>
        <button *ngIf="pendingapprovalForm.controls.status.value == 'Submitted'" class="btn btn-danger mr-2 mb-2"
          (click)="reject()">
          Reject
        </button>
        <button class="btn btn-danger mr-2 mb-2" (click)="Cancel()">Cancel</button>
      </div>
    </div>
    <br />
  </mat-card>
</div>