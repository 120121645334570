import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserData } from 'src/app/modules/attendance/models/EmployeeData';
import { PayrollService } from '../../payroll.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { Moment } from 'moment';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { Router } from '@angular/router';
const moment = _moment;
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PdfHeaderService } from 'src/app/services/pdf-header-service/pdf-header.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-advance-salary-report',
  templateUrl: './advance-salary-report.component.html',
  styleUrls: ['./advance-salary-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdvanceSalaryReportComponent implements OnInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  advancesalaryreportForm: any = UntypedFormGroup;
  locationList: any = [];
  dataSource: MatTableDataSource<UserData> = <any>[];
  companyDBName: any = [];
  searchControl = new FormControl('');
  searchControlemp = new FormControl('');
  filteredLocations: any = [];
  filteredEmployees: any = [];
  displayedColumns = ['sno', 'empid', 'empname', 'advanceamount', 'tenure', 'installmentamount', 'recoveredamount', 'installmentspaid', 'remainingbalanceamount']
  date: any;
  minDate: any = new Date('2022-01-01');
  max: any;
  employeelist: any = [];
  userSession: any;
  @ViewChild('table') table!: ElementRef;
  List: any[] = [ ];
  year: any;
  monthdata: any;
  maxDate:any=new Date();
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  months = [{ id: 0, month: 'Jan' }, { id: 1, month: 'Feb' }, { id: 2, month: 'Mar' }, { id: 3, month: 'Apr' }, { id: 4, month: 'May' }, { id: 5, month: 'Jun' }, { id: 6, month: 'Jul' }, { id: 7, month: 'Aug' }, { id: 8, month: 'Sep' }, { id: 9, month: 'Oct' }, { id: 10, month: 'Nov' }, { id: 11, month: 'Dec' }]
  constructor(
    private formBuilder: FormBuilder,
    private payrollService: PayrollService,
    private companyService: CompanySettingService,
    public datePipe: DatePipe,
    private emsService: EmsService,
    private router:Router,
    public reportsService: ReportsService,
    private pdfService: PdfHeaderService,
  ) { }
  pageLoading=true;
  ngOnInit(): void {
    this.companyDBName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');

    this.advancesalaryreportForm = this.formBuilder.group({
      location: ['', Validators.required],
      empname: [''],
      fromDate: ['', Validators.required],
    });
    this.date = new UntypedFormControl(moment());
    let prevDate = new Date();
    prevDate.setDate(0)
    this.max = prevDate;
    this.getWorkLocation();
    this.advancesalaryreportForm.get('location')?.valueChanges.subscribe((selectedValue: any) => {
      this.advancesalaryreportForm.controls.empname.value = selectedValue;
      this.getEmployeelist();
  })
  this.searchControl.valueChanges.subscribe(searchText => {
    this.filterDesignations(searchText);
  });
  this.searchControlemp.valueChanges.subscribe(searchText => {
    this.filterEmployeeList(searchText);
  })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
  }
}
  submit(){
   let empid =  this.advancesalaryreportForm.controls.empname.value == '0' ? null : this.advancesalaryreportForm.controls.empname.value;
   let data = {
      locationId: this.advancesalaryreportForm.controls.location.value,
      year: this.date.value._d.getFullYear(),
      month: this.date.value._d.getMonth()+1,
      employeeId: empid,
    }

    this.payrollService.getEmployeeWiseAdvanceSalaryReportAdmin(data).subscribe((result: any) => {
      this.List = result.data;
      this.dataSource = new MatTableDataSource(result.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading=false;
    })
  }

  clear(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/advanceSalaryReport"])); 
  }


  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyDBName }).subscribe((result) => {
      this.locationList = result.data;
      this.filteredLocations = this.locationList;
      this.advancesalaryreportForm.controls.location.setValue(this.locationList[0].id);
      this.getEmployeelist();
      this.advancesalaryreportForm.controls.empname.setValue('0');
      this.submit();
    });
 }

  filterDesignations(searchText: any) {
    this.filteredLocations = this.locationList.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) ||
      val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControl.setValue('');
    } else {

    }
  }
  filterEmployeeList(searchText: any) {
    this.filteredEmployees = this.employeelist.filter((val: any) =>
      val.ename.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployees.length <= 0) {
      this.searchControlemp.setValue('');
    } else {

    }
  }
  getEmployeelist() {
    this.employeelist = [];
    let  locationId = this.advancesalaryreportForm.controls.location.value;
    this.emsService.getEmployeeDirectoryList(locationId).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.employeelist = [];
        this.employeelist = res.data;
        this.filteredEmployees = this.employeelist
        this.advancesalaryreportForm.controls.empname.setValue('0');
      }
    })
  }

  public exportPDF(): void {
    let date = (this.datePipe.transform(this.advancesalaryreportForm.controls.fromDate.value, "MM-YYYY"))
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: date + "-" + "Advance Salary Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: date + " - " + "Attendance Monthly Detailed Report \n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        // {
        //   text:
        //     "Designation :  " + this.designationForPdf +"\n" +
        //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
        //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
        //   fontSize: 10,
        //   margin: [0, 0, 0, 20],
        //   alignment: 'left'
        // },
        html,

      ], styles: {
        'html-table': {
          background: 'white' // it will add a yellow background to all <STRONG> elements
        }
      },
      pageOrientation: 'landscape',
      pageSize: 'A3'
      //'portrait'
    }).download("Attendance Salary Report.pdf");

  }

  exportAsXLSX() {
    let year = this.date.value._d.getFullYear();
    let month = this.date.value._d.getMonth()+1;
    // Format the data to match the required columns
    const exportData = this.List.map((row, index) => ({
      "S.No": index + 1,
      "Emp.ID": row.empcode,
      "Emp.Name": row.empname,
      "Adv. Sal. Amt": row.advamount,
      "Tenure":row.tenure ,
      "EMI": row.emiamount,
      "Recovered Amt": row.recoveredamount,
      "Installments Paid": row.recoveredmonths,
      "Remaining Amt": row.amounttoberecovered,
    }));
  
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'EmployeeAdvanceSalaryReport');
    /* save to file */
    XLSX.writeFile(wb, month + '-'+ year + " - " + 'employeeAdvanceSalaryReport.xlsx');
  
  }

  getPageSizes(): number[] {
      
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
    }

    generatePDF() {
  
      const doc = new jsPDF({ orientation: 'landscape' });
      const reportName = 'Advance Salary Report';
      let formattedDate: string;
      formattedDate = this.datePipe.transform(this.date.value._d, 'MM-yyyy') || '';

      let manager = '';
      let locationName = '';
      let reportDate = formattedDate;
      this.pdfService.generatePDFHeader(doc, reportName,locationName,manager,reportDate,false);
      const tableData = this.List.map((emp:  { 
        empcode: any;    
        empname: any;    
        advamount: any;  
        tenure: any ;
        emiamount: any;   
        recoveredamount: any; 
        recoveredmonths: any; 
        amounttoberecovered: any;
         }, index: number) => [
       
          index + 1,
        emp.empcode,
        emp.empname,
        emp.advamount,
        emp.tenure,
        emp.emiamount,
        emp.recoveredamount,
        emp.recoveredmonths,
        emp.amounttoberecovered,

      ]);
            autoTable(doc, {
              head: [['S.No.','Emp.ID','Emp.Name', 'Adv. Sal. Amt','Tenure','EMI','Recovered Amt','Installments Paid','Remaining Amt']],
              body:tableData,
              startY: 40,
              styles: { fontSize: 10 },
              headStyles: { fontSize: 12 }
           });
         doc.save('advance_salary_report.pdf');
    } 
}
