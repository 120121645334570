<div fxLayout="column" fxFill class="custom-pgr-height-auto first-col">
    <mat-card fxFill class="mat-elevation-z0">
        <mat-card-header class="headerbox">
          <div fxLayout="row" fxFlex="50">
            <mat-card-title class="title"> Pay Slips </mat-card-title>
        </div>
        </mat-card-header>
    
          <div fxLayout="column" class="div-pad">      
                <form [formGroup]="payslipsForm" autocomplete="off">
                  <br>
                    <div fxLayout="row" fxLayoutAlign="end">
                      <mat-form-field appearance="outline" fxFlex="28" fxFlex.xs="100">
                            <mat-label>Financial Year</mat-label>
                            <mat-select formControlName="financial_year">
                              <ng-container>
                                <div *ngFor="let fy of financeyears">
                                    <mat-option [value]="fy.return_value">{{fy.financial_year}}</mat-option>
                                </div>
                            </ng-container>
                          </mat-select>

                        </mat-form-field>
                     </div>
             <div fxLayout="column">
              <div class="mat-elevation-z1">
                <table mat-table [dataSource]="dataSource" matSort class="w-100 mat-elevation-z1">
                
                  <ng-container matColumnDef="Month">
                    <th mat-header-cell *matHeaderCellDef>Month & Year</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.salary_month}} </td>
                  </ng-container>
                
                  <ng-container matColumnDef="Amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.salary}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="Active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element">
                      <mat-icon (click)="onViewClick(element)" style="cursor: pointer;" title="View" >visibility</mat-icon> 
                    </td>
                  </ng-container>
                 <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                  </tr>
                </table>
              <!-- <table mat-table [dataSource]="dataSource"   multiTemplateDataRows>
                 <ng-container matColumnDef="Month">
                  <th   mat-header-cell *matHeaderCellDef> Month & Year </th>
                  <td mat-cell *matCellDef="let element"> {{element.salary_month}} </td>
                </ng-container>
        
                <ng-container matColumnDef="Amount">
                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                    <td  mat-cell *matCellDef="let element">{{element.salary}}
                    </td>
                  </ng-container>
                 <ng-container matColumnDef="Active">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td  mat-cell *matCellDef="let element"> 
                      <mat-icon (click)="onViewClick(element)" style="cursor: pointer;" title="View" >visibility</mat-icon> 
                   </td>
                  </ng-container>   
                <tr mat-header-row  class="dashboard-1 custom-investment-header" *matHeaderRowDef="displayedColumns;"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></tr>
             </table> -->
             </div>
            </div>
            </form>
  
        </div>
    </mat-card>
</div>   

