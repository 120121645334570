<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> Separation </mat-card-title>

    </mat-card-header>
    <form [formGroup]="resignForm" autocomplete="off">
      <div *ngIf="this.isview" class="div-Pad">

        <span>
          Dear {{ empname }},<br />

          We are concerned that you have visited this portal.<br /><br />

          It is our endeavor to provide a professional environment where
          employees can grow and in turn help
          {{ company }} grow. We would like to create opportunities within
          {{ company }} and eliminate conditions and factors that compel
          employees to leave or think of leaving {{ company }} family.<br />

          The company has a policy, which encourages an effective two-way
          communication process. We request and strongly recommend that you
          discuss your decision of leaving the organization with your
          Reporting Manager or your HR. It is in your own interest that the
          Exit Process is taken to its logical conclusion. Leaving
          {{ company }} without intimation and proper Exit formalities is
          counter-productive and may lead to inconvenience for you, your next
          organization and {{ company }}. <br /><br />

          Warm Regards,<br />
          Human Resources.
        </span>
        <br />
        <br />
        <div fxLayoutAlign="center">
          <button class="btn btn-primary custom mr-2 mb-2" (click)="submit()" type="submit">
            <span>Proceed</span>
          </button>
          <!-- <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
              <span>Cancel</span>
            </button> -->
          <br />
        </div>

        <br />
      </div>

      <div *ngIf="this.ishide">
        <div class="div-Pad" fxLayout="column" *ngIf="isresign">
          <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
              <mat-label>Applied Date </mat-label>
              <input matInput readonly [min]="min" [max]="max" [matDatepicker]="date1" placeholder="DD-MM-YYYY"
                formControlName="resigndate" [disabled]="true" [tabIndex]="1" />

              <mat-datepicker-toggle class="datepicker" matSuffix [for]="date1">
              </mat-datepicker-toggle>
              <mat-datepicker #date1></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
              <mat-label>Notice Period</mat-label>
              <input formControlName="noticperiod" readonly matInput placeholder="" maxlength="3" [tabIndex]="2" />
            </mat-form-field>

            <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
              <mat-label>Relieving Date </mat-label>
              <input matInput readonly [matDatepicker]="date2" placeholder="DD-MM-YYYY" formControlName="releivingdate"
                [disabled]="true" [tabIndex]="3" />

              <mat-datepicker-toggle class="datepicker" matSuffix [for]="date2">
              </mat-datepicker-toggle>
              <mat-datepicker #date2></mat-datepicker>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <div fxLayout="row" fxFlex="67" fxFlex.lt-lg="100" fxLayout.xs="column">
              <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-label>Requesting Exit Date </mat-label>
                <input matInput [matDatepicker]="date3" [max]="max" [min]="min" placeholder="DD-MM-YYYY"
                  formControlName="exitdate" (click)="date3.open()" [disabled]="isviewdata" required [tabIndex]="4" />

                <mat-datepicker-toggle class="datepicker" matSuffix [for]="date3">
                </mat-datepicker-toggle>
                <mat-datepicker #date3></mat-datepicker>
                <mat-error class="con-error" *ngIf="resignForm.controls.exitdate.errors?.required">
                  {{requiredField}}
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-label>Reason</mat-label>
                <mat-select [disabled]="isviewdata" formControlName="reason" required [tabIndex]="5">
                  <ng-container>
                    <div *ngFor="let r of reasondata">
                      <mat-option [value]="r.id" (onSelectionChange)="reasonSelect(r)">{{ r.reason }}</mat-option>
                    </div>
                  </ng-container>
                </mat-select>
                <mat-error class="con-error" *ngIf="resignForm.controls.reason.errors?.required">
                  {{requiredField}}
                </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div fxLayout="row" fxFlex="100">
            <div fxLayout="row" fxFlex="67" fxFlex.lt-lg="100" fxLayoutAlign.xs="center">
              <mat-form-field fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-label>Notes</mat-label>
                <textarea formControlName="notice" matInput placeholder="" [readonly]="isviewdata" maxlength="250"
                  [tabIndex]="6"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxFlex="100">
            <div fxLayout="row" fxFlex="67" fxFlex.lt-lg="100" fxLayoutAlign.xs="center" *ngIf="isApproverComment">
              <mat-form-field fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-label>Approver Comment</mat-label>
                <textarea formControlName="approverComment" matInput placeholder="" [readonly]="isviewdata"
                  maxlength="250" [tabIndex]="6"></textarea>
              </mat-form-field>
            </div>
          </div>


          <div fxLayoutAlign="center">
            <button class="btn btn-primary mr-2 mb-2" [tabIndex]="7" [disabled]="btnDisable"
              [ngClass]="{'disable':btnDisable}" (click)="saved()" *ngIf="isadd">
              Submit
            </button>
            <button class="btn btn-primary mr-2 mb-2" [tabIndex]="8" [disabled]="btnDisable"
              [ngClass]="{'disable':btnDisable}" *ngIf="editing" (click)="editsaved()">
              Update
            </button>
            <button class="btn btn-danger mr-2 mb-2" [tabIndex]="9" (click)="clear()">
              Cancel
            </button>
          </div>
        </div>
        <mat-divider></mat-divider>
        <br>

        <div class="table-pad">
          <div class="mat-elevation-z1 example-container">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  <span>{{ i + 1 }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                  Date of Request
                </th>
                <td mat-cell *matCellDef="let row">
                  <!-- <span>{{row.programtype}} </span> -->
                  <span>{{ row.applied_date | date: "dd-MM-yyyy" }} </span>
                  <!--  <span *ngIf="enable === row.id">
                        <mat-form-field appearance="outline">
                        <input formControlName="resigndate" matInput placeholder="{{row.applied_date}}" >

                    </mat-form-field>
                    </span> -->
                </td>
              </ng-container>
              <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef>Reason</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.reason }} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{ row.status }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row,let i = index">
                  <button mat-icon-button title="Edit" [tabIndex]="[i+9]" (keyup.enter)="edit($event, row)"
                    *ngIf="row.status == 'Submitted'">
                    <mat-icon (click)="edit($event, row)" *ngIf="row.status == 'Submitted'" title="Edit">edit</mat-icon>
                  </button>
                  <button mat-icon-button title="View" [tabIndex]="[i+9]" (keyup.enter)="view($event, row)">
                    <mat-icon (click)="view($event, row)">visibility </mat-icon>

                  </button>
                  <button mat-icon-button title="Edit" [tabIndex]="[i+9]" (keyup.enter)="cancelresignation($event, row)"
                    *ngIf="row.status == 'Submitted'">
                    <mat-icon (click)="cancelresignation($event, row)" title="Cancel" *ngIf="row.status == 'Submitted'">
                      cancel</mat-icon>
                  </button>



                </td>
              </ng-container>
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" style="text-align: center">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>