
<div fxLayout="column" class="first-col">
<mat-card class="mat-elevation-z0">
    <mat-card-header  class="headerbox" fxLayout="row">
        <mat-card-title class="title">TDS Deduction Entry</mat-card-title>
    </mat-card-header>
        <mat-tab-group>
            <mat-tab label="TDS Entry"> 
            <div [formGroup]="searchForm" style="padding-top: 1%;">
                <div class="responsive-form">
                    <mat-form-field class="mx-2 my-2" appearance="outline">
                        <mat-label>City-Branch </mat-label>
                        <mat-select placeholder="" name="companylocation" formControlName="companylocation" required >
                            <input matInput [formControl]="searchControlforLoc" type="text" placeholder="  Search"
                                class="search-align">
                            <mat-option *ngFor="let res of filteredLocations" [value]="res" (onSelectionChange)="displayEmployeeEligibleForTDS()"
                                >
                                {{res.cityname}}-{{res.location}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
                            Please select an option
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mx-2 my-2" appearance="outline">
                        <mat-label>Month and Year</mat-label>
                        <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                        [min]="minDate" [max]="max" (click)="fromDate.open()">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                        <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                        panelClass="example-month-picker">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div>
                  <div class="tableP">
                    <div class="mat-elevation-z1 example-container">
                      <table mat-table [dataSource]="dataSource" matSort class="mat-table">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                          </td>
                        </ng-container>
                
                        <ng-container matColumnDef="sno">
                          <th mat-header-cell *matHeaderCellDef class="no-break"> S. No.</th>
                          <td mat-cell *matCellDef="let element; let i=index;">
                            {{ (i+1) }}
                          </td>
                        </ng-container>
                
                        <ng-container matColumnDef="employeeid">
                          <th mat-header-cell *matHeaderCellDef class="no-break" style="white-space: nowrap;"> Emp. Code </th>
                          <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.employeeid}}</td>
                        </ng-container>
                
                        <ng-container matColumnDef="empname">
                          <th mat-header-cell *matHeaderCellDef class="no-break" style="white-space: nowrap;"> Emp. Name </th>
                          <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                        </ng-container>
                
                        <ng-container matColumnDef="amount">
                          <th mat-header-cell *matHeaderCellDef class="no-break"> TDS Amount </th>
                          <td mat-cell *matCellDef="let row; let i = index" style="white-space: nowrap;">
                            <mat-form-field appearance="outline">
                              <input matInput [formControl]="getTdsAmountControl(i)" required>
                            </mat-form-field>
                          </td>
                        </ng-container>
                
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                        </tr>
                      </table>
                      <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                  </div>
                
                  <div fxLayoutAlign="center">
                    <button class="btn btn-primary custom mr-2 mb-2" [disabled]="branchSelected" (click)="submit()" type="button">
                      <span>Submit</span>
                    </button>
                    <button class="btn btn-danger custom mr-2 mb-2" (click)="cancel()" type="button">
                      <span>Cancel</span>
                    </button>
                  </div>
                </div>
            </div>  
            </mat-tab>

            <mat-tab label="Completed"> 
              <div class="tableP">
                <div class="mat-elevation-z1 example-container">
                  <table mat-table [dataSource]="dataSource1" matSort class="mat-table">
                    <ng-container matColumnDef="sno">
                      <th mat-header-cell *matHeaderCellDef class="no-break"> S. No.</th>
                      <td mat-cell *matCellDef="let element; let i=index;">
                        {{ (i+1) }}
                      </td>
                    </ng-container>
            
                    <ng-container matColumnDef="employeeid">
                      <th mat-header-cell *matHeaderCellDef class="no-break" style="white-space: nowrap;"> Emp. Code </th>
                      <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.employeeid}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="empname">
                      <th mat-header-cell *matHeaderCellDef class="no-break" style="white-space: nowrap;"> Emp. Name </th>
                      <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef class="no-break"> TDS Amount </th>
                      <td mat-cell *matCellDef="let row;" style="white-space: nowrap;">
                      </td>
                    </ng-container>
            
                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
              </div>
               </mat-tab>
          </mat-tab-group>
          
</mat-card>
</div>