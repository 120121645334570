import { BehaviorSubject, Observable, Subscribable, Subscription } from 'rxjs';
import { UserDashboardService } from 'src/app/services/user-dashboard.service';
import { RoleMasterService } from 'src/app/services/role-master.service';
import { Component, OnInit, ElementRef, ViewChild, HostListener, VERSION } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { MainService } from 'src/app/services/main.service';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';




@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  self: any = 'Employee';
  menuList: any[] = [];
  menu: any[] = [];
  showFiller = false;
  isExpanded = false;
  element = HTMLElement;
  currentItem: any = null;
  currentChild: any = null;
  firstRoute: any;
  selectedModule: any = 'Spryple';
  usersession: any;
  activeModuleData: any;
  moduleName: any;
  flag: boolean = true;
  sideNavigationWidth: any = '20';
  istoolTipExp: boolean = true;
  istoolTip = "Expand";
  icon: boolean = false;
  expirydate: any;
  check: any = false;
  isMobile: boolean = false;
  position: any = {
    value: 'after'
  }
  isHovered: boolean = false;
  hoverimgId: any;
  selectedItemId: any;
  click(child: any) {
    child.isOpen = !child.isOpen;
    if (child.displayName === this.self)
      child.isOpen = true;
  }

  sidemenuHover(item: any) {
    this.moduleName = item.modulename;
  }

  toggleActive(item: any) {
    if (item.id == 3) {
      this.timesheet();
      return;
    }
    if (!(item.children && item.children[0])) {
      return;
    }

    this.isExpanded = true;
    this.menuList.forEach((m: any) => {
      m.displayStatus = false;
    })
    item.displayStatus = true;
    sessionStorage.setItem('selectedModule', item.modulename);
  }
  timesheet() {
    window.open('http://103.44.0.75:5050', '_blank');
  }

  onClickMainDashboard() {
    let isMainDB = false;
    if (this.href === '/main/MainDashboard') {
      isMainDB = true;
    } else {
      isMainDB = false;
    }
    if (this.usersession.is_super_admin == "1" && isMainDB == false) {
      sessionStorage.setItem('selectedModule', 'Spryple');
      this.router.navigate(['/main/client-superadmin-dashboard']);

    } else if (isMainDB == true) {
      sessionStorage.setItem('selectedModule', 'Spryple');
      this.router.navigate(['/main/MainDashboard']);

    } else {
      sessionStorage.setItem('selectedModule', 'Spryple');
      this.router.navigate(['/main/MainDashboard']);
    }
  }

  toggleExpand() {
    if (this.isExpanded && (sessionStorage.getItem('selectedModule') && sessionStorage.getItem('selectedModule') !== 'Spryple')) {
      this.isExpanded = true;
    } else this.isExpanded = false;

    if (sessionStorage.getItem('selectedModule') === 'Edit Profile')
      this.isExpanded = false;
    if (sessionStorage.getItem('selectedModule') === 'Change Password')
      this.isExpanded = false;
    return this.isExpanded;
  }
  /**for desktop and tab view */
  toggleChild(item: any, route: any) {
    this.selectedItemId = item.id;

    let _this = this;
    this.showSpinner();
    if (item.id == 3) {
      this.timesheet();
      return;
    }
    if (!(item.children && item.children[0])) {
      return;
    }
    this.isExpanded = true;

    this.menuList.forEach((m: any) => {
      if (m.children && m.children[0]) {

        m.children.forEach((c: any) => {
          if (c.subChildren && c.subChildren[0]) {
            c.isOpen = false;
            c.subChildren.forEach((sc: any) => {
              m.displayStatus = false;
              if (sc.routename === route) {
                c.isOpen = true;
                _this.currentItem = m;
                _this.selectedModule = _this.currentItem.modulename;
                sc.childStatus = true;
                _this.currentChild = sc;
                _this.activeModuleData.moduleid = _this.currentItem.id;
                _this.activeModuleData.module = _this.currentItem;
                // sessionStorage.setItem('selectedModule',_this.selectedModule );
                sessionStorage.setItem('activeModule', (_this.activeModuleData) ? JSON.stringify(_this.activeModuleData) : '');
                sessionStorage.setItem('activeChild', (_this.currentChild) ? JSON.stringify(_this.currentChild) : '');
                this.router.navigate([sc.routename]);
              }
              else {
                //  m.displayStatus = false;
              }
            });
          }
        });
      }
    });
    sessionStorage.setItem("moduleData", JSON.stringify(this.menuList));
    item.displayStatus = true;
    sessionStorage.setItem('selectedModule', item.modulename);
  }
  /**for mobile view */
  toggleMobileChild(item: any, route: any) {

    let _this = this;
    this.showSpinner();
    if (item.id == 3) {
      this.timesheet();
      return;
    }
    if (!(item.children && item.children[0])) {
      return;
    }
    this.isExpanded = true;

    this.menuList.forEach(function (m: any) {
      if (m.children && m.children[0]) {
        m.children.forEach(function (c: any) {
          if (c.subChildren && c.subChildren[0]) {
            c.isOpen = false;
            c.subChildren.forEach(function (sc: any) {
              m.displayStatus = false;
              if (sc.routename === route) {
                c.isOpen = true;
                _this.currentItem = m;
                _this.selectedModule = _this.currentItem.modulename;
                sc.childStatus = true;
                _this.currentChild = sc;
                _this.activeModuleData.moduleid = _this.currentItem.id;
                _this.activeModuleData.module = _this.currentItem;
                // sessionStorage.setItem('selectedModule',_this.selectedModule );
                sessionStorage.setItem('activeModule', (_this.activeModuleData) ? JSON.stringify(_this.activeModuleData) : '');
                sessionStorage.setItem('activeChild', (_this.currentChild) ? JSON.stringify(_this.currentChild) : '');
              }
              else {
                //  m.displayStatus = false;
              }
            });
          }
        });
      }
    });
    sessionStorage.setItem("moduleData", JSON.stringify(this.menuList));
    item.displayStatus = true;
    this.isExpanded = !this.isExpanded;
    this.istoolTipExp = this.isExpanded;
    if (this.istoolTipExp) {
      this.istoolTip = "Collapse";
    } else {
      this.istoolTip = "Expand";
    }
    sessionStorage.setItem('selectedModule', item.modulename);
  }

  /** for page navigating from leave/attendance requests */
  toggleRoute(route: any) {
    let _this = this;
    this.showSpinner();
    if (route === '/LeaveManagement/EditProfile') {
      this.isExpanded = false;
      sessionStorage.setItem('selectedModule', 'Spryple');
      return;
    }
    this.isExpanded = true;
    this.menuList.forEach(function (m: any) {
      if (m.children && m.children[0]) {
        m.children.forEach(function (c: any) {
          if (c.subChildren && c.subChildren[0]) {
            c.isOpen = false;
            c.subChildren.forEach(function (sc: any) {
              m.displayStatus = false;
              if (sc.routename === route) {
                c.isOpen = true;
                _this.currentItem = m;
                m.displayStatus = true;
                _this.selectedModule = _this.currentItem.modulename;
                sc.childStatus = true;
                _this.currentChild = sc;
                _this.activeModuleData.moduleid = _this.currentItem.id;
                _this.activeModuleData.module = _this.currentItem;
                sessionStorage.setItem('selectedModule', m.modulename);
                sessionStorage.setItem('activeModule', (_this.activeModuleData) ? JSON.stringify(_this.activeModuleData) : '');
                sessionStorage.setItem('activeChild', (_this.currentChild) ? JSON.stringify(_this.currentChild) : '');
              }
              else {
                //  m.displayStatus = false;
              }
            });
          }
        });
      }
    });
    sessionStorage.setItem("moduleData", JSON.stringify(this.menuList));

  }


  toggleBack(event: any) {
    let _this = this;
    this.isExpanded = !this.isExpanded;
    this.istoolTipExp = this.isExpanded;
    if (this.istoolTipExp) {
      this.istoolTip = "Collapse";
    } else {
      this.istoolTip = "Expand";
    }
  }

  // getRouterStyle(){
  //   return this.isExpanded? '16%':'';
  // }

  @ViewChild('parentMenu') parentMenu!: ElementRef;
  @ViewChild('childMenu') childMenu!: ElementRef;

  constructor(private mainService: MainService, private baseService: BaseService, private UD: UserDashboardService,
    private spinner: NgxSpinnerService, public datePipe: DatePipe, public router: Router) {
    this.usersession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.expirydate = sessionStorage.getItem('expirydate');
    if (this.usersession.firstlogin == "N")
      this.flag = true;
    else this.flag = false;
    this.isExpanded = this.flag;
    // this.isMobile();
  }
  private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  href: any;

  ngOnInit(): void {
    //  if (navigator.userAgent.indexOf("Firefox") != -1) {
    //   this.sideNavigationWidth = 30;
    // }
    this.href = this.router.url;
    this.getScreenWidth().subscribe((width) => {
      if (width < 768) {
        this.isExpanded = false;
        this.isMobile = true;
      } else if (width > 768) {
        this.isExpanded = true;
        this.isMobile = false;
      }

    });
    // this.usersession = JSON.parse(sessionStorage.getItem('user') ?? '');

    this.isExpanded = true;
    this.activeModuleData = {
      empid: this.usersession.id
    };
    const date = new Date();
    const formattedDate: any = this.datePipe.transform(date, 'yyyy-MM-dd');

    if (this.expirydate >= formattedDate) {
      this.validateSideNavigation();
    }
    /**this call for if client subscription date expired then superadmin need to acces only subscription module. */
    else {
      this.getScreensForSuperAdmin();
    }


  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth$.next(event.target.innerWidth);
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

  validateSideNavigation() {
    if (sessionStorage.getItem("moduleData") && sessionStorage.getItem("moduleData")?.toString().length) {
      let menu = sessionStorage.getItem("moduleData");
      if (menu && menu.toString().length) {
        this.menu = JSON.parse(menu);
        if (this.menu[0].employee_id !== this.usersession.id || this.menu[0].companyName !== sessionStorage.getItem('companyName'))
          sessionStorage.setItem("moduleData", '');
      }
    }
    this.getSideNavigation();
  }


  getSideNavigation() {
    this.showSpinner();
    if (!(sessionStorage.getItem("moduleData") && sessionStorage.getItem("moduleData")?.toString().length)) {
      this.spinner.show();
      this.mainService.getSideNavigation({ empid: this.usersession.id, companyName: sessionStorage.getItem('companyName') }).subscribe((res: any) => {
        this.spinner.hide();
        this.menuList = res.data;
   
        sessionStorage.setItem("moduleData", JSON.stringify(this.menuList));
        sessionStorage.setItem('selectedModule', 'Spryple');
        sessionStorage.setItem('compOffEnabled', res.compOffEnabled ? 'Active' : 'Inactive');
      });
    }
    else {
      let menuList = sessionStorage.getItem("moduleData");
      if (menuList && menuList.toString().length)
        this.menuList = JSON.parse(menuList);
    }

    if (sessionStorage.getItem('selectedModule') === 'Spryple') {
      this.isExpanded = false;
      this.onClickMainDashboard();

    } else if (sessionStorage.getItem('selectedModule') === 'Edit Profile') {
      this.isExpanded = false;
      this.router.navigate(['LeaveManagement/EditProfile']);

    } else if (sessionStorage.getItem('selectedModule') === 'Change Password') {
      this.isExpanded = false;
      this.router.navigate(['Attendance/ChangePassword']);
    }
    else {
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i].displayStatus = this.menuList[i].modulename === sessionStorage.getItem('selectedModule');
      }
    }

  }

  getScreensForSuperAdmin() {
    this.showSpinner();
    if (!(sessionStorage.getItem("moduleData"))) {
      this.spinner.show();
      this.mainService.getScreensForSuperAdmin({ empid: this.usersession.id }).subscribe((res: any) => {
        this.spinner.hide();
        this.menuList = res.data;
        sessionStorage.setItem("moduleData", JSON.stringify(this.menuList));
        sessionStorage.setItem('selectedModule', 'Spryple');
      });
    }
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000); // 2 seconds
  }
  filterByStatus(sc: any) {

    sc.forEach(function (x: any) {
      x.status = 1;
      if (x.routename.toString().toLowerCase().includes('compoff'))
        x.status = (sessionStorage.getItem("compOffEnabled") === 'Inactive') ? 0 : 1;
    });
    return sc.filter((x: any) => x.status === 1);
  }

  onHover(item: any) {
    this.hoverimgId = item.id;

  }

  onLeave() {
    this.hoverimgId = null;
  }
}
