import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { Router } from '@angular/router';
export interface UserData {
  id: number;
  department: string;
  designation: string;
  status: string;
}

@Component({
  selector: 'app-department-designation-mapping',
  templateUrl: './department-designation-mapping.component.html',
  styleUrls: ['./department-designation-mapping.component.scss']
})
export class DepartmentDesignationMappingComponent implements OnInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  pageLoading = true;
  dataSource!: MatTableDataSource<UserData>;;
  mappingForm: any = UntypedFormGroup;
  searchControl = new FormControl('');
  filteredDepartments: any = [];
  availableDepartments: any = [];
  companyDBName: any = environment.dbName;
  availableDesignations: any = [];
  filteredDesignations: any = [];
  searchControlfordesig = new FormControl('');
  displayedColumns = ['sno', 'department', 'designations'];
  designationIshide: boolean = false;
  designationIschecked: boolean = false;
  selectedDesignations: any = [];
  arrayValue: any;
  ismappingEnable: boolean = true;
  mappingValue: any;
  mappingData: any;
  btnDisable:boolean = false;

  constructor(
    private companyService: CompanySettingService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private dialog: MatDialog,
    private router: Router,

  ) {
    this.getDesignationDepartmentMappingEnabledFlag();
   }

  ngOnInit() {
    this.mappingForm = this.formBuilder.group({
      department: ['', Validators.required],
      designation: ['', Validators.required]
    });
if(this.ismappingEnable){
  this.getDepartmentsMaster();
  this.getDesignationsMaster();
  this.searchControl.valueChanges.subscribe(searchText => {
    this.filterDepartments(searchText)
  });
  this.searchControlfordesig.valueChanges.subscribe(searchText => {
    this.filterDesignations(searchText)
  });
}

  }

  ngAfterViewInit() {
    this.getDepartDesigMapping();
    this.pageLoading = true;
  }

  getDepartmentsMaster() {
    this.companyService.getMastertable('departmentsmaster', 1, 1, 1000, this.companyDBName).subscribe(data => {
      if (data.status) {
        this.availableDepartments = data.data;
        this.filteredDepartments = this.availableDepartments;
      }
    })
  }

  filterDepartments(searchText: any) {
    this.filteredDepartments = this.availableDepartments.filter((val: any) =>
      val.deptname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredDepartments.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  getDesignationsMaster() {
    this.companyService.getMastertable('designationsmaster', 1, 1, 1000, this.companyDBName).subscribe(data => {
      if (data.status) {
        this.availableDesignations = data.data;
        this.filteredDesignations = this.availableDesignations;
      }
    })

  }

  filterDesignations(searchText: any) {
    this.filteredDesignations = this.availableDesignations.filter((val: any) =>
      val.designation.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredDesignations.length <= 0) {
      this.searchControlfordesig.setValue('');
    }
  }

  resetDesignations() {
    this.mappingForm.controls.designation.setValue('')
  }

  setMapping() {
    if (this.mappingForm.valid) {
      if(this.btnDisable){
        return;
      }
      let data = {
        department_id: this.mappingForm.controls.department.value,
        designation_ids: JSON.stringify(this.selectedDesignations),
      }
      this.btnDisable = true;
      this.adminService.setDepartDesignMapping(data).subscribe((result: any) => {
        if (result.status) {
          if (result.data[0].successstate == 0) {
            this.navigationToMapping();
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Designation mapped successfully.'
            })
          } else if (result.data[0].successstate) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Selected Designations and departments are already mapped.'
            })
            this.btnDisable = false;
            this.cancel();
          }
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to map designations with departments.'
          })
          this.btnDisable = false;
          this.cancel();

        }
      })
    }
  }

  cancel() {
    this.mappingForm.controls.designation.setValue('');
    this.mappingForm.controls.department.setValue('');
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getDepartDesigMapping() {
    let data = {
      department_id: 0
    }
    this.adminService.getDepartDesigMapping(data).subscribe((info: any) => {

      if (info.status && info.data[0].length != 0) {
        this.mappingData = info.data[0];
        this.dataSource = new MatTableDataSource(this.mappingData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    })
  }

  departmentSelectAll(select: MatSelect, values: any, array: any) {
    this.designationIshide = true;
    this.designationIschecked = true;
    select.value = values;
    array = values;
    this.mappingForm.controls.designation.setValue(array)
    let designations = this.mappingForm.controls.designation.value;
    designations.forEach((e: any) => {
      this.selectedDesignations.push(e.id);
    });
  }

  departmentDeselectAll(select: MatSelect) {
    this.designationIshide = false;
    this.designationIschecked = false
    select.value = [];
    this.mappingForm.controls.designation.setValue('');
    this.selectedDesignations = [];
  }

  onchangedepartment(res: any) {
    this.selectedDesignations = [];
    let designation = this.mappingForm.controls.designation.value;
    designation.forEach((e: any) => {
      this.selectedDesignations.push(e.id);
    });
  }

  status(status: any, id: any, deptname: any) {
    let data = {
      id: id,
      deptname: deptname,
      status: status,
      tableName: 'employee_designations',
      columnName: 'designationid',
      depthead: null,
      headcount: null
    }
    this.companyService.designationstatus(data).subscribe((result) => {
      if (result.status) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: status == 1 ? ' ' : ' '
        });

      } else {
        this.ngOnInit();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: ' '
        });
      }
    })
  }

  getstatuslist() {
    this.companyService.getMappingstatuslists().subscribe((result: any) => {
      if (result.status) {
        this.arrayValue = result.data;
      }
    })
  }

  getDesignationDepartmentMappingEnabledFlag() {
    this.companyService
      .getDesignationDepartmentMappingEnabledFlag()
      .subscribe((data: any) => {
        this.mappingValue = data.data[0].value;
        if (this.mappingValue == 0) {
          this.ismappingEnable = false;
        } else if (this.mappingValue == 1) {
          this.ismappingEnable = true;
        }
      });
  }
  navigationToMapping() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Admin/adminMapping"]));
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
}
