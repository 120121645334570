import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PayrollService } from '../../payroll.service';

@Component({
  selector: 'app-variable-pay-schedule',
  templateUrl: './variable-pay-schedule.component.html',
  styleUrls: ['./variable-pay-schedule.component.scss']
})
export class VariablePayScheduleComponent implements OnInit {

  constructor( private payrollService:PayrollService, private formBuilder:FormBuilder ) { }
  scheduleForm: any = UntypedFormGroup;
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = ['sno','year','duration','status','isallow','value','action'];
  variableList:any = [];
  pageLoading = true;
  permisionList:any = [{id:1,value:'Allow'},{id:2,value:'Denied'}]
  variablePayTypeList:any = [{id:1,value:'Lump Sum'},{id:2,value:'Equal Distrubtion'}]
  ngOnInit(): void {
    this.scheduleForm = this.formBuilder.group({

    })
    this.getVariablePayList();
  }

  getVariablePayList() {
    this.variableList = [];

      this.payrollService.getVariablePaySchedule().subscribe((res: any) => {
        if (res.status && res.data.length > 0 ) {
            console.log("data--",res.data)
            this.variableList = res.data
            this.dataSource = new MatTableDataSource(this.variableList);
            this.pageLoading = false;
       
      } else{
        this.dataSource = new MatTableDataSource(this.variableList);
        this.pageLoading = false;

      }
    })
  }

  onStatusChange(event: any, element: any) {
    console.log('Selected designation:', event.value, 'for', element);
  }

  onTypeChange(event: any, element: any) {
    console.log('Selected designation:', event.value, 'for', element);
  }

  editRequest(data:any){

  }

  reset(){

  }

}
