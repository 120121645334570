import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { PayrollService } from '../../payroll.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PdfHeaderService } from 'src/app/services/pdf-header-service/pdf-header.service';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-payable-days-report',
  templateUrl: './payable-days-report.component.html',
  styleUrls: ['./payable-days-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PayableDaysReportComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  searchForm: any = UntypedFormGroup;
  managerList: any = [];
  arrayList: any = [];
  minFromDate: Date | undefined;
  maxFromDate: Date | undefined;
  minToDate: Date | null;
  maxToDate: Date | undefined;
  currentDate: Date = new Date();
  List: any[] = [
  ];
  dataSource: MatTableDataSource<any> = <any>[];
  dataSource2: MatTableDataSource<any> = <any>[];
  calendarColumns: string[] = ['sno','empid','empname','department','designation','presents','absents','leaves','paidweekoff','paiddays','lopdays'];
  workingDaysColumns: string[] = ['sno','empid','empname','department','designation','presents','absents','leaves','workdays','paiddays','lopdays'];
  pageLoading = true;
  searchControl = new FormControl('');
  empSearchControl = new FormControl('');
  filteredEmployees: any = [];
  userSession: any;
  isLoading = false;
  locationList: any = [];
  filteredLocations: any = [];
  companyDBName: any;
  date: any;
  minDate: any = new Date('2000-01-01');
  max = this.getEndOfMonth(new Date());
  monthfirstDay: any;
  monthLastDay: any;
  isCalenderDaysPayroll:boolean=false;
 
  /**Here select Month and Date from month year picker */
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public datePipe: DatePipe,
    private reportsService: ReportsService,
    private companyService: CompanySettingService,
    public dialog: MatDialog,
    private payrollService: PayrollService,
    private pdfService: PdfHeaderService,

  ) {

    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate() - 90);
    this.maxFromDate = new Date();
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() - 90);
    this.maxToDate = new Date();
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
   
  }
  locationName:any;
  cityName:any;
  locationFullName:any;
  reportingMgrname:any;
  ngOnInit(): void {
    this.companyDBName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.getPayableDaysConfiguration();
    this.searchForm = this.formBuilder.group({
      employees: [0, Validators.required],
      fromDate: [new Date(), Validators.required],
      toDate: [this.max, Validators.required],
      location: [0, Validators.required],
    });
    /** */
    this.getWorkLocation();
    this.searchForm.get('location')?.valueChanges.subscribe((selectedValue: any) => {
    this.getmanagerList(selectedValue);
    
    if(selectedValue == 0){
      this.locationFullName = 'All Locations'
    } else{
      const selectedLocation = this.filteredLocations.find((loc:any) => loc.id === selectedValue);
      this.locationName = selectedLocation ? selectedLocation.location : '';
      this.cityName = selectedLocation ? selectedLocation.cityname : '';
      this.locationFullName = this.locationName +','+ this.cityName;
    }
    })
    /**This is used for drop down filters */
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });

    this.empSearchControl.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText);
    });

    this.date = new UntypedFormControl(moment())
    let prevDate = new Date();
    prevDate.setDate(0);

    this.searchForm.get('employees')?.valueChanges.subscribe((selectedValue: any) => {

      if(selectedValue == 0){
        this.reportingMgrname = 'All Managers'
      } else{
        const selectedManager = this.filteredEmployees.find((loc:any) => loc.manager_emp_id === selectedValue);
        this.reportingMgrname = selectedManager ? selectedManager.manager_name : '';
      }
      })
  }

  /**Get payroll payable days flag */
  getPayableDaysConfiguration() {
    this.payrollService.getPayableDaysConfiguration().subscribe((res) => {
     if(res.data?.value == 'CALENDAR'){
      this.isCalenderDaysPayroll =true;
     }
    })
  };

  /** Function to get the end of the current month*/
  getEndOfMonth(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0); // Last day of the current month
  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minToDate = event.value;
    if (event.value !== null) {
      this.maxToDate = new Date(
      );
    }
  }

  getSearchForm() {
    this.arrayList = [];
    this.isLoading = true;
    let formValue = this.searchForm.value;
    let data = {
      "location_id_value": formValue.location == '0' ? null : formValue.location,
      "manager_id_value": formValue.employees == '0' ? null: formValue.employees,
      "year_value": (this.date.value._d).getFullYear(),
      "month_value": (this.date.value._d).getMonth() + 1
    }
      this.payrollService.getPayableDaysReport(data).subscribe((res: any) => {
        if (res.status) {
          if(this.isCalenderDaysPayroll){
           
            this.isLoading = false;
            this.arrayList = res.data;
            this.dataSource = new MatTableDataSource(this.arrayList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.pageLoading = false;
       
          } else {
            this.isLoading = false;
            this.arrayList = res.data
            this.dataSource2 = new MatTableDataSource(this.arrayList);
            this.dataSource2.paginator = this.paginator;
            this.dataSource2.sort = this.sort;
            this.pageLoading = false;
        }
       
      } else{
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.dataSource2 = new MatTableDataSource(this.arrayList);
        this.isLoading = false;
      }
    })
 

  }
  /**Clear Functionality */
  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/payabledaysreport"]));
  }

  exportAsXLSX() {
     // Format the data to match the required columns
    const exportData = this.arrayList.map((row:any, index:any) => ({
      "S.No": index + 1,
      "Emp.Id":row.empCode,
      "Emp. Name": row.empName,
      "Department": row.department,
      "Designation": row.designation,
      "Presents": row.presents,
      "Absents": row.absents,
      "Leaves": row.leavesCount,
      "Paid Week-off": row.weekOffs,
      "Paid Days": row.payableDays,
      "LOP Days": row.lopdays,
    }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Payable_Days_Report');
    /* save to file */
     XLSX.writeFile(wb,  'Payable_Days_Report for ' +this.datePipe.transform(new Date(), 'MMM yyyy') +'.xlsx');

  }

  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  /**Drop Down Loactions Filter */
  filterLocations(searchText: any) {
    this.filteredLocations = this.locationList.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase()) || 
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) 
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControl.setValue('');
    } else {

    }
  }

  /**Drop Down Employees Filter */
  filterEmployees(searchText: any) {
    this.filteredEmployees = this.managerList.filter(
      (val: any) =>
        val.manager_name.toLowerCase().includes(searchText.toLowerCase()) 
      // ||
      //   val.ename.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployees.length <= 0) {
      this.empSearchControl.setValue('');
    }
  }
  
  getmanagerList(data:any) {
    this.managerList = [];
    let info ={
      location_id: data,
      companyName: this.companyDBName
    }
  
    this.reportsService.getManagersListByLocation(info).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.managerList = [];
        this.managerList = res.data;
        this.filteredEmployees = this.managerList
        this.searchForm.controls.employees.setValue('0');
      }
    })
  }

  /**Get All Active Work Locations */
  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyDBName }).subscribe((result) => {
      this.locationList = result.data;
      this.filteredLocations = this.locationList;
      this.searchForm.controls.location.setValue('0');
      this.getmanagerList(null)
    })
  }
  /**Table Search Functioanlity */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(this.isCalenderDaysPayroll){
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }else{
      this.dataSource2.filter = filterValue.trim().toLowerCase();
      if (this.dataSource2.paginator) {
        this.dataSource2.paginator.firstPage();
      }
    }

  }
 /** PDF Functionality */
 downloadPDF() {
  let data = {
    'list': this.arrayList,
    'report_date': this.datePipe.transform(new Date(), 'MMM yyyy')
  }
  if(!this.isCalenderDaysPayroll){
  
  this.reportsService.getWorkingPayableDaysReportPdf(data).subscribe(response => {
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Payable_Days_Report for ' +this.datePipe.transform(new Date(), 'MMM yyyy') +'.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
  });
} else {

  this.reportsService.getCalendarPayableDaysReportPdf(data).subscribe(response => {
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Payable_Days_Report for ' +this.datePipe.transform(new Date(), 'MMM yyyy') +'.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
  });
}
}

getPageSizes2(): number[] {

  var customPageSizeArray = [];
  if (this.dataSource2.data.length > 5) {
    customPageSizeArray.push(5);
  }
  if (this.dataSource2.data.length > 10) {
    customPageSizeArray.push(10);
  }
  if (this.dataSource2.data.length > 20) {
    customPageSizeArray.push(20);
  }
  customPageSizeArray.push(this.dataSource2.data.length);
  return customPageSizeArray;
}

generatePDF() {
  
  let formattedDate: string;
  formattedDate = this.datePipe.transform(this.date.value._d, 'MM-yyyy') || '';

  const doc = new jsPDF({ orientation: 'landscape' });
  const reportName = 'Payable Days Report';
  let manager = this.reportingMgrname;
  let locationName = this.locationFullName;
  let rtDate = formattedDate;
  this.pdfService.generatePDFHeader(doc, reportName,locationName,manager,rtDate,true);

  if(!this.isCalenderDaysPayroll){
  const tableData = this.arrayList.map((emp: { empCode: any; empName: any; department: any; designation: any; presents: any; absents: any; leavesCount: any; weekOffs: any; payableDays: any; lopdays: any; }, index: number) => [
    index + 1,
    emp.empCode,
    emp.empName,
    emp.department,
    emp.designation,
    emp.presents,
    emp.absents,
    emp.leavesCount,
    emp.weekOffs,
    emp.payableDays,
    emp.lopdays
  ]);
        // Continue with table or other content
        autoTable(doc, {
          head: [['S.No.', 'Emp.ID', 'Name', 'Department', 'Designation', 'Presents', 'Absents', 'Leaves', 'Paid Week-off', 'Paid Days', 'LOP Days']],
          body:tableData,
          startY: 40,
          styles: { fontSize: 10 },
          headStyles: { fontSize: 12 }
       });
} else{
  const tableData = this.arrayList.map((emp: { empCode: any; empName: any; department: any; designation: any; presents: any; absents: any; leavesCount: any; workingDays: any; payableDays: any; lopdays: any; }, index: number) => [
    index + 1,
    emp.empCode,
    emp.empName,
    emp.department,
    emp.designation,
    emp.presents,
    emp.absents,
    emp.leavesCount,
    emp.workingDays,
    emp.payableDays,
    emp.lopdays
  ]);
        autoTable(doc, {
          head: [['S.No.', 'Emp.ID', 'Name', 'Department', 'Designation', 'Presents', 'Absents', 'Leaves', 'Working Days', 'Paid Days', 'LOP Days']],
          body:tableData,
          startY: 40,
          styles: { fontSize: 10 },
          headStyles: { fontSize: 12 }
       });
}
     doc.save('Payable_Days_Report.pdf');
}
}

