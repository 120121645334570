<div fxLayout="column">
  <form [formGroup]="changePasswordform" autocomplete="off">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title"> Change Password </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
        <br>
        <div fxLayout="column" ngStyle.gt-md="width:30%">
          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Current Password</mat-label>
            <input [type]="hide1 ? 'password' : 'text'" formControlName="oldPassword" matInput
              placeholder="Old Password">
            <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error class="con-error" *ngIf="changePasswordform.controls.oldPassword.errors?.required">
              {{msgEM1}}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>New Password</mat-label>
            <input [type]="hide2 ? 'password' : 'text'" formControlName="password"
              pattern="(?=.*?[0-9])(?=.*[a-z])(?=.*?[!@#%\$&*~])(?=.*[A-Z]).{8,20}" matInput placeholder="New Password">
            <mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <!--<div>-->

            <mat-error class="con-error" *ngIf="changePasswordform.controls['password'].invalid">
              <!-- {{msgLM1}} -->
              One lower case letter.One UPPER case letter. <br>
              One Number.
              One special character (! ^ $ or %).<br>
              Minimum 8 characters in length (20 characters max).
            </mat-error>
            <!--</div>-->

            <!-- <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                  <div *ngIf="f.password.errors.pattern">
                    Password must be at least 6 characters
                  </div>

                </div> -->


          </mat-form-field>
          <br *ngIf="changePasswordform.controls['password'].touched && !changePasswordform.controls['password'].valid">
          <br *ngIf="changePasswordform.controls['password'].touched && !changePasswordform.controls['password'].valid">

          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input [type]="hide3 ? 'password' : 'text'" formControlName="confirmPassword" matInput
              placeholder="Confirm Password">
            <mat-icon matSuffix (click)="hide3 = !hide3">{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>

            <mat-error class="con-error" *ngIf="changePasswordform.controls['confirmPassword'].invalid">
              The confirm password you entered does not match with new password. Please re-enter your password
            </mat-error>
            <!-- <div
            *ngIf="submitted && f.confirmPassword.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.confirmPassword.errors.required">
              Confirm Password is required
            </div>
            <div *ngIf="f.confirmPassword.errors.matching">
              Confirm Password does not match
            </div>
          </div> -->
          </mat-form-field>
        </div>
      </mat-card-content>

      <div fxLayoutAlign="center" style="margin-bottom: 20px; margin-top: 40px;">
        <span style="padding-left: 1%;">
          <button class="btn btn-primary mr-2 mb-2" (click)="changePassword()" type="submit">
            <span>Submit</span>
          </button>
          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()" *ngIf="flag">
            <span>Cancel</span>
          </button>
        </span>

      </div>
    </mat-card>
  </form>
  <!--<mat-divider></mat-divider>-->




</div>

<!-- <!DOCTYPE html>
<html>
<head>
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <title>New Leave Request | Leave Management</title>
</head>
<body class="cmn-dahboard">
<div class="wrapper">
  <div class="main-panel">
    <div class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header ">
            <h4 class="card-title">Change Password</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card no-value">
                  <div style="border-bottom:0 !important;" class="card-header no-value">
                    <div class="card-head-row">
                      <div class="row">
                        <div class="col-md-8">

                            <div class="card-body">
                              <form  >

                                <div class="form-row">
                                  <div class="form-group row m-0 col-md-8">
                                    <label for="staticEmail" class="col-sm-4 col-form-label pl-0">Old Password<span  style="color: red">*</span></label>
                                    <div class="col-sm-8">
                                      <input type="text" class="form-control"  name="oldpwd" #oldpwd="ngModel" id="old-password" placeholder="" [(ngModel)]="changePasswordAddObj.oldPassword" required >
                                        <div class="custom-text-error" *ngIf="(issubmitted && !oldpwd.valid)||(oldpwd.touched && !oldpwd.valid)">
								                         </div>
                                    </div>


                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group row m-0 col-md-8">
                                    <label for="staticEmail" class="col-sm-4 col-form-label  pl-0">New Password<span  style="color: red">*</span></label>
                                    <div class="col-sm-8">
                                      <input type="email" required  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}"name="newpassword"class="form-control" id="new-password" placeholder=""  #newpassword="ngModel" [(ngModel)]="changePasswordAddObj.newPassword" (keypress)="validate($event)" (keyup)="validate($event)">
                                       <div class="custom-text-error" *ngIf="(issubmitted && !newpassword.valid) || (newpassword.touched && !newpassword.valid)">
						                         </div>
                                      </div>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="form-group row m-0 col-md-8">
                                    <label for="staticEmail" class="col-sm-4 col-form-label  pl-0">Confirm Password<span  style="color: red">*</span></label>
                                    <div class="col-sm-8">
                                      <input type="text" name="confirmpassword"class="form-control" id="confirm-password" placeholder="" #confirmpassword="ngModel" [(ngModel)]="changePasswordAddObj.confirmPassword" required>
                                      <div class="custom-text-error" *ngIf="(issubmitted && !confirmpassword.valid) || (confirmpassword.touched && !confirmpassword.valid)">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group text-right">


                                  <button  id="submit" name="submit" class="col-md-2 btn btn-primary"  (click)="changePassword()">Submit</button>&nbsp;
                                  <button id="cancel" name="cancel" class="col-md-2 btn btn-default" (click)="cancel()"value="1" *ngIf="isView">Cancel</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        <div class="col-sm-12">

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</body>
</html> -->