<div fxLayout="column" fxFill class="custom-pgr-height-auto first-col">
    <mat-card fxFill>
        <mat-card-header class="headerbox">
            <mat-card-title class="title">Pay Group</mat-card-title>
        </mat-card-header>

        <div fxLayout="column" class="div-pad">
            <form [formGroup]="payGroupRequestForm" autocomplete="off" fxLayout="column">
                <div fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex="49" fxFlex.xs="100">
                        <mat-label>Name of the Pay Group</mat-label>
                        <input type="text" formControlName="payNameGroup" noLeadingWhitespace matInput maxlength="30"
                            alphaNumericSpace>
                        <mat-error class="con-error"
                            *ngIf="  payGroupRequestForm.controls.payNameGroup.errors?.required">
                            {{PR1}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column">
                    <p>
                        <label style="font-size: 18px !important;">Annual CTC</label>
                    </p>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Start Range</mat-label>
                            <input type="text" onlyNumber preventLeadingZero maxlength="10"
                                formControlName="start_range" matInput>
                            <mat-error class="con-error"
                                *ngIf="  payGroupRequestForm.controls.start_range.errors?.required ">
                                {{PR1}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>End Range</mat-label>
                            <!-- max="99999999999999"   oninput="validity.valid||(value='9999999999999');"  -->
                            <input type="text" preventLeadingZero onlyNumber maxlength="10" formControlName="end_range"
                                matInput placeholder="">
                            <mat-error class="con-error"
                                *ngIf="payGroupRequestForm.controls.end_range.errors?.required">
                                {{PR1}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="50">
                        <mat-expansion-panel hideToggle style="box-shadow: none !important;">
                            <mat-expansion-panel-header style="padding: 0 !important;"
                                (click)="validateExpansion('earnings')">
                                <mat-panel-title>
                                    <span class="material-icons" *ngIf="isEarnings"
                                        style="margin-right: 5px;color:#28acaf;">add_box</span>
                                    <span class="material-icons" *ngIf="!isEarnings"
                                        style="margin-right: 5px;color:#28acaf;">indeterminate_check_box</span>

                                    <p style="text-decoration: underline;color:#28acaf;">Earnings</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div>
                                <!-- <p formArrayName="earnings" *ngFor="let earnings of payGroupRequestForm.controls.earnings.controls; let i = index">
                <input type="checkbox" [formControlName]="i">
                {{earnings[i].name}}
            </p> -->
                                <p formArrayName="earnings"
                                    *ngFor="let order of earningsFormArray.controls; let i = index">
                                    <!-- <input type="checkbox" [formControlName]="i" [disabled]="i === 0" >
                
                {{earningData[i].component}} -->

                                    <mat-checkbox [formControlName]="i" [disabled]="i === 0">
                                        {{earningData[i].component}}
                                    </mat-checkbox>
                                </p>


                                <!-- <p><mat-checkbox formControlName="basic">Basic</mat-checkbox></p>
            <p><mat-checkbox formControlName="hra">HRA</mat-checkbox></p>
            <p><mat-checkbox formControlName="conveyance">Conveyance</mat-checkbox></p>
            <p><mat-checkbox formControlName="fixedAllowance">Fixed Allowance</mat-checkbox></p> -->
                            </div>

                        </mat-expansion-panel>
                    </div>
                    <div fxFlex="50">
                        <mat-expansion-panel hideToggle style="box-shadow: none !important;"
                            ngStyle.gt-md="padding-left: 10px;">
                            <mat-expansion-panel-header style="padding: 0 !important;"
                                (click)="validateExpansion('deductions')">
                                <mat-panel-title>
                                    <span class="material-icons" *ngIf="isDeductions"
                                        style="margin-right: 5px;color:#28acaf;">add_box</span>
                                    <span class="material-icons" *ngIf="!isDeductions"
                                        style="margin-right: 5px;color:#28acaf;">indeterminate_check_box</span>

                                    <p style="text-decoration: underline;color:#28acaf;"> Deductions</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div>
                                <p formArrayName="deducts"
                                    *ngFor="let order of deductionFormArray.controls; let i = index">
                                    <!-- <input type="checkbox" [formControlName]="i" >
                {{deductionData[i].component}} -->

                                    <mat-checkbox [formControlName]="i" (change)="onSelect()">
                                        {{deductionData[i].component}}
                                    </mat-checkbox>
                                </p>
                            </div>

                        </mat-expansion-panel>
                    </div>
                    <!-- <div fxFlex="30">
    <mat-expansion-panel hideToggle style="box-shadow: none !important;" ngStyle.gt-md="padding-left: 10px;">
        <mat-expansion-panel-header style="padding: 0 !important;" (click)="validateExpansion('deductions')">
          <mat-panel-title>
            <span class="material-icons" *ngIf="isDeductions" style="margin-right: 5px;color:#28acaf;">add_box</span>
            <span class="material-icons" *ngIf="!isDeductions" style="margin-right: 5px;color:#28acaf;">indeterminate_check_box</span>
            
            <p style="text-decoration: underline;color:#28acaf;">Other Deductions</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <p formArrayName="deducts" *ngFor="let order of deductionFormArray.controls; let i = index">
               

                <mat-checkbox [formControlName]="i" [disabled]="i === 0">
                    {{deductionData[i].component}}
                </mat-checkbox>
            </p>
        </div>

    </mat-expansion-panel>
</div> -->
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Status</mat-label>
                        <input type="text" formControlName="status" [readonly]="true" matInput placeholder="">

                        <!-- <mat-select formControlName="status">
            <mat-option value="To Be Configured">To Be Configured</mat-option>
        </mat-select> -->
                        <mat-error class="con-error" *ngIf="  payGroupRequestForm.controls.status.errors?.required">
                            {{PR30}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Description</mat-label>
                        <textarea formControlName="descriptions" matInput placeholder=""></textarea>
                    </mat-form-field>
                </div>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}"
                        (click)="setPayGroup()" type="submit">
                        <span>Save</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </form>
        </div>

    </mat-card>
</div>