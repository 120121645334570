import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as XLSX from 'xlsx';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../../reports.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PdfHeaderService } from 'src/app/services/pdf-header-service/pdf-header.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  }
}

@Component({
  selector: 'app-employee-late-attendance-report',
  templateUrl: './employee-late-attendance-report.component.html',
  styleUrls: ['./employee-late-attendance-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class EmployeeLateAttendanceReportComponent implements OnInit {

  @ViewChild('table') table!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sorter!: MatSort;

  searchForm: any = UntypedFormGroup;
  minFromDate: Date;
  maxFromDate: Date | null;
  minToDate: Date | null;
  maxToDate: Date;
  currentDate: Date = new Date();
  attendanceReportList: any[] = [
  ];
  displayedColumns: string[] = ['sno', 'shift', 'Date', 'shiftInTime', 'InTime',
    'lateHours'];
  dataSource: MatTableDataSource<any> = <any>[];
  pageLoading = true;
  userSession: any;
  isLoading = false;
  headersList: any = [];

  constructor(
    public formBuilder: FormBuilder,
    public datePipe: DatePipe,
    public reportsService: ReportsService,
    private pdfService: PdfHeaderService,
  ) {
    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate() - 90);
    this.maxFromDate = new Date();
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() - 90);
    this.maxToDate = new Date();
  }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.searchForm = this.formBuilder.group({
      fromDate: [''],
      toDate: ['']
    })
    this.searchForm.controls.fromDate.setValue(new Date());
    this.searchForm.controls.toDate.setValue(new Date())
    this.searchform()
  }
  searchform() {
    if (this.searchForm.valid) {
      let data = {
        'manager_empid': null,
        'shift_id': null,
        'from_date': this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-dd"),
        'to_date': this.datePipe.transform(this.searchForm.controls.toDate.value, "y-MM-dd"),
        'employee_id': this.userSession.id,
      }
      this.isLoading = true;
      this.reportsService.getEmployeeLateAttendanceReport(data).subscribe((res: any) => {
        this.attendanceReportList = [];
        if (res.status) {
          this.attendanceReportList = res.data
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.attendanceReportList);
          this.dataSource.paginator = this.paginator;
          this.pageLoading = false;
        }
      }, error => {
        this.isLoading = false;
        error.error.text
      });
    }
  }
  resetform() {
    this.dataSource.data = [];
    this.searchForm.reset();
    this.searchForm.controls.fromDate.setValue(new Date());
    this.searchForm.controls.toDate.setValue(new Date());
    this.searchform();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public exportPDF(): void {
    let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-yyyy");
    let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-yyyy");
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: fromDate + '-' + toDate + '-' + "Late Attendance Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: fromDate + '-' + toDate + '-' + "Late Attendance Report\n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        // {
        //   text:
        //     "Designation :  " + this.designationForPdf +"\n" +
        //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
        //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
        //   fontSize: 10,
        //   margin: [0, 0, 0, 20],
        //   alignment: 'left'
        // },
        html,


      ], defaultStyle: {
        alignment: "justify",
      },
      pageOrientation: 'landscape',//'portrait'
      pageSize: 'A4'
    }).download(fromDate + '-' + toDate + '-' + "Late Attendance Report.pdf");

  }

  exportAsXLSX() {
    let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-yyyy");
    let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-yyyy");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Late_attendance_Report');

    /* save to file */
    XLSX.writeFile(wb, fromDate + '-' + toDate + '-' + 'Late_attendance_Report.xlsx');

  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }


  generatePDF() {
  
    const doc = new jsPDF();
    const reportName = 'Late Attendance report';
    let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"));
    let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"));

    let manager = '';
    let locationName = '';
    let reportDate = fromdate +' to '+ todate;
    this.pdfService.generatePDFHeader(doc, reportName,locationName,manager,reportDate,false);
    const tableData = this.attendanceReportList.map((emp:  {
       shiftname: any;   
       attendancedate: any ;
       shiftstarttime: any;   
       actual_in_time: any; 
       latehours: any; 
    }, index: number) => [

      index + 1,
      emp.shiftname,
      emp.attendancedate,
      emp.shiftstarttime,
      emp.actual_in_time,
      emp.latehours
    ]);
          autoTable(doc, {
            head: [['S.No.', 'Shift','Date','Shift InTime','In Time','Late Hours']],
            body:tableData,
            startY: 40,
            styles: { fontSize: 10 },
            headStyles: { fontSize: 12 }
         });
       doc.save('late_attendance_report.pdf');
  } 
}
