<div fxLayout="column" class="first-col">
  <mat-card class="mat-elevation-z0">
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> Manager Regularization </mat-card-title>
    </mat-card-header>
    <div fxLayout="column">
      <!-- <mat-divider></mat-divider> -->
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-left: 1%;"
        fxLayoutAlign="space-between center">
        <span fxFlex="50">
          <mat-radio-group (change)="radioChange($event)">
            <mat-radio-button value="1" [checked]="true"><b>Full Day</b></mat-radio-button>&nbsp;
            <mat-radio-button value="2"><b>Half Day</b></mat-radio-button>
          </mat-radio-group>
        </span>
        <span style="padding-top: 5px;height: 70px;" fxFlex="50" fxLayoutAlign="end">
          <mat-form-field fxFlex="56" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput placeholder="" maxlength="100">
          </mat-form-field>
        </span>
      </div>

      <div *ngIf="isFullday" class="tableP">
        <div class="mat-elevation-z1 example-container">
          <form [formGroup]="attendanceRequestForm" autocomplete="off">
            <table mat-table [dataSource]="dataSource" matSort class="mat-table">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>


              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="no-wrap"> S. No.</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="empoyeeid">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Date </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                  date:'dd-MM-yyyy'}}</td>
              </ng-container>
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp. Name </th>
                <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
              </ng-container>

              <ng-container matColumnDef="shift">
                <th mat-header-cell *matHeaderCellDef> Shift </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  {{row.shiftname}}
                </td>
              </ng-container>

              <ng-container matColumnDef="intime">
                <th mat-header-cell *matHeaderCellDef> In Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logintime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="intime" matInput mask="00:00:00" placeholder="{{ row.shift_start_time }}"
                        maxlength="100" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="outtime">
                <th mat-header-cell *matHeaderCellDef> Out Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logouttime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="outtime" matInput mask="00:00:00" placeholder="{{ row.shift_end_time }}"
                        maxlength="100" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">

                  <span>

                    <button mat-icon-button>
                      <a style="padding-right: 10px;" matTooltip="Leave request" (click)="setLeaveRegularization(row)">
                        <img src="assets\img\leave-request-black.png" style="height: 35px" />
                      </a>
                    </button>
                  </span>

                  <!-- <button mat-icon-button [tabIndex]="2" (keyup.enter)="saveRegularization(row)"
                    *ngIf="enable === row.id">
                    <mat-icon class="mr-1" title="Save" (click)="saveRegularization(row)">save</mat-icon>
                  </button>
                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="canceledit()" *ngIf="enable === row.id">
                    <mat-icon class="mr-1" title="Cancel" (click)="canceledit()">cancel</mat-icon>
                  </button> -->
                </td>


              </ng-container>
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td *ngIf="!isLoading" class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                <td *ngIf="isLoading" class="mat-cell" colspan="9" style="text-align: center;">Loading...</td>
              </tr>
            </table>
            <mat-card *ngIf="isLoading"
              style="display: flex; justify-content: center; align-items: center; opacity:0.5">
              <mat-progress-spinner color="primary" [diameter]="70" mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [10, 20,30]"></mat-paginator>
          </form>

        </div>
        <br>
        <span *ngIf="fullDayList.length > 0" style="color:red">* Use multi selection for Attendance</span>
        <div fxLayoutAlign="center">
          <!-- is full day submit button -->

          <div *ngIf="fullDayList.length > 0">
            <button class="btn btn-primary custom mr-2 mb-2" [disabled]="btnDisable"
              (click)="setAttendanceRegularization()" type="submit">
              <span>Attendance</span>
            </button>

            <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
              <span>Clear</span>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="isHalfdayView" class="tableP">
        <div class="mat-elevation-z1 example-container">
          <form [formGroup]="attendanceRequestForm" autocomplete="off">
            <table class="mat-table" mat-table [dataSource]="dataSource2" matSort>
              <!-- <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container> -->


              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="empoyeeid">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Date </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                  date:'dd-MM-yyyy'}}</td>
              </ng-container>
              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Name </th>
                <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
              </ng-container>

              <ng-container matColumnDef="shift">
                <th mat-header-cell *matHeaderCellDef> Shift </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  {{row.shiftname}}
                </td>
              </ng-container>

              <ng-container matColumnDef="intime">
                <th mat-header-cell *matHeaderCellDef> In Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logintime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="intime" matInput mask="00:00:00" placeholder="{{ row.shift_start_time }}"
                        maxlength="100" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="outtime">
                <th mat-header-cell *matHeaderCellDef> Out Time</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span *ngIf="enable != row.id">{{row.logouttime}} </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="outtime" matInput mask="00:00:00" placeholder="{{ row.shift_end_time }}"
                        maxlength="100" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">

                  <!-- half day functionality -->
                  <!-- <span *ngIf="row.halfday !=null " style="padding-left: 5px;"> -->
                  <span style="padding-left: 5px;">
                    <button mat-icon-button>
                      <a style="padding-right: 10px;" title="Half Day Regularization"
                        (click)="halfDayRegularization(row)">
                        <img src="assets\img\halfday.png" style="height: 28px" />
                      </a>
                    </button>
                  </span>

                  <!-- <button mat-icon-button [tabIndex]="2" (keyup.enter)="saveRegularization(row)"
                    *ngIf="enable === row.id">
                    <mat-icon class="mr-1" title="Save" (click)="saveRegularization(row)">save</mat-icon>
                  </button>
                  <button mat-icon-button [tabIndex]="2" (keyup.enter)="canceledit()" *ngIf="enable === row.id">
                    <mat-icon class="mr-1" title="Cancel" (click)="canceledit()">cancel</mat-icon>
                  </button> -->
                </td>
              </ng-container>

              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td *ngIf="!isLoading" class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                <td *ngIf="isLoading" class="mat-cell" colspan="9" style="text-align: center;">Loading...</td>
              </tr>
            </table>
            <mat-card *ngIf="isLoading"
              style="display: flex; justify-content: center; align-items: center; opacity:0.5">
              <mat-progress-spinner color="primary" [diameter]="70" mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes2() : [10, 20,30]"></mat-paginator>
          </form>

        </div>
      </div>

    </div>
  </mat-card>
</div>