import { MainComponent } from 'src/app/pages/main/main.component';
import { MinMaxDirective } from './directive/min-max-length-directive';
import { NgModule, Directive } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialElevationDirective } from 'src/app/modules/payroll/directive/material-elevation-directive';
import { PayrollRoutingModule } from './payroll-routing.module';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogComponent } from './dialog/dialog.component';
import { FinanceDashboardComponent } from './pages/finance-dashboard/finance-dashboard.component';
import { InvestmentProofComponent } from './pages/investment-proof/investment-proof.component';
import { InvestmentProofRequestComponent } from './pages/investment-proof-request/investment-proof-request.component';
import { ProfessionalTaxComponent } from './pages/professional-tax/professional-tax.component';
import { PayGroupComponent } from './pages/pay-group/pay-group.component';
import { PayGroupRequestComponent } from './pages/pay-group-request/pay-group-request.component';
import { ProfessionalTaxRequestComponent } from './pages/professional-tax-request/professional-tax-request.component';
import { SalaryMasterComponent } from './pages/salary-master/salary-master.component';
import { PayScheduleComponent } from './pages/pay-schedule/pay-schedule.component';
import { PayScheduleRequestComponent } from './pages/pay-schedule-request/pay-schedule-request.component';
import { EarningsComponent } from './pages/earnings/earnings.component';
import { EarningsRequestComponent } from './pages/earnings-request/earnings-request.component';
import { EmployeeItDeclarationsComponent } from './pages/employee-it-declarations/employee-it-declarations.component';
import { PaySlipsComponent } from './pages/pay-slips/pay-slips.component';
import { PaySlipsViewComponent } from './pages/pay-slips-view/pay-slips-view.component';
import { EmployeeForm16Component } from './pages/employee-form16/employee-form16.component';
import { FinanceForm16Component } from './pages/finance-form16/finance-form16.component';
import { EsiComponent } from './pages/esi/esi.component';
import { EpfComponent } from './pages/epf/epf.component';
import { EmployeeDashboardComponent } from './pages/employee-dashboard/employee-dashboard.component';
import { OrganizationDetailsComponent } from './pages/organization-details/organization-details.component';
import { ProfessionalTaxMainComponent } from './pages/professional-tax-main/professional-tax-main.component';
import { NgChartsModule } from 'ng2-charts';
import { ChartModule } from 'angular2-chartjs';
import 'chartjs-adapter-date-fns';
import { AssignPayGroupComponent } from './pages/assign-pay-group/assign-pay-group.component';
import { AssignPaygroupPopupComponent } from './pages/assign-paygroup-popup/assign-paygroup-popup.component';
import { InvestmentRejectComponent } from './pages/investment-reject/investment-reject.component';
import { MonthlyPayrollComponent } from './pages/monthly-payroll/monthly-payroll.component';
import { CopyContentService } from './copy-content.service';
import { ConfirmationDialogueComponent } from './pages/confirmation-dialogue/confirmation-dialogue.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EsiReportsComponent } from './pages/esi-reports/esi-reports.component';
import { PftaxReportsComponent } from './pages/pftax-reports/pftax-reports.component';
import { EpfReportsComponent } from './pages/epf-reports/epf-reports.component';
import { MonthlyPayrollReportComponent } from './pages/monthly-payroll-report/monthly-payroll-report.component';
import { ReAssignPaygroupComponent } from './pages/re-assign-paygroup/re-assign-paygroup.component';
import { AdminPayrollReportComponent } from './pages/admin-payroll-report/admin-payroll-report.component';
import { LeaveBalanceReportManagerComponent } from './pages/leave-balance-report-manager/leave-balance-report-manager.component';
import { AdvanceSalaryRequestComponent } from './pages/advance-salary-request/advance-salary-request.component';
import { AdvanceSalaryApprovalComponent } from './pages/advance-salary-approval/advance-salary-approval.component';
import { OtherDeductionComponent } from './pages/other-deduction/other-deduction.component';
import { PaygroupOtherdeductionMappingComponent } from './pages/paygroup-otherdeduction-mapping/paygroup-otherdeduction-mapping.component';
import { ComponetWisePayrollReportComponent } from './pages/componet-wise-payroll-report/componet-wise-payroll-report.component';
import { BankDetailsComponent } from './pages/bank-details/bank-details.component';
import { BankWiseSalarySummaryComponent } from './pages/bank-wise-salary-summary/bank-wise-salary-summary.component';
import { BankWiseSalaryDialogComponent } from './pages/bank-wise-salary-dialog/bank-wise-salary-dialog.component';
import { EmployeePayGroupsComponent } from './pages/employee-pay-groups/employee-pay-groups.component';
import { IncomeTaxExemptionComponent } from './pages/income-tax-exemption-for-employee/income-tax-exemption.component';
import { EmployeeTaxRegimeSelectionComponent } from './pages/employee-tax-regime-selection/employee-tax-regime-selection.component';
import { OverTimeConfigureComponent } from './pages/over-time-configure/over-time-configure.component';
import { PayrollFinalSettlementComponent } from './pages/payroll-final-settlement/payroll-final-settlement/payroll-final-settlement.component';
import { ITExemptionApprovalsComponent } from './pages/tax-exemption-approvals/it-exemption-approvals/it-exemption-approvals.component';
import { TaxExemptionBehalfEmployeeComponent } from './pages/tax-exemption-behalf-employee/tax-exemption-behalf-employee.component';

import { DecimalLimitDirective } from './decimal-limit.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdvanceSalaryReportComponent } from './pages/advance-salary-report/advance-salary-report.component';
import { PayableDaysReportComponent } from './reports/payable-days-report/payable-days-report.component';
import { VariablePayDistributionComponent } from './pages/variable-pay-distribution/variable-pay-distribution.component';
import { VariablePayScheduleComponent } from './pages/variable-pay-schedule/variable-pay-schedule.component';
import { TDSDistributionComponent } from './pages/tds-distribution/tds-distribution.component';

FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin]);

@NgModule({
  declarations: [
    DialogComponent,
    FinanceDashboardComponent,
    InvestmentProofComponent,
    InvestmentProofRequestComponent,
    ProfessionalTaxComponent,
    PayGroupComponent,
    PayGroupRequestComponent,
    ProfessionalTaxRequestComponent,
    SalaryMasterComponent,
    PayScheduleComponent,
    PayScheduleRequestComponent,
    EarningsComponent,
    EarningsRequestComponent,
    EmployeeItDeclarationsComponent,
    PaySlipsComponent,
    PaySlipsViewComponent,
    EmployeeForm16Component,
    FinanceForm16Component,
    EsiComponent,
    EpfComponent,
    EmployeeDashboardComponent,
    OrganizationDetailsComponent,
    ProfessionalTaxMainComponent,
    MaterialElevationDirective,
    AssignPayGroupComponent,
    AssignPaygroupPopupComponent,
    InvestmentRejectComponent,
    MonthlyPayrollComponent,
    MinMaxDirective,
    ConfirmationDialogueComponent,
    EsiReportsComponent,
    PftaxReportsComponent,
    EpfReportsComponent,
    MonthlyPayrollReportComponent,
    ReAssignPaygroupComponent,
    AdminPayrollReportComponent,
    LeaveBalanceReportManagerComponent,
    AdvanceSalaryRequestComponent,
    AdvanceSalaryApprovalComponent,
    OtherDeductionComponent,
    PaygroupOtherdeductionMappingComponent,
    ComponetWisePayrollReportComponent,
    BankDetailsComponent,
    BankWiseSalarySummaryComponent,
    BankWiseSalaryDialogComponent,
    EmployeePayGroupsComponent,
    IncomeTaxExemptionComponent,
    EmployeeTaxRegimeSelectionComponent,
    OverTimeConfigureComponent,
    PayrollFinalSettlementComponent,
    ITExemptionApprovalsComponent,
    TaxExemptionBehalfEmployeeComponent,
    DecimalLimitDirective,
    AdvanceSalaryReportComponent,
    PayableDaysReportComponent,
    VariablePayDistributionComponent,
    VariablePayScheduleComponent,
    TDSDistributionComponent,


  ],
  imports: [
    CommonModule,
    PayrollRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FlexLayoutModule,
    NgChartsModule,
    ChartModule,
    NgxMaskModule.forRoot({
      // showMaskTyped : true,
      // clearIfNotMatch : true
    }),
    SharedModule
  ],
  providers: [DatePipe, CopyContentService],
})
export class PayrollModule { }
