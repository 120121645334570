import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
  AbstractControl,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { GetLocationComponent } from 'src/app/modules/crm/pages/get-location/get-location.component';

export interface UserData {
  total: number;
  address1: string;
  address2: string;
  branchcode: string;
  city: string;
  cityname: string;
  country: string;
  currentvalue: number;
  id: number;
  location: string;
  pincode: number;
  prefix: string;
  seed: number;
  state: string;
  status: string;
}
@Component({
  selector: 'app-worklocation',
  templateUrl: './worklocation.component.html',
  styleUrls: ['./worklocation.component.scss'],
})
export class WorklocationComponent implements OnInit {
  worklocationForm: any = UntypedFormGroup;
  CountryDetails: any = [];
  workLocationDetails: any = [];
  stateDetails: any = [];
  CityDetails: any = [];
  worklocationdata: any = [];
  ischeckprefix: boolean = false;
  isViewSeed: boolean = false;
  isnote: boolean = false;
  isdata: boolean = true;
  isadd: boolean = false;
  isview: boolean = false;
  ishide: boolean = true;
  editworklocation: boolean = false;
  userSession: any;
  msgEM1: any;
  msgEM3: any;
  msgEM2: any;
  msgEM77: any;
  msgEM31: any;
  msgEM76: any;
  msgEM80: any;
  msgEM81: any;
  msgEM82: any;
  msgEM83: any;
  msgEM149: any;
  msgEM150: any;
  msgEM157: any;
  pageLoading = true;
  btndisabled: boolean = false;

  displayedColumns: string[] = [
    'sno',
    'city-branch',
    'prefix',
    'seed',
    'status',
    'Action',
  ];
  departmentData: any = [];
  arrayValue: any = [
    { Value: 'Active', name: 'Active ' },
    { Value: 'Inactive', name: 'Inactive' },
  ];
  dataSource: MatTableDataSource<UserData> = <any>[];
  companyDBName: any = environment.dbName;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  messagesDataList: any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private LM: CompanySettingService,
    private dialog: MatDialog,
    private ts: LoginService, private emsService: EmsService,
  ) {

  }

  ngOnInit(): void {

    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getstatuslist();
    this.getMessages();
    this.getWorkLocation();
    this.getCountry();

    this.worklocationForm = this.formBuilder.group({
      address1: [''],
      address2: [''],
      branch: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      pincode: [''],
      prefix: [''],
      seed: ['', Validators.required],
      id: [''],
      /**For Location Logitude and Lattitude */
      longitude: [''],
      latitude: [''],
      measure: [25]
    });

    this.worklocationForm
      .get('country')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.stateDetails = [];
        this.LM.getStatesc(selectedValue).subscribe((data) => {
          this.stateDetails = data.data;
          if (this.worklocationdata != null) {
            this.worklocationForm.controls.state.setValue(
              this.worklocationdata.state
            );
          }
        });
      });
    /**get city details for residance address */
    this.worklocationForm
      .get('state')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.CityDetails = [];
        this.LM.getCities(selectedValue).subscribe((data) => {
          this.CityDetails = data.data;
          if (this.worklocationdata != null) {
            this.worklocationForm.controls.city.setValue(
              this.worklocationdata.city
            );
          }
        });
      });
    this.worklocationForm
      .get('prefix')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.isViewSeed = false;
        this.isnote = false;
        selectedValue.trim();
        if (selectedValue != '') {
          for (var i = 0; i < this.workLocationDetails.length; i++) {
            if (
              this.workLocationDetails[i].prefix == selectedValue.toUpperCase()
            ) {
              this.worklocationForm.controls.seed.setValue(
                this.workLocationDetails[i].seed
              );
              this.ischeckprefix = true;
              this.isViewSeed = true;
              this.worklocationForm.controls.seed.disable();
              this.isnote = true;
              break;
            } else {
              this.worklocationForm.controls.seed.setValue('');
              // this.isViewSeed=false;
              this.worklocationForm.controls.seed.enable();
              this.isnote = false;
              this.ischeckprefix = true;
              // valid = true;
            }
          }
        } else {
          for (let i = 0; i < this.workLocationDetails.length; i++) {
            if (this.workLocationDetails[i].prefix == selectedValue) {
              this.worklocationForm.controls.seed.setValue(
                this.workLocationDetails[i].seed
              );
              this.ischeckprefix = true;
              this.worklocationForm.controls.seed.disable();
              this.isnote = true;
              break;
            }
          }
        }
        // return this.isViewSeed;
        // return this.ischeckprefix;
      });
  }
  emptyprefix() {
    if (this.worklocationForm.controls.prefix.value == '') {
      for (var i = 0; i < this.workLocationDetails.length; i++) {
        if (this.workLocationDetails[i].prefix == '') {
          this.worklocationForm.controls.seed.setValue(
            this.workLocationDetails[i].seed
          );
          this.ischeckprefix = true;
          this.isViewSeed = true;
          this.worklocationForm.controls.seed.disable();
          this.isnote = true;
          break;
        }
      }

      // this.worklocationForm.controls.seed.disable();
      // return this.ischeckprefix;
    }
    return this.isViewSeed;
  }
  getstatuslist() {
    this.LM.getstatuslists().subscribe((result: any) => {
      if (result.status) {
        this.arrayValue = result.data;
      }
    });
  }
  getWorkLocation() {
    // this.spinner.show();
    this.workLocationDetails = []
    this.LM.getWorkLocation({
      id: null,
      companyName: this.companyDBName,
    }).subscribe((result: any) => {

      this.workLocationDetails = result.data == null ? [] : result.data;
      this.emptyprefix();
      this.dataSource = new MatTableDataSource(this.workLocationDetails);
      this.dataSource.paginator = this.paginator;
      this.pageLoading = false;
    });
  }
  edit(data: any) {
    this.editworklocation = true;
    this.worklocationdata = data;
    this.isadd = true;
    this.isdata = false;
    this.isview = false;
    this.worklocationForm.controls.id.setValue(data.id);
    this.worklocationForm.controls.address1.setValue(data.address1);
    this.worklocationForm.controls.address2.setValue(data.address2);
    this.worklocationForm.controls.branch.setValue(data.location);
    this.worklocationForm.controls.country.setValue(data.country);
    this.worklocationForm.controls.country.disable();
    this.worklocationForm.controls.state.setValue(data.state);
    this.worklocationForm.controls.state.disable();
    this.worklocationForm.controls.city.setValue(data.city);
    this.worklocationForm.controls.city.disable();
    this.worklocationForm.controls.pincode.setValue(data.pincode);
    this.worklocationForm.controls.prefix.setValue(data.prefix);
    this.worklocationForm.controls.seed.setValue(Number(data.seed));
    this.worklocationForm.controls.longitude.setValue(data.longitude);
    this.worklocationForm.controls.latitude.setValue(data.latitude);
    this.worklocationForm.controls.measure.setValue(data.measure == 0 ? 25 : data.measure);
  }
  view(data: any) {
    this.worklocationdata = data;
    this.isadd = true;
    this.isdata = false;
    this.isview = true;
    this.ishide = false;
    this.worklocationForm.controls.country.setValue(data.country);
    this.worklocationForm.controls.address1.setValue(data.address1);
    this.worklocationForm.controls.address2.setValue(data.address2);
    this.worklocationForm.controls.branch.setValue(data.location);

    this.worklocationForm.controls.state.setValue(data.state);
    this.worklocationForm.controls.city.setValue(data.city);
    this.worklocationForm.controls.pincode.setValue(data.pincode);
    this.worklocationForm.controls.prefix.setValue(data.prefix);
    this.worklocationForm.controls.seed.setValue(data.seed);
    this.worklocationForm.controls.longitude.setValue(data.longitude);
    this.worklocationForm.controls.latitude.setValue(data.latitude);
    this.worklocationForm.controls.measure.setValue(data.measure);

    this.worklocationForm.controls.address1.disable();
    this.worklocationForm.controls.address2.disable();
    this.worklocationForm.controls.branch.disable();

    this.worklocationForm.controls.longitude.disable();
    this.worklocationForm.controls.latitude.disable();
    this.worklocationForm.controls.measure.disable();
    this.worklocationForm.controls.pincode.disable();
    this.worklocationForm.controls.prefix.disable();
    this.worklocationForm.controls.seed.disable();
    this.worklocationForm.controls.longitude.disable();
    this.worklocationForm.controls.latitude.disable();
    this.worklocationForm.controls.measure.disable();
    this.isnote = false;
  }
  Add() {
    this.isadd = true;
    this.isdata = false;
    this.isview = false;
    this.ishide = true;
    this.location();
  }
  getCountry() {
    this.LM.getCountry(
      'countrymaster',
      null,
      1,
      10,
      this.companyDBName
    ).subscribe((data) => {
      this.CountryDetails = data.data;
    });
  }
  status(status: any, id: any) {
    let data = {
      checktable: 'companyworklocationsmaster',
      tableName: 'employee_worklocations',
      columnName: 'locationid',
      id: id,
      status: status,
    };
    this.LM.updateStatusall(data).subscribe((result) => {
      if (result.status) {
        this.ngOnInit();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: status == 1 ? this.msgEM149 : this.msgEM150
        });
      } else {
        this.ngOnInit();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.msgEM81,
        });
      }
    });
  }
  submit() {
    if (this.btndisabled) {
      return;
    }
    if (this.worklocationForm.controls.measure.valid && this.worklocationForm.controls.branch.valid && (this.editworklocation || this.worklocationForm.controls.seed.valid) && (this.editworklocation || this.worklocationForm.controls.state.valid) && (this.editworklocation || this.worklocationForm.controls.city.valid)) {
      if (
        this.worklocationForm.controls.id.value == '' ||
        this.worklocationForm.controls.id.value == null
      ) {
        let isvalid = true;
        if (this.workLocationDetails.length > 0) {
          for (let i = 0; i < this.workLocationDetails.length; i++) {
            if (
              this.worklocationForm.controls.branch.value
                .trim()
                .toLowerCase() ===
              this.workLocationDetails[i].location.trim().toLowerCase() &&
              this.worklocationForm.controls.city.value ===
              this.workLocationDetails[i].city
            ) {
              isvalid = false;
              break;
            } else {
              isvalid = true;
            }
          }
        }
        if (isvalid == true) {
          let data = {
            id: this.worklocationForm.controls.id.value,
            address1: this.worklocationForm.controls.address1.value,
            branchCode: '',
            address2: this.worklocationForm.controls.address2.value,
            location: this.worklocationForm.controls.branch.value,
            pincode: this.worklocationForm.controls.pincode.value,
            city: this.worklocationForm.controls.city.value,
            state: this.worklocationForm.controls.state.value,
            country: this.worklocationForm.controls.country.value,
            prefix: this.worklocationForm.controls.prefix.value.toUpperCase(),
            seed: this.worklocationForm.controls.seed.value,
            status: 1,
            created_by: this.userSession.id,
            // longitude:13.2020,
            // latitude:78.898,
            // measure:Number(this.worklocationForm.controls.measure.value)
            longitude: Number(this.worklocationForm.controls.longitude.value),
            latitude: Number(this.worklocationForm.controls.latitude.value),
            measure: Number(this.worklocationForm.controls.measure.value)
          };
          this.btndisabled = true;
          this.LM.setWorkLocation(data).subscribe((result) => {
            /**For edit worklocation */
            if (this.editworklocation) {

              if (result.status && result.data == 0) {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: 'Work Location Updated Successfully',
                });
              } else if (result.status && result.data == 1) {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM83
                });
                this.btndisabled = false;
              } else if (result.status && result.data == 2) {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM157
                });
                this.btndisabled = false;
              }
              else {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM77,
                });
                this.btndisabled = false;
              }
            } else {
              /**For add worklocation */
              if (result.status && result.data == 0) {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM80,
                });
              } else if (result.status && result.data == 1) {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM83
                });

              }
              else {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM76,
                });
                this.btndisabled = false
              }
            }
          });
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.msgEM83,
          });
        }
      } else {
        let isvalid = true;
        if (this.workLocationDetails.length > 0) {
          for (let i = 0; i < this.workLocationDetails.length; i++) {
            if (this.worklocationForm.controls.id.value != this.workLocationDetails[i].id &&
              this.worklocationForm.controls.branch.value
                .trim()
                .toLowerCase() ===
              this.workLocationDetails[i].location.trim().toLowerCase() &&
              this.worklocationForm.controls.city.value ===
              this.workLocationDetails[i].city
            ) {
              isvalid = false;
              break;
            } else {
              isvalid = true;
            }
          }
        }
        if (isvalid == true) {
          let data = {
            id: this.worklocationForm.controls.id.value,
            address1: this.worklocationForm.controls.address1.value,
            branchCode: '',
            address2: this.worklocationForm.controls.address2.value,
            location: this.worklocationForm.controls.branch.value,
            pincode: this.worklocationForm.controls.pincode.value,
            city: this.worklocationForm.controls.city.value,
            state: this.worklocationForm.controls.state.value,
            country: this.worklocationForm.controls.country.value,
            prefix: this.worklocationForm.controls.prefix.value.toUpperCase(),
            seed: this.worklocationForm.controls.seed.value,
            status: 1,
            updated_by: this.userSession.id,
            longitude: Number(this.worklocationForm.controls.longitude.value),
            latitude: Number(this.worklocationForm.controls.latitude.value),
            measure: Number(this.worklocationForm.controls.measure.value)
          };
          this.btndisabled = true;
          this.LM.setWorkLocation(data).subscribe((result) => {
            /**For edit worklocation */
            if (this.editworklocation) {
              if (result.status && result.data == 0) {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: 'Work Location Updated Successfully',
                });
              } else if (result.status && result.data == 1) {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM83
                });
              }
              else if (result.status && result.data == 2) {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM157
                });
                this.btndisabled = false;
              }
              else {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM77,
                });
                this.btndisabled = false;
              }
            } else {
              /**For add worklocation */
              if (result.status && result.data == 0) {
                this.router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM80,
                });
              } else if (result.status && result.data == 1) {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                  .then(() => this.router.navigate(['/Admin/Worklocation']));
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM83
                });
              } else {
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.msgEM76,
                });
                this.btndisabled = false;
              }
            }
          });
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.msgEM83,
          });
          this.btndisabled = false;
        }
      }
    }
  }
  cancel() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/Worklocation']));
  }
  close() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/Worklocation']));
  }


  /**Google Location API intgrated location data retrive */
  location() {
    const locationData: any = this.dialog.open(GetLocationComponent, { width: '1250px', height: '450px', disableClose: true });
    locationData.afterClosed().subscribe((result: any) => {
      if (result != undefined) {
        this.worklocationdata = result;
        this.worklocationForm.controls.country.setValue(result.country);
        this.worklocationForm.controls.address1.setValue(result.address1);
        this.worklocationForm.controls.address2.setValue(result.address2);
        this.worklocationForm.controls.state.setValue(result.state);
        this.worklocationForm.controls.city.setValue(result.city);
        this.worklocationForm.controls.pincode.setValue(result.pincode);
        this.worklocationForm.controls.longitude.setValue(result.longitude)
        this.worklocationForm.controls.latitude.setValue(result.latitude)

      }
    });
  }

  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.emsService.getMessagesListApi(data).subscribe((result: any) => {
      if (result.status) {
        this.messagesDataList = result.data;
        this.messagesDataList.forEach((e: any) => {

          if (e.code == "EM1") {
            this.msgEM1 = e.message
          }
          else if (e.code == "EM2") {
            this.msgEM2 = e.message;
          }
          else if (e.code == "EM3") {
            this.msgEM3 = e.message
          }
          else if (e.code == "EM76") {
            this.msgEM76 = e.message
          }
          else if (e.code == "EM77") {
            this.msgEM77 = e.message
          }
          else if (e.code == "EM80") {
            this.msgEM80 = e.message
          }
          else if (e.code == "EM81") {
            this.msgEM81 = e.message
          }

          else if (e.code == "EM82") {
            this.msgEM82 = e.message
          }

          else if (e.code == "EM83") {
            this.msgEM83 = e.message
          }

          else if (e.code == "EM149") {
            this.msgEM149 = e.message
          }
          else if (e.code == "EM150") {
            this.msgEM150 = e.message

          } else if (e.code == "EM157") {
            this.msgEM157 = e.message
          }

        })
      } else {
        this.messagesDataList = [];
      }
    })
  }


  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  nowhiteSpaces(event: any) {
    let charcode = event.keyCode;
    let currentvalue = this.worklocationForm.controls.address1.value;
    let address = this.worklocationForm.controls.address2.value;
    let branch = this.worklocationForm.controls.branch.value;
    if ((currentvalue.length === 0 || address.length === 0 || branch.length === 0) && charcode === 32) {
      return false;
    } else {
      return true;
    }
  }
}
