<div fxLayout="column" fxFill class="custom-investment-of custom-pgr-height-auto first-col">
  <mat-card>
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title">Employee Provident Fund</mat-card-title>
      <div style="margin-right: 2%" *ngIf="isadd">
        <button mat-icon-button (click)="edit()" matTooltip="Edit">
          <i class="fa fa-edit" aria-hidden="true" style="font-size: 25px"></i>
        </button>
      </div>
    </mat-card-header>

    <div fxLayout="column" class="custom-card" ngStyle.xs="padding:5px">
      <form [formGroup]="epfRequestForm" autocomplete="off" style="width: 100%;padding-top:2%">
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
            <div fxFlex="3"></div>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>EPF Number</mat-label>
              <input type="text" matInput formControlName="epfNumber" mask="AA/AAA/0000000/000" minlength="15" />
              <mat-error class="con-error" *ngIf="epfRequestForm.controls.epfNumber.errors?.required">
                Please enter valid data.
              </mat-error>
              <mat-error class="con-error" *ngIf="epfRequestForm.controls.epfNumber.errors?.minlength">
                Please enter valid data
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Deduction Cycle</mat-label>
              <input type="text" [ngClass]="{ disable: true }" [readonly]="true" matInput class="form-control"
                formControlName="deductionCycle" placeholder="" />
            </mat-form-field>
            <div fxFlex="3"></div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
            <div fxFlex="3"></div>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Employee Contribution Rate</mat-label>
              <mat-select [disabled]="enable" formControlName="employeeContribution">
                <ng-container>
                  <div *ngFor="let b of employeecontribution">
                    <mat-option value="{{ b.return_value }}">{{
                      b.display_string
                      }}</mat-option>
                  </div>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Employers Contribution Rate</mat-label>
              <mat-select [disabled]="enable" [ngClass]="{ disable: enable }" formControlName="employersContribution">
                <ng-container>
                  <div *ngFor="let b of employercontribution">
                    <mat-option value="{{ b.return_value }}">{{
                      b.display_string
                      }}</mat-option>
                  </div>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <div fxFlex="3"></div>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="5"></div>
            <div fxFlex="50">
              <section class="example-section">
                <span class="example-list-section">
                  <mat-checkbox class="example-margin" [checked]="allComplete" [color]="employeeContributionCTCColor"
                    (change)="setAll($event.checked, 'employeeContribution')" [disabled]="enable">
                    Include Employer's Contribution in the CTC
                  </mat-checkbox>
                </span>
                <span class="example-list-section" *ngIf="allComplete">
                  <ul formArrayName="checkboxes">
                    <li *ngFor="let subtask of checkboxes; let i = index">
                      <mat-checkbox [formControlName]="i" [color]="subtask.color" [disabled]="enable">
                        {{ subtask.name }}
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </section>
            </div>
            <div fxFlex="50">
              <section>
                <a style="font-weight: bold">PF Configuration when LOP is applied</a>
                <ul>
                  <!-- <li>
                          <mat-checkbox formControlName="pfRestrict">
                          Pro-rate Restricted PF wage
                          </mat-checkbox>

                          </li> -->
                  <li>
                    <mat-checkbox formControlName="pfRestrict" [disabled]="enable"
                      [color]="employeeContributionCTCColor">
                      <!-- <a > Consider all applicable salary components if PF wage is less than ₹{{MaxPfWageForEmployerContribution}} after Loss of Pay</a>
                              <a>PF wage will be computed using the salary earned in that particular month (based on LOP) rather than the actual amount mentioned in the salary structure.</a>  -->
                    </mat-checkbox>
                    <a>
                      Consider all applicable salary components if PF wage is
                      less than ₹{{ MaxPfWageForEmployerContribution }} after
                      Loss of Pay.</a>
                    <a>
                      PF wage will be computed using the salary earned in that
                      particular month (based on LOP) rather than the actual
                      amount mentioned in the salary structure.</a>
                  </li>
                  <li>
                    <a></a>
                  </li>
                </ul>
              </section>
            </div>
            <div fxFlex="3"></div>
          </div>

          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="5"></div>
            <mat-form-field appearance="outline" fxFlex="45" fxFlex.xs="100">
              <mat-label>Effective Date</mat-label>
              <input matInput [matDatepicker]="picker" [disabled]="enable" formControlName="effective_date"
                [min]="mindate" (click)="picker.open()" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div fxLayout="row">
            <p>
              Note:
              <span style="color: red">This configuration of EPF is applicable to all Pay Groups that
                contain EPF as a deduction component.</span>
            </p>
          </div>

          <!-- <span fxLayout="row"   fxLayoutAlign="center center" *ngIf="enable">
                <button class="btn btn-primary mr-2 mb-2" (click)="edit()"   type="submit">
                  <span>Edit</span>
              </button>
            </span>  -->
        </div>

        <!-- <div class="form-group row" *ngIf="!enable">
          <div class="col-sm-6 text-right" style="padding: 0px">
            <button class="btn btn-primary mr-2 mb-2" (click)="setEpf()" type="submit">
              <span>Save</span>
            </button>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
              <span>Cancel</span>
            </button>
          </div>
        </div> -->

        <div fxLayoutAlign="center" *ngIf="!enable">
          <button class="btn btn-primary mr-2 mb-2" [disabled]="btnDisable" [ngClass]="{'disable':btnDisable}"
            (click)="setEpf()" type="submit">
            <span>Save</span>
          </button>
          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
            <span>Cancel</span>
          </button>
        </div>


        <br />
      </form>
    </div>
  </mat-card>
</div>