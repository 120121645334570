<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="title">Employee Summary Report</mat-card-title>
        </mat-card-header>

        <div fxLayout="column">
            <div fxLayout="column" fxLayout.lt-lg="column" fxLayoutAlign.xs="center" class="dev-Pad"
                [formGroup]="searchForm" style="margin-top: 5px;">
                <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxFlex.xs="100%">
                    <mat-form-field fxFlex.xs="100%" fxFlex="30" class="mx-1 my-1" appearance="outline">
                        <mat-label>From Date</mat-label>
                        <input matInput [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                            formControlName="fromDate" (click)="fromDate.open()" placeholder="From Date">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                        <mat-datepicker #fromDate></mat-datepicker>
                        <mat-error class="con-error" *ngIf="searchForm.controls.fromDate.errors?.required"> Please
                            select a
                            date</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex.xs="100%" fxFlex="30" class="mx-1 my-1" appearance="outline">
                        <mat-label>To Date</mat-label>
                        <input matInput [matDatepicker]="toDate" [min]="minToDate" [max]="maxDate"
                            formControlName="toDate" (click)="toDate.open()" placeholder="To Date">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>
                        <mat-error class="con-error" *ngIf="searchForm.controls.toDate.errors?.required">
                            Please select a date
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="center">
                    <span>
                        <button [tabIndex]="4" class="btn btn-primary mr-2 mb-2" (click)="Searchform()">
                            Search
                        </button>

                        <span class="line" style="color:#1898D5;font-size:16px"></span>
                        <button [tabIndex]="5" class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                            <span>Clear</span>
                        </button>
                    </span>
                </div>
            </div>

            <div fxLayout="row xs-column" fxLayoutAlign="end center" *ngIf="List.length > 0" style="margin-right: 3%;">
                <div>
                    <span class="exlprint"> | </span>
                    <button mat-icon-button>
                        <a title="PDF" [tabIndex]="6" (keyup.enter)="generatePDF()"> <img (click)="generatePDF()"
                                src="./assets/images/icons/pdf.png" width="20px" height="20px"></a>
                    </button>
                    <button mat-icon-button>
                        <a title="Excel" [tabIndex]="7" (keyup.enter)="exportAsXLSX()"> <img (click)="exportAsXLSX()"
                                src="./assets/images/icons/excel.png">
                        </a>
                    </button>
                </div>

            </div>
            <div fxLayout="column" class="table-pad">
                <div class="mat-elevation-z1 example-container">
                    <div id="table" #table=''>
                        <table mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef class="no-wrap">S. No.</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="empname">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Employee Name</th>
                                <td mat-cell *matCellDef="let row;">{{row.empname}}</td>

                            </ng-container> -->
                            <ng-container matColumnDef="attendancedate">
                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                                    date:'dd-MM-yyyy'}} </td>
                            </ng-container>
                            <ng-container matColumnDef="firstlogintime">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">First In</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.firstlogintime|
                                    date:'dd-MM-yyyy HH:mm:ss'}} </td>

                            </ng-container>
                            <ng-container matColumnDef="lastlogouttime">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Last Out</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.lastlogouttime |
                                    date:'dd-MM-yyyy HH:mm:ss'}} </td>

                            </ng-container>
                            <ng-container matColumnDef="totalhours">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Total Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.totalhours}} </td>

                            </ng-container>


                            <ng-container matColumnDef="productivehours">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Productive Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.productivehours}} </td>
                            </ng-container>

                            <ng-container matColumnDef="ot">
                                <th mat-header-cell *matHeaderCellDef>OT Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.overtime_minutes}} </td>
                            </ng-container>

                            <ng-container matColumnDef="ph">
                                <th mat-header-cell *matHeaderCellDef>Permission Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.permissionhours}} </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row" (keyup.enter)="openDialog(row)">
                                    <button mat-icon-button (click)="openDialog(row)" [tabIndex]="8">
                                        <a style="color: blue">
                                            <u>Details</u>
                                        </a>
                                    </button>

                                </td>

                            </ng-container>
                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                            </tr>

                        </table>

                    </div>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>