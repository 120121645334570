import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EncryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { EmsService } from '../../ems.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { EmpValidationPopUpComponent } from '../emp-validation-pop-up/emp-validation-pop-up.component';
export interface UserData {
  deptname: string;
  status: string;
  depthead: string;
  headcount: number;
  id: number;
  total: number;
}
@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ]
})
export class EmployeeListComponent implements OnInit {
  companyDBName:any;
  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,private emsService:EmsService,
    private companyService: CompanySettingService,
    private dialog: MatDialog) { 
      this.companyDBName = sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
    }
    @ViewChild(MatExpansionPanel, { static: true })
  matExpansionPanelElement!: MatExpansionPanel;

  employeeDirectoryForm!: UntypedFormGroup;

  employeeList: any = [];
  employeeFilteredList: any = [];
  encriptPipe= new EncryptPipe();
  startTime:any = new Date();

  worklocationDetails: any = [] ;
  locationId:any;
  subscriptionflag:boolean=true;
  activeemployeecount:any;
  ngOnInit(): void {
    this.employeeDirectoryForm=this.formBuilder.group(
      {
      reason: ["",],
      });
      this.getWorkLocation();
      this.getActiveEmployeesCount();
  }
  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyDBName }).subscribe((result) => {
      this.worklocationDetails = result.data;
    })

  }

  newHire() {
    if(!this.subscriptionflag){
      let dialogRef = this.dialog.open(EmpValidationPopUpComponent, {
        width: '800px',position: { top: `100px` },
        disableClose: true,
        data: "Attention! Please note that you are no longer authorized to add new employees. If you want to add new employees, please upgrade your plan or increase the user count in your current plan. For further assistance, please contact your admistrator. Thank you!"
      });
    } else{
     this.router.navigate(["/ems/empInformation"],{state: {}});
    }
  }

  editEmployee(id:any, data:any) {
  
    let empId=this.encriptPipe.transform(data.id.toString());
    this.router.navigate(["/ems/empInformation",{empId}])
 }

 showItem(index: number): boolean {     // Add your delay logic here    
  const delay = 100; // 1 second delay for example    
  return index === 0 || (index > 0 && Date.now() - this.startTime >= index * delay);   
}

  getEmployeeList() {
    this.employeeList =[];
    this.employeeFilteredList =[];
    this.emsService.getEmployeeDirectoryList( this.locationId).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.employeeList = res.data;
        this.employeeFilteredList = this.employeeList.sort((a:any, b:any) => a.ename.localeCompare(b.ename));
    
      }
    })
  }


  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value.toLowerCase().trim();
    const filterTerms = filterValue.split(' ');

    this.employeeFilteredList = this.employeeList.filter((employee: any) =>
      filterTerms.every(term =>
        employee.ename.toLowerCase().includes(term) || 
        employee.location.toLowerCase().includes(term)
      )
    );
  }

  validateExpansion(element:any,index:any)
 
  {
  this.locationId = element.id;

  this.getEmployeeList();   
  }

  selectItem() {
    this.matExpansionPanelElement.close(); //open()
  }

  getClientSubscriptionDetails(){
    this.companyService.getClientSubscriptionDetails().subscribe((data: any) => {
      if (data.status && data.data.length != 0) {
        if(data.data[0].user_count>this.activeemployeecount){
          
          this.subscriptionflag =true;
          
        }
        else{
          this.subscriptionflag =false;
        }
      }
    })
  }

  getActiveEmployeesCount(){
    this.companyService.getActiveEmployeesCount().subscribe((data: any) => {
      if (data.status && data.data.length != 0) {
        this.getClientSubscriptionDetails();
        this.activeemployeecount = data.data[0].active_employees_count;
      }
    });
  }
}