import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { PayrollService } from '../../payroll.service';
import { SelectionModel } from '@angular/cdk/collections';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-variable-pay-distribution',
  templateUrl: './variable-pay-distribution.component.html',
  styleUrls: ['./variable-pay-distribution.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class VariablePayDistributionComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  searchForm: any = UntypedFormGroup;
  managerList: any = [];
  arrayList: any = [];
  minFromDate: Date | undefined;
  maxFromDate: Date | undefined;
  minToDate: Date | null;
  maxToDate: Date | undefined;
  currentDate: Date = new Date();
  List: any[] = [
  ];
  dataSource: MatTableDataSource<any> = <any>[];
  dataSource2: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['select','sno','empid','empname','amount'];
  pageLoading = true;
  searchControl = new FormControl('');
  empSearchControl = new FormControl('');
  filteredEmployees: any = [];
  userSession: any;
  isLoading = false;
  locationList: any = [];
  filteredLocations: any = [];
  companyDBName: any;
  date: any;
  minDate: any = new Date('2000-01-01');
  max = this.getEndOfMonth(new Date());
  monthfirstDay: any;
  monthLastDay: any;
  isCalenderDaysPayroll:boolean=false;
  /**Here select Month and Date from month year picker */
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public datePipe: DatePipe,
    private reportsService: ReportsService,
    private companyService: CompanySettingService,
    public dialog: MatDialog,
    private payrollService: PayrollService,
  ) {

    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate() - 90);
    this.maxFromDate = new Date();
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() - 90);
    this.maxToDate = new Date();
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
   
  }
  selection = new SelectionModel<any>(true, []);
  weekoffs: any = [];
  selectedEmps: any = [];
  workingDays: any = [];
  ngOnInit(): void {
    this.companyDBName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.getPayableDaysConfiguration();
    this.searchForm = this.formBuilder.group({
      employees: [0, Validators.required],
      fromDate: [new Date(), Validators.required],
      toDate: [this.max, Validators.required],
      location: [0, Validators.required],
    });
    /** */
    this.getWorkLocation();
    this.searchForm.get('location')?.valueChanges.subscribe((selectedValue: any) => {
    this.getmanagerList(selectedValue)  
 
    })
    /**This is used for drop down filters */
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });

    this.empSearchControl.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText);
    });

    this.date = new UntypedFormControl(moment())
    let prevDate = new Date();
    prevDate.setDate(0);
    // this.getSearchForm();
  }

  /**Get payroll payable days flag */
  getPayableDaysConfiguration() {
    this.payrollService.getPayableDaysConfiguration().subscribe((res) => {
     if(res.data?.value == 'CALENDAR'){
      this.isCalenderDaysPayroll =true;
     }
    })
  };

  /** Function to get the end of the current month*/
  getEndOfMonth(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0); // Last day of the current month
  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minToDate = event.value;
    if (event.value !== null) {
      this.maxToDate = new Date(
      );
    }
  }
  /**Missed Punches Report*/
  getSearchForm() {
    this.arrayList = [];
    this.isLoading = true;
    let formValue = this.searchForm.value;
    let data = {
      "location_id_value": formValue.location == '0' ? null : formValue.location,
      "manager_id_value": formValue.employees == '0' ? null: formValue.employees,
      "year_value": (this.date.value._d).getFullYear(),
      "month_value": (this.date.value._d).getMonth() + 1
    }
      this.payrollService.getPayableDaysReport(data).subscribe((res: any) => {
        if (res.status) {
          if(this.isCalenderDaysPayroll){
           
            this.isLoading = false;
            this.arrayList = res.data
            this.dataSource = new MatTableDataSource(this.arrayList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.pageLoading = false;
       
          } else {
            this.isLoading = false;
            this.arrayList = res.data
            this.dataSource2 = new MatTableDataSource(this.arrayList);
            this.dataSource2.paginator = this.paginator;
            this.dataSource2.sort = this.sort;
            this.pageLoading = false;
        }
       
      } else{
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.dataSource2 = new MatTableDataSource(this.arrayList);
        this.isLoading = false;
      }
    })
 

  }
  /**Clear Functionality */
  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/variablePayDistribute"]));
  }

  exportAsXLSX() {
     // Format the data to match the required columns
    const exportData = this.arrayList.map((row:any, index:any) => ({
      "S.No": index + 1,
      "Emp.Id":row.empCode,
      "Emp. Name": row.empName,
      "Department": row.department,
      "Designation": row.designation,
      "Presents": row.presents,
      "Absents": row.absents,
      "Leaves": row.leavesCount,
      "Paid Week-off": row.weekOffs,
      "Paid Days": row.payableDays,
      "LOP Days": row.lopdays,
    }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Payable_Days_Report');
    /* save to file */
     XLSX.writeFile(wb,  'Payable_Days_Report for ' +this.datePipe.transform(new Date(), 'MMM yyyy') +'.xlsx');

  }

  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  /**Drop Down Loactions Filter */
  filterLocations(searchText: any) {
    this.filteredLocations = this.locationList.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase()) || 
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) 
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControl.setValue('');
    } else {

    }
  }

  /**Drop Down Employees Filter */
  filterEmployees(searchText: any) {
    this.filteredEmployees = this.managerList.filter(
      (val: any) =>
        val.manager_name.toLowerCase().includes(searchText.toLowerCase()) 
      // ||
      //   val.ename.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployees.length <= 0) {
      this.empSearchControl.setValue('');
    }
  }
  
  getmanagerList(data:any) {
    this.managerList = [];
    let info ={
      location_id: data,
      companyName: this.companyDBName
    }
  
    this.reportsService.getManagersListByLocation(info).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.managerList = [];
        this.managerList = res.data;
        this.filteredEmployees = this.managerList
        this.searchForm.controls.employees.setValue('0');
      }
    })
  }

  /**Get All Active Work Locations */
  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyDBName }).subscribe((result) => {
      this.locationList = result.data;
      this.filteredLocations = this.locationList;
      this.searchForm.controls.location.setValue('0');
      this.getmanagerList(null)
    })
  }
  /**Table Search Functioanlity */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 /** PDF Functionality */
 downloadPDF() {
  let data = {
    'list': this.arrayList,
    'report_date': this.datePipe.transform(new Date(), 'MMM yyyy')
  }
  if(!this.isCalenderDaysPayroll){
  
  this.reportsService.getWorkingPayableDaysReportPdf(data).subscribe(response => {
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Payable_Days_Report for ' +this.datePipe.transform(new Date(), 'MMM yyyy') +'.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
  });
} else {

  this.reportsService.getCalendarPayableDaysReportPdf(data).subscribe(response => {
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Payable_Days_Report for ' +this.datePipe.transform(new Date(), 'MMM yyyy') +'.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
  });
}
}

checkCheckBoxvalue(event: any, data: any) {

  if (event.checked == true) {
    this.weekoffs.push(data.id);
    this.workingDays.forEach((e: any, index: any) => {
      if (e.id == data.id) {
        this.workingDays.splice(index, 1);
      }
    });

  } else {
    this.weekoffs.forEach((a: any, index: any) => {
      if (a == data.id) {
        this.weekoffs.splice(index, 1);
      }
    })
    let obj = { "id": data.id, "name": data.week_name }
    this.workingDays.push(obj);
  }
}
/** Whether the number of selected elements matches the total number of rows. */
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}

/** Selects all rows if they are not all selected; otherwise clear selection. */
masterToggle() {
  // if there is a selection then clear that selection
  if (this.isSomeSelected()) {
    this.selection.clear();
  } else {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }
}

isSomeSelected() {
  return this.selection.selected.length > 0;
}
}
