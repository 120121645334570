<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Invoice History</mat-card-title>
        </mat-card-header>
        <div fxLayout="row"  fxFlex.xs="100%" fxLayoutAlign="end" class="dev-Pad"  style="padding-top: 5px;height: 70px;">
                <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100%" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
        </div>
        <div class="table-pad">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="no-wrap"> S. No. </th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                          </ng-container>
  
                        
                            <!-- billingdate -->
                            <ng-container matColumnDef="billingdate">
                                <th mat-header-cell *matHeaderCellDef > Billing Date </th>
                                <td mat-cell *matCellDef="let element" >{{element.payment_date | date:'dd-MM-yyyy'}} </td>
                            </ng-container>
                              
                                <!-- invoice -->
                            <ng-container matColumnDef="invoice">
                              <th mat-header-cell *matHeaderCellDef > Invoice </th>
                              <td mat-cell *matCellDef="let element"  > {{element.invoiceno}} </td>
                           </ng-container>
                            <!-- amount -->
                            <ng-container matColumnDef="amount">
                              <th mat-header-cell *matHeaderCellDef > Amount </th>
                              <td mat-cell *matCellDef="let element"  > {{element.amount_paid}} </td>
                           </ng-container>
                           <!-- <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef > Status </th>
                              <td mat-cell *matCellDef="let element"  >{{element.payment_status}}</td>
                           </ng-container> -->
                           <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef >  </th>
                              <td mat-cell *matCellDef="let element"  > 
                                <button mat-icon-button (keyup.enter)="view(element)">
                                <mat-icon  class="mr-1"  title="View" (click)="view(element)" >visibility</mat-icon> 
                            </button>
                            </td>
                           </ng-container>
                          
                        
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
        </div>
    </mat-card>
    </div>

