import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogDetailComponent } from 'src/app/modules/reports/pages/dialog-detail/dialog-detail.component';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { environment } from 'src/environments/environment';

export interface UserData {
  deptname: string;
  status: string;
  depthead: string;
  headcount: number;
  id: number;
  total: number;
}

@Component({
  selector: 'app-add-new-dialog',
  templateUrl: './add-new-dialog.component.html',
  styleUrls: ['./add-new-dialog.component.scss'],
})
export class AddNewDialogComponent implements OnInit {
  Form!: UntypedFormGroup;
  designationForm!: UntypedFormGroup;
  departmentData: any = [];
  department: any;
  designation: any;
  enableDepartmentMapping: boolean = false;

  valid: boolean = false;
  userSession: any;
  pipe = new DatePipe('en-US');
  companyDBName: any = environment.dbName;
  availableDepartments: any = [];
  showDepartmentForm: boolean = true;
  designationData: any = [];
  availableDesignations: any = [];
  btnDisable: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private LM: CompanySettingService,
    private dialog: MatDialog,
    private companyService: CompanySettingService
  ) {
    this.getDepartmentsMaster();
    this.getDesignationsMaster();
  }

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      department: ['', [Validators.required]],
    });
    this.designationForm = this.formBuilder.group({
      designation: ['', [Validators.required]],
      department: [''],
    });

    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    if (this.data.addTo === 'Department') {
      this.showDepartmentForm = true;
    } else if (this.data.addTo === 'Designation') {
      this.showDepartmentForm = false;
    }
    this.enableDepartmentMapping = this.data.ismappingEnable;
    this.designationForm.controls.department.setValue(this.data.departmentid)
  }
  setdepartment() {
    if (this.btnDisable) {
      return;
    }
    this.validatedepartments(this.Form.controls.department.value);
    this.department = this.Form.controls.department.value;
    var data = {
      departmentName: this.department,
      created_by: this.userSession.id,
      created_on:
        this.pipe.transform(new Date(), 'yyyy-MM-dd') +
        ' ' +
        this.pipe.transform(new Date(), 'HH:mm:ss'),
      companyDBName: this.companyDBName,
    };
    if (this.Form.valid) {
      if (this.valid) {
        this.btnDisable = true;
        this.LM.setDepartments(data).subscribe((data) => {
          this.valid = false;
          if (data.status) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Department added successfully.',
            });
            this.btnDisable = false;
            this.dialogRef.close('Department');
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to add department. Please try again.',
            });
            this.btnDisable = false;
          }
        });
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Department already exist.',
        });
        this.btnDisable = false;
      }
    }
  }
  cancel() {
    this.dialogRef.close();
  }
  validatedepartments(data: any) {
    if (this.departmentData.length == 0) {
      this.valid = true;
    } else {
      if (this.departmentData.length > 0) {
        for (let i = 0; i < this.departmentData.length; i++) {
          if (
            data.replace(/\s{1,}/g, '').trim().toLowerCase() === this.departmentData[i].deptname.replace(/\s{1,}/g, '').trim().toLowerCase()
          ) {
            this.valid = false;
            break;
          } else {
            this.valid = true;
          }
        }
      }
    }
  }
  getDepartmentsMaster() {
    this.companyService
      .getMastertable('departmentsmaster', 1, 1, 1000, this.companyDBName)
      .subscribe((data) => {
        if (data.status) {
          this.availableDepartments = data.data;
          this.departmentData = this.availableDepartments;
        }
      });
  }

  setdesignations() {
    if (this.designationForm.valid) {
      if (this.btnDisable) {
        return;
      }
      let designationdata = {
        desig_name: this.designationForm.controls.designation.value,
        action_by: this.userSession.id,
        dept_id: this.designationForm.controls.department.value,
      };
      this.btnDisable = true;
      this.LM.setNewDesignationMapping(designationdata).subscribe((data: any) => {
        if (data.status && data.data[0].successstate == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Designation added successfully.',
          });
          this.btnDisable = false;
          this.dialogRef.close('Designation');
        } else if (data.status && data.data[0].successstate == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Designation already exists.',
          });
          this.btnDisable = false;
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to add designation. Please try again.',
          });
          this.btnDisable = false;
        }

      });

    }
  }

  getDesignationsMaster() {
    this.companyService.getMastertable('designationsmaster', 1, 1, 1000, this.companyDBName).subscribe((data) => {
      if (data.status) {
        this.availableDesignations = data.data;
        this.designationData = this.availableDesignations;
      }
    });
  }

}
