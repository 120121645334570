<div fxLayout="column" class="first-col" fxFlex="100">
    <mat-card>
        <div fxLayout="row"  fxFlex.xs="100" fxFlex="100" class="mx-2 my-2"  fxLayoutAlign.xs="center"> 
                
                  <div fxFlex.xs="100" fxFlex="45" class="mx-2 my-2"  fxLayoutAlign.xs="center">
                
                    <div fxLayout="column" >
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" class="clock-attendance" [style.marginTop.px]="mobileQuery.matches ? 0 : 10">
                                <div style="padding-top:10px; color: white;height: 45px;" fxFlex="100" fxLayout="row"
                                    fxLayoutAlign="space-between">
                                    <div fxLayout="row" fxFlex="50" >
                                            <p>  <mat-icon>alarm</mat-icon>  </p>  <p><b> Punch In/Out</b> </p>
                                    </div>
                                    <div fxLayout="row" fxFlex="50" fxLayoutAlign="end center">
                                            <p style="margin-right: 5px;"><b>{{todayDate }}</b></p>
                                            <p style="margin-top: 10px;cursor: pointer;"> <mat-icon (click)="enableCalender()" class="icon" >calendar_month</mat-icon></p>
                                    </div>
                                </div>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="space-between" class="time-attendance">
                            <div fxLayout="row" fxLayoutAlign="space-evenly left" >
                                <button class="btn btn-primary custom mr-2 mb-2" (click)="setPunchInandOut('in')" [disabled]="isWebRestricted" *ngIf="!punchIn && !leaveApplied">Punch In</button>
                                <p *ngIf="punchIn" style="align-self: end;"> Punch In {{punchIn}}</p>
                            </div>
                           
                                <!-- <img *ngIf="imageDataUrl && imageDataUrl.imageData" [src]="imageDataUrl.imageData" alt="Captured Image" style=" width: 50px; height: auto;">    -->
                            <div fxLayout="row" fxLayoutAlign="space-evenly right" >
                                <button class="btn btn-primary custom mr-2 mb-2" (click)="setPunchInandOut('out')"  [disabled]="isWebRestricted"  *ngIf="!punchOut && punchIn">Punch Out</button>
                                <p *ngIf="punchOut" style="align-self: end;">Punch Out {{punchOut}}</p>
                                
                            </div>
                        </div>
                        <div fxLayout="row" *ngIf="(imageDataUrl && imageDataUrl.imageData) || locationTracked" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="space-between" class="time-attendance" style="border-top: groove;">
                            <p *ngIf="imageDataUrl && imageDataUrl.imageData" style="align-self: end;"> Image Uploaded.</p>
                            <p *ngIf="locationTracked" style="align-self: end;"> Location tracked.</p>
                        </div>
                    </div>
                    <div fxLayout="column" *ngIf="show_callender">
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="space-between" >
                            <div fxLayout="column" >
                                <div class='demo-app-main'>
                                    <full-calendar #calendar fxLayout="column" [options]='calendarOptions'>
                                    </full-calendar>
                                </div>
                                <div style="display: flex;padding: 1em" class="example-container">
                                    <mat-icon class="green-icon">check_circle</mat-icon>Present &nbsp;&nbsp;
                                    <mat-icon class="red-icon">check_circle</mat-icon> Absent &nbsp;&nbsp;
                                    <mat-icon class="blue-icon">check_circle</mat-icon><span class="no-wrap">Week-Off</span> &nbsp;&nbsp;
                                    <mat-icon class="yellow-icon">check_circle</mat-icon> Holiday &nbsp;&nbsp;
                                    <mat-icon class="orange-icon">check_circle</mat-icon> Leave
                                </div>
                                <div style="display: flex;padding: 1em" class="example-container">
                                    <mat-icon class="light-icon">check_circle</mat-icon> Half Day &nbsp;&nbsp;
                                    <mat-icon class="wp-icon">check_circle</mat-icon> Week-off Present &nbsp;&nbsp;
                                    <mat-icon class="hp-icon">check_circle</mat-icon> Holiday Present
                             </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" style="padding: 1%;">
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="center"  class="pending-attendance" style="padding-top:10px; color: white;height: 45px;" >
                            <div fxLayout="row" fxLayoutAlign="space-evenly left" >
                                <p> <mat-icon>notifications</mat-icon></p>
                              <p style="align-self: end; margin-top: 5px;color: white;"> Today Follow-ups </p>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="space-evenly right" >
                            <mat-icon *ngIf="showFollowUps" (click)="showFollowUps = !showFollowUps">expand_more</mat-icon>
                            <mat-icon *ngIf="!showFollowUps" (click)="showFollowUps = !showFollowUps">expand_less</mat-icon>
                               
                          </div>
                      </div>
                        <div class="scrollable-div"  *ngIf="showFollowUps"> 
                            <!-- <cdk-virtual-scroll-viewport class="example-viewport" style="background-color: white;"  itemSize="5" *ngIf="showFollowUps"  > -->
                                <div *ngFor="let client of followups"   >
                                    <div  class="card"  style="background-color: ghostwhite;margin: 1%;" *ngIf="showFollowUps" >
                                        <div fxLayoutAlign="space-evenly center"  fxLayout="row" (click)="checkClientDetails(client)"  [ngStyle]="{'background-color': getbackgroundColor(client)}"   style="color:black;font-size: initial;font-weight: 300;">
                                        <div [fxFlex]="'90%'" style="padding: 1%;">
                                            <span  style="font-weight:500;font-size: small;vertical-align: sub;" class=""> <b style="color:olive;">{{getFormattedTime(client.next_follow_up_on) }}</b> <b style="color:darkcyan;">{{client.client_name && client.client_name.toString().length?' | '+client.client_name:''}}</b><b style="color:darkslategrey;">{{client.activity_status && client.activity_status.toString().length?' | '+client.activity_status:''}}</b></span>
                                            <!-- <span  style="    font-weight: bolder;  font-size: larger;">{{client.client_name }}</span><br>  -->
                                            <!-- <span  style="font-weight:300;font-size: small;vertical-align: sub;margin-right:10%"> {{client.activity_status }} </span> -->
                                           
                                        </div>
                                        <div [fxFlex]="'10%'"  >
                                            <span  (click)="selectClient($event,client)"  fxLayoutAlign="end">
                                            <button mat-icon-button matTooltip="" style="color:cadetblue;">
                                                <mat-icon class="icon" >keyboard_arrow_right</mat-icon>
                                            </button>
                                            </span>
                                        </div>
                                        </div>
                                    
                                        <div fxLayoutAlign="space-evenly left"  fxLayout="column" *ngIf="client.display" style="color:black;padding: 2%;font-size: 12px;font-weight: normal;">
                                        
                                        <div >
                                            <span >Created On: </span><span style="font-weight:300;" class="">  {{getFormattedDateTime(client.created_on) }}</span>
                                        </div>
                                        <div  *ngIf="client.notes ">
                                            <span class="">Notes:</span> <span style="font-weight:300;"  class=""> {{client.notes.toString().substring(0,100) }}<b>{{(client.notes.toString().length && client.notes.toString().length > 100) ?'...':''}}</b></span>
                                        </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                    <!-- <mat-list class="time-attendance">
                                            <ng-container *cdkVirtualFor="let item of followups">
                                                    <mat-list-item>  {{ item.client_name  }}    </mat-list-item>
                                                    <mat-list-item>  {{ item.activity_status  }}    </mat-list-item>
                                            </ng-container>
                                    </mat-list> -->
                            <!-- </cdk-virtual-scroll-viewport> -->
                        </div>
                    </div>
                    <div fxLayout="column"   style="padding: 1%;">
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="center"  class="pending-attendance" style="padding-top:10px; color: white;height: 45px;">
                            <div fxLayout="row" fxLayoutAlign="space-evenly left" style="margin-left: 25px;">
                              <p style="align-self: end; margin-top: 5px;color: white;"> Today Activities <span *ngIf="activitiesData && activitiesData.length">
                                ({{activitiesData.length}})
                              </span> </p>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="space-evenly right" >
                            <mat-icon *ngIf="showActivites" (click)="showActivites = !showActivites">expand_more</mat-icon>
                            <mat-icon *ngIf="!showActivites" (click)="showActivites = !showActivites">expand_less</mat-icon>
                              <!-- <p style="align-self: end; margin-top: 5px;color: white;">Total: {{activitiesData.length}}</p> -->
                          </div>
                      </div>
                        
                         
                        <div class="scrollable-div"  *ngIf="showActivites"> 
                      <ng-container *ngFor="let activity of activitiesData;index as i;">
                        <mat-card style=" margin:5px;padding: 1px;background-color: ghostwhite;" class="outline" *ngIf="showActivites">
                              
                            <div fxLayout="row" fxLayoutAlign="space-between" [ngStyle]="{'color': activity.display?'Orange':'black', 'font-weight': activity.display?'500':'300'}" style="padding:2px;text-align: left;" (click)="checkActivityDetails(activity)">
                                <mat-label >{{getFormattedTime(activity.created_on)}}{{activity.client_name && activity.client_name.toString().length?' | '+activity.client_name:''}}</mat-label>
                                    <mat-icon  class="hand-symbol" (click)="fetchActivityLocationOnMap(activity)" *ngIf="activity.activity_type === 'VISIT'">
                                        <i style="position:relative; margin-bottom:-5px;" [ngStyle]="{'color': getLocationIconColorForActivity(activity)}" class="material-icons"> 
                                          {{activity.location_icon_val}} 
                                      </i>  
                                </mat-icon>
                            </div>
                            <div fxLayout="row" *ngIf="activity.display">
                              <div fxLayout="column" fxFlex="100"  style="padding:5px;text-align: left;">  
                                <mat-divider ></mat-divider>
                                <div  fxLayout="column" fxFlex="100" *ngIf="activity && activity.imageData && activity.imageData.image">
                                  <img class="img-fluid custom-image"   [src]="activity.imageData.image">
                                </div> 
                                <mat-divider ></mat-divider>
                                  <mat-label>
                                    <div fxLayout="row" fxFlex="100" *ngIf="activity.activity_status && activity.activity_status.toString().length ">
                                      <span style="font-size: smaller;"> <b  style="font-weight: 500;"> {{activity.activity_type.toString().length?activity.activity_type+' | ':''}}Client {{ activity.client_status}} {{ activity.activity_status?' | '+activity.activity_status:'' }} </b> </span>
                                    </div>
                                  </mat-label>
                                <mat-divider ></mat-divider>
                                  <mat-label>
                                    <div fxLayout="row" fxFlex="100" *ngIf="activity.activity_status && activity.activity_status.toString().length ">
                                      <span style="font-size: smaller;"> <b  style="font-weight: 500;">Follow-up On &nbsp;</b>  {{ getFormattedDateTime(activity.next_follow_up_on) }}</span>
                                    </div>
                                  </mat-label>
                                <mat-divider ></mat-divider>
                                  <mat-label>
                                    <div fxLayout="row" fxFlex="100" *ngIf="activity.notes && activity.notes.toString().length ">
                                      <span style="font-size: smaller;"> <b  style="font-weight: 500;">Notes:&nbsp; </b>{{ activity.notes.toString().substring(0,100) }}<b>{{ (activity.notes && activity.notes.toString().length>100) ?'...':'' }}</b></span>
                                    </div>
                                  </mat-label>
                                  <mat-divider></mat-divider>
                                  <mat-label >
                                    <div fxLayout="row" fxFlex="100" *ngIf="activity.location && activity.location.toString().length ">
                                      <span style="font-size: smaller;"> <b  style="font-weight: 500;">Location:&nbsp; </b>{{ activity.location }}</span>
                                    </div>
                                   
                                  </mat-label>
                              </div>
                            </div>
                        </mat-card>
                      </ng-container>
                        </div>
                    </div>
                </div>
                <div class="sub-section-2" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayout="column" fxFlex="45" *ngIf="false">
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" class="clock-attendance" [style.marginTop.px]="mobileQuery.matches ? 0 : 10">
                        <div style="padding-top:10px; color: white;height: 45px;" fxFlex="100" fxLayout="row"
                            fxLayoutAlign="space-between">
                            <div fxLayout="row" fxFlex="50" >
                                    <p><b>{{todayDate }}</b></p>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="companyForm" autocomplete="off">
                        <div fxLayout="row" >
                            <mat-form-field fxFlex    appearance="outline">
                                <mat-label>Organization</mat-label>
                                <input  formControlName="companyname" matInput placeholder="" required  onlyAlphabets >
                                <mat-error class="con-error" *ngIf="  companyForm.controls.companyname.errors?.required">
                                    Please Enter Organization
                                    </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" >
                            <mat-form-field fxFlex    appearance="outline">
                            <mat-label>Contact Person</mat-label>
                            <input  formControlName="contact_person_name" matInput placeholder="" required onlyAlphabets >
                                <mat-error class="con-error" *ngIf="  companyForm.controls.contact_person_name.errors?.required">
                                    Please Enter Person Name
                                    </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex    appearance="outline">
                            <mat-label>Email</mat-label>
                            <input  formControlName="email" matInput placeholder="" required  >
                            <mat-error class="con-error" *ngIf="  companyForm.controls.email.errors?.required">
                                Please Enter email address
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex    appearance="outline">
                            <mat-label>Contact Number</mat-label>
                            <input  formControlName="contact" matInput placeholder="" required  
                            onlyNumber >
                                <mat-error class="con-error" *ngIf="  companyForm.controls.contact.errors?.required">
                                    Please Enter contact number
                                    </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex    appearance="outline">
                            <mat-label>address1</mat-label>
                            <input  formControlName="address1" matInput placeholder="" required>
                            <mat-error class="con-error" *ngIf="  companyForm.controls.address1.errors?.required">
                                Please Enter contact number
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex    appearance="outline">
                            <mat-label>address2</mat-label>
                            <input  formControlName="address2" matInput placeholder=""  >
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field   fxFlex  appearance="outline">
                            <mat-label>Country </mat-label>
                                <mat-select    formControlName="country" required  [tabIndex]="2">
                                    <ng-container >
                                        <div *ngFor="let b of CountryDetails">
                                            <mat-option    [value]="b.id">{{b.country}}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="  companyForm.controls.country.errors?.required">
                                        select country name
                                </mat-error>
                        </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field fxFlex appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select   required formControlName="state"  >
                                    <ng-container >
                                        <div *ngFor="let b of stateDetails">
                                            <mat-option   [value]="b.id">{{b.state}}</mat-option>
                                        </div>
            
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="  companyForm.controls.state.errors?.required">
                                    select state
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field  fxFlex  appearance="outline">
                                <mat-label>City</mat-label>
                                <mat-select required  formControlName="city" >
                                    <ng-container >
                                        <div *ngFor="let b of cityDetails">
                                            <mat-option [value]="b.id">{{b.location}}</mat-option>
                                        </div>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="con-error" *ngIf="  companyForm.controls.city.errors?.required">
                                    select location  </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
                            <mat-form-field  fxFlex  appearance="outline">
                                <mat-label>Pincode</mat-label>
                                <input type="text" onlyNumber formControlName="pincode" matInput  placeholder="" minlength="6" maxlength="6" required>
                                <mat-error class="con-error" *ngIf="  companyForm.controls.pincode.errors?.required">
                                enter pincode
                                </mat-error>
                                <mat-error class="con-error" *ngIf="  companyForm.controls.pincode.errors?.maxlength || companyForm.controls.pincode.errors?.minlength">
                                    This field length should be 6 digits
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- <div fxLayout="row" >
                            <mat-form-field fxFlex    appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select required  formControlName="client_status" >
                                <ng-container >
                                    <div *ngFor="let b of clientStatusVal">
                                        <mat-option [value]="b.id">{{b.display_name}}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="  companyForm.controls.client_status.errors?.required">
                                select status  </mat-error>
                            </mat-form-field>
                        </div> -->
                        <!-- <div fxLayout="row" >
                            <mat-form-field fxFlex    appearance="outline">
                            <mat-label>Demo Status</mat-label>
                            <mat-select required  formControlName="demo_status" >
                                <ng-container >
                                    <div *ngFor="let b of demoStatusVal">
                                        <mat-option [value]="b.id">{{b.display_name}}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="  companyForm.controls.demo_status.errors?.required">
                                select status  </mat-error>
                            </mat-form-field>
                        </div> -->
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" appearance="outline">
                            <!-- <button class="btn btn-outline-primary custom mr-2 mb-2" (click)="takePicture()" style=" min-width: -webkit-fill-available;">  -->
                                <button class="btn btn-outline-primary custom mr-2 mb-2" (click)="openWebcamModal()"  style=" min-width: -webkit-fill-available;"> 
                                <mat-label>Capture Photo</mat-label>
                            </button>
                        </div>
                        <div fxLayoutAlign="center" >
                            <button [tabIndex]="7" class="btn btn-primary mr-2 mb-2"  (click)="saveNewClient()" type="button">
                            <span>Submit</span>
                        </button>
                        <button [tabIndex]="8" class="btn btn-danger mr-2 mb-2" (click)="Cancel()" type="submit">
                            <span>Cancel</span>
                        </button>
                        </div>
                    </form>
                </div>
                <div fxFlex="55" fxFill>
                    <div fxLayout="row" class="clock-attendance" [style.marginTop.px]="mobileQuery.matches ? 0 : 10" >
                        <div style="padding-top:10px; color: white;height: 45px;" fxFlex="100" fxLayout="row"
                            fxLayoutAlign="space-between">
                            <div fxLayout="row" fxFlex="50" fxLayoutAlign="end center">
                            </div>
                            <div >
                                <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center"  style="padding-right: 60px;text-align: right;" >
                                    <button mat-icon-button (click)="selectAddClient()"  [disabled]="isWebRestricted == 1" matTooltip="Add Client" text="Add Client">Add Client 
                                                           <mat-icon style="font-size: 25px">add_circle</mat-icon>
                                    </button>
                                </mat-card-header>
                            </div>
                            
                        </div>
                    </div>
                    <!-- <div fxLayout="row" class="clock-attendance" [style.marginTop.px]="mobileQuery.matches ? 0 : 10"  *ngIf="isNewclient">
                        <div style="padding-top:10px; color: white;height: 45px;" fxFlex="100" fxLayout="row"
                            fxLayoutAlign="space-between">
                            <div fxLayout="row" fxFlex="50" fxLayoutAlign="end center">
                            </div>
                            <div *ngIf="!isNewclient">
                                <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center" style="padding-right: 60px;text-align: right;" >
                                    <button mat-icon-button (click)="isNewclient = !isNewclient" matTooltip="Add Client" text="Add Client">Add Client
                                        <mat-icon style="font-size: 25px">add_circle</mat-icon>
                                    </button>
                                </mat-card-header>
                            </div>
                            
                        </div>
                    </div> -->
                    <!-- <div id="map" *ngIf="apiLoaded | async" > -->
                        <div style="height:500px ; width: 560px; position: relative; overflow: hidden;" *ngIf="mapInitialized">
                            <!-- <google-map [center]="center" [height]="500" [width]="560"  [options]="mapOptions" >
                                <map-marker #marker="mapMarker" *ngFor="let markerPosition of markerPositions"  [position]="markerPosition"
                                (mapClick)="openInfo(marker,markerPosition.description)">
                                <map-info-window>{{infoContent}}</map-info-window>
                                </map-marker>
                            </google-map> -->
                            <google-map [center]="center" height="100%" width="100%"  [options]="mapOptions" >
                                <!-- <map-traffic-layer [autoRefresh]="true"></map-traffic-layer> -->
                                <!-- <map-directions-renderer  #directionsRender *ngFor="let directionResult of directionsResults"  [options]="directionsRenderOption" [directions]="directionResult"></map-directions-renderer> -->
                                  <map-marker #marker="mapMarker" *ngFor="let markerPosition of markerPositions"  [position]="markerPosition"  
                                   [label]="markerPosition.label" [icon]="markerPosition.icon" (mapMouseover)="openInfoWindow(marker,markerPosition)"
                                   (mapMousedown)="closeMouseOver(markerPosition)">
                                    <map-info-window  fxLayout="row" style="text-align: center;">
                
                                      <div *ngIf="markerSelected && markerSelected.markerInfo && markerSelected.markerInfo.length "  >
                                            
                                          <div *ngFor="let mi of markerSelected.markerInfo"  fxLayout="column">
                                              <div fxLayout="column" style="width: 190px;" > 
                                                 <span  (click)="changeDisplayStatus(mi)"  style="width: 190px;"  [ngStyle]="{'color':mi.markerColor}" [ngClass]="getMarkerClass(mi)" >  {{selectedEmployee?'':mi.empname}} {{!selectedEmployee && mi.mapActivity?' | ':''}}  {{mi.mapActivity?mi.mapActivity:''}}{{mi.mapActivity && mi.time?' | ':''}}  {{mi.time }}  </span>
                                                 <mat-divider ></mat-divider>
                                              </div>
                                                <div *ngIf="mi.display"  fxLayout="column">
                                                  <div fxLayout="column"  [ngStyle]="{'color':mi.markerColor}" style="width: 190px;"> 
                                                      <div fxLayout="column"   *ngIf="mi.client && mi.client.toString().length" style="width: 190px;" > 
                                                        <span  style="width: 190px;"> Client:<b>{{mi.client}}</b> </span>
                                                    </div>
                                                    <div fxLayout="column"  *ngIf="mi.checkImage && mi.imageDisplayOnMap" ><img  [src]="mi.displayImage" style="width: 100px;margin: auto;" >
                                                    </div>
                                                    
                                                    <div fxLayout="column" style="width: 190px;" *ngIf="mi.infoContent && mi.infoContent.toString().length"> 
                                                      <span  style="width: 190px;font-weight: bold;font-size: x-small">Notes: {{mi.infoContent}}</span>
                                                    </div>
                                                    <mat-divider ></mat-divider>
                                                    
                                                </div>
                                              </div>
                                            </div>
                                            <div fxLayout="column"   *ngIf="markerSelected.markerInfo[0].location && markerSelected.markerInfo[0].location.toString().length" style="width: 190px;" > 
                                              <span  style="width: 190px;font-size: x-small;"> {{markerSelected.markerInfo[0].location}}</span>
                                            </div>
                                        </div>
                                    </map-info-window>
                                  </map-marker>
                              </google-map>
                        </div>
                    <!-- </div> -->
                    <div>
                        <video #videoElement autoplay *ngIf="displayVideo"></video>
                        <!-- <button (click)="takePicture()">Capture Photo</button> -->
                        <canvas #canvasElement style="display:none;"></canvas>
                      </div>
                </div>
            </div>
    </mat-card>
</div>


