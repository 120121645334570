import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from 'src/environments/environment';

import * as _moment from 'moment';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { ShitConfigureHistoryDialogComponent } from 'src/app/modules/attendance/pages/shit-configure-history-dialog/shit-configure-history-dialog.component';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-rotational-shift-reassign',
  templateUrl: './rotational-shift-reassign.component.html',
  styleUrls: ['./rotational-shift-reassign.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RotationalShiftReassignComponent implements OnInit {

  requestform: any = UntypedFormGroup;
  searchform: any = UntypedFormGroup;
  minFromDate: Date;
  maxFromDate: Date | null;
  currentDate: Date = new Date();
  pipe = new DatePipe('en-US');
  displayedColumns: string[] = ["select", 'id', 'empid', 'empname', 'currentshift','sequencename' ,'rotationShift'];
  dataSource: MatTableDataSource<any> = <any>[];
  ATT74: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchControl = new FormControl('');
  searchControlforShift = new FormControl('');
  searchControlforSequenceShift = new FormControl('');
  today = new Date();
  minDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1);
  arrayList: any = [];
  userSession: any;
  employeesData: any;

  selectedEmps: any = [];

  pageLoading = true;
  messagesDataList: any = [];
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  recordExist: any;
  companyDBName: any = environment.dbName;
  availableLocations: any = [];
  filteredAvailableLocations: any = [];
  isShow = false;
  filteredShiftSequenceList: any = [];
  btnDisable: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private attendanceService: AttendanceService,
    public dialog: MatDialog, public datePipe: DatePipe, private router: Router,
    private companyService: CompanySettingService, private adminService: AdminService
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate() + 1);
    this.maxFromDate = new Date();
    this.maxFromDate.setDate(this.currentDate.getFullYear() + 1);
    this.arrayList = [];

  }
  selection = new SelectionModel<any>(true, []);
  shifDetails: any;
  finaldate: any;
  //******************** */
  managerId: any;
  rotationShiftList: any = [];
  locationId: any;
  assignedRotationEmployeeList: any = [];
  filteredSequenceList: any = [];
  shiftSequenceList: any = [];
  ngOnInit(): void {
    this.managerId = this.userSession.id;
    this.getMessagesList();

    this.searchform = this.formBuilder.group(
      {
       
      });
    this.requestform = this.formBuilder.group(
      {
        shift: ['', Validators.required],
        sequenceShift: ['', Validators.required],
        fromDate: [this.minDate],
        location: ['', Validators.required],

      });
    this.getLocations();
    this.requestform.get('location')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue) {
        this.locationId = selectedValue;
        this.getShiftSequenceList();
        this.getEmployeesForRotationShiftAssignment();
      }
    });
    this.searchControl.valueChanges.subscribe((searchText: any) => {
      this.filterLocations(searchText);
    });
    this.searchControlforShift.valueChanges.subscribe(searchText => {
      this.filterShift(searchText);
    })
    this.searchControlforSequenceShift.valueChanges.subscribe(searchText => {
      this.filterSequenceShift(searchText);
    })
  }

  ngAfterViewInit() {
  }
  get f(): { [key: string]: AbstractControl } {
    return this.requestform.controls;
  }

  shiftChange(shiftId: any) {
    this.shiftSequenceList = [];
    this.attendanceService.getShiftsForRotationSequence(shiftId).subscribe((res) => {
      if (res.status) {
        this.shiftSequenceList = JSON.parse(res.data[0].shift_sequence);
        this.filteredSequenceList = this.shiftSequenceList
      }
    });
  }

  getShiftSequenceList() {
    // if(this.searchform.valid){
    this.rotationShiftList = [];
    this.isShow = true;
    let data = {
      manager_id_value: this.userSession.id,
      location_id_value: this.locationId,
    }
    this.attendanceService.getRotationShiftSequence(data).subscribe((res) => {
      if (res.status) {
        this.rotationShiftList = res.data;
        this.filteredShiftSequenceList = this.rotationShiftList;
      }
    });
    // }
  }
  getEmployeesForRotationShiftAssignment() {
    this.isShow = true;
    let data = {
      manager_employee_id: this.userSession.id,
      location_id_value: this.locationId,
    }
    this.attendanceService.getEmployeeRotationShiftReMapping(data).subscribe((res) => {
      if (res.status) {
        this.arrayList = res.data;
        this.dataSource = new MatTableDataSource(this.arrayList);
        this.pageLoading = false;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  submit() {
    if (this.requestform.valid) {
      if (this.btnDisable) {
        return;
      }
      if (this.selection.selected.length > 0) {
        this.selectedEmps = [];
        this.selection.selected.forEach((e: any) => {
          this.selectedEmps.push(e.employeeid);
        });
        let info = {
          "crssm_id_value": this.requestform.controls.shift.value,
          "employee_id_value": JSON.stringify(this.selectedEmps),
          "user_id_value": this.userSession.id,
          "start_shift_id_value": this.requestform.controls.sequenceShift.value,
          "applicable_date_value": this.pipe.transform(new Date(this.requestform.controls.fromDate.value ?? ''), 'yyyy-MM-dd'),

        };
        this.btnDisable = true;
        this.attendanceService.setEmployeeRotationShiftReMapping(info).subscribe((res) => {
          if (res.status) {
            let resMessage: any;
            if (res.data.successstate == "0") {
              resMessage = "Rotation shift reassigned successfully"
            } else {
              resMessage = "Unable to reassign rotation shift"
            }
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage
            });
            this.btnDisable = false;
            this.resetform();
          }
        })
      } else {
        this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Select atleast one employee."
        });
        this.btnDisable = false;
        return;
      }
    }
  }

  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/reassignShiftRotation"]));

  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 100
    }
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "ATT2") {
            this.requiredOption = e.message
          } else if (e.code == "ATT69") {
            this.dataSave = e.message
          } else if (e.code == "ATT70") {
            this.dataNotSave = e.message
          } else if (e.code == "ATT74") {
            this.ATT74 = e.message
          }
        })
      }
      else {
        this.messagesDataList = [];
      }

    })
  }

  getLocations() {
    this.attendanceService.getLocationsForReportingManager(this.managerId).subscribe(res => {
      if (res.status) {
        this.availableLocations = res.data;
        this.filteredAvailableLocations = this.availableLocations;
      }
    })
  }

  view(data: any) {
    let givenData = {
      "empId": data.empid,
      "empCode": data.emp_code,
      "empName": data.empname,
    }
    let dialogRef = this.dialog.open(ShitConfigureHistoryDialogComponent, {
      width: '500px', position: { top: `100px` },
      disableClose: true,
      data: givenData
    });
    this.clearValidators();
  }

  addValidators() {
    this.requestform.controls.shift.setValidators([Validators.required])
    this.requestform.controls.fromDate.setValidators([Validators.required])
    this.requestform.get('shift').updateValueAndValidity();
    this.requestform.get('fromDate').updateValueAndValidity();
  }


  clearValidators() {
    this.requestform.reset();
    this.requestform.get('shift').clearValidators();
    this.requestform.get('shift').updateValueAndValidity();
    this.requestform.get('fromDate').clearValidators();
    this.requestform.get('fromDate').updateValueAndValidity();

  }
  filterLocations(searchText: any) {
    this.filteredAvailableLocations = this.availableLocations.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredAvailableLocations.length <= 0) {
      this.searchControl.setValue('')
    }
  }
  filterShift(searchText: any) {
    this.filteredShiftSequenceList = this.rotationShiftList.filter((val: any) =>
      val.shift_sequence_name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredShiftSequenceList.length <= 0) {
      this.searchControlforShift.setValue('');
    }
  }

  getRotationOrders(seqValue: string) {
    if (!seqValue) return '';
    const shifts = JSON.parse(seqValue);
    return shifts.map((shift: any) => `${shift.shiftName} - ${shift.duration} days`).join('<br/>');


  }

  filterSequenceShift(searchText: any) {
    this.filteredSequenceList = this.shiftSequenceList.filter((val: any) =>
      val.shift_sequence_name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredSequenceList.length <= 0) {
      this.searchControlforShift.setValue('');
    }
  }
  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}